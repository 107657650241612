enum BidState {
	ACCEPTED = "ACCEPTED",
	REJECTED = "REJECTED",
	OPEN = "OPEN",
  NOT_APPLICABLE = "NOT_APPLICABLE"
}

export default BidState;

export function bidStateFromString(bidState: string): BidState {
  switch (bidState) {
    case "ACCEPTED": 
      return BidState.ACCEPTED;
    case "REJECTED":
      return BidState.REJECTED;
    case "OPEN":
      return BidState.OPEN;
    case "NOT_APPLICABLE":
      return BidState.NOT_APPLICABLE;
    default: 
      return null;
  }
}

export function bidStateToString(bidState: BidState): string {
  switch (bidState) {
    case BidState.OPEN: 
      return "Open";
    case BidState.REJECTED:
      return "Afgewezen";
    case BidState.ACCEPTED:
      return "Geaccepteerd";
    case BidState.NOT_APPLICABLE:
      return "Niet van toepassing";
    default:
      return "";
  }
}

export const ALL_BID_STATES = [
  BidState.ACCEPTED,
  BidState.REJECTED,
  BidState.OPEN,
  BidState.NOT_APPLICABLE
]