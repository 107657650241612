import * as Yup from 'yup';
import LocationType from './location.type';
import ProductType from '../item/product.type';
import { DEFAULT_MAP_RADIUS } from '../constants';
import { SortBy, SortDirection } from './sort.direction';
import { PriceType } from '../../gropp/items/new/price.type';
import OfferType, { ALL_OFFER_TYPES } from '../offer/offer.type';

export interface EditProfileValues {
  street: string,
  postalCode: string,
  city: string,
  phoneNumber: string,
  emailAddress: string,
  showLastName: boolean,
  showAddress: boolean,
  showBirthDate: boolean,
  showPhoneNumber: boolean,
  showCoordinates: boolean,
  showKvkNumber: boolean,
  showStatistics: boolean
}

export class EditProfileValidator {
    public static initialValues: EditProfileValues = {
      street: "",
      postalCode: "",
      city: "",
      phoneNumber: "",
      emailAddress: "",
      showLastName: false,
      showAddress: false,
      showBirthDate: false,
      showPhoneNumber: false,
      showCoordinates: false,
      showKvkNumber: false,
      showStatistics: false
    };

    public static validationSchema = Yup.object({

    });
}

interface ReviewValues {
  reviewText: string
}

export class ReviewValidator {
  public static initialValues: ReviewValues = {
    reviewText: ""
  }

  public static validationSchema = Yup.object({
    reviewText: Yup.string().required("Schrijf een review")
  });

  public static cleanInput(input: any): any {
    return {};
  }
}

interface ChangePasswordValues {
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
}

export class ChangePasswordValidator {
  public static initialValues: ChangePasswordValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  };

  public static validationSchema = Yup.object({
    oldPassword: Yup.string()
      .required("Vul je oude wachtwoord in"),
    newPassword: Yup.string()
      .required('Vul je nieuwe wachtwoord in')
      .test('not-equal-to-old', "Nieuw mag niet gelijk zijn aan oude wachtwoord",
        function (value) {
          return this.parent.oldPassword !== value;
        }),
    confirmPassword: Yup.string()
      .required("Vul je nieuwe wachtwoord nogmaals in")
      .test('passwords-match', 'Wachtwoord komt niet overeen', 
        function (value) {
          return this.parent.newPassword == value;
        })
  });

  public static cleanInput(input: any): any {
    return {
      "oldPassword": input.oldPassword,
      "newPassword": input.newPassword
    };
  }

  public static resetForm(formik) {
    formik.values.oldPassword = formik.initialValues.oldPassword;
    formik.values.newPassword = formik.initialValues.newPassword;
    formik.values.confirmPassword = formik.initialValues.confirmPassword;

    formik.isValid = true;
    formik.dirty = false;

    formik.touched.oldPassword = false;
    formik.touched.newPassword = false;
    formik.touched.confirmPassword = false;

    formik.errors.oldPassword = null;
    formik.errors.newPassword = null;
    formik.errors.confirmPassword = null;
  }
}

interface NewItemInitialValues {
  title: string,
  brand: string,
  productType: ProductType.WINE,
  itemYear: string,
  description: string,
  visible: boolean,
  priceType: PriceType,
  nrItems: string,
  fixedPrice: string,
  auctionDays: string,
  auctionHours: string,
  auctionMinutes: string,
  auctionHasStartPrice: boolean,
  auctionStartPrice: string,
  hasSimpleStartPrice: boolean,
  simpleStartPrice: string,
  images: any[]
}

export class NewItemValidator {
  public static initialValues: NewItemInitialValues = {
    title: "",
    brand: "",
    nrItems: "1",
    productType: ProductType.WINE,
    itemYear: "",
    description: "",
    visible: true,
    priceType: PriceType.FIXED_PRICE,
    fixedPrice: "",
    auctionDays: "",
    auctionHours: "",
    auctionMinutes: "",
    auctionHasStartPrice: false,
    auctionStartPrice: "",
    hasSimpleStartPrice: false,
    simpleStartPrice: "",
    images: []
  };

  public static validationSchema = Yup.object({
    title: Yup.string()
      .required('Vul een titel in'),
    brand: Yup.string()
      .required("Vul een merk in"),
    nrItems: Yup.number()
      .typeError("Voer een geldig aantal in")
      .required("Voer een geldig aantal in")
      .min(1, "Voer een geldig aantal in"),
    auctionDays: Yup.string()
      .test({
        name: "auctionDuration",
        exclusive: false,
        params: {},
        message: "Voer een geldige veilingduur in",
        test: function (value) {
            if (this.parent.priceType == PriceType.AUCTION) {
              return checkValidAuctionDuration(
                value, 
                this.parent.auctionHours, 
                this.parent.auctionMinutes);
            } else {
              return true;
            }
        },
      }),
    auctionHours: Yup.string()
      .test({
        name: "auctionDuration",
        exclusive: false,
        params: {},
        message: "Voer een geldige veilingduur in",
        test: function (value) {
            if (this.parent.priceType == PriceType.AUCTION) {
              return checkValidAuctionDuration(
                this.parent.auctionDays, 
                value,
                this.parent.auctionMinutes);
            } else {
              return true;
            }
        },
      }),
    auctionMinutes: Yup.string()
      .test({
        name: "auctionDuration",
        exclusive: false,
        params: {},
        message: "Voer een geldige veilingduur in",
        test: function (value) {
            if (this.parent.priceType == PriceType.AUCTION) {
              return checkValidAuctionDuration(
                this.parent.auctionDays,
                this.parent.auctionHours, 
                value);
            } else {
              return true;
            }
        },
      }),
    itemYear: Yup.string()
      .optional()
      .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/, "Vul een geldig jaartal in")
      .test({
        name: "itemYear",
        exclusive: false,
        params: {},
        message: "Voer een geldig jaartal in",
        test: function (value) {
          if (value) {
            if (value.indexOf(".") != -1 || value.indexOf(",") != -1) {
              return false;
            } else {
              var parsed = parseInt(value);

              if (isNaN(parsed)) {
                return false;
              } else {
                return parsed >= 1900 && parsed <= new Date().getFullYear();
              }
            }
          } else {
            return true;
          }
        },
      }),
    fixedPrice: Yup.number()
      .typeError("Vul een geldig bedrag in")
      .when("priceType", (x, schema) => {
        if (x && x.length > 0 && x[0] == "FIXED_PRICE") {
            return schema
              .required("Vul een geldig bedrag in")
              .typeError("Vul een geldig bedrag in")
              .min(0, "Vul een geldig bedrag in");
        } else {
          return schema;
        }
      }),
    auctionHasStartPrice: Yup.boolean(),
    auctionStartPrice: Yup.number()
      .typeError("Vul een geldig bedrag in")
      .min(0, "Vul een geldig bedrag in")
      .when("priceType", (x, schema) => {
        var elem = (document
          .getElementById("auctionHasStartPrice") as any);

        if (elem) {
          var auctionHasStartPriceChecked = elem.checked;

          if (x && x.length > 0 && x[0] == "AUCTION" && auctionHasStartPriceChecked) {
              return schema
                .required("Vul een geldig bedrag in")
                .typeError("Vul een geldig bedrag in")
                .min(0, "Vul een geldig bedrag in");
          } else {
            return schema;
          }
        } else {
          return schema;
        }
      }),
    hasSimpleStartPrice: Yup.boolean(),
    simpleStartPrice: Yup.number()
      .typeError("Vul een geldig bedrag in")
      .when("priceType", (x, schema) => {
        var elem = (document
          .getElementById("hasSimpleStartPrice") as any);

        if (elem) {
          var hasSimpleStartPriceChecked = elem.checked;

          if (x && x.length > 0 && x[0] == "ALLOW_BIDDING" && hasSimpleStartPriceChecked) {
            return schema
              .required("Vul een geldig bedrag in")
              .typeError("Vul een geldig bedrag in")
              .min(0, "Vul een geldig bedrag in");
          } else {
            return schema;
          }
        } else {
          return schema;
        }
      }),
    productType: Yup.string()
      .required(),
    description: Yup.string()
      .required("Vul een omschrijving in"),
    visible: Yup.boolean()
      .required()
  });

  public static cleanInput(input: any): any {
    var data = JSON.parse(JSON.stringify(input));

    if (data["radio-button-price"]) {
      data["priceType"] = data["radio-button-price"];
    }

    for (var key of Object.keys(data)) {
      if (key.startsWith("radio-")) {
        delete data[key];
      }
    }

    var visibleCheckBox = (document
      .getElementById("visible") as any);

    if (visibleCheckBox) {
      data["visible"] = visibleCheckBox.checked;
    }

    if (data.priceType == "AUCTION") {
      var days = parseInt(data["auctionDays"]) * 24 * 60 * 60;
      var hours = parseInt(data["auctionHours"]) * 60 * 60;
      var minutes = parseInt(data["auctionMinutes"]) * 60;

      if (isNaN(days)) {
        days = 0;
      }

      if (isNaN(hours)) {
        hours = 0;
      }

      if (isNaN(minutes)) {
        minutes = 0;
      }

      var auctionDuration = days + hours + minutes;
      data["auctionDuration"] = auctionDuration;

      if (data["auctionStartPrice"]) {
        data["startPrice"] = parseFloat(data["auctionStartPrice"]);
      }
    }

    if (data.priceType == "FIXED_PRICE") {
      var fixedPrice = parseFloat(data["fixedPrice"]);

      if (!isNaN(fixedPrice)) {
        data["price"] = fixedPrice;
      }
    }

    if (data.priceType == "ALLOW_BIDDING") {
      if (data["simpleStartPrice"]) {
        data["startPrice"] = parseFloat(data["simpleStartPrice"]);
      }
    }

    var nrItems = data["nrItems"];
    delete data["nrItems"];
    var parsedNrItems = parseInt(nrItems);

    if (!isNaN(parsedNrItems)) {
      data["nrItems"] = parsedNrItems;
    }

    if (Object.keys(data).indexOf("itemYear") != -1) {
      data["itemYear"] = parseInt(data["itemYear"]);
    }

    if (isNaN(data["itemYear"])) {
      delete data["itemYear"];
    }

    delete data["radio-button-price"];
    delete data["auctionDays"];
    delete data["auctionMinutes"];
    delete data["auctionHours"];
    delete data["auctionHasStartPrice"]
    delete data["auctionStartPrice"];
    delete data["hasSimpleStartPrice"];
    delete data["simpleStartPrice"];
    delete data["fixedPrice"];

    return data;
  }
}

interface SearchInitialValues {
  name: string;
  offerTypes?: OfferType[];
  minYear?: string;
  maxYear?: string;
  minPrice?: string;
  maxPrice?: string;
  locationType?: LocationType;
  location?: string;
  distance?: string;
  brand?: string;
  productTypes?: ProductType[];
  provider?: string;
  onlineSince?: string;
  sortBy?: SortBy;
  sortDirection?: SortDirection;  
};

export class SearchValidator {
  public static initialValues: SearchInitialValues = {
    name: "",
    offerTypes: ALL_OFFER_TYPES,
    minPrice: "",
    maxPrice: "",
    minYear: "",
    maxYear: "",
    locationType: LocationType.NO_LOCATION,
    location: "",
    distance: "" + DEFAULT_MAP_RADIUS,
    brand: "",
    productTypes: [],
    provider: "",
    onlineSince: "",
    sortBy: SortBy.CREATE_TIME,
    sortDirection: SortDirection.DESC
  };

  public static validationSchema = Yup.object({
    name: Yup.string().optional().nullable(),
    brand: Yup.string().optional().nullable(),
    offerTypes: Yup.array(Yup.mixed<PriceType>()
      .oneOf(Object.values(PriceType)).required())
      .min(1, "Selecteer minimaal 1 type aanbieding")
      .max(3, "Selecteer maximaal 3 typen aanbiedingen"),
    minPrice: Yup.number().optional().nullable()
      .typeError("Voer een geldige minimumprijs in")
      .min(0, "De minimumprijs kan niet lager zijn dan 0"),
    maxPrice: Yup.number().optional().nullable()
      .typeError("Voer een geldige maximumprijs in")
      .moreThan(Yup.ref("minPrice"), "De maximumprijs moet hoger zijn dan de minimumprijs"),
    minYear: Yup.number().optional().nullable()
      .typeError("Voer een geldig startjaar in")
      .min(1900, "Het startjaar kan niet lager zijn dan 1900")
      .max(new Date().getFullYear(), "Het startjaar kan niet groter zijn dan " + (new Date().getFullYear())),
    maxYear: Yup.number().optional().nullable()
      .typeError("Voer een geldig eindjaar in")
      .max(new Date().getFullYear(), "Het eindjaar kan niet groter zijn dan " + (new Date().getFullYear()))
      .moreThan(Yup.ref("minYear"), "Het eindjaar moet groter zijn dan het startjaar"),
    locationType: Yup.string().optional(),
    location: Yup.string().optional().nullable()
      .when("locationType", (x, schema) => { 
        if (x && x.length > 0 && x[0] == "SPECIFIC_LOCATION") {
            return schema.required("Vul een specifieke locatie in");
        } else {
          return schema;
        }
      })
  });

    // nrImages: Yup.number()
    //   .min(1, "Selecteer ten minste 1 afbeelding"),
    // title: Yup.string()
    //   .required('Vul een titel in'),
    // brand: Yup.string()
    //   .required("Vul een merk in"),
    // itemYear: Yup.string()
    //   .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    //   "Vul een geldig jaartal in"),
    // nrItems: Yup.number()
    //   .optional()
    //   .typeError("Voer een geldige prijs in")
    //   .min(1, "Voer een geldig aantal in"),
    // fixedPrice: Yup.number()
    //   .optional()
    //   .typeError("Voer een geldige prijs in")
    //   .min(0, "Voer een geldige prijs in"),
    // auctionStartPrice: Yup.number()
    //   .optional()
    //   .typeError("Voer een geldige startprijs in")
    //   .min(0, "Voer een geldige startprijs in"),
    // simpleStartPrice: Yup.number()
    //   .optional()
    //   .typeError("Voer een geldige startprijs in")
    //   .min(0, "Voer een geldige startprijs in"),
    // productType: Yup.string()
    //   .required(),
    // description: Yup.string()
    //   .required("Vul een omschrijving in"),
    // visible: Yup.boolean()
    //   .required()

    public static cleanInput(input: any): any {
      if (input["name"] == "") {
        input["name"] = null;
      }

      if (input["minPrice"]) {
        input["minPrice"] = parseInt(input["minPrice"]);
      } else {
        input["minPrice"] = null;
      }

      if (input["maxPrice"]) {
        input["maxPrice"] = parseInt(input["maxPrice"]);
      } else {
        input["maxPrice"] = null;
      }

      if (input["minYear"]) {
        input["minYear"] = parseInt(input["minYear"]);
      } else {
        input["minYear"] = null;
      }

      if (input["maxYear"]) {
        input["maxYear"] = parseInt(input["maxYear"]);
      } else {
        input["maxYear"] = null;
      }

      if (input["onlineSince"] == "") {
        input["onlineSince"] = null;
      }

      if (input["offerTypes"]) {

      }

      if (input["location"] == "") {
        input["location"] = null;
      }

      if (input["brand"] == "") {
        input["brand"] = null;
      }

      if (input["provider"] == "") {
        input["provider"] = null;
      }

      if (input["locationType"] == "NO_LOCATION") {
        input["distance"] = null;
      }

      return input;
    }
}

export function validAuctionDuration(f) {
  return checkValidAuctionDuration(f.values.auctionDays, f.values.auctionHours, f.values.auctionMinutes);
}

export function checkValidAuctionDuration(auctionDays, auctionHours, auctionMinutes) {
  if (!auctionDays) {
    auctionDays = "0";
  }

  if (!auctionHours) {
    auctionHours = "0";
  }

  if (!auctionMinutes) {
    auctionMinutes = "0";
  }

  var days = parseInt(auctionDays) * 24 * 60 * 60
  var hours = parseInt(auctionHours) * 60 * 60;
  var minutes = parseInt(auctionMinutes) * 60;

  var auctionDurationSeconds = days + hours + minutes;
  var oneHour = 60 * 60;

  var longEnough = auctionDurationSeconds >= oneHour;

  return longEnough;
}

export function validSimpleStartPrice(f) {
  // var value = f.values.simpleStartPrice;

  // if (allowBiddingHasStartPrice) {
  //   if (!value || value == "") {
  //     return false;
  //   }

  //   var parsed = parseInt(value);

  //   if (isNaN(parsed)) {
  //     return false;
  //   }

  //   if (parsed < 0) {
  //     return false;
  //   }
  // }

  return true;
}

/*

  var formik = useFormik({
    initialValues: {
      nrImages: 0,
      title: "",
      brand: "",
      productType: ProductType.WINE,
      itemYear: "",
      description: "",
      visible: true,
      priceType: "FIXED_PRICE",
      nrItems: "1",
      fixedPrice: "",
      auctionDays: "",
      auctionHours: "",
      auctionMinutes: "",
      auctionStartPrice: "",
      simpleStartPrice: "",
      images: []
    },
    validationSchema: Yup.object({
      nrImages: Yup.number()
        .min(1, "Selecteer ten minste 1 afbeelding"),
      title: Yup.string()
        .required('Vul een titel in'),
      brand: Yup.string()
        .required("Vul een merk in"),
      itemYear: Yup.string()
        .matches(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
        "Vul een geldig jaartal in"),
      auctionDays: Yup.string()
        .when("priceType", (_, schema) => { 
          if (priceType == "AUCTION") {
            if (validAuctionDuration(formik) == false) {
              return schema.required("Voer een geldige duur in");
            } else {
              return schema;
            }
          } else {
            return schema;
          }
        }),
      auctionHours: Yup.string()
        .when("priceType", (_, schema) => { 
          if (priceType == "AUCTION") {
            if (validAuctionDuration(formik) == false) {
              return schema.required("Voer een geldige duur in");
            } else {
              return schema;
            }
          } else {
            return schema;
          }
        }),
      auctionMinutes: Yup.string()
        .when("priceType", (_, schema) => { 
          if (priceType == "AUCTION") {
            if (validAuctionDuration(formik) == false) {
              return schema.required("Voer een geldige duur in");
            } else {
              return schema;
            }
          } else {
            return schema;
          }
        }),
      nrItems: Yup.number()
        .required("Voer een geldig aantal items in")
        .typeError("Voer een geldig aantal items in")
        .min(1, "Voer een geldig aantal items in"),
      fixedPrice: Yup.number()
        .optional()
        .typeError("Voer een geldige prijs in")
        .min(0, "Voer een geldige prijs in")
        .when("priceType", (_, schema) => { 
          if (priceType == "FIXED_PRICE") {
            return schema.required("Voer een geldige prijs in");
          } else {
            return schema;
          }
        }),
      auctionStartPrice: Yup.number()
        .optional()
        .typeError("Voer een geldige startprijs in")
        .min(0, "Voer een geldige startprijs in")
        .when("priceType", (_, schema) => { 
          if (priceType == "AUCTION") {
            if (auctionHasStartPrice && !validAuctionStartPrice(formik)) {
              return schema.required("Voer een geldige startprijs in");
            } else {
              return schema;
            }
          } else {
            return schema;
          }
        }),
      simpleStartPrice: Yup.number()
        .optional()
        .typeError("Voer een geldige startprijs in")
        .min(0, "Voer een geldige startprijs in")
        .when("priceType", (_, schema) => { 
          if (priceType == "ALLOW_BIDDING") {
            if (allowBiddingHasStartPrice && !validSimpleStartPrice(formik)) {
              return schema.required("Voer een geldige startprijs in");
            } else {
              return schema;
            }
          } else {
            return schema;
          }
        }),
      productType: Yup.string()
        .required(),
      description: Yup.string()
        .required("Vul een omschrijving in"),
      visible: Yup.boolean()
        .required()
    }),
    onSubmit: (values, { setSubmitting }) => {
      setShowSpinner(true);

      var data = JSON.parse(JSON.stringify(values));

      data["productType"] = productType;

      if (data["radio-button-price"]) {
        data["priceType"] = data["radio-button-price"];
      }

      if (data.priceType == "AUCTION") {
        var days = parseInt(data["auctionDays"]) * 24 * 60 * 60;
        var hours = parseInt(data["auctionHours"]) * 60 * 60;
        var minutes = parseInt(data["auctionMinutes"]) * 60;

        if (isNaN(days)) {
          days = 0;
        }

        if (isNaN(hours)) {
          hours = 0;
        }

        if (isNaN(minutes)) {
          minutes = 0;
        }

        var auctionDuration = days + hours + minutes;
        data["auctionDuration"] = auctionDuration;

        if (data["auctionStartPrice"]) {
          data["startPrice"] = parseFloat(data["auctionStartPrice"]);
        }
      }

      if (data.priceType == "FIXED_PRICE") {
        var fixedPrice = parseFloat(data["fixedPrice"]);

        if (!isNaN(fixedPrice)) {
          data["price"] = fixedPrice;
        }
      }

      if (data.priceType == "ALLOW_BIDDING") {
        if (data["simpleStartPrice"]) {
          data["startPrice"] = parseFloat(data["simpleStartPrice"]);
        }
      }

      var nrItems = data["nrItems"];
      delete data["nrItems"];
      var parsedNrItems = parseInt(nrItems);

      if (!isNaN(parsedNrItems)) {
        data["nrItems"] = parsedNrItems;
      }

      delete data["radio-button-price"];
      delete data["auctionDays"];
      delete data["auctionMinutes"];
      delete data["auctionHours"];
      delete data["auctionStartPrice"];
      delete data["simpleStartPrice"];
      delete data["fixedPrice"];

      uploadToServer(data);
    }
  });
*/
