import { Tooltip } from "@mui/joy";
import { Link } from "react-router-dom";
import account from "../../../domain/account/account";
import { accountRoleToString } from "../../../domain/account/account.role";
import ChangePassword from "../../account/password/change";
import Account from "../../../domain/account/account";
import { utils } from "../../../services";

export default function AdminAccountDetailPanel({ data }: { data: Account }) {
  return (
    <>
      <div className="grid grid-flow-row auto-cols-min gap-4">
        <div className="font-bold text-right">Type account:</div>
        <div>{ accountRoleToString(data.accountRole) }</div>

        <div className="font-bold text-right">Voornaam:</div>
        <div>{ data.firstName }
        { 
            data.idVerified ? 
              <Tooltip title={
                <div className="body-style">
                  Je identiteit is geverifieerd.
                </div>
              } variant="plain">
                <img 
                  className="z-20 inline-block relative left-[10px] top-[-1px]" 
                  src="/gropp_verified.png"
                  width="17" 
                  height="17" 
                  alt="Grop logo"/>
              </Tooltip>
              : <></>
          }
        </div>

        <div className="font-bold text-right">Achternaam:</div>
        <div>{ data.lastName }</div>

        <div className="font-bold text-right">Geboortedatum:</div>
        <div>{ utils.renderDate(data.birthDate) }</div>

        <div className="font-bold text-right">Straat:</div>
        <div>{ data.street }</div>

        <div className="font-bold text-right">Postcode:</div>
        <div>{ data.postalCode }</div>
        
        <div className="font-bold text-right">Mobiel telefoonnummer:</div>
        <div>{ data.phoneNumber }</div>

        <div className="font-bold text-right">Emailadres:</div>
        <div>{ data.emailAddress }
        { 
            data.emailVerified ? 
              <Tooltip title={
                <div className="body-style">
                  Je emailadres is geverifieerd.
                </div>
              } variant="plain">
                <img 
                  className="z-20 inline-block relative left-[10px] top-[-1px]" 
                  src="/gropp_verified.png" 
                  width="17" 
                  height="17" 
                  alt="Grop logo"/>
              </Tooltip>
              : <></>
          }
        </div>

        <div className="font-bold text-right">Aantal items:</div>
        <div>{ data.nrItems }</div>

        <div className="font-bold text-right">Aantal aanbiedingen:</div>
        <div>{ data.nrOffers }</div>

        <div className="font-bold text-right">Aantal biedingen:</div>
        <div>{ data.nrBids }</div>

        <div className="font-bold text-right">Aantal transacties:</div>
        <div>{ data.nrTrades }</div>

        <div className="font-bold text-right">Afbeelding:</div>
        <div>{ }</div>
      </div>
    </>);
}