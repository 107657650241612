import { utils } from './services';
import NavBar from './navbar';
import { Outlet, useLocation } from 'react-router-dom';
import BottomBar from './bottombar';
import MobileRoot from './mobile.root';

export default function Root() {
  const location = useLocation();
  
  function renderWeb() {
    return (
      <>
        { location.pathname != "/login" ? <NavBar/> : <></>}

        <Outlet/>

        { location.pathname != "/login" ? <BottomBar/> : <></>}
      </>
    );
  }

  if (utils.isMobile()) {
    return <MobileRoot/>;
  } else {
    return renderWeb();
  }
}