import * as Ons from "react-onsenui";

import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";

import { searchService, settings, utils } from "./services";
import { useEffect, useState } from "react";
import { Utils } from "./services/utils";
import { DefaultMobileToolbar } from "./gropp/default.mobile.toolbar";
import Login from "./login/login";
import Marketplace from "./gropp/marketplace/page";
import MyAccountItems from "./gropp/account/items/page";
import MobileMapSearch from "./gropp/search/mobile.map.search";
import MobileSearchFilter from "./gropp/search/mobile.search.filter";
import MobileAccountPage from "./gropp/account/mobile.account.page";
import MobileNewItem from "./gropp/items/new/mobile.new.item";
import MobileUnreadMessages from "./gropp/mobile.unread.messages";
import { EventBus } from "./services/event.bus";
import { useLocation, useNavigate } from "react-router";
import Inbox from "./gropp/account/inbox/page";
import VerifiedAccountPage from "./gropp/account/verified/page";

export default function MobileRoot() {
  var currentTab = 0;

  const [registeredEventBus, setRegisteredEventBus] = useState<boolean>(false);
  const [navigator, setNavigator] = useState<any>(null);
  const location = useLocation();
  const navigate = useNavigate();

  function goToMarketplace() {
    if (Utils.mobileNavigator.isRunning()) {
      return;
    }
    
    if (currentTab == 0) {
      return;
    }
 
    currentTab = 0;

    utils.cleanElements();

    Utils.mobileNavigator.pushPage({
      page: <Marketplace/>,
      name: "marketplace/" + utils.makeId(5),
      renderToolbar: () => {
        Utils.showTabbar = true;
        return <DefaultMobileToolbar text="" backButton={false} showLogo={true}/>
      }
    }, { animation: "none" });

    utils.cleanPageStack();
  }

  function goToInbox() {
    if (Utils.mobileNavigator.isRunning()) {
      return;
    }
    
    if (currentTab == 1) {
      return;
    }

    currentTab = 1;

    utils.cleanElements();

    Utils.mobileNavigator.pushPage({
      page: <Inbox/>,
      name: "inbox/" + utils.makeId(5),
      renderToolbar: () => {
        Utils.showTabbar = true;
        return <DefaultMobileToolbar text="Inbox" backButton={false} showLogo={false}/>
      }
    }, { animation: "none" });

    utils.cleanPageStack();
  }

  function goToNewItem() {
    if (Utils.mobileNavigator.isRunning()) {
      return;
    }

    if (currentTab == 2) {
      return;
    }

    currentTab = 2;

    utils.cleanElements();

    Utils.mobileNavigator.pushPage({
      page: <MobileNewItem/>,
      name: "new-item/" + utils.makeId(5),
      renderToolbar: () => {
        Utils.showTabbar = true;
        return <DefaultMobileToolbar text="Nieuw item" backButton={false}/>
      }
    }, { animation: "none" });

    utils.cleanPageStack();
  }

  function goToMyItems() {
    if (Utils.mobileNavigator.isRunning()) {
      return;
    }
    
    if (currentTab == 3) {
      return;
    }

    currentTab = 3;

    utils.cleanElements();

    Utils.mobileNavigator.pushPage({
      page: <MyAccountItems/>,
      name: "my-items/" + utils.makeId(5),
      renderToolbar: () => {
        Utils.showTabbar = true;
        return <DefaultMobileToolbar text="Mijn items" backButton={false}/>
      }
    }, { animation: "none" });

    utils.cleanPageStack();
  }

  function goToAccount() {
    if (Utils.mobileNavigator.isRunning()) {
      return;
    }
    
    if (currentTab == 4) {
      return;
    }

    currentTab = 4;

    utils.cleanElements();

    Utils.mobileNavigator.pushPage({
      page: <MobileAccountPage/>,
      name: "my-account/" + utils.makeId(5),
      renderToolbar: () => {
        Utils.showTabbar = true;
        return <DefaultMobileToolbar text="Account" backButton={false}/>
      }
    }, { animation: "none" });

    utils.cleanPageStack();
  }

  function getMobileTabbar() {
    if (Utils.showTabbar) {
      return (
        <div className={`grid grid-cols-5 redhat-display background-accent-color mobile-tabbar-style`}>
          <div className={`text-center w-full ${currentTab == 0 ? "selected-tabbar-label-color" : "tabbar-label-color" }`} 
              onClick={() => goToMarketplace()}>
            <Ons.Icon icon="fa-home" className={`${currentTab == 0 ? "selected-tabbar-icon" : "tabbar-icon" }`}/>
            <div className="text-xs">Home</div>
          </div>

          <div className={`text-center w-full ${currentTab == 1 ? "selected-tabbar-label-color" : "tabbar-label-color" } justify-center align-middle`}       
              onClick={() => goToInbox()}>
            <div className="">
              <div className="w-min ml-auto mr-auto">
                <Ons.Icon icon="md-email" className={`${currentTab == 1 ? "selected-tabbar-icon" : "tabbar-icon" }`}/>

                <div className="relative top-[-30px] left-[10px] min-h-[18px] underline-unread">
                  <MobileUnreadMessages/>
                </div>
              </div>
            </div>

            <div className={`text-white text-xs mt-[-18px] ${currentTab == 1 ? "selected-tabbar-label-color" : "tabbar-label-color" }`}>Inbox</div>
          </div>
          
          <div className={`text-center link-color w-full ${currentTab == 2 ? "selected-tabbar-label-color" : "tabbar-label-color" }`}  
              onClick={() => goToNewItem()}>
            <div className="p-1 rounded-lg mobile-tabbar-camera-style">
              <Ons.Icon icon="md-camera-add" className={`tabbar-icon-camera mt-[1px] ${currentTab == 2 ? "selected-tabbar-icon" : "tabbar-icon" }`}/>
              <div className="text-xs mt-[-2px]">Gropp it!</div>
            </div>
          </div>

          <div className={`text-center w-full ${currentTab == 3 ? "" : "" }`} 
              onClick={() => goToMyItems()}>
            <Ons.Icon icon="fa-wine-bottle" className={`${currentTab == 3 ? "selected-tabbar-icon" : "tabbar-icon" }`}/>
            <div className={`${currentTab == 3 ? "selected-tabbar-label-color" : "tabbar-label-color" } text-xs`}>Mijn&nbsp;items</div>
          </div>
          
          <div className={`text-center w-full ${currentTab == 4 ? "" : "" }`} 
              onClick={() => goToAccount()}>
            <Ons.Icon icon="fa-user" className={`${currentTab == 4 ? "selected-tabbar-icon" : "tabbar-icon" }`}/>
            <div className={`${currentTab == 4 ? "selected-tabbar-label-color" : "tabbar-label-color" } text-xs`}>Account</div>
          </div>
        </div>);
    } else {
      return (<></>);
    }
  }

  useEffect(() => {
    console.log("useEffect bitch");

    if (!registeredEventBus) {
      setRegisteredEventBus(true);

      EventBus.getInstance().register(
        EventBus.ON_LOGOUT, () => {
          goToMarketplace();
        });

      EventBus.getInstance().register(
        EventBus.ON_GOTO_MARKETPLACE, () => {
          goToMarketplace();
        });

      EventBus.getInstance().register(
        EventBus.ON_GOTO_INBOX, () => {
          goToInbox();
        });

      EventBus.getInstance().register(
        EventBus.ON_GOTO_NEW_ITEM, () => {
          goToNewItem();
        });

      EventBus.getInstance().register(
        EventBus.ON_GOTO_MY_ITEMS, () => {
          goToMyItems();
        });

      EventBus.getInstance().register(
        EventBus.ON_GOTO_ACCOUNT, () => {
          goToAccount();
        });

      settings.load();
      
      setNavigator(
        <Ons.Navigator
          swipeable
          renderPage={renderNavigatorPage}
          animationOptions={{
            animation: "none",
          }}
          initialRoute={{
            name: "marketplace"
          }}/>);

      Utils.mobileNavigator = navigator;
    }
  }, null);
  
  function renderNavigatorPage(route, navigator) {
    Utils.mobileNavigator = navigator;

    var routeName = route.name;

    if (!routeName) {
      routeName = "" + route.page.type.name;
    }

    var body = null;
    
    if (location.pathname.startsWith("/gropp/account/verified")) {
      // Manually entered URL in address bar, does not come through routeName
      Utils.showTabbar = false;

      body = (
        <Ons.Page key={"account-verified"}>
          <VerifiedAccountPage params={location.search}/>
        </Ons.Page>);
    } else {
      // Navigation inside app itself, not seen in address bar
      if (routeName.startsWith("marketplace")) {
        body = (
          <Ons.Page
              key={routeName}
              className={`${ utils.getPlatform() == "ios" ? "mt-[30px]" : "" }`}
              renderToolbar={() => <DefaultMobileToolbar text="" backButton={false} showLogo={true}/>}>
            <Marketplace/>
          </Ons.Page>);
      } else if (routeName.startsWith("login")) {
        Utils.showTabbar = false;
        
        body = (
          <Ons.Page key={routeName}>
            <Login/>
          </Ons.Page>);
      } else {
        if (!route.renderToolbar) {
          route.renderToolbar = () => <DefaultMobileToolbar text="" backButton={false} showLogo={true}/>;
        }

        body = (
          <Ons.Page 
              key={routeName} 
              className={`${ utils.getPlatform() == "ios" ? "mt-[30px]" : "" }`}
              renderToolbar={() => route.renderToolbar()}> 
            { route.page }
          </Ons.Page>);
      }
    }

    return (
      <>
        { body }
        { Utils.showTabbar ? getMobileTabbar() : <></> }
      </>);
  }

  return navigator;
}