import * as Ons from "react-onsenui";
import { useEffect, useState } from "react";
import { DefaultMobileToolbar } from "../default.mobile.toolbar";
import { BACKEND_URL } from "../../domain/env";
import { authService, utils } from "../../services";
import { Utils } from "../../services/utils";
import AccountBuilder from "../../domain/account/account.builder";
import MobileProfileOverview from "./mobile.profile.overview";
import { MobileTrades } from "./trades/mobile.trades";
import MyFavorites from "./favorites/page";
import MyBids from "./bids/page";
import Loading from "../../components/loading";
import { useNavigate } from "react-router";
import MobileSettings from "./settings/mobile.settings";
import { EventBus } from "../../services/event.bus";
import MyChats from "./chats/page";
import ShippingConfiguration from "../shipping/page";
import MobilePaymentPage from "../payment/mobile.payment.page";

export default function MobileAccountPage() {
  const navigate = useNavigate();
  
  const [account, setAccount] = useState<any>(null);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState<any>(false);
  
  const loadData = async () => {
    utils.getLoggedInAccountId()
      .then((accountId) => {
        if (accountId) {
          var url = BACKEND_URL + "/accounts/" + accountId;
    
          authService.getSecure(url)
            .then((accountInfo) => {
              var account = AccountBuilder.buildAccount(accountInfo.data);
    
              if (account != null) {
                setAccount(account);
              }
            }).catch((_) => {
              utils.handleUnauthorized(() => navigate("/login"));
            });
        } else {
          utils.handleUnauthorized(() => navigate("/login"));
        }
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  useEffect(() => {
    if (!account) {
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => {
          loadData();
      });

      loadData();
    }
  }, null);

  function goToEditProfile() {
    Utils.mobileNavigator.pushPage({
      page: <MobileProfileOverview account={account}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Profiel bewerken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToMyBids() {
    Utils.mobileNavigator.pushPage({
      page: <MyBids/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Mijn biedingen" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToMyFavorites() {
    Utils.mobileNavigator.pushPage({
      page: <MyFavorites/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Mijn favorieten" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToMyTrades() {
    Utils.mobileNavigator.pushPage({
      page: <MobileTrades/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Mijn transacties" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToMyChats() {
    Utils.mobileNavigator.pushPage({
      page: <MyChats/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Mijn chats" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToSettings() {
    Utils.mobileNavigator.pushPage({
      page: <MobileSettings/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Instellingen" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function logOut() {
    setShowLogoutConfirmation(true);
  }

  function doLogOut() {
    utils.deleteJwt();

    setShowLogoutConfirmation(false);

    EventBus.getInstance()
      .dispatch<void>(EventBus.ON_LOGOUT);
  }

  if (!account) {
    return <Loading/>;
  }

  return (
    <div className="ml-6">
      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => goToEditProfile()}>Profiel bewerken</div>
      </div>

      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => goToMyBids()}>Mijn biedingen</div>
      </div>

      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => goToMyFavorites()}>Mijn favorieten</div>
      </div>

      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => goToMyTrades()}>Mijn transacties</div>
      </div>

      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => goToMyChats()}>Mijn chats</div>
      </div>

      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => goToSettings()}>Instellingen</div>
      </div>

      <div>
        <div className="redhat-display mt-4 mb-2" onClick={() => logOut()}>Uitloggen</div>
      </div>

      <Ons.AlertDialog isOpen={showLogoutConfirmation} cancelable>
        <div className="text-center w-full mt-2 redhat-display">
          <span className="alert-dialog-text font-bold">Uitloggen</span>
        </div>
        
        <div className="text-center w-full mt-4 p-4 alert-dialog-text redhat-display text-sm">
          <span className="">Weet je zeker dat je wilt uitloggen?</span>
        </div>

        <div className="w-full p-4 gap-2 justify-center flex flex-row">
          <Ons.Button onClick={() => doLogOut() } className="button min-w-[110px]">
            <span className="redhat-display text-sm">Ja</span>
          </Ons.Button>

          <Ons.Button onClick={() => setShowLogoutConfirmation(false)} className="button min-w-[110px]">
            <span className="redhat-display text-sm">Nee</span>
          </Ons.Button>
        </div>
      </Ons.AlertDialog>
    </div>
  );
}