import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { bidStateToString } from "../../../domain/bid/bid.state";
import { bidTypeToString } from "../../../domain/bid/bid.type";
import { DATE_TIME_FORMAT, FRIENDLY_DATE_TIME_FORMAT } from "../../../domain/constants";
import { BACKEND_URL } from "../../../domain/env";
import { utils } from "../../../services";

export default function BidItem({ bid }) {
  const navigate = useNavigate();
  
  function getAmount() {
    return utils.roundedAmount(bid.amount, 2);
  }

  function goToBid() {
    navigate("/gropp/bids/" + bid.id);
  }

  return (
    <div className="justify-center text-center w-full ml-auto mr-auto">
      <div className="flex flex-row h-[250px] w-full justify-center">
        <div className="mt-auto mb-auto">
          <img 
            className="mr-4 ml-0 mt-0 rounded-lg item-image-bid-info"
            src={`${BACKEND_URL}/static/img/preview/${bid.itemId}`}
            alt="Item image"
            width={150}
            height={150}/>
        </div>

        <div className="grid grid-cols-2 min-w-[300px] auto-rows-min mt-auto mb-auto">
          <div className="text-right font-bold mr-4 p-1">Type:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{bidTypeToString(bid.bidType)}</div>

          <div className="text-right font-bold mr-4 p-1">Datum:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">
            { moment(bid.createTime, DATE_TIME_FORMAT).format(FRIENDLY_DATE_TIME_FORMAT) }
          </div>

          <div className="text-right font-bold mr-4 p-1">Item:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">
            <Link to="#">bekijk item</Link>
          </div>

          <div className="text-right font-bold mr-4 p-1">Status:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">
            { bidStateToString(bid.bidState) }
          </div>

          <div className="text-right font-bold mr-4 p-1">Bedrag:</div>
            <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">
            <div className="font-bold bid-amount">{getAmount()}</div>
          </div>
        </div>

        <div className="mt-auto mb-auto ml-[50px]">
          <button className="normal-button" type="submit" onClick={goToBid}>Bekijken</button>
        </div>
      </div>

      <div className="w-2/4 ml-auto mr-auto h-[1px] border-solid search-line"></div>
    </div>
  );
}