import ReactDOM from 'react-dom/client';
import './index.css';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import { settings, utils } from './services';
import { Snackbar } from '@mui/joy';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import { Utils } from './services/utils';
import Login from './login/login';
import Marketplace from './gropp/marketplace/page';
import Root from './root';
import NewAccount from './gropp/account/new/page';
import Search from './gropp/search/page';
import MyAccountItems from './gropp/account/items/page';
import NewItem from './gropp/items/new/page';
import VerifiedAccountPage from './gropp/account/verified/page';
import ResetPassword from './gropp/account/password/page';
import OfferPageRouteHelper from './gropp/offers/[offerId]/offer.page.route.helper';
import MyBids from './gropp/account/bids/page';
import MyChats from './gropp/account/chats/page';
import EditAccount from './gropp/account/edit/page';
import MyFavorites from './gropp/account/favorites/page';
import MyInbox from './gropp/account/inbox/page';
import EditItemRouteHelper from './gropp/account/items/edit/edit.item.route.helper';
import Settings from './gropp/account/settings/page';
import BidPageRouteHelper from './gropp/bids/[bidId]/bid.page.route.helper';
import TradePageRouteHelper from './gropp/trades/[tradeId]/trade.page.route.helper';
import AccountPageRouteHelper from './gropp/accounts/[accountId]/account.page.route.helper';
import AdminPanel from './gropp/admin/admin.panel';
import AdminAccountsPanel from './gropp/admin/admin.accounts';
import MobilePaymentResultRouteHelper from './gropp/payment/mobile.payment.result.route.helper';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Set to Style.Dark to make the top text white
if (utils.getPlatform() == "web") {
  // settings.updateColorScheme(ColorScheme.DARK);
} else {
  // NavigationBar.hide();
  StatusBar.setStyle({ style: Style.Dark });
  StatusBar.setBackgroundColor({ color: "#33192d" });
} 

export const routes = [
  {
    path: "/",
    element: <Root/>,
    children: [
      {
        path: "",
        element: <Marketplace/>
      },
      {
        path: "login",
        element: <Login/>
      },
      {
        path: "gropp",
        children: [
          {
            path: "items",
            children: [
              {
                path: "new",
                element: <NewItem/>
              }
            ]
          },
          {
            path: "search",
            element: <Search/>
          },
          {
            path: "marketplace",
            element: <Marketplace/>
          },

          {
            path: "payment",
            children: [
              {
                path: ":status",
                element: <MobilePaymentResultRouteHelper/>
              }
            ]
          },
          {
            path: "offers",
            children: [
              {
                path: ":offerId",
                element: <OfferPageRouteHelper/>
              }
            ]
          },
          {
            path: "bids",
            children: [
              {
                path: ":bidId",
                element: <BidPageRouteHelper/>
              }
            ]
          },
          {
            path: "trades",
            children: [
              {
                path: ":tradeId",
                element: <TradePageRouteHelper/>
              }
            ]
          },
          {
            path: "accounts",
            children: [
              {
                path: ":accountId",
                element: <AccountPageRouteHelper/>
              }
            ]
          },
          {
            path: "account",
            children: [
              {
                path: "new",
                element: <NewAccount/>
              },
              {
                path: "edit",
                element: <EditAccount/>
              },
              {
                path: "favorites",
                element: <MyFavorites/>
              },
              {
                path: "inbox",
                element: <MyInbox/>
              },
              {
                path: "verified",
                element: <VerifiedAccountPage/>
              },
              {
                path: "password",
                children: [
                  {
                    path: "reset",
                    element: <ResetPassword/>
                  }
                ]
              },
              {
                path: "items",
                children: [
                  {
                    path: "",
                    element: <MyAccountItems/>,
                  },
                  {
                    path: "edit",
                    element: <EditItemRouteHelper/>
                  }
                ]
              },
              {
                path: "bids",
                element: <MyBids/>
              },
              {
                path: "chats",
                element: <MyChats/>
              },
              {
                path: "settings",
                element: <Settings/>
              },
              {
                path: "trades",
                element: <></>
              }
            ]
          },
          {
            path: "admin",
            children: [
              {
                path: "",
                element: <AdminPanel/>
              },
              {
                path: "accounts",
                element: <AdminAccountsPanel/>
              }
            ]
          }
        ]
      }
    ]
  }
];

const router = createBrowserRouter(routes);

root.render(
  <>
    <RouterProvider router={router} />
    
    {
      Utils.showGlobalConnectionError ?
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{
            backgroundColor: "red",
            color: "white",
            borderRadius: "5px",
            border: "2px solid white"
          }}
          open={true}
          onClose={() => {}}
          key="no-connection">
            <div className="font-bold justify-center text-center w-full align-middle">
              <p className="w-full text-center">Geen verbinding met de server.</p>
              <p className="w-full text-center">Controleer je internetconnectie.</p>
            </div>
        </Snackbar>
      : <></>
    }
  </>
);