import AbstractEntity from "../abstract.entity";

abstract class Item extends AbstractEntity {
	title: string;
	description: string;
	ownerId: string;
  isGroup: boolean;

	constructor($id: string,
              $createTime: Date,
              $title: string,
              $description: string,
              $ownerId: string,
              $isGroup: boolean,) {
		super($id, $createTime);

		this.title = $title;
		this.description = $description;
		this.ownerId = $ownerId;
    this.isGroup = $isGroup;
	}
}

export default Item;