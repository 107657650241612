import Carrier, { carrierFromString } from "./carrier";
import ShippingFunctionality from "./shipping.functionality";
import bigDecimal from 'js-big-decimal';

export default class ShippingServiceDetails {
  id: number;
  name: string;
  carrier: Carrier;
  functionalities: ShippingFunctionality[];
  minWeight: number;
  maxWeight: number;
  maxLength: number;
  maxWidth: number;
  maxHeight: number;
  ageCheck18: boolean;
  signature: boolean;
  neighborDelivery: boolean;
  weekendDelivery: boolean;
  price: bigDecimal;
  leadTimeHours: number;

  constructor($id: number,
              $name: string,
              $carrier: Carrier,
              $functionalities: ShippingFunctionality[],
              $minWeight: number,
              $maxWeight: number,
              $maxLength: number,
              $maxWidth: number,
              $maxHeight: number,
              $ageCheck18: boolean,
              $signature: boolean,
              $neighborDelivery: boolean,
              $weekendDelivery: boolean,
              $price: string,
              $leadTimeHours: number) {
      this.id = $id;
      this.name = $name;
      this.carrier = $carrier;
      this.functionalities = $functionalities;
      this.minWeight = $minWeight;
      this.maxWeight = $maxWeight;
      this.maxLength = $maxLength;
      this.maxWidth = $maxWidth;
      this.maxHeight = $maxHeight;
      this.ageCheck18 = $ageCheck18;
      this.signature = $signature;
      this.neighborDelivery = $neighborDelivery;
      this.weekendDelivery = $weekendDelivery;
      this.price = new bigDecimal($price);
      this.leadTimeHours = $leadTimeHours;
    }
}