import * as Ons from "react-onsenui";
import { utils } from "../services";

export default function MobilePaginator({ pageNumber, pageSize, totalNrPages, first, previous, next, last, isTop }) {
  var topMargin = isTop ? "min-h-[30px] h-[30px]" : utils.getPlatform() == "android" ? 
  "mt-[20px] min-h-[20px] h-[20px]" : "mt-[7px] min-h-[20px] h-[20px]";

  return (
    <>
      <div className={`w-full flex flex-row gap-4 justify-center ` + topMargin}>
        <div>
          <Ons.Button 
            className="text-2xl font-bold paginator-button"
            modifier="quiet"
            onClick={() => first()}>
              <Ons.Icon icon="md-long-arrow-tab" className={`relative top-[-8px] mobile-navigation-button rotate-180 ${ pageNumber == 1 ? "custom-input-border-color" : "link-color" }`}/>
          </Ons.Button>
        </div>

        <div>
          <Ons.Button 
            className="text-2xl font-bold paginator-button"
            modifier="quiet"
            onClick={() => previous()}>
              <Ons.Icon icon="md-long-arrow-left" className={`relative top-[-8px] mobile-navigation-button ${ pageNumber == 1 ? "custom-input-border-color" : "link-color" }`}/>
            </Ons.Button>
        </div>

        <div className="redhat-display ml-4 mr-4 text-sm mt-[1px]">{ pageNumber } van { totalNrPages }</div>

        <div>
          <Ons.Button 
            className="text-2xl font-bold paginator-button"
            modifier="quiet"
            onClick={() => next()}>
              <Ons.Icon icon="md-long-arrow-right" className={`relative top-[-8px] mobile-navigation-button ${ pageNumber == totalNrPages ? "custom-input-border-color" : "link-color" }`}/>
            </Ons.Button>
        </div>

        <div>
          <Ons.Button 
            className="text-2xl font-bold paginator-button"
            modifier="quiet"
            onClick={() => last()}>
              <Ons.Icon icon="md-long-arrow-tab" className={`relative top-[-8px] mobile-navigation-button ${ pageNumber == totalNrPages ? "custom-input-border-color" : "link-color" }`}/>
            </Ons.Button>
        </div>
      </div>
    </>
  );
}