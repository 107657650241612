import * as Ons from "react-onsenui";
import Map from "../../components/map";
import { DEFAULT_MAP_LOCATION, DEFAULT_ZOOMED_IN_MAP_ZOOM } from "../../domain/constants";
import { useEffect, useMemo, useState } from "react";
import { authService, mapService, utils } from "../../services";
import { BACKEND_URL, GOOGLE_API_KEY } from "../../domain/env";
import { ServicePointBuilder } from "../../domain/shipping/service.point.builder";
import ServicePointMarker from "./service.point.marker";
import Loading from "../../components/loading";
import ReactDOMServer from 'react-dom/server';
import { useLoadScript } from '@react-google-maps/api';

export default function ServicePointMap({ carriers, markerClickCallback }) {
  const [servicePoints, setServicePoints] = useState<any[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mapZoom, setMapZoom] = useState<number>(DEFAULT_ZOOMED_IN_MAP_ZOOM);
  const [postalCode, setPostalCode] = useState<string>(null);
  const [mapMarkers, setMapMarkers] = useState<any[]>(null);
  const libraries = useMemo(() => ['places'], []);
  const mapId = "gropp-google-map";
  
  // useEffect(() => {
  //   if (!servicePoints && !isLoading) {
  //     setIsLoading(true);
  //     fetchCarriersList();
  //   }
  // }, null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: libraries as any
  });

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
      fetchCarriersList();
    }
  }, [ carriers ]);

  function fetchCarriersList() {
    setServicePoints([]);
    setMapMarkers([]);

    utils.getLoggedInAccountId().then((accountId) => {
      var postalCodeUrl = BACKEND_URL + "/accounts/" 
        + accountId + "/postalCode";

      authService.getSecure(postalCodeUrl).then((postalCodeResult) => {
        var ps = postalCodeResult.data.replace(" ", "");
        setPostalCode(ps);

        var body = {
          "carriers": carriers,
          "postalCode": ps,
          "range": 10000,
          "openTomorrow": false,
          "openUpcomingWeek": false
        };

        authService.postSecure(BACKEND_URL + "/shipping/service-points", body).then((result) => {
          var sp = result.data.map(i => ServicePointBuilder.buildServicePoint(i));

          setTimeout(() => {
            setServicePoints(sp);
          });
          
          var markers = sp.map(servicePoint => {
            return {
              key: servicePoint.id,
              lat: parseFloat(servicePoint.latitude),
              lng: parseFloat(servicePoint.longitude),
              data: servicePoint
            }
          });

          setTimeout(() => {
            setMapMarkers(markers);
          });
          
          setIsLoading(false);
        });
      });
    });
  }
  
  function buildOpeningTimes(marker) {
    const getBlocks = (details, day) => {
      const blockHours = (b) => {
        return (b.startHours < 10 ? "0" + b.startHours : b.startHours) + ":" + 
               (b.startMinutes < 10 ? "0" + b.startMinutes : b.startMinutes) + " - " + 
               (b.endHours < 10 ? "0" + b.endHours : b.endHours) + ":" + 
               (b.endMinutes < 10 ? "0" + b.endMinutes : b.endMinutes);
      }
      
      var thisDay = details.data.openingTimes.openingDays[day];

      var result = "";

      if (thisDay.blocks.length == 0) {
        return "";
      } else if (thisDay.blocks.length > 1) {
        for (var i = 0; i < thisDay.blocks.length - 1; i++) {
          var block = thisDay.blocks[i];

          result += blockHours(block) + ", ";
        }
      }

      if (thisDay.blocks.length > 0) {
        result += blockHours(thisDay.blocks[thisDay.blocks.length - 1]);
      }

      return result;
    }

    if (marker.data.openingTimes.openingDays.length != 7) {
      return <></>;
    }

    return (
      <div className="text-[10px]">
        <div>ma:&nbsp;{getBlocks(marker, 0)}</div>
        <div>di:&nbsp;{getBlocks(marker, 1)}</div>
        <div>wo:&nbsp;{getBlocks(marker, 2)}</div>
        <div>do:&nbsp;{getBlocks(marker, 3)}</div>
        <div>vr:&nbsp;{getBlocks(marker, 4)}</div>
        <div>za:&nbsp;{getBlocks(marker, 5)}</div>
        <div>zo:&nbsp;{getBlocks(marker, 6)}</div>
      </div>);
  }

  function renderServicePointMarker(marker) {
    var elem = (
      <div className="redhat-display ml-2 service-point-popup">
        <div className="text-xs">
          <div>{marker.data.name}</div>
          <div>{marker.data.street} {marker.data.houseNumber}</div>
          <div>{marker.data.postalCode} { marker.data.city}</div>
        </div>

        <div className="mt-2">Openingstijden:</div>
        { buildOpeningTimes(marker) }
      </div>);
    
    return ReactDOMServer.renderToString(elem);
  }

  if (!servicePoints || !mapMarkers) {
    return <Loading/>;
  }

  return (  
    <Map
      mapLocation={postalCode} 
      zoom={mapZoom} 
      searchRadius={1}
      markers={mapMarkers}
      markerRenderer={ (marker) => renderServicePointMarker(marker) }
      width={"calc(100%)"}
      height={"300px"}
      markerClickCallback={(marker, markerElement, infoWindow) => {
        markerClickCallback(marker, markerElement, infoWindow) 
      }
    }/>
  );
}