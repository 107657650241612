import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants";
import Favorite from "./favorite";
import { favoriteTypeFromString } from "./favorite.type";

export class FavoriteBuilder {
  static buildFavorite(data: any): Favorite {
    var favorite = new Favorite(
      data.id, 
      moment(data.createTime, DATE_TIME_FORMAT).toDate(),
      data.favoriteEntityId,
      favoriteTypeFromString(data.favoriteType),
      data.accountId);

    return favorite;
  }
}
