import * as Ons from "react-onsenui";
import * as Yup from 'yup';

import Spinner from "../components/spinner";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authService, utils } from "../services";
import { Utils } from "../services/utils";
import { EventBus } from "../services/event.bus";
import NewAccount from '../gropp/account/new/page';
import { DefaultMobileToolbar } from '../gropp/default.mobile.toolbar';
import ResetPassword from "../gropp/account/password/page";
import { ENABLE_CREATE_ACCOUNT } from "../domain/env";

var invalidCredentials = false;

export default function Login() {
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
		  email: "",
		  password: ""
		},
		validationSchema: Yup.object({
			email: Yup.string()
        .email('Ongeldig emailadres')
        .required('Vul een emailadres in'),
		  password: Yup.string()
			  .required('Vul een wachtwoord in')
		}),
		onSubmit: (values, { setSubmitting}) => {
      setShowSpinner(true);

      authService.login(values.email, values.password)
				.then((result) => {
          if (utils.isMobile()) {
            EventBus.getInstance()
              .dispatch<void>(EventBus.ON_LOGIN_SUCCESSFUL);

            Utils.mobileNavigator.popPage({ animation: "none"});
            Utils.showTabbar = true;
          } else {
            navigate("/gropp/marketplace");
          }
				})
				.catch((e) => {
          setShowSpinner(false);
					invalidCredentials = true;
					setSubmitting(false);
				});
		},
	  });

  function showCreateAccountScreen() {
    if (utils.isMobile()) {
      Utils.mobileNavigator.pushPage({
        page: <NewAccount/>,
        name: "new-account/" + utils.makeId(5),
        renderToolbar: () => {
          return <DefaultMobileToolbar 
            text="Nieuw account aanmaken"/>
        }
      }, { animation: "none" });
    } else {
      navigate("/gropp/account/new");
    }
  }

  function resetPassword() {
    if (utils.isMobile()) {
      Utils.mobileNavigator.pushPage({
        page: <ResetPassword/>,
        name: "reset-password/" + utils.makeId(5),
        renderToolbar: () => {
          return <DefaultMobileToolbar 
            text="Zoekresultaten" backButton={true}/>
        }
      }, { animation: "none" });
    } else {
      navigate("/gropp/account/password/reset");
    }
  }

  function renderWeb() {
    return (
      <form onSubmit={formik.handleSubmit} className="login-background-image-web">
        <div className="max-w-xs text-center container mx-auto flex flex-col place-content-center align-middle min-h-screen mt-[0px] redhat-display z-2">
          <img className="ml-auto mr-auto pb-10" alt="Grop logo" src="/gropp_logo_design2.png" width={200}/>
          
          <div className="rounded-lg bg-white login-border p-[15px] pt-[20px] pr-[20px]">
            <div className="flex flex-row">
              <label className="min-w-[80px] text-right mr-2 mt-1 text-sm white-label" htmlFor="email">E-mail:</label>
              <div className="">
                <input 
                  className="login-field mb-2"
                  id="email"
                  data-testid="email"
                  type="text"
                  onChange={ e => {
                    invalidCredentials = false;
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
              </div>
            </div>

            {formik.touched.email && formik.errors.email ? (
              <div className="text-xs text-right mb-2 text-red-600">{formik.errors.email}</div>
            ) : null }

            <div className="flex flex-row">
              <label className="min-w-[80px] text-right mr-2 mt-1 text-sm redhat-display white-label" htmlFor="password">Wachtwoord:</label>
              <div className="">
                <input
                  className="login-field mb-2"
                  id="password"
                  data-testid="password"
                  type="password"
                  onChange={ e => {
                    invalidCredentials = false;
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}/>
              </div>
            </div>

            {formik.touched.password && formik.errors.password ? (
              <div className="text-xs text-right mb-2 text-red-600">{formik.errors.password}</div>
            ) : null}

            <div className="text-right w-full">
              <div className="w-full text-center pb-7 mt-2">
                <div onClick={() => resetPassword()} className="text-xs link-color cursor-pointer">Wachtwoord&nbsp;vergeten?</div>
              </div>
            </div>

            {invalidCredentials ? (
              <div className="text-xs text-red-600 text-center mt-4 mb-[-10px]">Ongeldige gebruikersnaam of wachtwoord</div>
            ) : null }

            <div className="pt-10">
              <button className="button min-w-[150px]" type="submit"><span className="redhat-display text-sm">Inloggen</span></button>
            </div>

            <div className="w-full text-center pb-7 mt-4">
              {
                ENABLE_CREATE_ACCOUNT ? 
                  <div onClick={() => showCreateAccountScreen()} className="text-xs link-color cursor-pointer">Account&nbsp;aanmaken</div>
                  : <div className="text-xs link-color">&nbsp;</div>
              }
            </div>

            <p className="text-sm">
              Door in te loggen accepteer je onze <br></br>
              <Link to='#'>algemene voorwaarden</Link> en <Link to='#'>privacyverklaring.</Link>
            </p>

            { showSpinner ? <Spinner/> : <></> }
          </div>
        </div>

        <div className="absolute top-0 left-0 right-0 bottom-0 login-color z-[-1]"></div>
      </form>
    );
  }
  
  function renderMobile() {
    return (
      <form onSubmit={formik.handleSubmit} className="login-background-image">
        <div className="max-w-xs text-center container mx-auto flex flex-col place-content-center align-middle min-h-screen mt-[0px] redhat-display z-2">          
          <div className="rounded-lg bg-white login-border p-[15px] pt-[20px] pr-[20px]">
            <img className="ml-auto mr-auto mb-6 mt-6" alt="Grop logo" src="/gropp_logo_design.png" width={150}/>

            <div>
              <div className="flex flex-row mr-4">
                <label className="min-w-[80px] text-right mr-2 mt-1 text-sm redhat-display white-label" htmlFor="email">E-mail:</label>
                <div className="">
                  <input 
                    className="login-field mb-2 w-[190px]"
                    id="email"
                    data-testid="email"
                    type="text"
                    onChange={ e => {
                      invalidCredentials = false;
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </div>
              </div>

              {formik.touched.email && formik.errors.email ? (
                <div className="text-xs text-right mb-2 text-red-600">{formik.errors.email}</div>
              ) : null }

              <div className="flex flex-row">
                <label className="min-w-[80px] text-right mr-2 mt-1 text-sm white-label" htmlFor="password">Wachtwoord:</label>
                <div className="">
                  <input
                    className="login-field mb-2 w-[190px]"
                    id="password"
                    data-testid="password"
                    type="password"
                    onChange={ e => {
                      invalidCredentials = false;
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}/>
                </div>
              </div>

              {formik.touched.password && formik.errors.password ? (
                <div className="text-xs text-right mb-2 text-red-600">{formik.errors.password}</div>
              ) : null}
            </div>

            <div className="text-right w-full">
              <div className="w-full text-center pb-7 mt-2">
                <div onClick={() => resetPassword()} className="text-xs link-color redhat-display">Wachtwoord&nbsp;vergeten?</div>
              </div>
            </div>

            {invalidCredentials ? (
              <div className="text-xs text-red-600 text-center mt-4 mb-[-10px]">Ongeldige gebruikersnaam of wachtwoord</div>
            ) : null }

            <div className="pt-10">
              {
                utils.isMobile() ?
                  <button className="button min-w-[150px]" type="submit"><span className="redhat-display text-sm">Inloggen</span></button>
                  : <button className="normal-button" type="submit">Inloggen</button>
              }
            </div>

            <div className="w-full text-center pb-7 mt-2">
              {
                ENABLE_CREATE_ACCOUNT ? 
                  <div onClick={() => showCreateAccountScreen()} className="text-xs link-color">Account&nbsp;aanmaken</div>
                  : <div className="text-xs link-color">&nbsp;</div>
              }
            </div>

            <p className="text-sm">
              Door in te loggen accepteer je onze <br></br>
              <Link to='#'>algemene voorwaarden</Link> en <Link to='#'>privacyverklaring.</Link>
            </p>

            { showSpinner ? <Spinner/> : <></> }
          </div>
        </div>

        <div className="absolute top-0 left-0 right-0 bottom-0 login-color z-[-1]"></div>
      </form>
    );
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}
