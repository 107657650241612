import axios, { AxiosInstance } from "axios";
import DocumentType from "../domain/account/document.type";
import { Utils } from "./utils";
import { utils } from ".";
import { BACKEND_URL, WEBSITE_URL } from "../domain/env";
import { jwtDecode } from "jwt-decode";

export class AuthService {
  protected readonly instance: AxiosInstance;
  
  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
      transitional: {
        clarifyTimeoutError: true
      }
    });
  }
  
  public async login(emailAddress: string, password: string): Promise<any> {
    var body = {
      "emailAddress": emailAddress,
      "password": password
    };

    return this.instance
      .post("/accounts/authenticate", body)
      .then((res) => {
        utils.setJwt(res.data.jwt);
        return res.data;
      });
  };

  public async newAccount(account: any,
                          accountImageId?: string,
                          avatarIdx?: number): Promise<any> {
    var url = "/accounts";

    if (accountImageId) {
      url += "?accountImageId=" + accountImageId;
    } else if (avatarIdx) {
      url += "?avatarIdx=" + avatarIdx;
    }

    return this.instance
      .post(url, account)
      .then((result) => {
        return result.data;
      });
  }

  public async emailExists(emailAddress: string) {
    var url = "/accounts/email-exists/" + btoa(emailAddress);

    return this.instance
      .get(url)
      .then((result) => {
        return result.data;
      }).catch((e) => {
        return true;
      })
  }

  public async checkId(image: any,
                       accountId: string,
                       documentType: DocumentType): Promise<any> {
    var formData = new FormData();
    formData.append("file", image);

    var url = "/accounts/id-check/" + accountId + "/" + documentType;

    return this.instance
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((result) => {
        return result.data;
      }).catch((_) => {
        return false;
      })
  }
  
  public async uploadNewAccountImage(image: any): Promise<any> {
    var formData = new FormData();
    formData.append("file", image);

    return this.instance
      .post("/static/img/account/new", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then((result) => {
        var location = result.headers.location;

        if (location) {
          var filename = location.substring(
            location.lastIndexOf("/") + 1, location.length);

          var uuid = filename.substring(
            0, filename.lastIndexOf("."));
          
          return uuid;
        } else {
          return null;
        }
      });
  }

  /**
   * Fetch images for a given item 
   * from the backend.
   */
  public async fetchImages(itemId: string): Promise<any> {
    var url = BACKEND_URL + "/static/img/list/" + itemId;

    return this.getSecure(url)
      .then((result) => {
        var array = (result.data as any[]);

        var sorted = array.sort((i1, i2) => {
          return i1["sequenceNr"] - i2["sequenceNr"];
        });

        return Promise.all(sorted.map(i => {
          var url = BACKEND_URL + "/static/img/" + i["id"];
          
          return fetch(url)
            .then(result => result.blob())
            .then(blob => {
              const name = i["id"] + "." + (i["imageExtension"] as string).toLowerCase();
              return new File([blob], name, blob);
            })
        }));
      });
  }

  handleBackendError(e) {
    if (e && e.response & e.response.status && e.response.status == 401) {
      window.location.href = WEBSITE_URL + "/login";
    } else {
      Utils.showGlobalConnectionError = true;
    }
  }
  
  async getSecure(url): Promise<any> {
    return this.getAuthHeader().then((header) => {
      return this.instance.get(url, {
        headers: header
      });
    });
  }

  async postSecure(url, body): Promise<any> {
    return this.getAuthHeader().then((header) => {
      return this.instance.post(url, body, {
        headers: header
      });
    });
  }

  async putSecure(url, body): Promise<any> {
    return this.getAuthHeader().then((header) => {
      return this.instance.put(url, body, {
        headers: header
      });
    });
  }

  async deleteSecure(url): Promise<any> {
    return this.getAuthHeader().then((header) => {
      return this.instance.delete(url, {
        headers: header
      });
    });
  }

  async get(url): Promise<any> {
    return this.instance.get(url)
      .catch((e) => {
        Utils.showGlobalConnectionError = true;
      });
  }

  async post(url, body): Promise<any> {
    return this.instance.post(url, body)
    .catch((_) => {
        Utils.showGlobalConnectionError = true;
    });
  }

  async getAuthHeader(): Promise<any> {
    return utils.getJwt().then((jwt) => {
      var headers = {
        Authorization: `Bearer ${jwt || ""}`
      };
  
      return headers;
    });
  }
}
