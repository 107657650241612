import { Dropdown, MenuButton, Menu, MenuItem } from "@mui/joy";
import { useState } from "react";

export default function AccountMenu() {
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false);
  
  function editAccount() {
    
  }

  function myBids() {
    //navigate("/gropp/account/bids");
  }

  function myFavorites() {
    //navigate("/gropp/account/favorites");
  }

  function myTransactionHistory() {
    //navigate("/gropp/account/trades");
  }

  function myChats() {
    //navigate("/gropp/account/chats"); 
  }
  
  function settings() {

  }

  return (
    <>
      <Dropdown>
        <MenuButton
          variant="plain"
          sx={{ 
            fontWeight: "normal", 
            color: "white", 
            fontFamily: "Red Hat Display", 
            marginLeft: "-30px",
            marginTop: "-10px", 
            ":hover": { 
              backgroundColor: "transparent", 
              color: "white", 
              textDecoration: "underline"
            }}}>
              <img className="ml-3 navbar-logo mr-2" src="/circle_user_regular.png" width="22" alt="Grop logo"/>
              <span className="redhat-display text-sm">Account</span></MenuButton>
        <Menu className="redhat-display text-sm">
          <MenuItem onClick={() => editAccount()}>Profiel bewerken</MenuItem>
          <MenuItem onClick={() => myBids()}>Mijn biedingen</MenuItem>
          <MenuItem onClick={() => myFavorites()}>Mijn favorieten</MenuItem>
          <MenuItem onClick={() => myTransactionHistory()}>Mijn transacties</MenuItem>
          <MenuItem onClick={() => myChats()}>Mijn chats</MenuItem>
          <MenuItem onClick={() => settings()}>Instellingen</MenuItem>
          <MenuItem onClick={() => setLogoutOpen(true)}>Uitloggen</MenuItem>
        </Menu>
      </Dropdown>
    </>
  );
}