import * as Ons from "react-onsenui";

import "onsenui/css/onsenui.css";
import "onsenui/css/onsen-css-components.css";

import moment from "moment";
import CountDownTimer from "../../components/countdown.timer";
import OfferPage from "../offers/[offerId]/page";
import PriceType, { offerTypeToString } from "../../domain/offer/offer.type";
import MobileAllAccountsPage from "../accounts/mobile.all.accounts";
import MobileSearchItemsResultPage from "../items/mobile.search.items.result";
import Loading from "../../components/loading";
import MobileMapSearch from "../search/mobile.map.search";
import Favorite from "../../components/favorite";
import AccountPage from "../accounts/[accountId]/page";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "../../domain/constants";
import { favoritesService, searchService, utils } from "../../services";
import { Utils } from "../../services/utils";
import { DefaultMobileToolbar } from "../default.mobile.toolbar";
import { accountRoleToString } from "../../domain/account/account.role";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../domain/env";
import { EventBus } from "../../services/event.bus";
import ProductType, { productTypeFromSearchQuery } from "../../domain/item/product.type";
import MobileSearchFilter from "../search/mobile.search.filter";

export default function MobileMarketplace({ data }) {
  const navigate = useNavigate();

  const [topOffers, setTopOffers] = useState<any[]>(null);
  const [topAccounts, setTopAccounts] = useState<any[]>(null);
  const [favorites, setFavorites] = useState<any[]>(null);

  function loadData() {
    if (data.offers.length > 10) {
      var top = data.offers.slice(0, 10);
      top.push({ "show-everything": true });
      setTopOffers(top);
    } else {
      setTopOffers(data.offers);
    }

    if (data.accounts.length > 10) {
      var top = data.accounts.slice(0, 10);
      top.push({ "show-everything": true });

      setTopAccounts(top);
    } else {
      setTopAccounts(data.accounts);
    }

    favoritesService.getFavorites(data,
      (result) => { 
        setFavorites(result);
      });
  }

  useEffect(() => {
    if (data && !topOffers && !topAccounts) {
      loadData();

      EventBus.getInstance().register(
        EventBus.AUCTION_TIME_UP, (id) => {
          auctionTimeUp(id);
        });
    }
  }, [data]);

  function goToOffer(offer) {  
    Utils.mobileNavigator.pushPage({
      page: <OfferPage offerId={offer.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Item bekijken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToAccountInfo(account) {
    Utils.mobileNavigator.pushPage({
      page: <AccountPage accountId={account.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Account" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToAllItems() {
    Utils.mobileNavigator.pushPage({
      page: <MobileSearchItemsResultPage search={{}} searchResults={null}/>,
      renderToolbar: () => {
          return <DefaultMobileToolbar text="Alle items" backButton={true} 
            mapCallback={() => mobileSearchMapCallback({})}/>
      }
    }, { animation: "none" });
  }

  function goToAllAccounts() {
    Utils.mobileNavigator.pushPage({
      page: <MobileAllAccountsPage searchResults={null} showMapIcon={true}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Alle accounts"/>
      }
    }, { animation: "none" });
  }

  const renderHeader = (text) => {
    return (
      <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
        <div className="body-style">{text}</div>
      </Ons.ListHeader>
    );
  }

  function auctionTimeUp(offerId) {

  }

  const renderOfferRow = (row, index) => {
    var offer = topOffers[index];

    if (row["show-everything"] == true) {
      offer = topOffers[0];

      return (
        <div key={"show-everything"}>
          <Ons.ListItem key={"show-everything"}>
            <div className="flex flex-row grow body-style blur-sm mb-[-30px]" onClick={() => {}}>
              <img 
                className={`mr-4 ml-0 mt-0 rounded-lg mobile-item-list-image ${ offer.offerType == PriceType.AUCTION ? "mobile-auction-image" : "" } ${ offer.offerType == PriceType.BUSINESS_OFFER ? "mobile-business-image" : ""}`}
                src={`${BACKEND_URL}/static/img/preview/${offer.itemId}`}
                alt="Item image"
                width={80}
                height={80}/>

                <div className="flex flex-col">
                  <div className="text-xs font-bold secondary-color">{ offer.title }</div>

                  <div className="font-bold bid-amount relative text-left">{
                    offer.amount ?
                      "\u20AC" + utils.roundedAmount(offer.amount, 2)
                      : "Bieden"
                  }</div>

                  <div className="text-xs">
                    Sinds { moment(offer.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
                  </div>

                  <div className="text-xs">
                    Type: { offerTypeToString(offer.offerType) }
                  </div>

                  {
                    offer.city ?
                      <div className="text-xs">
                        { offer.city } { utils.getDistance(offer) }
                      </div>
                      : <></>
                  }
                </div>
            </div>

            <div className="w-full text-center">
              <Ons.Button className="link-color min-w-[150px] relative top-[-30px]" onClick={() => {goToAllItems()}}>
                <span className="redhat-display text-sm normal-case">Alles&nbsp;bekijken</span>
              </Ons.Button>
            </div>
          </Ons.ListItem>
        </div>);
    } else {
      return (
        <div key={offer.id}>
          <Ons.ListItem key={offer.id} modifier="nodivider">
            <div className="flex flex-row w-full body-style" onClick={() => goToOffer(offer)}>
              <img 
                className={`mr-4 ml-0 mt-0 rounded-lg mobile-item-list-image ${ offer.offerType == PriceType.AUCTION ? "mobile-auction-image" : "" } ${ offer.offerType == PriceType.BUSINESS_OFFER ? "mobile-business-image" : ""}`}
                src={`${BACKEND_URL}/static/img/preview/${offer.itemId}`}
                alt="Item image"
                width={80}
                height={80}/>
              
              <div className="flex flex-col w-full">
                <div className="text-xs font-bold secondary-color">{ offer.title }</div>

                <div className="font-bold bid-amount relative text-left">{
                  offer.amount ?
                    "\u20AC" + utils.roundedAmount(offer.amount, 2)
                    : "Bieden"
                }</div>

                <div className="text-xs">
                  Sinds { moment(offer.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
                </div>

                <div className="text-xs">
                  Type: { offerTypeToString(offer.offerType) }
                </div>

                {
                  offer.city ?
                    <div className="text-xs">
                      { offer.city } { utils.getDistance(offer) }
                    </div>
                    : <></>
                }
              </div>

              {
                offer.offerType == PriceType.AUCTION ? 
                  <CountDownTimer 
                    offer={offer}
                    labelClassName="relative left-[30px] top-[20px] text-xs"
                    timerClassName="relative left-[40px] top-[20px] auction-time-mobile"/>
                  : <></>
              }

              <div className="grow"></div>
              
              <Favorite itemType={"offer"} favoriteEntityId={offer.id}/>
            </div>
          </Ons.ListItem>
        </div>);
    }
  }

  const renderAccountRow = (row, index) => {
    var account = topAccounts[index];

    if (row["show-everything"] == true) {
      account = topAccounts[0];

      return (
        <div key={"show-everything"}>
          <Ons.ListItem key={"show-everything"}>
            <div className="flex flex-row grow body-style blur-sm mb-[-30px]" onClick={() => {}}>
              <img 
                className="avatar-img-account-mobile rounded-full h-[100px] w-[100px] bg-gray-50 p-2 z-30 relative left-[-5px] top-[0px]"
                src={`${BACKEND_URL}/static/img/preview/${account.id}`}
                alt="Item image"
                width={40}
                height={40}/>

              <div className="flex flex-col">
                <div className="text-xs font-bold">{ account.firstName }</div>

                <div className="text-xs">
                  Sinds { moment(account.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
                </div>

                <div className="text-xs">
                  Type: { accountRoleToString(account.accountRole) }
                </div>

                {
                  account.city ?
                    <div className="text-xs">
                      { account.city } { utils.getDistance(account.city) }
                    </div>
                    : <></>
                }

                {
                  account.nrOffers ?
                    <div className="text-xs">
                      { account.nrOffers } aanbiedingen
                    </div>
                    : <>&nbsp;</>
                }

                {
                  account.nrTrades ?
                    <div className="text-xs">
                      { account.nrTrades } transacties
                    </div>
                    : <>&nbsp;</>
                }
              </div>
            </div>

            <div className="w-full text-center">
              <Ons.Button className="link-color min-w-[150px] relative top-[-40px]" onClick={() => {goToAllAccounts()}}>
                <span className="redhat-display text-sm normal-case">Alles&nbsp;bekijken</span>
              </Ons.Button>
            </div>
          </Ons.ListItem>      
        </div>);
    } else {
      return (
        <div key={account.id}>
          <Ons.ListItem key={account.id} modifier="nodivider">
            <div className="flex flex-row grow body-style"
                onClick={() => goToAccountInfo(account)}>
              <img 
                className="avatar-img-account-mobile rounded-full h-[100px] w-[100px] bg-gray-50 p-2 z-30 relative left-[-5px] top-[0px]"
                src={`${BACKEND_URL}/static/img/preview/${account.id}`}
                alt="Item image"
                width={40}
                height={40}/>

              <div className="flex flex-col">
                <div className="text-xs font-bold">{ account.firstName }</div>

                <div className="text-xs">
                  Sinds { moment(account.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
                </div>

                <div className="text-xs">
                  Type: { accountRoleToString(account.accountRole) }
                </div>

                {
                  account.city ?
                    <div className="text-xs">
                      { account.city } { utils.getDistance(account.city) }
                    </div>
                    : <></>
                }

                {
                  account.nrOffers ?
                    <div className="text-xs">
                      { account.nrOffers } aanbiedingen
                    </div>
                    : <>&nbsp;</>
                }

                {
                  account.nrTrades ?
                    <div className="text-xs">
                      { account.nrTrades } transacties
                    </div>
                    : <>&nbsp;</>
                }
              </div>

              <div className="grow"></div>

              <Favorite itemType={"account"} favoriteEntityId={account.id}/>
            </div>
          </Ons.ListItem>
        </div>);
    }
  }


  function mobileQuickSearch(e) {
    var query = e.target.value;

    if (!query || query == "") {
      return;
    }

    var parts = query.split(" ");
    parts = parts.map(part => part.trim());

    var productTypes: ProductType[] = [];
    var years: number[] = [];
    var description: string[] = [];

    for (var part of parts) {
      var tryProductType = productTypeFromSearchQuery(part);

      if (tryProductType) {
        productTypes.push(tryProductType);
      } else {
        var tryYear: number = null;

        tryYear = parseInt(part);

        if (isNaN(tryYear)) {
          description.push(part);
        } else {
          if (tryYear >= 1900 && tryYear <= new Date().getFullYear()) {
            years.push(tryYear);
          }
        }
      }
    }

    var search = {
      "productTypes": productTypes,
      "years": years,
      "description": description
    };
    
    searchService.mobileQuickSearch(search)
      .then((results) => {
        Utils.mobileNavigator.pushPage({
          page: <MobileSearchItemsResultPage search={search} searchResults={results}/>,
          name: "search/" + utils.makeId(5),
          renderToolbar: () => {
            return <DefaultMobileToolbar text="Zoekresultaten" 
              backButton={true} showMapIcon={true} 
              mapCallback={() => mobileSearchMapCallback(search)}/>
          }
        }, { animation: "none" });
      });
  }

  function showSearchFilter() {
    utils.cleanElements();

    Utils.mobileNavigator.pushPage({
      page: <MobileSearchFilter/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Zoeken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function showMapSearch() {
    var search = {};

    searchService.mapSearch(search)
      .then((results) => {
        Utils.mobileNavigator.pushPage({
          page: <MobileMapSearch data={results} search={search}/>,
          renderToolbar: () => {
            return <DefaultMobileToolbar text="Kaart"/>
          }
        }, { animation: "none" });
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      })
  }

  function mobileSearchMapCallback(search) {
    searchService.mapSearch(search)
      .then((results) => {
        Utils.mobileNavigator.pushPage({
          page: <MobileMapSearch data={results} search={search}/>,
          name: "search/" + utils.makeId(5),
          renderToolbar: () => {
            return <DefaultMobileToolbar text="Zoekresultaten" backButton={true}/>
          }
        }, { animation: "none" });
      });
  }  

  if (!data || !topOffers || !topAccounts) {
    return <Loading/>;
  }

  return (
    <div className="mt-[18px]">
      <div className="flex flex-col">
        <div className="flex flex-row ml-[10px] mr-[10px]">
          <Ons.SearchInput placeholder="Zoeken" 
            style={{ 
              padding: "0", 
              width: "calc(100%)",
              margin: "10px", 
              marginTop: "-10px",
              marginLeft: "0" }}
            onChange={(e) => mobileQuickSearch(e)}/>

          <div className="min-h-[28px] min-w-[28px] relative top-[-6px]" 
              onClick={() => showSearchFilter()}>
            <Ons.Icon icon="md-tune" className="search-icons relative top-[-3px]"/>
          </div>

          <div className="min-h-[28px] min-w-[28px] relative top-[-6px]" 
              onClick={() => showMapSearch() }>
            <Ons.Icon icon="md-globe" className="search-icons relative top-[-3px]"/>
          </div>
        </div>
      </div>

      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={topOffers}
        renderRow={(row, index) => renderOfferRow(row, index)}
        renderHeader={() => renderHeader(data.nrOffers + " items")}/>

      <div className="w-full h-[1px] border-solid search-line mb-[10px]"></div>

      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={topAccounts}
        renderRow={(row, index) => renderAccountRow(row, index)}
        renderHeader={() => renderHeader(data.nrAccounts + " accounts")}/>

      <div className="mb-[80px]"></div>
    </div>);
}