import * as Ons from "react-onsenui";
import { Utils } from "../../services/utils";
import { stripeCheckout } from "../../domain/payment/stripe.connect";
import { Preferences } from "@capacitor/preferences";
import Loading from "../../components/loading";

export default function MobilePaymentPage({ tradeId, stripeAccountId, stripeClientSecret, paymentFinishedCallback }) {
  // const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(null);

  function pay() {
   
  }
  
  function cancelPayment() {
    Utils.mobileNavigator.popPage({ animation: "none" });

    // paymentFinishedCallback(PaymentStatus.FAILED, null,
    //   "Fill in payment failure reason here");
  }

  function getStripeSessionId(): Promise<string> {
    return Preferences.get({ key: "stripeSessionId" }).then((result) => {
      console.log(result.value);
      return result.value;
    });
  }

  // function getTransactionCosts(p) {
  //   switch (p) {
  //     case PaymentMethod.IDEAL: 
  //       return "+\u20AC0,29";
  //     case PaymentMethod.CREDIT_CARD:
  //       return "+\u20AC0,25 + 1,8%";
  //     case PaymentMethod.APPLE_PAY:
  //       return "Tarief creditcard";
  //     case PaymentMethod.BANK_TRANSFER:
  //       return "+\u20AC0,25";
  //   }
  // }
  
  // function getPaymentMethod(p) {
  //   return (
  //     <div className="redhat-display mt-4 mb-8 ml-4" onClick={() => setPaymentMethod(p)}>
  //       <div className="flex flex-row">
  //         <div className="mt-auto mb-auto">
  //           <input 
  //             type="radio" 
  //             id={"radio-PAYMENT-" + p}
  //             name="radio-PAYMENT"
  //             className="pr-3 body-style redhat-display relative top-[4px]"
  //             checked={paymentMethod == p}/>
  //         </div>

  //         <div className="flex flex-col grow" 
  //             onClick={() => {}}>
  //           <div className="flex flex-row">
  //             <img 
  //               className="rounded-lg m-2 max-w-[35px] max-h-[35px] mt-auto mb-auto"
  //               src={ "/" + p + ".png" }
  //               alt="carrier logo"
  //               width={35}
  //               height={35}/>

  //             <div className="flex flex-col">
  //               <div className="break-keep text-sm font-bold">{ paymentMethodToString(p) }</div>
  //               <div className="text-sm text-slate-300">{ getTransactionCosts(p)} </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>);
  // }

  if (!stripeClientSecret) {
    return <Loading/>;
  }

  const options = {clientSecret: stripeClientSecret};

  return (
    <>
      { stripeCheckout(options) }

      <div className="w-full justify-center flex flex-row gap-2 absolute bottom-0 mb-8">
        <div className="">
          <Ons.Button className="link-color p-2 min-w-[150px]" onClick={() => cancelPayment()}>
            <span className="redhat-display text-sm capitalize">Annuleren</span>
          </Ons.Button>
        </div>

        <div className="">
          <Ons.Button className="link-color p-2 min-w-[150px]" onClick={() => pay()}>
            <span className="redhat-display text-sm capitalize">Betalen</span>
          </Ons.Button>
        </div>
      </div>
    </>
  );
}