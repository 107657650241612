import { GOOGLE_API_KEY } from "../domain/env";

export default class MapService {
  public getLatLngFromPostalCode(map, postalCode, callback) {
    // var service = new window.google.maps.places.PlacesService(map);

    // var request = {
    //   "query": postalCode + ", The Netherlands",
    //   "fields": [ "geometry" ]
    // };

    // service.findPlaceFromQuery(request, function(results, status) {
    //   console.log(results, status);
    // });
  }

  public getAddressLocation(address, callback) {
    try {
      address = address.replace(" ", "+");
      var baseUrl = "https://maps.googleapis.com/maps/api/geocode/json";
      baseUrl += "?address=" + address + "&key=" + GOOGLE_API_KEY;

      fetch(baseUrl)
        .then((response) => {
          response.json().then((json) => {
            try {
              const location = json["results"][0]["geometry"]["location"];

              if (location) {
                callback(location);
              }
            } catch {

            }
          });
        });
    } catch {
      return null;
    }
  }
}