import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants";
import Trade from "./trade";
import BusinessTrade from "./business.trade";
import UserTrade from "./user.trade";
import { bidTypeFromString } from "../bid/bid.type";
import { tradeTypeFromString } from "./trade.type";
import { offerTypeFromString } from "../offer/offer.type";

export class TradeBuilder {
  static buildTrade(data: any): Trade {
    var offer = null;

    if (data["tradeType"] == "BUSINESS_TRADE") {
      offer = new BusinessTrade(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        tradeTypeFromString(data.tradeType),
        data.amount,
        data.bidId,
        data.bidAccountId,
        bidTypeFromString(data.bidType),
        data.buyerFirstName,
        data.offerId,
        data.itemId,
        offerTypeFromString(data.offerType),
        data.offerAccountId,
        data.title,
        data.incoming);
    } else if (data["tradeType"] == "USER_TRADE") {
      offer = new UserTrade(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        tradeTypeFromString(data.tradeType),
        data.amount,
        data.bidId,
        data.bidAccountId,
        bidTypeFromString(data.bidType),
        data.buyerFirstName,
        data.offerId,
        data.itemId,
        offerTypeFromString(data.offerType),
        data.offerAccountId,
        data.title,
        data.incoming);
    }

    return offer;
  }
}
