import { carrierFromString } from "./carrier";
import { shippingFunctionalityFromString } from "./shipping.functionality";
import ShippingServiceDetails from "./shipping.service.details";

export default class ShippingServiceDetailsBuilder {
  static buildShippingServiceDetails(data: any): ShippingServiceDetails {
    return new ShippingServiceDetails(
      data.id,
      data.name,
      carrierFromString(data.carrier),
      data.functionalities.map(f => shippingFunctionalityFromString(f)),
      data.minWeight,
      data.maxWeight,
      data.maxLength,
      data.maxWidth,
      data.maxHeight,
      data.ageCheck18,
      data.signature,
      data.neighborDelivery,
      data.weekendDelivery,
      data.price,
      data.leadTimeHours);
  }
}