import * as Ons from "react-onsenui";
import * as React from "react";
import * as Yup from 'yup';
import parse from "date-fns/parse";
import dayjs from "dayjs";
import Radio from "@mui/joy/Radio";
import Map from "../../../components/map";
import AccountRole from "../../../domain/account/account.role";
import Checkbox from "../../../components/checkbox";
import { useFormik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { ALL_AVATARS, DEFAULT_MAP_LOCATION } from "../../../domain/constants";
import { mapService, authService, utils, settings } from "../../../services";
import { useNavigate } from "react-router-dom";
import { ENABLE_CREATE_ACCOUNT } from "../../../domain/env";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import StripeOnboarding from "./stripe.onboarding";
import ColorScheme from "../../../domain/color.scheme";
import { Utils } from "../../../services/utils";
import MobileCreatedAccount from "./mobile.created.account";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import MobileIdCheck from "./idcheck/mobile.idcheck";
import Spinner from "../../../components/spinner";

export default function NewAccount() {
  const [accountRole, setAccountRole] = useState<AccountRole>(AccountRole.USER);

  const [cityMapZoom, setCityMapZoom] = useState<number>(6);
  const [cityMapLocation, setCityMapLocation] = useState<any>(DEFAULT_MAP_LOCATION);
  const [cityMapMarkers, setCityMapMarkers] = useState<any[]>([]);

  const [selectedAvatar, setSelectedAvatar] = useState<number>(1);
  const [useOwnImage, setUseOwnImage] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [showDeleteImageConfirmation, setShowDeleteImageConfirmation] = useState<boolean>(false);

  const [stripeAccountOnboarding, setStripeAccountOnBoarding] = useState<boolean>(false);
  const [stripeAccountId, setStripeAccountId] = useState<string>(null);
  const [stripeClientSecret, setStripeClientSecret] = useState<string>(null);
  const [createdAccountId, setCreatedAccountId] = useState<string>(null);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  
  const navigate = useNavigate();

  const handleAccountRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == "USER") {
      setAccountRole(AccountRole.USER);
    } else if (event.target.value == "BUSINESS") {
      setAccountRole(AccountRole.BUSINESS);
    }
  }

  const handleCityChange = (e) => {
    var city = e.target.value;

    mapService.getAddressLocation(city, (location) => {
      if (location) {
        setCityMapLocation(location);
        setCityMapZoom(10);
        setCityMapMarkers([{
          key: city,
          lat: location.lat,
          lng: location.lng,
          tooltip: false
        }]);
      }
    });
  }

  const formik = useFormik({
		initialValues: {
      accountRole: "USER",
		  firstName: "Steven",
		  lastName: "Raemaekers",
      birthDate: "01-08-1984",
      street: "Amstelboulevard 62",
      postalCode: "1096 HJ",
      city: "Amsterdam",
      phoneNumber: "+31626966234",
      emailAddress: "stevenraemaekers@gmail.com",
      password: "hoerenlul123",
      confirmPassword: "hoerenlul123",
      visible: true,
      images: [],
      kvkNumber: "",
      businessName: "",
      agreeToTerms: true,
      older18: true,
      avatar: "",
      showLastName: false,
      showAddress: false,
      showBirthDate: false,
      showPhoneNumber: false,
      showCoordinates: false,
      showKvkNumber: false,
      showStatistics: false
		},
    onSubmit: values => {
      createAccount(values);
    },
		validationSchema: () => {
      var baseValidation = Yup.object({
        accountRole: Yup.string()
          .required("Kies een type account"),
        firstName: Yup.string()
          .required("Vul een voornaam in"),
        lastName: Yup.string()
          .required("Vul een achternaam in"),
        street: Yup.string()
          .required("Vul een straat in"),
        postalCode: Yup.string()
          .matches(/^\d\d\d\d ?[a-zA-Z][a-zA-Z]$/, "Vul een geldige postcode in")
          .required("Vul een postcode in"),
        city: Yup.string()
          .required("Vul een stad in"),
        phoneNumber: Yup.string()
          .required("Vul een telefoonnummer in"),
        emailAddress: Yup.string().email("Vul een geldig emailadres in")
          .required("Vul een geldig emailadres in")
          .test("email-exists", "Dit emailadres bestaat al",
            async function(value) {
              if (value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
                var exists = await authService.emailExists(value);
                return !exists;
              } else {
                return false;
              }
            }),
        password: Yup.string()
          .min(10, "Uw wachtwoord moet minimaal 10 karakters lang zijn")
          .required("Vul een wachtwoord in"),
        confirmPassword: Yup.string()
          .required("Vul opnieuw je wachtwoord in")
          .min(10, "Uw wachtwoord moet minimaal 10 karakters lang zijn")
          .test('passwords-match', 'Wachtwoord komt niet overeen', 
            function (value) {
              return this.parent.password == value;
            }),
        visible: Yup.boolean()
          .optional(),
        agreeToTerms: Yup.bool()
          .oneOf([true], "U moet akkoord gaan met de algemene voorwaarden"),
        showLastName: Yup.boolean()
          .optional(),
        showAddress:  Yup.boolean()
          .optional(),
        showBirthDate: Yup.boolean()
          .optional(),
        showPhoneNumber: Yup.boolean()
          .optional(),
        showCoordinates: Yup.boolean()
          .optional(),
        showKvkNumber: Yup.boolean()
          .optional(),
        showStatistics: Yup.boolean()
          .optional()
      });

      if (accountRole == AccountRole.USER) {
        var userValidation = Yup.object({
          birthDate: Yup.date()
            .required("Vul een geboortedatum in")
            .max(dayjs().subtract(18, "year"), "Je moet minimaal 18 jaar oud zijn")
            .transform(function (value, originalValue) {
              if (!/^\d\d-\d\d-\d\d\d\d$/.test(originalValue)) {
                return null;
              }

              try {
                return parse(originalValue, "dd-MM-yyyy", new Date());
              } catch {
                return null;
              }
            })
            .typeError("Vul een geldige geboortedatum in"),
          older18: Yup.bool()
            .oneOf([true], "U moet minimaal 18 jaar oud zijn")
        })

        return baseValidation.concat(userValidation);
      } else if (accountRole == AccountRole.BUSINESS) {
        var businessValidation = Yup.object({
          kvkNumber: Yup.string()
            .required("Vul een KVK-nummer in"),
          businessName: Yup.string()
            .required("Vul een bedrijfsnaam in")
        })

        return baseValidation.concat(businessValidation);
      }

      return baseValidation;
		}
  });

  function createAccount(values: any) {
    if (!ENABLE_CREATE_ACCOUNT) {
      return;
    }

    setShowSpinner(true);

    if (accountRole == AccountRole.USER) {
      values["accountRole"] = "USER";
    } else if (accountRole == AccountRole.BUSINESS) {
      values["accountRole"] = "BUSINESS";
      delete values.older18;
    }

    delete values.confirmPassword;

    if (useOwnImage && !uploadedFile) {
      setUseOwnImage(false);
      setSelectedAvatar(1);
    }

    if (uploadedFile) {
      uploadAccountImage((imageId) => {
        createAccountInBackend(values, imageId, null);
      });
    } else {
      createAccountInBackend(values, null, selectedAvatar);
    }
  }

  function uploadAccountImage(callback: (imageId) => void) {
    authService.uploadNewAccountImage(uploadedFile)
      .then((id) => {
        callback(id);
      })
  }
  
  function createAccountInBackend(values: any, 
                                  accountImageId?: string, 
                                  avatarIdx?: number) {
    authService.newAccount(values, accountImageId, avatarIdx)
      .then((result) => {
        console.log(result);

        setStripeAccountId(result.stripeAccountId);
        setStripeClientSecret(result.stripeClientSecret);
        setCreatedAccountId(result.id);

        if (utils.isMobile()) {
          setStripeAccountOnBoarding(true);
        } else {
          var url = "/gropp/account/new/idcheck?accountId=" + result.id;
          navigate(url);
        }
      }).catch((e) => {
        console.error(e);

        // if (e.response.status == 409) {
        //   setEmailAlreadyExists(true);
        // } else {
        //   setEmailAlreadyExists(false);
        // }
      });
  }

  const handleUploadFile = (file: any) => {
    setUploadedFile(file);
  }

  const handleFileEvent = (e: any) => {
    const chosenFile = e.target.files;

    if (e.target.files && e.target.files.length > 0) {
      handleUploadFile(chosenFile[0]);
    }
  }

  function changeImageTab(e) {
    if (e.target.innerText == "Avatar") {
      setUseOwnImage(false);
    } else {
      setUseOwnImage(true);
    }
  }

  function askDeleteImage() {
    setShowDeleteImageConfirmation(true);
  }

  function doDeleteImage() {
    setUploadedFile(null);
    setShowDeleteImageConfirmation(false);
  }

  function selectMobilePhoto() {
    Camera.checkPermissions().then((result) => {
      
    });
    
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 90,
        width: 200,
        height: 200,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      });
      
      let blob = await fetch(image.webPath).then(r => r.blob());
    }
    
    takePicture();
  }

  function getColorScheme() {
    if (settings.colorScheme == ColorScheme.DARK) {
      return {
        colorBackground: "#33192D",
        colorPrimary: "#0076BD",
        colorText: "#FFFFFF"
      };
    } else {
      return {
        colorBackground: "#F2E5D6",
        colorPrimary: "#0076BD",
        colorText: "#FFFFFF"
      };
    }
  }

  function stripeOnboardingFinished(a, i, s) {
    console.log("stripeOnboardingFinished", a, i, s, createdAccountId);

    if (!createdAccountId) {
      return;
    }
    
    Utils.mobileNavigator.pushPage({
      page: <MobileIdCheck accountId={createdAccountId}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Account aangemaakt" backButton={false}/>
      }
    }, { animation: "none" });
  }

  function renderMobile() {
    return (
      <div className="p-2">
        <form onSubmit={formik.handleSubmit}>
          {
            stripeAccountOnboarding && stripeAccountId && stripeClientSecret ?
              <StripeOnboarding 
                accountId={createdAccountId}
                stripeAccountId={stripeAccountId} 
                stripeClientSecret={stripeClientSecret}
                callback={ (a, i, s) => stripeOnboardingFinished(a, i, s) }
                colorScheme={getColorScheme()}/>
              :
              <>
                <div className="m-1 ml-2 redhat-display">Type&nbsp;account:</div>
                <Ons.List>
                  <div key="listitem-USER" className="m-2">
                      <label className="left">
                        <input
                          type="radio"
                          id="input-USER"
                          className="relative top-[4px]"
                          checked={accountRole == AccountRole.USER}
                          value="USER"
                          onChange={ e => {
                            handleAccountRole(e);
                          }}
                        />
                      </label>
                      <label htmlFor={`input-USER`} className="center redhat-display">Gebruiker</label>
                    </div>

                    <div key="listitem-BUSINESS" className="m-2">
                      <label className="left">
                        <input
                          type="radio"
                          id="input-BUSINESS"
                          className="relative top-[4px]"
                          checked={accountRole == AccountRole.BUSINESS}
                          value="BUSINESS"
                          onChange={ e => {
                            handleAccountRole(e);
                          }}
                        />
                      </label>
                      <label htmlFor={`input-BUSINESS`} className="center redhat-display">Zakelijk</label>
                    </div>
                </Ons.List>

                <div className="m-1 ml-2 redhat-display">Voornaam:</div>
                <input
                  type="text"
                  id="firstName"
                  className="search-field redhat-display m-1 ml-2"
                  placeholder=""
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}/>

                {
                  formik.touched.firstName && formik.errors.firstName ?
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.firstName}</div>
                    : null
                }

                <div className="m-1 ml-2 redhat-display">Achternaam:</div>
                <input
                  type="text"
                  id="lastName"
                  className="search-field redhat-display m-1 ml-2"
                  placeholder=""
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}/>

                {
                  formik.touched.lastName && formik.errors.lastName ?
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.lastName}</div>
                    : null
                }

                {
                  accountRole == AccountRole.USER ?
                    <>
                      <div className="ml-2 redhat-display">Geboortedatum:</div>
                      <input className="search-field redhat-display m-1 ml-2" 
                        id="birthDate" 
                        placeholder="dd-mm-jjjj" 
                        type="text"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.birthDate}
                        />

                      {
                        formik.touched.birthDate && formik.errors.birthDate ? (
                          <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.birthDate}</div>) 
                          : null
                      }
                    </>
                    : <></>
                }

                <div className="m-1 ml-2 redhat-display">Straat:</div>
                <input
                  type="text"
                  id="street"
                  className="search-field w-full redhat-display ml-2 mr-2"
                  placeholder=""
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}/>

                {
                  formik.touched.street && formik.errors.street ?
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.street}</div>
                    : null
                }

                <div className="m-1 ml-2 redhat-display">Postcode:</div>
                <input 
                  className="w-full search-field uppercase redhat-display ml-2 mr-2" 
                  id="postalCode" 
                  placeholder="1234 AB" 
                  type="text"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.postalCode}
                />

                {
                  formik.touched.postalCode && formik.errors.postalCode ?
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.postalCode}</div>
                    : null
                }

                <div className="m-1 ml-2 redhat-display">Stad:</div>
                <input
                  type="text"
                  id="city"
                  className="search-field w-full redhat-display ml-2 mr-2"
                  placeholder=""
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}/>

                {
                  formik.touched.city && formik.errors.city ?
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.city}</div>
                    : null
                }

                <div className="m-1 ml-2 redhat-display">Telefoonnummer:</div>
                <input className="w-full search-field redhat-display m-1 ml-2" 
                  id="phoneNumber" 
                  placeholder="+31612345678" 
                  type="text"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                />

                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.phoneNumber}</div>
                ) : null }
                
                <div className="m-1 ml-2 redhat-display">Emailadres:</div>
                <input 
                  className="w-full search-field redhat-display m-1 ml-2" 
                  id="emailAddress" 
                  type="text"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailAddress}
                />
                  
                {formik.touched.emailAddress && formik.errors.emailAddress ? (
                  <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.emailAddress}</div>
                ) : null }

                <div className="m-1 ml-2 redhat-display">Privacy:</div>
                <div className="flex flex-col m-1 ml-2">
                  <div className="mb-1">
                    <label className="right">
                      <Checkbox
                        disabled={false}
                        id="showLastName"
                        label=""
                        className="relative top-[4px]"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.showLastName}
                      />
                    </label>
                    <label htmlFor={`showLastNam`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;achternaam</label>
                  </div>
                
                  <div className="mb-1">
                    <label className="right">
                      <Checkbox
                        disabled={false}
                        id="showAddress"
                        label=""
                        className="relative top-[4px]"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.showAddress}
                      />
                    </label>
                    <label htmlFor={`showAddress`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;adres</label>
                  </div>
                  
                  <div className="mb-1">
                    <label className="right">
                      <Checkbox
                        disabled={false}
                        id="showBirthDate"
                        label=""
                        className="relative top-[4px]"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.showBirthDate}
                      />
                    </label>
                    <label htmlFor={`showBirthDate`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;geboortedatum</label>
                  </div>

                  <div className="mb-1">
                    <label className="right">
                      <Checkbox
                        disabled={false}
                        id="ShowPhoneNumber"
                        label=""
                        className="relative top-[4px]"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.showPhoneNumber}
                      />
                    </label>
                    <label htmlFor={`ShowPhoneNumber`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;telefoonnummer</label>
                  </div>

                  <div className="mb-1">
                    <label className="right">
                      <Checkbox
                        disabled={false}
                        id="showCoordinates"
                        label=""
                        className="relative top-[4px]"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.showCoordinates}
                      />
                    </label>
                    <label htmlFor={`ShowCoordinates`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;locatie&nbsp;op&nbsp;kaart</label>
                  </div>

                  {
                    accountRole == AccountRole.BUSINESS ?
                      <div className="mb-1">
                        <label className="right">
                          <Checkbox
                            disabled={false}
                            id="showKvkNumber"
                            label=""
                            className="relative top-[4px]"
                            onChange={ e => {
                              formik.handleChange(e);
                            }}
                            value={formik.values.showKvkNumber}
                          />
                        </label>
                        <label htmlFor={`ShowKvkNumber`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;KvK-nummer</label>
                      </div>
                      : <></>
                  }

                  <div className="mb-1">
                    <label className="right">
                      <Checkbox
                        disabled={false}
                        id="showStatistics"
                        label=""
                        className="relative top-[4px]"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        value={formik.values.showStatistics}
                      />
                    </label>
                    <label htmlFor={`ShowStatistics`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;statistieken</label>
                  </div>
                </div>

                { 
                  accountRole == AccountRole.BUSINESS ?
                    <>
                      <div className="m-1 ml-2 redhat-display">Kvk-nummer:</div>
                      <input className="w-full search-field m-1 ml-2" id="kvkNumber" type="text"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.kvkNumber}
                      />
                      
                      {
                        formik.touched.kvkNumber && formik.errors.kvkNumber ? (
                          <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.kvkNumber}</div>) 
                          : null 
                      }
                    </>
                    : <></> 
                }

                { 
                  accountRole == AccountRole.BUSINESS ?
                    <>
                      <div className="m-1 ml-2 redhat-display">Bedrijfsnaam:</div>

                      <div className="grow">
                        <input className="w-full search-field m-1 ml-2 redhat-display" id="businessName" type="text"
                          onChange={ e => {
                            formik.handleChange(e);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.businessName}
                        />
                      </div>
                        
                      {
                        formik.touched.businessName && formik.errors.businessName ? (
                          <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.businessName}</div>) 
                          : null
                      }
                    </>
                    : <></>
                }

                <div className="m-1 ml-2 redhat-display">Wachtwoord:</div>
                <input className="w-full search-field redhat-display m-1 ml-2" id="password" type="password"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />

                {
                  formik.touched.password && formik.errors.password ? (
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.password}</div>) 
                    : null
                }
                
                <div className="m-1 ml-2 redhat-display">Herhaal wachtwoord:</div>          
                <input
                  className="w-full search-field redhat-display m-1 ml-2" 
                  id="confirmPassword" 
                  type="password"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                />
                
                {
                  formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.confirmPassword}</div>) 
                    : null 
                }

                <div className="m-2">
                  <label className="right">
                    <Checkbox
                      id="visible"
                      disabled={false}
                      label=""
                      className="relative top-[4px]"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      value={formik.values.visible}
                    />
                  </label>
                  <label htmlFor={`visibleInputId`} className="mt-4 mr-4 ml-2 redhat-display">Zichtbaar</label>
                </div>

                {
                  formik.touched.visible && formik.errors.visible ? (
                    <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.visible}</div>) 
                    : null
                }

                <div className="mt-2">
                  <div className="mt-2 mb-4 redhat-display">Afbeelding:</div>

                  {
                    <div>
                      <Tabs defaultValue={0} 
                          onChange={changeImageTab}
                          className="m-0 p-0 rounded-lg mobile-avatar-border min-w-[350px] max-w-[350px] ml-auto mr-auto">
                        <TabList className="body-style grow">
                          <Tab className="select-image-grow mobile-avatar-corner-tl">Avatar</Tab>
                          <Tab className="select-image-grow rounded-tr-lg">Eigen&nbsp;afbeelding</Tab>
                          <Tab className="select-image-grow mobile-avatar-corner-tr">Foto&nbsp;maken</Tab>
                        </TabList>

                        <TabPanel value={0}>
                          <div className="grid grid-cols-4 mb-0 mt-2 h-[500px]">
                          {
                            ALL_AVATARS.map((a, i) => (
                              <div key={i + 1}>
                                <img 
                                  className="mx-auto m-2 cursor-pointer w-[50px] h-[50px]" 
                                  src={a}
                                  onClick={() => setSelectedAvatar(i + 1)}
                                  width="50" 
                                  height="50" 
                                  alt="Avatar"/>
                                { 
                                  selectedAvatar == (i + 1) ? 
                                    <div key={i} className="selected-avatar-box rounded-lg border-accent-color border-2 border-dashed"></div>
                                    : <></>
                                }
                              </div>
                            ))
                          }
                          </div>
                        </TabPanel>

                        <TabPanel value={1}>
                          <div className="h-[500px]">
                            <div className="dropZoneContainer mt-2">
                              {
                                !uploadedFile ? 
                                  <input type="file"
                                    multiple 
                                    id="drop_zone" 
                                    className="FileUpload relative top-[145px]" 
                                    accept=".jpg,.png"
                                    onChange={handleFileEvent}/>
                                : <></>
                              }
                              {
                                uploadedFile ? 
                                  <>
                                    <img src={URL.createObjectURL(uploadedFile)} 
                                      width={100} 
                                      height={100} 
                                      className="mx-auto rounded-lg w-[100px] h-[100px] mb-12"/>
                                    <div className="w-full mx-auto text-center">
                                      <a className="dropZoneOverlay" onClick={askDeleteImage}>Verwijderen</a>
                                    </div>
                                  </>
                                  : <div 
                                    className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed mx-auto mb-12"></div>
                              }
                              {
                                !uploadedFile ? 
                                  <div className="dropZoneOverlay rounded-lg">Selecteren</div>
                                  : <></>
                              }
                              <Modal open={showDeleteImageConfirmation}>
                                <ModalDialog variant="plain" role="alertdialog">
                                  <DialogTitle>Annuleren</DialogTitle>
                                  <Divider />
                                  <DialogContent>
                                    Weet je zeker dat je deze afbeelding wilt verwijderen?
                                  </DialogContent>
                                  <DialogActions>
                                    <Button variant="solid" 
                                      className="background-accent-color" 
                                      onClick={() => setShowDeleteImageConfirmation(false)}>Nee</Button>
                                    <Button variant="plain" color="neutral" onClick={doDeleteImage}>Ja</Button>
                                  </DialogActions>
                                </ModalDialog>
                              </Modal>
                            </div>
                          </div>
                        </TabPanel>

                        <TabPanel value={2}>
                          <div className="h-[500px] text-center justify-center align-center">
                            <div className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed  bg-white ml-auto mr-auto mt-[38px]"
                              onClick={() => selectMobilePhoto() }>
                              <div className="w-[100px] h-[100px] text-xl">
                                <Ons.Icon icon="md-camera-add" className="relative link-color top-[33px]"/>
                              </div>
                              <div className="link-color mt-4">Foto maken</div>
                            </div>
                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  }
                </div>

                <div className="mt-8 mb-8 ml-2 mr-2 redhat-display text-center">
                  Als u een profiel aanmaakt op Gropp.nl moet u instemmen met de <Link to="#">algemene voorwaarden</Link>. Ook moet u verklaren minimaal 18 jaar oud te zijn. Na het aanmaken van uw profiel zal er een identiteitscontrole worden uitgevoerd.
                </div>

                <div className="mt-4 mb-4 ml-2">
                  <label className="right">
                    <Checkbox
                      disabled={false}
                      id="agreeToTerms"
                      label=""
                      className="relative top-[4px]"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      value={formik.values.agreeToTerms}
                    />
                  </label>
                  <label htmlFor={`agreeToTerms`} className="text-xs mt-4 mr-4 ml-2 redhat-display">
                    <span>Ik&nbsp;ga&nbsp;akkoord&nbsp;met&nbsp;de&nbsp;algemene&nbsp;voorwaarden</span>
                  </label>
                </div>

                {
                  formik.touched.agreeToTerms && formik.errors.agreeToTerms ? (
                    <div className="text-xs mt-1 ml-2 text-red-600 redhat-display">{formik.errors.agreeToTerms}</div>) 
                    : null
                }

                {
                  accountRole == AccountRole.USER ? 
                    <>
                      <div className="mt-2 ml-2">
                        <label className="right">
                        <Checkbox
                          disabled={false}
                          id="older18"
                          label=""
                          className="relative top-[4px]"
                          onChange={ e => {
                            formik.handleChange(e);
                          }}
                          value={formik.values.older18}/>
                        </label>
                        <label htmlFor={`older18`} className="text-xs mt-4 mr-4 ml-2 redhat-display">
                          <span>Ik&nbsp;ben&nbsp;op&nbsp;dit&nbsp;moment&nbsp;18&nbsp;jaar&nbsp;of&nbsp;ouder</span>
                        </label>
                      </div>

                      {
                        formik.touched.older18 && formik.errors.older18 ? (
                          <div className="text-xs mt-1 ml-2 text-red-600 redhat-display">{formik.errors.older18}</div>) 
                          : null
                      }
                    </>
                    : <></>
                }
                
                {
                  !formik.isValid && formik.submitCount > 0 ? 
                    <>
                      <div className="text-center text-sm text-red-600 redhat-display ml-4 mr-4 mt-4 mb-0">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
                    </>
                    : <></>
                }

                {
                  showSpinner ? 
                    <div className="justify-center mx-auto scale-50 mt-[-20px]">
                      <Spinner/>
                    </div>
                    : <></>
                }
                
                <div className="flex flex-row gap-4 w-full mt-4 mb-[100px] justify-center text-center">
                  <button className="redhat-display button min-w-[150px] mb-4 link-color" 
                      type="button" 
                      onClick={() => formik.submitForm()}>
                    <span className="relative top-[0px] text-sm redhat-display pt-0 normal-case">Account&nbsp;aanmaken</span>
                  </button>
                </div>
              </>
          }
        </form>
      </div>
    );
  }

  function renderWeb() {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="container grid grid-cols-1 max-w-sm mx-auto justify-center place-content-center aling-middle">
          <div className="grid grid-rows-3 mb-8">
            <label className="grow" htmlFor="accountRole">Type account:</label>
              <Radio
                id="radio_account_role_user"
                className="pt-3 pr-3 body-style"
                onChange={ e => {
                  handleAccountRole(e);
                }}
                checked={accountRole == AccountRole.USER}
                value="USER"
                name="radio-button-account-role"
                label="Gebruiker"/>

              <Radio
                id="radio_account_role_business"
                className="pt-3 pr-3 body-style"
                onChange={ e => {
                  handleAccountRole(e);
                }}
                checked={accountRole == AccountRole.BUSINESS}
                value="BUSINESS"
                name="radio-button-account-role"
                label="Zakelijk"/>

            {formik.touched.accountRole && formik.errors.accountRole ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.accountRole}</div>
            ) : null }
          </div>
            
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="firstName">Voornaam:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="firstName" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
            </div>
            
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.firstName}</div>
            ) : null }
          </div>
          
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="lastName">Achternaam:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="lastName" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
            </div>

            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.lastName}</div>
            ) : null }
          </div>

          {
            accountRole == AccountRole.USER ? 
              <div className="grid grid-rows-3">
                <label className="grow" htmlFor="birthDate">Geboortedatum:</label>

                <div className="grow">
                  <input className="min-w-md w-full larger-input" 
                      id="birthDate" 
                      placeholder="dd-mm-jjjj" 
                      type="text"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.birthDate}
                      />
                </div>

                {formik.touched.birthDate && formik.errors.birthDate ? (
                  <div className="text-xs mt-1 text-right text-red-600">{formik.errors.birthDate}</div>
                ) : null }
              </div>
              : <></>
          }

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="city">Stad:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="city" type="text"
                onChange={ e => {
                  handleCityChange(e);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
            </div>

            {formik.touched.city && formik.errors.city ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.city}</div>
            ) : null }
          </div>

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="street">Straat:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="street" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.street}
              />
            </div>

            {formik.touched.street && formik.errors.street ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.street}</div>
            ) : null }
          </div>

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="postalCode">Postcode:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input uppercase" id="postalCode" placeholder="1234 AB" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
              />
            </div>

            {formik.touched.postalCode && formik.errors.postalCode ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.postalCode}</div>
            ) : null }
          </div>
          
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="phoneNumber">Telefoonnummer:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="phoneNumber" placeholder="+31612345678" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
              />
            </div>

            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.phoneNumber}</div>
            ) : null }
          </div>

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="emailAddress">Emailadres:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="emailAddress" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.emailAddress}
              />
            </div>
            
            {formik.touched.emailAddress && formik.errors.emailAddress ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.emailAddress}</div>
            ) : null }
          </div>


          <div className="flex flex-col">
            <label className="mb-4">Privacy:</label>
            
            <div className="flex flex-col gap-2 mb-4">
              <Checkbox
                disabled={false}
                id="showLastName"
                label="Toon achternaam"
                className="relative top-[4px]"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.showLastName}
              />

              <Checkbox
                disabled={false}
                id="showAddress"
                label="Toon adres"
                className="relative top-[4px]"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.showAddress}
              />

              <Checkbox
                disabled={false}
                id="showBirthDate"
                label="Toon geboortedatum"
                className="relative top-[4px]"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.showBirthDate}
              />

              <Checkbox
                disabled={false}
                id="showPhoneNumber"
                label="Toon telefoonnummer"
                className="relative top-[4px]"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.showPhoneNumber}
              />

              <Checkbox
                disabled={false}
                id="showCoordinates"
                label="Toon locatie op kaart"
                className="relative top-[4px]"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.showCoordinates}
              />

              {
                accountRole == AccountRole.BUSINESS ?
                  <Checkbox
                    disabled={false}
                    id="showKvkNumber"
                    label="Toon KvK-nummer"
                    className="relative top-[4px]"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.showKvkNumber}
                  />
                  : <></>
              }

              <Checkbox
                disabled={false}
                id="showStatistics"
                label="Toon profielstatistieken"
                className="relative top-[4px]"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.showStatistics}
              />
            </div>

            <div></div>
          </div>

          <div className="p-0 m-0 mx-auto w-[350px] h-[350px] mb-4 mt-0">
            <Map
              mapLocation={cityMapLocation}
              markerRenderer={(marker) => "" }
              zoom={cityMapZoom} 
              searchRadius={10} 
              markers={cityMapMarkers}
              width={"calc(100% - 20px)"}
              height={"calc(100% - 20px)"}
            />
          </div>

          { 
            accountRole == AccountRole.BUSINESS ?
              <>
                <div className="grid grid-rows-3">
                  <label className="grow" htmlFor="kvkNumber">KVK-nummer:</label>

                  <div className="grow">
                    <input className="min-w-md w-full larger-input" id="kvkNumber" type="text"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.kvkNumber}
                    />
                  </div>
                  
                  {formik.touched.kvkNumber && formik.errors.kvkNumber ? (
                    <div className="text-xs mt-1 text-right text-red-600">{formik.errors.kvkNumber}</div>
                  ) : null }
                </div>
              </>
              : <></> 
          }

          { 
            accountRole == AccountRole.BUSINESS ?
              <>
                <div className="grid grid-rows-3">
                  <label className="grow" htmlFor="businessName">Bedrijfsnaam:</label>

                  <div className="grow">
                    <input className="min-w-md w-full larger-input" id="businessName" type="text"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.businessName}
                    />
                  </div>
                  
                  {formik.touched.businessName && formik.errors.businessName ? (
                    <div className="text-xs mt-1 text-right text-red-600">{formik.errors.businessName}</div>
                  ) : null }
                </div>
              </>
              : <></>
          }

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="password">Wachtwoord:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="password" type="password"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.password}
              />
            </div>

            {formik.touched.password && formik.errors.password ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.password}</div>
            ) : null }
          </div>
          
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="confirmPassword">Herhaal wachtwoord:</label>
            
            <div className="grow">
              <input
                className="min-w-md w-full larger-input" 
                id="confirmPassword" 
                type="password"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
              />
            </div>
            
            {
              formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.confirmPassword}</div>) 
                : null
            }
          </div>

          <div className="flex flex-row">
            <Checkbox
              disabled={false}
              id="visible"
              label="Zichtbaar"
              className=""
              onChange={ e => {
                formik.handleChange(e);
              }}
              value={formik.values.visible}
            />
          </div>
          
          {
            formik.touched.visible && formik.errors.visible ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.visible}</div>) 
              : null
          }

          <div className="mt-2">
            <div className="mt-2 mb-4">Afbeelding:</div>

            {
              <>
                <Tabs defaultValue={0} 
                    onChange={changeImageTab}
                    className="w-full body-style mb-8 rounded-br-lg rounded-bl-lg">
                  <TabList className="body-style">
                    <Tab className="grow rounded-tl-lg">Avatar</Tab>
                    <Tab className="grow rounded-tr-lg">Eigen afbeelding</Tab>
                  </TabList>

                  <TabPanel value={0}>
                    <div className="grid grid-cols-4 mb-0 mt-4 h-[500px]">
                    {
                      ALL_AVATARS.map((a, i) => (
                        <div key={i + 1}>
                          <img 
                            className="mx-auto mb-4 mt-2 cursor-pointer w-[50px] h-[50px]" 
                            src={a}
                            onClick={() => setSelectedAvatar(i + 1)}
                            width="50" 
                            height="50" 
                            alt="Avatar"/>
                          { 
                            selectedAvatar == (i + 1) ? 
                              <div key={i} className="rounded-lg w-[70px] h-[70px] relative top-[-76px] left-[5px] mb-[-70px] border-accent-color border-2 border-dashed"></div>
                              : <></>
                          }
                        </div>
                      ))
                    }
                    </div>
                  </TabPanel>
                  <TabPanel value={1}>
                    <div className="h-[500px]">
                      <div className="dropZoneContainer mt-2">
                        {
                          !uploadedFile ? 
                            <input type="file"
                              multiple 
                              id="drop_zone" 
                              className="FileUpload relative top-[145px]" 
                              accept=".jpg,.png"
                              onChange={handleFileEvent}/>
                          : <></>
                        }
                        {
                          uploadedFile ? 
                            <>
                              <img src={URL.createObjectURL(uploadedFile)} 
                                width={100} 
                                height={100} 
                                className="mx-auto rounded-lg w-[100px] h-[100px] mb-12"/>
                              <div className="w-full mx-auto text-center">
                                <a className="dropZoneOverlay" onClick={askDeleteImage}>Verwijderen</a>
                              </div>
                            </>
                            : <div 
                              className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed mx-auto mb-12"></div>
                        }
                        {
                          !uploadedFile ? 
                            <div className="dropZoneOverlay rounded-lg">Selecteren</div>
                            : <></>
                        }
                        <Modal open={showDeleteImageConfirmation}>
                          <ModalDialog variant="plain" role="alertdialog">
                            <DialogTitle>Annuleren</DialogTitle>
                            <Divider />
                            <DialogContent>
                              Weet je zeker dat je deze afbeelding wilt verwijderen?
                            </DialogContent>
                            <DialogActions>
                              <Button variant="solid" 
                                className="background-accent-color" 
                                onClick={() => setShowDeleteImageConfirmation(false)}>Nee</Button>
                              <Button variant="plain" color="neutral" onClick={doDeleteImage}>Ja</Button>
                            </DialogActions>
                          </ModalDialog>
                        </Modal>
                      </div>
                    </div>

                  </TabPanel>
                </Tabs>
              </>
            }
          </div>

          <div className="mt-0">
            Als u een profiel aanmaakt op Gropp.nl moet u instemmen met de <Link to="#">algemene voorwaarden</Link>. Ook moet u verklaren minimaal 18 jaar oud te zijn. Na het aanmaken van uw profiel zal er een identiteitscontrole worden uitgevoerd.
          </div>

          <div className="grid grid-rows-3 mt-8">
            <div className="grow mb-4">
              <Checkbox
                disabled={false}
                className="relative top-[4px]"
                id="agreeToTerms"
                label="&nbsp;Ik ga akkoord met de algemene voorwaarden"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                value={formik.values.agreeToTerms}
              />
            </div>

            {formik.touched.agreeToTerms && formik.errors.agreeToTerms ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.agreeToTerms}</div>
            ) : null }
          </div>

          {
            accountRole == AccountRole.USER ? 
              <div className="grid grid-rows-3 mt-[-20px]">
                <div className="grow mb-4">
                  <Checkbox
                    disabled={false}
                    className="relative top-[4px]"
                    id="older18"
                    label="&nbsp;Ik ben op dit moment 18 jaar of ouder"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.older18}
                  />
                </div>

                {formik.touched.older18 && formik.errors.older18 ? (
                  <div className="text-xs mt-1 text-right text-red-600">{formik.errors.older18}</div>
                ) : null }
              </div>
              : <></>
          }

          {
            !formik.isValid && formik.submitCount > 0 ? 
              <>
                <div className="text-center text-red-600">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
              </>
              : <></>
          }

          <div className="flex flex-row-reverse row-cols-2 mb-8">        
          <div className="pt-10 pb-7 mr-4">
            <button className="normal-button" onClick={() =>{}}>Annuleren</button>
          </div>
          </div>
        </div>
      </form>
    );
  }
  
  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}