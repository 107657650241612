import * as Ons from "react-onsenui";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalClose, ModalDialog } from "@mui/joy";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import { DEFAULT_MAP_LOCATION } from "../../../domain/constants";
import { productTypeToString } from "../../../domain/item/product.type";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { OfferState, offerStateToString } from "../../../domain/offer/offer.state";
import { authService, utils } from "../../../services";
import { Utils } from "../../../services/utils";
import { EventBus } from "../../../services/event.bus";
import { BACKEND_URL } from "../../../domain/env";
import GroupItem from "../../../domain/item/group.item";
import ItemBuilder from "../../../domain/item/item.builder";
import SingleItem from "../../../domain/item/single.item";
import Auction from "../../../domain/offer/auction";
import BusinessOffer from "../../../domain/offer/business.offer";
import AccountBuilder from "../../../domain/account/account.builder";
import UserOffer from "../../../domain/offer/user.offer";
import ChatDrawer from "../../chat/chat.drawer";
import Map from "../../../components/map";
import ChatPanelMessages from "../../chat/chat.panel.messages";
import NewTrade from "../../trades/new/page";
import Loading from "../../../components/loading";
import EditItem from "../../account/items/edit/page";
import MobileBidPage from "../mobile.bid.page";
import PriceType from "../../../domain/offer/offer.type";
import MobileSingleLocation from "../../search/mobile.single.location";
import CountDownTimer from "../../../components/countdown.timer";
import AccountPage from "../../accounts/[accountId]/page";
import BidsItem from "../bids/page";

export default function OfferPage({ offerId, readOnly = false }: { offerId: string, readOnly?: boolean }) {
  const [item, setItem] = useState<any>(null);
  const [owner, setOwner] = useState<any>(null);
  const [offer, setOffer] = useState<any>(null);

  const [itemImages, setItemImages] = useState<File[]>([]);
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [showButtons, setShowButtons] = useState<boolean>(false);

  const [enlargedImage, setEnlargedImage] = useState<any>(null);
  const [enlargeImageOpen, setEnlargeImageOpen] = useState<boolean>(false);
  
  const [showOwnerLocation, setShowOwnerLocation] = useState<boolean>(false);
  const [ownerMapMarker, setOwnerMapMarker] = useState<any[]>(null);

  const [accountId, setAccountId] = useState<string>(null);

  const navigate = useNavigate();

  const loadData = (offerId: string) => {
    utils.getLoggedInAccountId().then((result1) => {
      if (!result1) {
        return;
      }
      
      setAccountId(result1);

      var url = BACKEND_URL + "/offers/" + offerId;
    
      authService.getSecure(url)
        .then((result2) => {
          if (result2 && result2.data) {
            var offer = OfferBuilder.buildOffer(result2.data);
  
            if (offer) {
              setOffer(offer);
              
              fetchItem(offer.itemId);
              fetchOwner(offer.accountId);
  
              authService.fetchImages(offer.itemId)
                .then((result3) => {
                  setItemImages(result3);
                });
  
              setTimeout(() => {
                setShowButtons(true);
              }, 200);
            }
          }
        }).catch((_) => {
          utils.handleUnauthorized(() => navigate("/login"));
        });
    });
  }

  useEffect(() => {
    if (!offer) {    
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => loadData(offerId));
  
      loadData(offerId);
    }
  }, null);
  
  function fetchItem(itemId: string) {
    var url = BACKEND_URL + "/items/" + itemId;

    authService.getSecure(url)
      .then((result) => {
        var item = ItemBuilder.buildItem(result.data);
        setItem(item);
      });
  }

  function fetchOwner(ownerId: string) {
    var url = BACKEND_URL + "/accounts/info/" + ownerId;

    authService.getSecure(url)
      .then((result) => {
        var account = AccountBuilder.buildAccount(result.data);
        setOwner(account);
      });
  }

  /**
    * If the currently logged in account
    * is not the owner of the offer, and
    * the offer is a business offer,
    * buy it directly.
    */
  function buyItem() {
    if (offer == null) {
      return;
    }

    if (offer instanceof BusinessOffer) {
      // Create a new trade
      // Forward the user to the trade screen
    } else if (offer instanceof UserOffer) {
    }

    navigate("/gropp/trades/new?offerId=" + offer.id);
  }

  /**
    * If the currently logged in account
    * is not the owner of the offer,
    * create a new bid on this offer.
    */
  function bidOnOffer() {
    if (!hasBidding()) {
      return;
    }

    // if (offer == null) {
    //   return;
    // }

    // var url = null;

    // if (offer.isUserOffer()) {
    //   url = BACKEND_URL + "/bids/user/" + offerId;
    // } else if (offer.isBusinessOffer()) {
    //   url = BACKEND_URL + "/binds/business/" + offerId; 
    // }
  }

  /**
    * If the currently logged in account
    * is not the owner of the offer, chat
    * with the owner of the offer.
    */
  function chatWithOwner() {
    if (offer == null) {
      return;
    }

    setChatOpen(true);
  }

  /**
    * If the currently logged in account is not the 
    * owner of the offer, and he has made a bid,
    * retract that bid on this offer.
    */
  function retractBid() {
    if (offer == null) {
      return;
    }
  }

  /**
    * If the currently logged in account
    * is the owner of the offer, edit the offer.
    */
  function editOffer() {
    if (offer == null) {
      return;
    }

    Utils.mobileNavigator.pushPage({
      page: <EditItem offer={offer} item={item}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Item bewerken" 
          backButton={true} showBackConfirmation={true}/>
      }
    }, { animation: "none" });
  }

  /**
    * If the currently logged in account is 
    * the owner of the offer, retract the offer.
    */
  function retractOffer() {
    if (offer == null) {
      return;
    }
  }

  /**
    * If the currently logged in account
    * is the owner of the offer, accept a bid on this offer.
    */
  function acceptBid(bidId: string) {
    if (offer == null) {
      return;
    }

  }

  /**
    * If the currently logged in account
    * is the owner of the offer, reject a bid on this offer.
    */
  function rejectBid(bidId: string) {
    if (offer == null) {
      return;
    }

  }

  /**
    * If the currently logged in account
    * is the owner of the offer, and the offer 
    * is an auction, close the auction.
    */
  function closeAuction(auctionId: string) {
    if (offer == null) {
      return;
    }

  }

  /**
    * Obtain the title belonging to the
    * item of this offer.
    */
  function getTitle(): string {
    if (item == null) {
      return "";
    }

    return item.title;
  }

  function getPriceLabel(): string {
    if (hasBidding()) {
      return "Huidige biedprijs: ";
    } else if (isUserOffer()) {
      return "Vraagprijs: ";
    } else {
      return "Prijs: ";
    }
  }

  function getOfferState() {
    if (offer == null) {
      return "";
    }
     
    return offerStateToString(offer.offerState);
  }

  /**
   * Obtain the description belonging to
   * the item of this offer.
   */
  function getDescription(): string {
    if (item == null) {
      return "";
    }

    return item.description;
  }

  function getBrand(): string {
    if (item == null) {
      return "";
    }

    return item.brand;
  }

  function offerTypeToStringStr(): string {
    if (offer == null) {
      return "";
    }

    if (offer instanceof Auction) {
      return "Veiling";
    } else if (offer instanceof UserOffer) {
      return "Gebruiker";
    } else if (offer instanceof BusinessOffer) {
      return "Winkel";
    }

    return "";
  }

  function getProductType(): string {
    if (item == null) {
      return "";
    }

    return productTypeToString(item.productType);
  }

  function getNrItems(): string {
    if (item instanceof SingleItem) {
      return "1";
    } else if (item instanceof GroupItem) {
      var total = 0;

      for (var i = 0; i < item.elements.length; i++) {
        total += item.elements[i].itemCount;
      }

      return "" + total;
    }
  }

  function getItemYear(): string {
    if (item == null) {
      return "";
    }

    return item.itemYear;
  }

  function getLocation(): string {
    if (owner == null) {
      return "";
    }

    return owner.firstName;
  }

  /**
    * Obtain the owner id of this offer.
    */
  function getOwner(): string {
    if (offer == null) {
      return "";
    }
    
    return offer.ownerId;
  }

  /**
    * Obtain the creation time of this offer.
    */
  function getCreateTime(): string {
    if (offer == null) {
      return "";
    }
    
    return utils.renderFriendlyDateTime(offer.createTime);
  }

  /**
    * Check whether the currently logged 
    * in account is the owner of this item.
    */
  function isOwner(): boolean {
    if (offer == null) {
      return false;
    }

    if (!accountId) {
      return false;
    }

    return accountId == owner.id &&
      accountId == item.ownerId;
  }
 
  /**
    * If the currently logged in account 
    * is the owner of this offer, show
    * a link to the underlying item.
    */
  function getItem(): string {
    if (offer == null) {
      return "";
    }

    return "item link";
  }

  function printOfferState(): string {
    if (offer == null) {
      return "";
    }

    return offerStateToString(offer.offerState);
  }

  function getAuctionEnd(): string {
    if (offer == null) {
      return "";
    }

    return "auction end";
  }

  function getStartPrice(): string {
    if (offer == null) {
      return "";
    }

    return "start price";
  }

  function getAllowBidding(): boolean {
    var result = false;

    if (offer != null) {
      if (offer instanceof UserOffer) {
        result = (offer as UserOffer).allowBidding;
      }
    }
 
    return result;
  }

  function isUserOffer(): boolean {
    if (offer == null) {
      return false;
    }

    return offer instanceof UserOffer;
  }

  function hasBidding(): boolean {
    if (offer instanceof Auction) {
      return true;
    }

    if (offer instanceof UserOffer) {
      if ((offer as UserOffer).allowBidding) {
        return true;
      }
    }
    
    return false;
  }

  function getToAccountId(): string {
    if (owner) {
      return owner.id;
    }

    return "";
  }

  function getRemainingItemImages() {
    if (itemImages.length >= 3) {
      return Array(0);
    } else {
      return Array(3 - itemImages.length);
    }
  }

  function showPrice(): boolean {
    if (!offer) {
      return false;
    }

    if (offer instanceof UserOffer) {
      return true;
    } if (offer instanceof BusinessOffer) {
      return true;
    } else if (offer instanceof Auction) {
      if (offer.bids.length > 0) {
        return true;
      }
    }

    return false;
  }

  function enlargeImage(file) {
    setEnlargedImage(URL.createObjectURL(file));
    setEnlargeImageOpen(true);
  }

  function getMapLocation() {
    if (owner) {
      return {
        lat: owner.latitude,
        lng: owner.longitude
      };
    } else {
      return null;
    }
  }

  function validCoordinates(item) {
    if (!item) {
      return false;
    }

    if (!owner.showCoordinates) {
      return false;
    }

    if (!item.latitude || !item.longitude) {
      return false;
    }

    return (item.latitude >= -90.0 && item.latitude <= 90.0 &&
        item.longitude >= -180.0 && item.longitude <= 180.0);
  }

  function showOwnerLocationPopup() {
    if (!item) {
      return;
    }

    var marker = [];
    var location = {};

    if (!validCoordinates(item)) {
      return;
    }

    location = {
      lat: item.latitude, 
      lng: item.longitude
    };

    marker = [{
      key: item.offerId,
      lat: item.latitude,
      lng: item.longitude,
      tooltip: false
    }];

    setOwnerMapMarker(marker);

    if (utils.isMobile()) {
      Utils.mobileNavigator.pushPage({
        page: <MobileSingleLocation marker={marker}/>,
        renderToolbar: () => {
          return <DefaultMobileToolbar text="Bekijk locatie" backButton={true}/>
        }
      }, { animation: "none" });
    } else {
      setShowOwnerLocation(true);
    }
  }

  function getOwnerLocationPopup() {
    return (
      <Modal open={showOwnerLocation}>
        <ModalDialog variant="plain" role="alertdialog">
          <DialogTitle>Locatie eigenaar</DialogTitle>
          <Divider />
          <DialogContent>
            <div className="w-[300px] h-[200px] text-center justify-center mx-auto">
              <Map 
                mapLocation={DEFAULT_MAP_LOCATION} 
                zoom={10} 
                searchRadius={0} 
                markers={ownerMapMarker}
                markerRenderer={(marker) => "" }
                width={"100%"}
                height={"100%"}/>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" 
              className="background-accent-color" 
              onClick={() => setShowOwnerLocation(false)}>Sluiten</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }

  function getOwnerLink() {
    if (owner) {
      return "/gropp/accounts/" + owner.id;
    } else {
      return "#";
    }
  }

  if (!accountId || !offer || !item || !owner || !itemImages) {
    return <Loading/>;
  }

  function openMobileChat() {
    if (!owner) {
      return;
    }
    
    Utils.mobileNavigator.pushPage({
      page: <ChatPanelMessages 
        fromAccountId={accountId}
        toAccountId={owner.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Chatten"/>
      }
    }, { animation: "none" });

    setTimeout(() => {
      EventBus.getInstance()
        .dispatch<void>(EventBus.ON_CHAT_OPEN);
    }, 500);
  }

  function openOwner() {
    if (!owner) {
      return;
    }

    Utils.mobileNavigator.pushPage({
      page: <AccountPage accountId={owner.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Bekijk eigenaar"/>
      }
    }, { animation: "none" });
  }

  function openMobileBid() {
    if (!offer) {
      return;
    }

    Utils.mobileNavigator.pushPage({
      page: <MobileBidPage offer={offer}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Bieden"/>
      }
    }, { animation: "none" });
  }

  function openMobileBuy() {
    if (!offer) {
      return;
    }

    Utils.mobileNavigator.pushPage({
      page: <NewTrade offerId={offer.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Kopen"/>
      }
    }, { animation: "none" });
  }

  function getBottomButtons() {
    const getMobileEditButton = () => {
      if (isOwner() && !readOnly) {
        return (
          <>
            <Ons.Button className="link-color p-2 min-w-[120px]" onClick={() => {editOffer()}}>
            <span className="redhat-display text-sm capitalize">Bewerken</span></Ons.Button>
          </>);
      } else {
        return (<></>);
      }
    }

    const getMobileChatButton = () => {
      if (isOwner() || offer.offerType == PriceType.BUSINESS_OFFER || readOnly) {
        return (<></>);
      } else {
        return (
          <>
            <Ons.Button className="link-color p-2 min-w-[120px]" onClick={() => {openMobileChat()}}>
              <span className="redhat-display text-sm capitalize">Chatten</span>
            </Ons.Button>
          </>);
      }
    }

    const getMobileBidBuyButton = () => {
      if (isOwner() || readOnly) {
        return (<></>);
      } else if (hasBidding()) {
        return (
          <>
            <Ons.Button
                className="link-color p-2 min-w-[120px]" 
                onClick={() => {openMobileBid()}}>
              <span className="redhat-display text-sm capitalize">Bieden</span>
            </Ons.Button>
          </>);
      } else {
        return (
          <>
            <Ons.Button className="link-color p-2 min-w-[120px]" 
                onClick={() => {openMobileBuy()}}>
              <span className="redhat-display text-sm capitalize">Kopen</span></Ons.Button>
          </>);
      }
    }

    if (utils.isMobile()) {
      return (
        <>
          <div className="flex flex-row mb-16 w-full justify-center gap-2 mt-8">
            { getMobileChatButton() }
            { getMobileEditButton() }
            { getMobileBidBuyButton() }
          </div>

          <div className="mb-[100px]"></div>
        </>
      );
    } else {
        return (<></>);
    }
  }
  
  function renderMobileOffer() {
    return (
      <>
        <div className="">
          <div className="p-4 pt-0 body-style">
            <div className="flex flex-row gap-2 mt-4 justify-center mb-8">
              {itemImages.map((file: any) => (
                <div key={file.name}>
                  <img 
                    src={URL.createObjectURL(file)} 
                    width={100} 
                    height={100}
                    onClick={() => enlargeImage(file)}
                    className="rounded-lg w-[100px] h-[100px] cursor-pointer"/>
                </div>
              ))}
            </div>
            
            <Modal
              open={enlargeImageOpen}
              onClose={() => setEnlargeImageOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <>
                  <ModalClose variant="plain" sx={{ m: 1 }} />
                  <img 
                    src={enlargedImage}
                    width={400} 
                    height={400}
                    className="w-full cursor-pointer"/>
                </>
            </Modal>

            <div className="font-bold">Naam</div>
            <div className={"overflow-scroll w-[300px] h-min h-max-[190px] break-words mb-6"}>
              { item.title }
            </div>

            <div className="font-bold">Omschrijving</div>
            <div className={"overflow-scroll w-[300px] h-min h-max-[190px] break-words mb-6"}>
              { item.isGroup ? item.groupDescription : item.description }
            </div>

            <div className="font-bold">Producttype</div>
            <div className="mb-6">{ productTypeToString(item.productType) }</div>    

            {
              item.itemYear ? 
                <>
                  <div className="font-bold">Jaar</div>
                  <div className="mb-6">{ item.itemYear }</div>
                </>
                : <></>
            }
            
            <div className="font-bold">Type aanbieding</div>
            <div className="mb-6">{ offerTypeToStringStr() }</div>

            <div className="font-bold">Aangemaakt</div>
            <div className="mb-6">{ getCreateTime() }</div>

            <div className="font-bold">Aantal items</div>
            <div className="mb-6">{ item instanceof SingleItem ? "1" : "" + item.nrItems }</div>

            {
              offer instanceof Auction ? 
                <>
                  <div className="font-bold">Status&nbsp;veiling</div>
                  <div className="mb-4">{ getOfferState() }</div>

                  {
                    offer.offerState == OfferState.OPEN ?
                      <>
                        <div className="font-bold">Resterende&nbsp;veilingtijd</div>
                        <CountDownTimer offer={offer} labelClassName={"opacity-0"} timerClassName={"relative top-[-25px]"}/>
                      </>
                      : <></>
                  }

                  <div className="font-bold">Totale&nbsp;veilingduur</div>
                  <div className="mb-4">{ utils.getAuctionDuration(offer) }</div>

                  <div className="font-bold">Aantal andere biedingen</div>
                  <div className="mb-4">{ offer.bids && offer.bids.length > 0 ? offer.bids.length - 1 : 0 }</div>

                  { 
                    offer.offerState == OfferState.OPEN ?
                      <>
                        <div className="font-bold">Sluiting&nbsp;veiling</div>
                        <div className="mb-4">{ utils.getAuctionCloseTime(offer) }</div>
                      </>
                      : 
                      <>
                        <div className="font-bold">Sluiting&nbsp;veiling</div>
                        <div className="mb-4">{ getOfferState() }</div>
                      </>
                  }
                  
                  {
                    offer.startPrice ? 
                      <>
                        <div className="font-bold">Startprijs</div>
                        <div className="font-bold link-color text-xl mb-4">{ utils.roundedAmount(offer.startPrice, 2) }</div>
                      </>
                      : <></>
                  }
                </>
                : <></>
            }

            {
              validCoordinates(item) ? 
                <>
                  <div className="font-bold">Locatie</div>
                  <div className="mb-6 mt-2">
                    <Ons.Button className="link-color p-2 min-w-[120px]" onClick={() => {openMobileChat()}}>
                      <span className="redhat-display text-sm">Bekijk&nbsp;locatie</span>
                    </Ons.Button>
                  </div>
                </>
                : <></>
            }
            
            { showOwnerLocation ? getOwnerLocationPopup() : <></> }
        
            {
              !readOnly ? 
                <>
                  <div className="font-bold">Eigenaar</div>
                  <div className="mb-6 mt-2">
                    <Ons.Button className="link-color p-2 min-w-[120px]" onClick={() => {openOwner()}}>
                      <span className="redhat-display text-sm normal-case">Bekijk&nbsp;eigenaar</span>
                    </Ons.Button>
                  </div>
                </>
              : <></>
            }

            {
              isUserOffer() ? 
                <>
                  <div className="font-bold">Bieden toegestaan</div>
                  <div className="mb-6">{ getAllowBidding() ? "ja" : "nee" }</div>
                </>
              : <></>
            }

            {
              showPrice() ?
                <>
                  <div className="font-bold">Prijs</div>
                  <div className="offer-price text-2xl mb-4">{ "\u20AC" + utils.roundedAmount(offer.amount) }</div>
                </>
                : <></>
            }

            {
              hasBidding() ? 
                <>
                  <div className="font-bold">Status</div>
                  <div className="mb-6">{ printOfferState() }</div>

                  <div className="font-bold mb-1">Biedingen</div>
                  <div className="embedded-bid-list"><BidsItem offerId={offer.id} showBidButton={false}/></div>
                </>
              : <></>
            }
          </div>
              
          { getBottomButtons() }

          <div className="mb-[80px]"></div>
      </div>
    </>);
  }

  function renderWebOffer() {
    return (
      <>
        <div className="mb-10">
          <div className="grid grid-cols-2 gap-4 justify-center">
            <div className="flex flex-row-reverse font-bold mt-auto mb-auto">
              <div className="">Afbeeldingen:</div>
            </div>

            <div className="flex flex-row gap-2 mt-4">
              {itemImages.map((file: any) => (
                <div key={file.name}>
                  <img 
                    src={URL.createObjectURL(file)} 
                    width={100} 
                    height={100}
                    onClick={() => enlargeImage(file)}
                    className="rounded-lg w-[100px] h-[100px] cursor-pointer"/>
                </div>
              ))}
            </div>

            <Modal
              open={enlargeImageOpen}
              onClose={() => setEnlargeImageOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <>
                  <ModalClose variant="plain" sx={{ m: 1 }} />
                  <img 
                    src={enlargedImage}
                    width={400} 
                    height={400}
                    className="rounded-lg w-[400px] h-[400px] cursor-pointer"/>
                </>
            </Modal>

            {
              !item.isGroup ?
                <>
                  <div className="font-bold text-right">Titel:</div>
                  <div>{ getTitle() }</div>
                </>
                : <></>
            }

            <div className="font-bold text-right">Omschrijving:</div>
            <div className={"overflow-scroll w-[300px] h-min h-max-[190px] break-words"}>
              { item.isGroup ? item.groupDescription : item.description }
            </div>
            
            <div className="font-bold text-right">Aangemaakt:</div>
            <div>{ getCreateTime() }</div>

            <div className="font-bold text-right">Type aanbieding:</div>
            <div>{ offerTypeToStringStr() }</div>

            <div className="font-bold text-right">Locatie:</div>
            <div><Link to="#" onClick={() => showOwnerLocationPopup()}>bekijk locatie</Link></div>

            { showOwnerLocation ? getOwnerLocationPopup() : <></> }
        
            { 
              <>
                <div className="font-bold text-right">Eigenaar:</div>
                <div><Link to={getOwnerLink()}>bekijk eigenaar</Link></div>
              </>
            }

            {
              hasBidding() ? 
                <>
                  <div className="font-bold text-right">Status:</div>
                  <div>{ printOfferState() }</div>
                </>
              : <></>
            }

            {
              isUserOffer() ? 
                <>
                  <div className="font-bold text-right">Bieden toegestaan:</div>
                  <div>{ getAllowBidding() ? "ja" : "nee" }</div>
                </>
              : <></>
            }

            {
              offer && offer.amount ? 
                <>
                  <div className="flex flex-row-reverse font-bold mt-auto mb-auto">
                    <div className="">{ getPriceLabel() }</div>
                  </div>

                  <div className="offer-price text-2xl">{ "\u20AC" + utils.round(offer.amount) }</div>
                </>
                : <></>
            }
          </div>
          
          { 
            showButtons ? 
              <div 
                id="button_row"
                className="flex flex-row-reverse row-cols-2 mb-8 mr-8 justify-center">
                {
                  isOwner() ?
                    <div className="pt-10 pb-7 mr-4">
                      <button 
                        id="button_edit"
                        className="normal-button"
                        type="submit" 
                        onClick={editOffer}>Bewerken</button>
                    </div>
                    : <></>
                }

                {
                  isOwner() ?
                    <></> :
                    (hasBidding() ? 
                      <div className="pt-10 pb-7 mr-4">
                        <button 
                          id="button_bid"
                          className="normal-button capitalize" 
                          type="submit" 
                          onClick={bidOnOffer}>Bieden</button>
                      </div>
                      : <>
                        <div className="pt-10 pb-7 mr-4">
                          <button 
                            id="button_buy"
                            className="normal-button capitalize" 
                            type="submit"
                            onClick={buyItem}>Kopen</button>
                        </div>
                      </>)
                }

                {
                  isOwner() ? 
                    <></> :
                    <div className="pt-10 pb-7 mr-4">
                      <button 
                        id="button_chat"
                        className="normal-button capitalize" 
                        type="submit" 
                        onClick={chatWithOwner}>Chatten</button>
                    </div>
                }
              </div>
            : <div className="mb-80"></div>
          }
        </div>
        
        {
          owner ?
            <>
              <ChatDrawer 
                fromAccountId={accountId} 
                toAccountId={getToAccountId()}
                isOpen={chatOpen}
                onCloseChat={() => {setChatOpen(false)}}/>
            </>
          : <></>
        }
      </>
    );
  }

  if (utils.isMobile()) {
    return renderMobileOffer();
  } else {
    return renderWebOffer();
  }
}