import moment from 'moment';
import { DATE_TIME_FORMAT } from '../constants';
import AccountRole, { accountRoleFromString } from './account.role';

class MarketplaceAccount {
  id: string;
  createTime: Date;
  accountRole: AccountRole;
  nrOffers: number;
  nrBids: number;
  nrTrades: number;
  nrItems: number;
  city: string;
  firstName: string;
  lastName: string;

  constructor($account: any) {
    this.id = $account.id;
    this.createTime = moment($account.createTime, DATE_TIME_FORMAT).toDate();
    this.accountRole = accountRoleFromString($account.accountRole);

    this.nrOffers = $account.nrOffers;
    this.nrBids = $account.nrBids;
    this.nrTrades = $account.nrTrades;
    this.nrItems = $account.nrItems;
    this.city = $account.city;
    this.firstName = $account.firstName;
    this.lastName = $account.lastName;
  }
}

export default MarketplaceAccount;