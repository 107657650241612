import AbstractEntity from "../abstract.entity";

class Review extends AbstractEntity {
  reviewedAccountId: string;
  accountId: string;
  firstName: string;
  review: string;
  
  constructor($id: string,
              $createTime: Date,
              $reviewedAccountId: string,
              $accountId: string,
              $firstName: string,
              $review: string) {
    super($id, $createTime);
    this.reviewedAccountId = $reviewedAccountId;
    this.accountId = $accountId;
    this.firstName = $firstName;
    this.review = $review;
  }
}

export default Review;