import moment from "moment";
import AbstractEntity from "./abstract.entity";
import HistoryItem from "./inbox/notification";
import { DATE_TIME_FORMAT } from "./constants";

export class ChatItem {
  createTime: Date;
  message: string;
  from: string;

  constructor($createTime: Date,
              $message: string) {
    this.createTime = moment($createTime, DATE_TIME_FORMAT).toDate();
    this.message = $message;
  }
}

export default class Chat extends AbstractEntity {
  fromAccountId: string;
  toAccountId: string;
  fromFirstName: string;
  toFirstName: string;
  messages: HistoryItem[];

  constructor($id: string,
              $createTime: Date,
              $fromAccountId: string,
              $toAccountId: string,
              $messages: HistoryItem[],
              $fromFirstName: string,
              $toFirstName: string) {
    super($id, $createTime);
    
    this.fromAccountId = $fromAccountId;
    this.toAccountId = $toAccountId;
    this.messages = $messages;
    this.fromFirstName = $fromFirstName;
    this.toFirstName = $toFirstName;
  }
}

export class ChatBuilder {
  static buildChat(data: any): Chat {
    var messages = JSON.parse(JSON.stringify(data.messages))
      .sort(function (a, b) {
        return a.i - b.i;
      }).map(i => {
        return new ChatItem(i.createTime, i.body);
      });

    var chat = new Chat(
      data.id,
      moment(data.createTime, DATE_TIME_FORMAT).toDate(),
      data.fromAccountId,
      data.toAccountId,
      messages,
      data.fromFirstName,
      data.toFirstName);

    return chat;
  }
}