import { accountRoleToString } from "../../domain/account/account.role";
import MarketplaceAccount from "../../domain/account/marketplace.account";
import { BACKEND_URL } from "../../domain/env";
import { utils } from "../../services";

export default function MarketplaceAccountItem({ account }: { account: MarketplaceAccount }) {
  return (
    <a href={`/gropp/accounts/${account.id}`} className="hover:no-underline no-decoration">
      <div suppressHydrationWarning className="flex flex-col m-8 mt-[-30px]">
        <div className="relative border-2 p-4 pt-20 rounded-lg shadow-box min-w-[200px] max-h-[400px] mb-[100px]">
          <img 
            className="avatar-img-account rounded-full h-24 w-24 bg-gray-50 p-2 z-30 mt-[-100px]"
            src={`${BACKEND_URL}/static/img/account/${account.id}`}
            alt="Account avatar"
            width={80}
            height={80}/>

          <div className="mb-1 font-bold offer-type">{ accountRoleToString(account.accountRole)}</div>

          <div className="font-bold mr-1">Naam</div>
          <div className="mb-1">{account.firstName + " " + account.lastName}</div>

          <div className="font-bold mr-1">Plaats</div>
          <div className="mb-1">{account.city}</div>

          <div className="font-bold mr-1">Aangemaakt</div>
          <div className="mb-1">{utils.renderFriendlyDateTime(account.createTime)}</div>

          <div className="font-bold mr-1">Aantal biedingen</div>
          <div className="mb-1">{account.nrBids}</div>

          <div className="font-bold mr-1">Aantal transacties</div>
          <div className="mb-1">{account.nrTrades}</div>

          <div className="font-bold mr-1">Aantal items</div>
          <div className="mb-1">{account.nrItems}</div>

          <div className="text-right mt-[-10px]">
            <span className="fake-link">Bekijken</span>
          </div>
        </div>
      </div>
    </a>
  );
}