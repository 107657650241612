import * as Ons from "react-onsenui";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DATE_TIME_FORMAT, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, DATE_FORMAT } from "../../domain/constants";
import moment from "moment";
import CountDownTimer from "../../components/countdown.timer";
import Loading from "../../components/loading";
import MobilePaginator from "../../components/mobile.paginator";
import PriceType, { offerTypeToString } from "../../domain/offer/offer.type";
import { searchService, utils } from "../../services";
import { EventBus } from "../../services/event.bus";
import { accountRoleToString } from "../../domain/account/account.role";
import Favorite from "../../components/favorite";
import { Utils } from "../../services/utils";
import AccountPage from "./[accountId]/page";
import { DefaultMobileToolbar } from "../default.mobile.toolbar";
import { BACKEND_URL } from "../../domain/env";

export default function MobileAllAccountsPage({ searchResults, showMapIcon }) {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalNrPages, setTotalNrPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<any>(null);
  const [itemCount, setItemCount] = useState<number>(null);
  const [links, setLinks] = useState<any>(null);
  
  function loadData() {
    goToResultsPage(pageNumber, pageSize);
  }

  function goToResultsPage(pageNumber, pageSize) {
    searchService.searchAccounts(pageNumber, "" + pageSize)
      .then((result) => {
        setItemCount(result.itemCount);
        setTotalNrPages(result.totalNrPages);
        setCurrentPage(result);
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  function goToAccountInfo(account) {
    Utils.mobileNavigator.pushPage({
      page: <AccountPage accountId={account.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Account"/>
      }
    }, { animation: "none" });
  }

  useEffect(() => {
    if (!currentPage) {
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => loadData());

      if (!searchResults) {
        loadData();
      } else {
        setTotalNrPages(searchResults.totalNrPages);
        setCurrentPage(searchResults);
      }
    }
  }, null);

  const renderHeader = (text) => {
    return (
      <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
        <div className="body-style">{text}</div>
      </Ons.ListHeader>
    );
  }

  const renderRow = (row, index) => {
    return (
      <>
        <Ons.ListItem key={row.id} modifier="nodivider">
          <div className="flex flex-row grow body-style" onClick={() => {goToAccountInfo(row)}}>
          <img 
            className="avatar-img-account-mobile rounded-full h-[100px] w-[100px] bg-gray-50 p-2 z-30 relative left-[-5px] top-[0px]"
            src={`${BACKEND_URL}/static/img/preview/${row.id}`}
            alt="Item image"
            width={80}
            height={80}/>

            <div className="flex flex-col">
              <div className="text-xs font-bold">{ row.firstName }</div>

              <div className="text-xs">
                Sinds { moment(row.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
              </div>

              <div className="text-xs">
                Type: { accountRoleToString(row.accountRole) }
              </div>

              {
                row.city ?
                  <div className="text-xs">
                    { row.city } { utils.getDistance(row.city) }
                  </div>
                  : <></>
              }

              {
                row.nrOffers ?
                  <div className="text-xs">
                    { row.nrOffers } aanbiedingen
                  </div>
                  : <>&nbsp;</>
              }

              {
                row.nrTrades ?
                  <div className="text-xs">
                    { row.nrTrades } transacties
                  </div>
                  : <>&nbsp;</>
              }     
            </div>

            {
              row.offerType == PriceType.AUCTION ? 
                <CountDownTimer 
                  offer={row}
                  labelClassName="relative left-[30px] top-[20px] text-xs"
                  timerClassName="relative left-[40px] top-[20px] auction-time-mobile"/>
                : <></>
            }

            <div className="grow"></div>

            <Favorite itemType={"account"} favoriteEntityId={row.id}/>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </>);
  }

  function first() {
    setPageNumber(1);
    goToResultsPage(1, pageSize);
  }

  function previous() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      goToResultsPage(pageNumber - 1, pageSize);
    }
  }

  function next() {
    if (pageNumber < totalNrPages) {
      setPageNumber(pageNumber + 1);
      goToResultsPage(pageNumber + 1, pageSize);
    }
  }

  function last() {
    setPageNumber(totalNrPages);
    goToResultsPage(totalNrPages, pageSize);
  }

  if (!currentPage) {
    return <Loading/>;
  }

  return (
    <>
      <MobilePaginator
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalNrPages={totalNrPages}
        first={first}
        previous={previous}
        next={next}
        last={last}
        isTop={true}/>

      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={currentPage.data}
        renderRow={(row, index) => renderRow(row, index)}
        renderHeader={() => renderHeader(itemCount + " accounts")}/>

      <MobilePaginator
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalNrPages={totalNrPages}
        first={first}
        previous={previous}
        next={next}
        last={last}
        isTop={false}/>

      <div className="mb-[80px]"/>
    </>
  );
}