import * as Ons from "react-onsenui";
import * as Yup from 'yup';
import { Radio } from "@mui/joy";
import { useState } from "react";
import { useNavigate } from "react-router";
import Spinner from "../../../../components/spinner";
import DocumentType, { documentTypeFromString } from "../../../../domain/account/document.type";
import { authService } from "../../../../services";
import { useFormik } from "formik";
import Loading from "../../../../components/loading";
import { Utils } from "../../../../services/utils";
import { DefaultMobileToolbar } from "../../../default.mobile.toolbar";
import MobileCreatedAccount from "../mobile.created.account";

export default function MobileIdCheck({ accountId }) {
  const [documentType, setDocumentType] = useState<DocumentType>(DocumentType.PASSPORT);
  const [idError, setShowIdError] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  
  const constraints = {
    video: { 
      "facingMode": "environment", 
      width: { 
        min: 600
      }, 
      height: {
        min: 400
      }
    }
  };  

  setTimeout(() => {
    const video = document.getElementById('videoele');
    const img = document.getElementById('imageele');
    const canvas = document.createElement('canvas');
    const screenshotButton = document.getElementById('btnele');

    function hasGetUserMedia() {
      return !!(navigator.mediaDevices &&
        navigator.mediaDevices.getUserMedia);
    }

    if (hasGetUserMedia()) {
      navigator.mediaDevices.getUserMedia(constraints).then(gotStream).catch(handleError);
    } else {
      alert('getUserMedia() is not supported by your browser');
    }
    
    function gotStream(stream) {
      setShowSpinner(false);

      (window as any).stream = stream;
      (video as any).srcObject = stream;
    }

    function handleError(error) {
      
    }

    screenshotButton.onclick = video.onclick = function() {
      setShowSpinner(true);

      canvas.width = (video as any).videoWidth;
      canvas.height = (video as any).videoHeight;
      canvas.getContext('2d').drawImage(video as any, 0, 0);
      var url = canvas.toDataURL('image/png');
      (img as any).src = url;

      authService.checkId(dataURItoBlob(url), accountId, documentType)
        .then((result) => {
          if (result == true) {
            Utils.mobileNavigator.pushPage({
              page: <MobileCreatedAccount/>,
              renderToolbar: () => {
                return <DefaultMobileToolbar text="Account aangemaakt"/>
              }
            }, { animation: "none" });
          } else {
            setShowSpinner(false);
            setShowIdError(true);
          }
        });
    }
  }, 100);

  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out thel mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  const handleDocumentTypeChange = (e) => {
    setDocumentType(documentTypeFromString(e.target.value));
  }

  const formik = useFormik({
		initialValues: {
		  documentType: DocumentType.PASSPORT
		},
		validationSchema: Yup.object({
			
		}),
		onSubmit: (values, { setSubmitting}) => {
      setShowSpinner(true);
		}
	  });

  if (!accountId) {
    return <Loading/>;
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container grid grid-cols-1 max-w-xl mx-auto justify-center place-content-center align-middle">
          <p className="text-center text-sm m-4 mb-0 redhat-display">Laat uw paspoort, ID-kaart of rijbewijs voor uw camera van uw mobiele telefoon zien.</p>
          <p className="text-center text-sm m-4 mb-0 redhat-display">Wij slaan geen foto van uw identiteitsbewijs op en we gebruiken deze informatie enkel om uw leeftijd te verifieren. Uw identiteitsbewijs wordt enkel automatisch gecontroleerd zonder handmatige inspectie. Wij kijken niet naar uw BSN-nummer of documentnummer. Om uw privacy te beschermen kunt U uw BSN-nummer, pasfoto en documentnummer afplakken.</p>

          <p className="text-center text-sm m-4 redhat-display">U verklaart met het laten zien van uw identiteitsdocument dat U de persoon bent op het document, en dat U daadwerkelijk 18 jaar of ouder bent.</p>

          <label className="grow mt-4 text-center" htmlFor="documentType">Selecteer uw documenttype:</label>
          <div className="ml-2">
            <div key="listitem-PASSPORT" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="PASSPORT"
                  className="relative top-[4px]"
                  checked={documentType == DocumentType.PASSPORT}
                  value="PASSPORT"
                  onChange={ e => {
                    formik.handleChange(e);
                    handleDocumentTypeChange(e);
                  }}
                />
              </label>
              <label htmlFor={`PASSPORT`} className="center redhat-display">Paspoort</label>
            </div>

            <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

            <div key="listitem-DRIVERS_LICENSE" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="DRIVERS_LICENSE"
                  className="relative top-[4px]"
                  checked={documentType == DocumentType.DRIVERS_LICENSE}
                  value="DRIVERS_LICENSE"
                  onChange={ e => {
                    formik.handleChange(e);
                    handleDocumentTypeChange(e);
                  }}
                />
              </label>
              <label htmlFor={`DRIVERS_LICENSE`} className="center redhat-display">Rijbewijs</label>
            </div>

            <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

            <div key="listitem-ID_CARD" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="ID_CARD"
                  className="relative top-[4px]"
                  checked={documentType == DocumentType.ID_CARD}
                  value="ID_CARD"
                  onChange={ e => {
                    formik.handleChange(e);
                    handleDocumentTypeChange(e);
                  }}
                />
              </label>
              <label htmlFor={`ID_CARD`} className="center redhat-display">ID-kaart</label>
            </div>

            <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>
          </div>

          <div className="justify-center mx-auto scale-50 mt-[0px]">
            { showSpinner ? <Spinner/> : <></> }
          </div>

          <video id="videoele" className="w-[600px] h-[400px] rounded-lg" autoPlay playsInline></video>

          {
            idError ? 
              <div className="text-red-600 text-center text-sm redhat-display mb-4 mt-[-30px]">ID-verificatie niet gelukt. Probeer het opnieuw.</div>
              : <></>
          }

          <div className="flex flex-row-reverse row-cols-2 mb-2 text-center justify-center mt-[10px]">
            <Ons.Button onClick={() => {}} id="btnele" className="button min-w-[110px] pt-2 pb-2 mr-4 mt-8" type="submit">
              <span className="redhat-display text-sm">Controleren</span>
            </Ons.Button>
          </div>

          <img id="imageele" src="" className="w-[0px] h-[0px] m-w-[600px] m-h-[0px]"></img>
          <canvas id="canvasele"></canvas>
        </div>
      </form>
    </>);
}