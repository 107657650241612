enum TradeState {
  CREATED = "CREATED",
  AT_SETTLEMENT_PROVIDER = "AT_SETTLEMENT_PROVIDER",
  SETTLED = "SETTLED",
  CLOSED = "CLOSED"
}

export default TradeState;

export function tradeStateFromString(tradeState: string): TradeState {
  switch (tradeState) {
    case "CREATED": 
      return TradeState.CREATED;
    case "AT_SETTLEMENT_PROVIDER": 
      return TradeState.AT_SETTLEMENT_PROVIDER;
    case "SETTLED": 
      return TradeState.SETTLED;
    case "CLOSED": 
      return TradeState.CLOSED;
    default:
      return null;
  }
}

export function tradeStateToString(tradeState: TradeState): string {
  switch (tradeState) {
    case TradeState.CREATED:
      return "Aangemaakt";
    case TradeState.AT_SETTLEMENT_PROVIDER:
      return "Betaling wordt verwerkt";
    case TradeState.SETTLED:
        return "Betaling is verwerkt";
    case TradeState.CLOSED:
      return "Verkoop afgehandeld";
    default:
      return "";
  }
}

export const ALL_TRADE_STATES = [
  TradeState.CREATED,
  TradeState.AT_SETTLEMENT_PROVIDER,
  TradeState.SETTLED,
  TradeState.CLOSED
];