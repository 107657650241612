import * as Ons from "react-onsenui";
import Notification from "../../../domain/inbox/notification";
import NotificationType from "../../../domain/inbox/notification.type";
import { utils } from "../../../services";

export default function InboxMessagePage({ message }) {
  function newBidItem(notification: Notification) {
    return (
      <>
        <div className="redhat-display">Er is een nieuw bod geplaatst op een van je items!</div>
        <div>Item: </div>
        <div className="redhat-display">Bod: </div>
        <div className="redhat-display">{ utils.roundedAmount(message.data.amount, 2) }</div>

        <div className="redhat-display">
          {
            utils.getPlatform() == "web" ? 
              <>

              </>
              : 
              <>
                <div className="flex flex-row">
                  <Ons.Button onClick={() => {}} className="button min-w-[110px]">
                    <span className="redhat-display text-sm">Accepteren</span>
                  </Ons.Button>

                  <Ons.Button onClick={() => {}} className="button min-w-[110px]">
                    <span className="redhat-display text-sm">Afwijzen</span>
                  </Ons.Button>
                </div>
              </>
          }
        </div>

        <div>
          utils.getPlatform() == "web" ? 
            <Ons.Button onClick={() => {}} className="button min-w-[110px]">
              <span className="redhat-display text-sm">Bekijk bieding</span>
            </Ons.Button>
            :
            <></>
        </div>
      </>);
  }

  function newBidFavoriteItem(notification: Notification) {
    return (<></>);
  }

  function favoriteAccountPlacedItem(notification: Notification) {
    return (<></>);
  }

  function transactionFavoriteItem(notification: Notification) {
    return (<></>);
  }

  function newChatMessage(notification: Notification) {
    return (<></>);
  }

  function soldItemShipped(notification: Notification) {
    return (<div className="redhat-display"></div>);
  }

  function boughtItemShipped(notification: Notification) {
    return (<></>);
  }

  function advertisement(notification: Notification) {
    return (<></>);
  }

  function systemNotification(notification: Notification) {
    return (<></>);
  }

  function getMessageBody(message: Notification) {
      switch (message.notificationType) {
        case NotificationType.NEW_BID_ITEM:
          return newBidItem(message);
        case NotificationType.NEW_BID_FAVORITE_ITEM:
          return newBidFavoriteItem(message);
        case NotificationType.FAVORITE_ACCOUNT_PLACED_ITEM:
          return favoriteAccountPlacedItem(message);
        case NotificationType.TRANSACTION_FAVORITE_ITEM:
          return transactionFavoriteItem(message);
        case NotificationType.NEW_CHAT_MESSAGE:
          return newChatMessage(message);
        case NotificationType.SOLD_ITEM_SHIPPED:
          return soldItemShipped(message);
        case NotificationType.BOUGHT_ITEM_SHIPPED:
          return boughtItemShipped(message);
        case NotificationType.ADVERTISEMENT:
          return advertisement(message);
        case NotificationType.SYSTEM_NOTIFICATION:
          return systemNotification(message);
        default:
          return (<></>);
    }
  }

  return (<> { getMessageBody(message) } </>);
}