import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { DATE_TIME_FORMAT, FRIENDLY_DATE_TIME_FORMAT } from "../../../domain/constants";
import { offerTypeToString } from "../../../domain/offer/offer.type";
import { BACKEND_URL } from "../../../domain/env";

export default function FavoriteOfferInfoItem({ favorite }) {
  const [offer, setOffer] = useState<any>(null);
  const navigate = useNavigate();

  const loadData = () => {
    if (!favorite) {
      return;
    }


  }

  function goToOffer() {
    if (!offer) {
      return;
    }

    navigate("/gropp/offers/" + offer.id);
  }

  useEffect(() => {
    if (!offer) {
      loadData();
    }
  }, null);

  if (!offer) {
    return <></>;
  }

  return (
    <div className="grid grid-cols-2">
      <div className="mx-auto justify-center w-full text-center mb-12">
        <img 
          className="avatar-img-account-page rounded-full h-24 w-24 bg-gray-50 p-2 mt-[30px] justify-center mx-auto"
          src={`${BACKEND_URL}/static/img/preview/${offer.itemId}`}
          alt="Account avatar"
          width={150}
          height={150}/>
      </div>

      <div className="grid grid-cols-2 ml-[-100px]">
        <div className="text-right font-bold mr-4 p-1">Type:</div>
        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{offerTypeToString(offer.offerType)}</div>

        <div className="text-right font-bold mr-4 p-1">Aangemaakt:</div>
        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{
          moment(offer.createTime, DATE_TIME_FORMAT).format(FRIENDLY_DATE_TIME_FORMAT)
        }</div>

        <div className="relative top-[-60px] left-[350px]">
          <button className="normal-button" type="submit" onClick={goToOffer}>Bekijken</button>
        </div>
      </div>
    </div>
  );
}