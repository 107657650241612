export enum OfferState {
	NOT_OPENED_YET = "NOT_OPENED_YET",
	OPEN = "OPEN",
	ACCEPTED_BID = "ACCEPTED_BID",
	TRADED = "TRADED",
	AFTER_CLOSE_TIME = "AFTER_CLOSE_TIME",
	NOT_APPLICABLE = "NOT_APPLICABLE",
	RETRACTED = "RETRACTED",
	FAILED = "FAILED"
}

export function offerStateFromString(offerState: string): OfferState {
  switch (offerState) {
    case "NOT_OPENED_YET": 
      return OfferState.NOT_OPENED_YET;
    case "OPEN":
      return OfferState.OPEN;
    case "ACCEPTED_BID":
      return OfferState.ACCEPTED_BID;
    case "TRADED":
      return OfferState.TRADED;
    case "AFTER_CLOSE_TIME":
      return OfferState.AFTER_CLOSE_TIME;
    case "NOT_APPLICABLE": 
      return OfferState.NOT_APPLICABLE;
    case "RETRACTED":
      return OfferState.RETRACTED;
    case "FAILED":
      return OfferState.FAILED;
    default: 
      return null;
  }
}

export function offerStateToString(offerState: OfferState): string {
  switch (offerState) {
    case OfferState.NOT_OPENED_YET: 
      return "Nog niet geopend";
    case OfferState.OPEN:
      return "Open";
    case OfferState.ACCEPTED_BID:
      return "Bod geaccepteerd";
    case OfferState.TRADED:
      return "Transactie voltooid";
    case OfferState.AFTER_CLOSE_TIME:
      return "Bieding gesloten";
    case OfferState.NOT_APPLICABLE:
      return "Niet van toepassing";
    case OfferState.RETRACTED:
      return "Teruggetrokken";
    case OfferState.FAILED:
      return "Afgebroken"
    default:
      return "";
  }
}

export const ALL_OFFER_STATES = [
  OfferState.NOT_APPLICABLE,
  OfferState.OPEN,
  OfferState.ACCEPTED_BID,
  OfferState.TRADED,
  OfferState.AFTER_CLOSE_TIME,
  OfferState.NOT_APPLICABLE,
  OfferState.RETRACTED,
  OfferState.FAILED
]