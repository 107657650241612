import { useParams } from "react-router";
import OfferPage from "./page";

export default function OfferPageRouteHelper() {
  const { offerId, readOnlyStr } = useParams();

  const readOnly = readOnlyStr == "true" ? true : false;

  return (
    <>
      <OfferPage offerId={offerId} readOnly={readOnly}/>
    </>
  );
}