import * as Ons from "react-onsenui";

import { favoritesService, utils } from "../services";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { EventBus } from "../services/event.bus";

export default function Favorite({ 
    itemType, 
    favoriteEntityId, 
    onClickCallback=() => {}, 
    forceEnabled = false}) {
  const [registeredEventBus, setRegisteredEventBus] = useState<boolean>(false);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [redirectLogin, setRedirectLogin] = useState<boolean>(false);
  
  useEffect(() => {
    if (!registeredEventBus) {
      EventBus.getInstance().register(
        EventBus.LOADED_FAVORITES, () => {
          var thisFavorite = favoritesService.allFavorites
            .filter(f => f.favoriteEntityId == favoriteEntityId);

          setIsFavorite(thisFavorite.length > 0);
        });

      EventBus.getInstance().register(
        EventBus.LOADING_FAVORITES_FAILED, () => {
          setRedirectLogin(true);
        });

      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => {
          setRedirectLogin(false);
        });

      setRegisteredEventBus(true);
    }
  }, null);

  function getFavoriteIcon() {
    var iconName = "";

    if (forceEnabled || isFavorite) {
      iconName = "md-favorite";
    } else {
      iconName = "md-favorite-outline";
    }

    return (<Ons.Icon icon={iconName} className="link-color" onClick={clicked}/>);
  }

  function clicked(e) {
    if (redirectLogin) {
      e.stopPropagation();
      utils.handleUnauthorized(() => {});
      return;
    }

    if (isFavorite) {
      favoritesService.removeFavorite("/" + itemType + "s", favoriteEntityId, () => {});
      setIsFavorite(false);
    } else {
      favoritesService.makeFavorite("/" + itemType + "s", favoriteEntityId, () => {});
      setIsFavorite(true);
    }

    e.stopPropagation();

    onClickCallback();
  }

  return (<>{ getFavoriteIcon() }</>);
}