import { useState, useMemo, useEffect } from "react";
import OfferMarker from "../gropp/search/offer.marker";
import Loading from "./loading";
import { GOOGLE_API_KEY } from "../domain/env";
import { DEFAULT_MAP_LOCATION } from "../domain/constants";

export default function Map({ 
    mapLocation,
    zoom,
    searchRadius, 
    markers,
    width, 
    height, 
    markerRenderer,
    markerClickCallback = null,
    className = "" }) {
  const [mapLoading, setMapLoading] = useState<boolean>(false);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [runningInit, setRunningInit] = useState<boolean>(false);
  const mapId = "gropp-google-map";

  var map;

  useMemo(() => {
    setMapLoading(false);
    setMapLoaded(false);
    initMap();
  }, [ markers ])
  
  async function initMap(): Promise<void> {
    if (typeof google == "undefined") {
      setTimeout(() => {
        setRunningInit(false);
        initMap();
      }, 200);
    } else {
      if (mapLoaded) {
        return;
      } else if (!mapLoading) {
        setMapLoading(true);
      
        const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;

        map = new Map(
          document.getElementById(mapId) as HTMLElement, {
            zoom: 4,
            center: DEFAULT_MAP_LOCATION,
            mapId: 'DEMO_MAP_ID',
            disableDefaultUI: true
          });

        var infoWindow = new InfoWindow();
        
        var service = new google.maps.places.PlacesService(map);

        if (typeof mapLocation == "object") {
          map.setZoom(14);
          map.setCenter(mapLocation);

          setRunningInit(false);
          setMapLoading(false);
        } else {
          var request = {
            query: mapLocation + ", The Netherlands",
            fields: [ "name", "geometry" ]
          };
    
          service.findPlaceFromQuery(request, async function(results, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              if (results.length > 0) {
                map.setZoom(14);
                map.setCenter(results[0].geometry.location);
                
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
      
                for (let marker of markers) {
                  const m = new AdvancedMarkerElement({
                    map: map,
                    position: { lat: marker.lat, lng: marker.lng },
                    title: ""
                  });
                  
                  m.addListener("click", ({ domEvent, latLng }) => {
                    infoWindow.close();
                    infoWindow.setContent(markerRenderer(marker));
                    infoWindow.open(map, m);

                    if (markerClickCallback) {
                      markerClickCallback(marker, m, infoWindow);
                    }
                  });
                }

                setRunningInit(false);
                setMapLoading(false);
                setMapLoaded(true);
              }
            }
          });
        }
      }
    }
  }

  return (
    <>
      <div className={`${"h-[400px] w-full "} ${className}`} id={mapId}></div>
    </>
  );
}