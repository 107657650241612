import * as Yup from 'yup';
import parse from "date-fns/parse";
import dayjs from "dayjs";
import AccountBuilder from "../../../domain/account/account.builder";
import AccountRole from "../../../domain/account/account.role";
import ChangePassword from '../password/change';
import { useFormik } from "formik";
import { useState } from "react";
import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Tooltip } from "@mui/joy";
import { Link, useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";

export default function EditAccount() {
  const [visibleChecked, setVisibleChecked] = useState<boolean>(true);
  const [emailAddress, setEmailAddress] = useState<string>(null);
  const [showCancelBox, setShowCancelBox] = useState<boolean>(false);

  const [changingPassword, setChangingPassword] = useState<boolean>(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>(null);
  const [showPasswordChanged, setShowPasswordChanged] = useState<boolean>(null);

  const navigate = useNavigate();

  const loadData = () => {
    utils.getLoggedInAccountId()
    .then((accountId) => {
      var url = BACKEND_URL + "/accounts/" + accountId;
    
      authService.getSecure(url)
        .then((result) => {
          var account = AccountBuilder.buildAccount(result.data);
  
          if (account != null) {
            setAccount(account);
            setEmailAddress(account.emailAddress);
            fillValues(account);
          }
        });
    });
  }

  function fillValues(account: any) {
    formik.values.accountRole = account.accountRole;
    formik.values.firstName = account.firstName;
    formik.values.lastName = account.lastName;
    formik.values.birthDate = account.birthDate;
    formik.values.city = account.city;
    formik.values.street = account.street;
    formik.values.postalCode = account.postalCode;
    formik.values.phoneNumber = account.phoneNumber;

    if (account.accountRole == AccountRole.BUSINESS) {
      formik.values.kvkNumber = account.kvkNumber;
      formik.values.businessName = account.businessName;
    }
  }

  const [account, setAccount] = useState<any>(() => {
    loadData();
  });

  const changePassword = () => {
    setChangingPassword(true);
  }

  function cancelEditAccount() {
    setShowCancelBox(false);
    navigate("/gropp/account");
  }

  const handleVisibleChange = () => {
    setVisibleChecked(!visibleChecked);
  }


  const updatePassword = (oldPassword, newPassword) => {
    var url = BACKEND_URL + "/accounts/change-password";

    var body = {
      "oldPassword": oldPassword,
      "newPassword": newPassword
    };

    authService.postSecure(url, body)
      .then((result) => {
        setChangingPassword(false);

        showPasswordChangedBox();
      }).catch((error) => {
        setPasswordErrorMessage("Oude wachtwoord is ongeldig");
      })
  }

  function showPasswordChangedBox() {
    setShowPasswordChanged(true);
  }

  function clearPasswordError() {
    setPasswordErrorMessage(null);
  }

  const formik = useFormik({
		initialValues: {
      accountRole: "USER",
		  firstName: "",
		  lastName: "",
      birthDate: "",
      street: "", 
      postalCode: "",
      city: "",
      phoneNumber: "",
      emailVerified: false,
      emailAddress: "",
      visible: true,
      images: [],
      kvkNumber: "",
      businessName: "",
      avatar: ""
		},
    onSubmit: values => {


    },
		validationSchema: () => {
      var baseValidation = Yup.object({
        street: Yup.string()
          .required("Vul een straat in"),
        postalCode: Yup.string()
          .matches(/^\d\d\d\d ?[a-zA-Z][a-zA-Z]$/, "Vul een geldige postcode in")
          .required("Vul een postcode in"),
        city: Yup.string()
          .required("Vul een stad in"),
        phoneNumber: Yup.string()
          .required("Vul een telefoonnummer in"),
        visible: Yup.boolean()
          .optional()
      });

      if (account.accountRole == AccountRole.USER) {
        var userValidation = Yup.object({
          birthDate: Yup.date()
            .required("Vul een geboortedatum in")
            .max(dayjs().subtract(18, "year"), "Je moet minimaal 18 jaar oud zijn")
            .transform(function (value, originalValue) {
              if (!/^\d\d-\d\d-\d\d\d\d$/.test(originalValue)) {
                return null;
              }

              try {
                return parse(originalValue, "dd-MM-yyyy", new Date());
              } catch {
                return null;
              }
            })
            .typeError("Vul een geldige geboortedatum in"),
          older18: Yup.bool()
            .oneOf([true], "U moet minimaal 18 jaar oud zijn")
        })

        return baseValidation.concat(userValidation);
      } else if (account.accountRole == AccountRole.BUSINESS) {
        var businessValidation = Yup.object({
          kvkNumber: Yup.string()
            .required("Vul een KVK-nummer in"),
          businessName: Yup.string()
            .required("Vul een bedrijfsnaam in")
        })

        return baseValidation.concat(businessValidation);
      }

      return baseValidation;
		}
  });

  if (!account) {
    return (<></>);
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container grid grid-cols-1 max-w-sm mx-auto justify-center place-content-center aling-middle"> 
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="firstName">
              Voornaam:
              { 
                account.idVerified ? 
                  <Tooltip title={
                    <div className="body-style">
                      Je identiteit is geverifieerd.
                    </div>
                  } variant="plain">
                    <img 
                      className="z-20 inline-block relative left-[10px] top-[-1px]" 
                      src="/gropp_verified.png" 
                      width="17" 
                      height="17" 
                      alt="Grop logo"/>
                  </Tooltip>
                  : <></>
              }
              </label>

            <div className="grow text-sm mt-1">
              <Tooltip title={
                  <div className="body-style">
                    Je kan je voornaam niet wijzigen nadat je identiteit is geverifieerd.
                  </div>
                } variant="plain">
                  <div>{ account.firstName }</div>
              </Tooltip>
            </div>
          </div>
          
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="lastName">Achternaam:</label>

            <div className="grow text-sm mt-1">
              <Tooltip title={
                    <div className="body-style">
                      Je kan je achternaam niet wijzigen nadat je identiteit is geverifieerd.
                    </div>
                  } variant="plain">
                <div>{ account.lastName }</div>
              </Tooltip>
            </div>
          </div>

          {
            account.accountRole == AccountRole.USER ? 
              <div className="grid grid-rows-3">
                <label className="grow" htmlFor="birthDate">Geboortedatum:</label>

                <div className="grow text-sm mt-1">
                  <Tooltip title={
                      <div className="body-style">
                        Je kan je geboortedatum niet wijzigen nadat je identiteit is geverifieerd.
                      </div>
                    } variant="plain">
                      <div>{ account.birthDate }</div>
                  </Tooltip>
                </div>
              </div>
              : <></>
          }

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="emailAddress">Emailadres:
            { 
                account.emailVerified ? 
                  <Tooltip title={
                      <div className="body-style">
                        Je emailadres is geverifieerd.
                      </div>
                    } variant="plain">
                    <img 
                      className="z-20 inline-block relative left-[10px] top-[-1px]" 
                      src="/gropp_verified.png" 
                      width="17" 
                      height="17" 
                      alt="Grop logo"/>
                  </Tooltip>
                    : <></>
              }
            </label>

            <div className="grow text-sm mt-1">
              <Tooltip title={
                    <div className="body-style">
                      Je kan je emailadres niet wijzigen nadat je emailadres is geverifieerd.
                    </div>
                } variant="plain">
                <div>{emailAddress}</div>
              </Tooltip>
            </div>
          </div>

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="city">Stad:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="city" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.city}
              />
            </div>

            {formik.touched.city && formik.errors.city ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.city}</div>
            ) : null }
          </div>

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="street">Straat:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="street" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.street}
              />
            </div>

            {formik.touched.street && formik.errors.street ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.street}</div>
            ) : null }
          </div>

          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="postalCode">Postcode:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input uppercase" id="postalCode" placeholder="1234 AB" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
              />
            </div>

            {formik.touched.postalCode && formik.errors.postalCode ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.postalCode}</div>
            ) : null }
          </div>
          
          <div className="grid grid-rows-3">
            <label className="grow" htmlFor="phoneNumber">Telefoonnummer:</label>

            <div className="grow">
              <input className="min-w-md w-full larger-input" id="phoneNumber" placeholder="+31612345678" type="text"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.phoneNumber}
              />
            </div>

            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.phoneNumber}</div>
            ) : null }
          </div>
          
          <div className="grid grid-rows-1 mb-4">
            <div className="flex flex-row flex-shrink">
              <div className="grow mb-0">
                <Checkbox
                  size="sm"
                  name="visible"
                  label="Zichtbaar"
                  className="body-style"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  checked={formik.values.visible}
                />
              </div>
            </div>
            
            {formik.touched.visible && formik.errors.visible ? (
              <div className="text-xs mt-1 text-right text-red-600">{formik.errors.visible}</div>
            ) : null }
          </div>

          <div className="grid grid-rows-3 mt-4">
            <label className="grow" htmlFor="emailAddress">Wachtwoord:</label>
            <div className="grow text-sm mt-0 mb-2">**********</div>
            
            <div><Link to="#" className="text-sm" onClick={() => changePassword()}>wijzig wachtwoord</Link></div>

            <ChangePassword/>
          </div>

          { 
            account.accountRole == AccountRole.BUSINESS ?
              <>
                <div className="grid grid-rows-3">
                  <label className="grow" htmlFor="kvkNumber">KVK-nummer:</label>

                  <div className="grow">
                    <input className="min-w-md w-full larger-input" id="kvkNumber" type="text"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.kvkNumber}
                    />
                  </div>
                  
                  {formik.touched.kvkNumber && formik.errors.kvkNumber ? (
                    <div className="text-xs mt-1 text-right text-red-600">{formik.errors.kvkNumber}</div>
                  ) : null }
                </div>
              </>
              : <></> 
          }

          { 
            account.accountRole == AccountRole.BUSINESS ?
              <>
                <div className="grid grid-rows-3">
                  <label className="grow" htmlFor="businessName">
                    Bedrijfsnaam:

                    { 
                      account.businessVerified ? 
                        <Tooltip title={
                          <div className="body-style">
                            Het bedrijf is geverifieerd.
                          </div>
                        } variant="plain">
                          <img 
                            className="z-20 inline-block relative left-[10px] top-[-1px]" 
                            src="/gropp_verified.png" 
                            width="17" 
                            height="17" 
                            alt="Grop logo"/>
                        </Tooltip>
                        : <></>
                    }
                    
                    </label>

                  <div className="grow">
                    <input className="min-w-md w-full larger-input" id="businessName" type="text"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.businessName}
                    />
                  </div>
                  
                  {formik.touched.businessName && formik.errors.businessName ? (
                    <div className="text-xs mt-1 text-right text-red-600">{formik.errors.businessName}</div>
                  ) : null }
                </div>
              </>
              : <></>
          }

          {
            !formik.isValid && formik.submitCount > 0 ? 
            <>
              <div className="text-center text-red-600">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
            </>
            : <></>
          }

          <div className="flex flex-row-reverse row-cols-2 mb-8">
            <div className="pt-10 pb-7 mr-4">
              <button className="normal-button" type="submit">Opslaan</button>
            </div>

            <div className="pt-10 pb-7 mr-4">
              <button className="normal-button" onClick={() => setShowCancelBox(true)}>Annuleren</button>
            </div>
          </div>
        </div>
      </form>

      { 
        <Modal open={showCancelBox}>
          <ModalDialog variant="plain" role="alertdialog">
            <DialogTitle>Annuleren</DialogTitle>
            <Divider />
            <DialogContent>
              Weet je zeker dat je wil annuleren? Je raakt je wijzigingen kwijt.
            </DialogContent>
            <DialogActions>
              <Button variant="solid" className="background-accent-color" onClick={() => setShowCancelBox(false)}>Nee</Button>
              <Button variant="plain" color="neutral" onClick={cancelEditAccount}>Ja</Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      }
    </>
  );
}