import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { authService, utils } from "../../../services";

export default function VerifiedAccountPage(params) {
  const navigate = useNavigate();
  const [verificationSuccess, setVerificationSuccess] = useState<boolean>(false);
  
  useEffect(() => {
    if (params && params.params && params.params.startsWith("?encoded=")) {
      var encoded = params.params.replace("?encoded=", "");
      var url = atob(encoded);
      console.log(url);

      authService.get(url).then((result) => {
        if (result.status == 200) {
          setVerificationSuccess(true);
        } else {
          setVerificationSuccess(false);
        }
      });
    } else {
      setVerificationSuccess(false);
    }
  }, null);
  
  function goToLogin() {
    if (utils.isMobile()) {
      
    } else {
      navigate("/login");
    }
  }

  if (verificationSuccess) {
    return (
      <>
        <div className="w-full text-center mt-8 redhat-display">
          <div>
            Uw emailadres is geverifieerd.
          </div>
          <div>
            Welkom bij Gropp.nl!
          </div>
        </div>

        <div className="text-center mt-8 mb-20">
          <div className="pt-10 pb-7">
            <button className="button min-w-[150px]" onClick={goToLogin}>
              <span className="redhat-display text-sm">Inloggen</span>
            </button>
          </div>
        </div>

        <div className="mb-[1000px]"></div>
      </>);
  } else {
    return (
      <>
        <div className="text-center m-8">
          <div className="text-md mx-auto m-8 redhat-display">
            Uw emailadres kon niet worden geverifieerd. Probeer het nogmaals.
          </div>
        </div>

        <div className="mb-[1000px]"></div>
      </>);
  }
}