import { Link } from "react-router-dom";
import { IndexKind } from "typescript";

export default function AdminPanel() {
  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex flex-row w-full justify-center gap-8 p-4">
          <Link to="/gropp/admin/accounts" className="no-link-decoration">
            <div className="admin-panel">
              Accounts
            </div>
          </Link>

          <Link to="/gropp/admin/offers" className="no-link-decoration">
            <div className="admin-panel">
              Offers
            </div>
          </Link>

          <Link to="/gropp/admin/auctions" className="no-link-decoration">
            <div className="admin-panel">
              Auctions
            </div>
          </Link>

          <Link to="/gropp/admin/favorites" className="no-link-decoration">
            <div className="admin-panel">
              Favorites
            </div>
          </Link>
        </div>

        <div className="flex flex-row w-full justify-center gap-8 p-4">
          <Link to="/gropp/admin/images" className="no-link-decoration"> 
            <div className="admin-panel">
              Images
            </div>
          </Link>

          <Link to="/gropp/admin/ratings" className="no-link-decoration">
            <div className="admin-panel">
              Ratings
            </div>
          </Link>

          <Link to="/gropp/admin/reviews" className="no-link-decoration">
            <div className="admin-panel">
              Reviews
            </div>
          </Link>

          <Link to="/gropp/admin/bids" className="no-link-decoration">
            <div className="admin-panel">
              Bids
            </div>
          </Link>
        </div>

        <div className="flex flex-row w-full justify-center gap-8 p-4 pb-10">
          <Link to="/gropp/admin/trades" className="no-link-decoration">
            <div className="admin-panel">
              Trades
            </div>
          </Link>

          <Link to="/gropp/admin/chats" className="no-link-decoration">
            <div className="admin-panel">
              Chats
            </div>
          </Link>

          <div className="admin-panel">
            
          </div>

          <div className="admin-panel">
            
          </div>
        </div>
      </div>
    </>);
}