import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../domain/env";
import { authService } from "../../services";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMemo } from 'react';
import { 
  type MRT_ColumnDef, 
  MaterialReactTable, 
  useMaterialReactTable,
  MRT_GlobalFilterTextField, 
  MRT_ToggleFiltersButton, 
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MRT_PaginationState} from 'material-react-table';
import { Box, Button, ListItemIcon, MenuItem, Typography, lighten } from '@mui/material';
import { AccountCircle, Send } from '@mui/icons-material';
import AccountBuilder from "../../domain/account/account.builder";
import Account from "../../domain/account/account";
import AccountRole from "../../domain/account/account.role";
import AdminAccountDetailPanel from "./detail/admin.account.detail.panel";

export default function AdminAccountsPanel() {
  const [data, setData] = useState<Account[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 10
  });

  useEffect(() => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    var url = BACKEND_URL + "/admin/accounts?" + 
      "pageNr=" + (pagination.pageIndex + 1) + 
      "&pageSize=" + pagination.pageSize;

    authService.getSecure(url)
      .then((result) => {
        var a = result.data.data.map(a => AccountBuilder.buildAccount(a));
        setData(a);
        setRowCount(result.data.itemCount);

        setPagination({
          pageIndex: result.data.pageNr - 1,
          pageSize: result.data.pageSize
        });
        
        setIsLoading(false);
        setIsRefetching(false);
      });
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
  ]);

  const columns = useMemo<MRT_ColumnDef<Account>[]>(
    () => [
      {
        accessorFn: (row) => `${row.firstName} ${row.lastName}`,
        id: 'name',
        header: 'Name',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Box className="flex flex-row justify-start" sx={{ gap: '1rem' }}>
            <span>{renderedCellValue}</span>
          </Box>)
      },
      {
        accessorKey: 'emailAddress',
        filterVariant: 'autocomplete',
        header: 'Email',
        size: 150
      },
      {
        accessorFn: (row) => `${row.accountRole == AccountRole.USER ? "User" : row.accountRole == AccountRole.BUSINESS ? "Business" : "Admin" }`,
        filterVariant: 'autocomplete',
        header: 'Role',
        size: 150
      },
      {
        accessorFn: (row) => `${row.birthDate ? row.birthDate : ""}`,
        id: 'birthDate',
        header: 'Birthdate',
        filterVariant: 'date',
        filterFn: 'lessThan',
        sortingFn: 'datetime',
        Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
        muiFilterTextFieldProps: {
          sx: {
            minWidth: '250px',
          },
        },
      },
      {
        accessorFn: (row) => `${row.street}`,
        id: 'street',
        header: 'Street',
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <Box className="flex flex-row justify-start" sx={{ gap: '1rem' }}>
            <span>{renderedCellValue}</span>
          </Box>)
      },
      {
        accessorKey: 'postalCode',
        filterVariant: 'autocomplete',
        header: 'Postal code',
        size: 150
      },
      {
        accessorKey: 'city',
        filterVariant: 'autocomplete',
        header: 'City',
        size: 200
      },
      {
        accessorKey: 'phoneNumber',
        filterVariant: 'autocomplete',
        header: 'Phone number',
        size: 150
      },
      {
        accessorKey: 'businessName',
        filterVariant: 'autocomplete',
        header: 'Business name',
        size: 150
      },
      {
        accessorKey: 'kvkNumber',
        filterVariant: 'autocomplete',
        header: 'KvK number',
        size: 150
      },
      {
        accessorFn: (row) => `${row.businessVerified === true ? "yes" : "no"}`,
        header: 'Business verified',
        size: 150
      },
      {
        accessorFn: (row) => `${row.emailVerified === true ? "yes" : "no"}`,
        header: 'Email verified',
        size: 150
      },
      {
        accessorFn: (row) => `${row.idVerified === true ? "yes" : "no"}`,
        header: 'ID verified',
        size: 150
      },
      {
        accessorKey: 'latitude',
        filterVariant: 'autocomplete',
        header: 'Latitude',
        size: 150
      },
      {
        accessorKey: 'longitude',
        filterVariant: 'autocomplete',
        header: 'Longitude',
        size: 150
      },
      {
        accessorKey: 'nrBids',
        header: 'Number of bids',
        size: 100
      },
      {
        accessorKey: 'nrTrades',
        header: 'Number of trades',
        size: 100
      },
      {
        accessorKey: 'nrOffers',
        header: 'Number of offers',
        size: 100
      },
      {
        accessorKey: 'nrItems',
        header: 'Number of items',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showLastName === true ? "yes" : "no"}`,
        header: 'Show last name',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showAddress === true ? "yes" : "no"}`,
        header: 'Show address',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showPhoneNumber === true ? "yes" : "no"}`,
        header: 'Show phone number',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showCoordinates === true ? "yes" : "no"}`,
        header: 'Show coordinates',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showBirthDate === true ? "yes" : "no"}`,
        header: 'Show birthdate',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showKvkNumber === true ? "yes" : "no"}`,
        header: 'Show Kvk number',
        size: 100
      },
      {
        accessorFn: (row) => `${row.showStatistics === true ? "yes" : "no"}`,
        header: 'Show statistics',
        size: 100
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: true,
    enableFacetedValues: false,
    enableRowActions: true,
    enableRowSelection: true,
    manualPagination: true,
    initialState: {
      density: 'compact',
      showColumnFilters: false,
      showGlobalFilter: true,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    mrtTheme: (theme) => ({
      baseBackgroundColor: '#33192D'
    }),
    muiTableFooterRowProps: {
      sx: {
        backgroundColor: 'yellow',
      }
    },
    muiSearchTextFieldProps: {
      size: 'medium',
      variant: 'standard',
    },
    muiPaginationProps: {
      color: 'primary',
      rowsPerPageOptions: [10, 20, 50, 100],
      shape: 'rounded',
      variant: 'text',
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    renderDetailPanel: ({ row }) => (
      getDetailPanel(row)
    ),
    renderRowActionMenuItems: ({ closeMenu }) => [
      <MenuItem key={0} onClick={() => closeMenu() } sx={{ m: 0 }}>
        <ListItemIcon>
          <AccountCircle/>
        </ListItemIcon>View Profile
      </MenuItem>,
      <MenuItem key={1} onClick={() => closeMenu() } sx={{ m: 0 }}>
        <ListItemIcon>
          <Send/>
        </ListItemIcon>Send Email
      </MenuItem>
    ],
    renderTopToolbar: ({ table }) => {
      const handleDelete = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('deactivating ' + row.getValue('name'));
        });
      };

      const handleChat = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('activating ' + row.getValue('name'));
        });
      };

      const handleBlock = () => {
        table.getSelectedRowModel().flatRows.map((row) => {
          alert('contact ' + row.getValue('name'));
        });
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            <MRT_GlobalFilterTextField table={table} />
            <MRT_ToggleFiltersButton table={table} />
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                className="normal-button w-[100px]"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleDelete}
                variant="contained">Delete
              </Button>
              <Button
                className="normal-button w-[100px]"
                disabled={!(table.getSelectedRowModel().rows.length == 1)}
                onClick={handleChat}
                variant="contained">Chat
              </Button>
              <Button
                className="normal-button w-[100px]"
                disabled={!table.getIsSomeRowsSelected()}
                onClick={handleBlock}
                variant="contained">Block
              </Button>
            </Box>
          </Box>
        </Box>
      );
    }
  });

  function getDetailPanel(row) {
    return (
      <div className="text-left w-[800px]">
        <AdminAccountDetailPanel data={row}/>
      </div>);
  }

  return (
    <div className="m-8 mt-0 mb-20">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable table={table} />
      </LocalizationProvider>
    </div>);
}