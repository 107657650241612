import ProductType from "../item/product.type";
import moment from 'moment';
import { DATE_TIME_FORMAT } from "../constants";
import { OfferState, offerStateFromString } from "./offer.state";
import OfferType, { offerTypeFromString } from "./offer.type";
import bigDecimal from 'js-big-decimal';

class MarketplaceOffer {
  id: string;
  title: string;
  createTime: Date;
  openTime: Date;
  offerType: OfferType;
  brand: string;
  productType: ProductType;
  description: string;
  offerState: OfferState;
  allowBidding: boolean;
  nrBids: number;
  city: string;
  itemId: string;
  auctionDuration: number;
  amount: bigDecimal;
  startPrice: bigDecimal;
  
  constructor($offer: any) {
    this.id = $offer.id;
    this.title = $offer.title;
    this.createTime = moment($offer.createTime, DATE_TIME_FORMAT).toDate();
    this.offerType = offerTypeFromString($offer.offerType);
    this.brand = $offer.brand;
    this.productType = $offer.productType;
    this.description = $offer.description;
    this.offerState = offerStateFromString($offer.offerState);
    this.allowBidding = $offer.allowBidding;
    this.nrBids = $offer.nrBids;
    this.city = $offer.city;
    this.itemId = $offer.itemId;

    if ($offer.amount) {
      this.amount = new bigDecimal($offer.amount);
    }

    if ($offer.startPrice) {
      this.startPrice = new bigDecimal($offer.startPrice);
    }

    if ($offer.openTime) {
      this.openTime = moment($offer.openTime, DATE_TIME_FORMAT).toDate();
    }
    
    if ($offer.auctionDuration) {
      this.auctionDuration = $offer.auctionDuration;
    }
  }
}

export default MarketplaceOffer;