import moment from "moment";
import { DATE_TIME_FORMAT, DATE_FORMAT, FRIENDLY_DATE_TIME_FORMAT } from "../../../domain/constants";
import { notificationTypeToString } from "../../../domain/inbox/notification.type";
import InboxMessagePage from "./InboxMessage";

export default function MobileNotification({notification }) {
  function goToNotificationLink(linkType, value) {
    
  }

  return (
    <div className="p-4">
      <div className="redhat-display"><span className="font-bold">Tijd</span></div>
      <div className="mb-4 redhat-display">{ moment(notification.createTime, DATE_TIME_FORMAT).format(FRIENDLY_DATE_TIME_FORMAT) }</div>

      <div className="redhat-display"><span className="font-bold">Type</span></div>
      <div className="mb-4 redhat-display">{ notificationTypeToString(notification.notificationType) }</div>

      <div className="redhat-display"><span className="font-bold">Bericht</span></div>
      <div className="redhat-display">
        <InboxMessagePage message={notification}/>
      </div>
    </div>
  );
}