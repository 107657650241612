import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import MobileInbox from "./mobile.inbox";
import { useNavigate } from "react-router";

export default function MyInbox() {
  const navigate = useNavigate();

  function renderWeb() {
    return (<></>);
  }

  if (utils.isMobile()) {
    return <MobileInbox/>
  } else {
    return renderWeb();
  }
}