import axios, { AxiosInstance } from "axios";
import { authService, utils } from ".";
import { BACKEND_URL } from "../domain/env";
import { FavoriteBuilder } from "../domain/review/favorite.builder";
import FavoriteType from "../domain/review/favorite.type";
import { EventBus } from "./event.bus";

export class FavoritesService {
  protected readonly instance: AxiosInstance;

  public topFavorites: any[] = null;
  public allFavorites: any[] = null;
  
  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 3000,
      timeoutErrorMessage: "Time out!",
    });
  }

  public getFavorites(data, 
                      topFavoriteCallback=(list) => {},
                      allFavoriteCallback=(list) => {}) {
    this.getTopFavorites(data, topFavoriteCallback);
    this.getAllFavorites(allFavoriteCallback);
  }

  private getTopFavorites(data, 
                          resultCallback=(list) => {}) {
    var url = BACKEND_URL + "/favorites/check-favorites";

    var offerIds = data.offers
      .slice(0, 10)
      .map(offer => offer.id)
      .filter(offer => typeof offer != "undefined");

    var accountIds = data.accounts
      .slice(0, 10)
      .map(account => account.id)
      .filter(account => typeof account != "undefined");

    var body = [
      {
        "favoriteType": "OFFER",
        "favorites": offerIds
      },
      {
        "favoriteType": "ACCOUNT",
        "favorites": accountIds
      }
    ];

    authService.postSecure(url, body)
      .then((result) => {
        var list = result.data.map(f => {
          return FavoriteBuilder.buildFavorite(f);
        });

        this.topFavorites = list;
        resultCallback(list);
      });
  }

  public getAllFavorites(resultCallback=(list) => {}) {
    var url = BACKEND_URL + "/favorites/all";

    authService.getSecure(url)
      .then((result) => {
        var accountList = result.data.accounts.map(f => {
          return FavoriteBuilder.buildFavorite(f);
        });

        var offerList = result.data.offers.map(f => {
          return FavoriteBuilder.buildFavorite(f);
        });

        var list = [];

        list = list.concat(accountList);
        list = list.concat(offerList);

        this.allFavorites = list;

        EventBus.getInstance().dispatch<void>(EventBus.LOADED_FAVORITES);
        
        resultCallback(list);
      }).catch(e => {
        EventBus.getInstance().dispatch<void>(EventBus.LOADING_FAVORITES_FAILED);
      });
  }

  public makeAccountFavorite(accountId, unauthorizedCallback) {
    this.makeFavorite("/accounts", accountId, unauthorizedCallback);
  }

  public makeOfferFavorite(offerId, unauthorizedCallback) {
    this.makeFavorite("/offers", offerId, unauthorizedCallback);
  }

  public makeFavorite(prefix, entityId, unauthorizedCallback) {
    if (this.isFavoriteByEntityId(entityId)) {
      this.removeFavorite(prefix, entityId, unauthorizedCallback);
    } else {
      this.doMakeFavorite(prefix, entityId, unauthorizedCallback);
    }
  }

  public isFavoriteByEntityId(entityId) {
    if (!this.allFavorites) {
      return false;
    } else {
      return this.allFavorites.findIndex(f => f.favoriteEntityId == entityId) != -1;
    }
  }

  public isFavoritByFavoriteId(favoriteId) {
    if (!this.allFavorites) {
      return false;
    } else {
      return this.allFavorites.findIndex(f => f.id == favoriteId) != -1;
    }
  }

  public doMakeFavorite(prefix, id, 
                        unauthorizedCallback) {     
    if (!this.allFavorites) {
      return;
    }

    utils.getLoggedInAccountId().then((accountId) => {
      var url = BACKEND_URL + "/favorites" + prefix;

      if (!accountId) {
        utils.handleUnauthorized(() => unauthorizedCallback());
      }
  
      var favoriteType = prefix == "/accounts" ? 
        FavoriteType.ACCOUNT : FavoriteType.OFFER;
  
      var body = {
        favoriteEntityId: id,
        favoriteType: favoriteType,
        accountId: accountId
      };
  
      authService.postSecure(url, body)
        .then((result) => {
          var f = FavoriteBuilder.buildFavorite(result.data);
         
          var idx = this.allFavorites.findIndex(f => f.favoriteEntityId == f.id);
      
          if (idx == -1) {
            this.allFavorites.push(f);
          }
        }).catch((e) => {
          utils.handleUnauthorized(() => unauthorizedCallback());
        });
    });
  }

  public removeFavorite(prefix, 
                        id,
                        unauthorizedCallback) {
    if (!this.allFavorites) {
      return;
    }

    var url = BACKEND_URL + "/favorites" + prefix + "/" + id;

    var idx = this.allFavorites.findIndex(f => f.favoriteEntityId == id);
    
    if (idx != -1) {
      this.allFavorites.splice(idx, 1);
    }
    
    authService.deleteSecure(url)
      .catch((e) => {
        utils.handleUnauthorized(() => unauthorizedCallback());
      });
  }

  getIsFavoriteAccount(accountId: any): Promise<boolean> {
    var url = BACKEND_URL + "/favorites/accounts/" + accountId;
    return authService.getSecure(url);
  }
  
  public getIsFavoriteItem(itemId: any): Promise<boolean> {
    var url = BACKEND_URL + "/favorites/items/" + itemId;
    return authService.getSecure(url);
  }
}