import Offer from "./offer";
import { OfferState } from "./offer.state";
import PriceType from "./offer.type";
import bigDecimal from 'js-big-decimal';

class BusinessOffer extends Offer {
	amount: bigDecimal;

	constructor($id: string,
              $createTime: Date,
              $accountId: string,
              $itemId: string,
              $bids: string[],
              $offerState: OfferState,
              $closedByOwner: boolean,
              $wonBidId: string,
              $amount: string) {
		super($id, $createTime, 
			$accountId, $itemId,
      $bids, $offerState, 
      $closedByOwner, $wonBidId, 
      PriceType.BUSINESS_OFFER);

    this.amount = new bigDecimal($amount);
	}
}

export default BusinessOffer;