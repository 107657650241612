import BidState from "./bid.state";
import bigDecimal from 'js-big-decimal';

export default class BidResponse {
  time: Date;
  bids: BidPosition[];
  bidState: BidState;
  highestBid: boolean;

  constructor($time: Date,
              $bids: BidPosition[],
              $bidState: BidState,
              $highestBid: boolean) {
    this.time = $time;
    this.bids = $bids;
    this.bidState = $bidState;
    this.highestBid = $highestBid;
  }
}

export class BidPosition {
  time: Date;
  accountId: string;
  firstName: string;
  amount: bigDecimal;
  position: number;

  constructor($time: Date,
              $accountId: string,
              $firstName: string,
              $amount: string,
              $position: number) {
    this.time = $time;
    this.accountId = $accountId;
    this.firstName = $firstName;
    this.amount = new bigDecimal($amount);
    this.position = $position;
  }
}