class SearchResults {
  pageNumber: number;
  pageSize: number;
  nrItemsThisPage: number;
  totalNrPages: number;
  itemCount: number;
  search: any;
  links: any;

  constructor($pageNumber: number,
              $pageSize: number,
              $nrItemsThisPage: number,
              $totalNrPages: number,
              $itemCount: number,
              $search: any,
              $links?: any) {
    this.pageNumber = $pageNumber;
    this.pageSize = $pageSize;
    this.nrItemsThisPage = $nrItemsThisPage;
    this.totalNrPages = $totalNrPages;
    this.itemCount = $itemCount;
    this.search = $search;

    if ($links) {
      this.links = $links;
    }
  }
}

export default SearchResults;