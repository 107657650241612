import { BACKEND_URL } from "../../domain/env";
import { productTypeToString } from "../../domain/item/product.type";
import { offerTypeToString } from "../../domain/offer/offer.type";
import MarketplaceTrade from "../../domain/trade/marketplace.trade";
import { utils } from "../../services";

export default function MarketplaceTradeItem({ trade }: { trade: MarketplaceTrade }) {
  return (
    <a href={`/gropp/trades/${trade.id}`} className="hover:no-underline no-decoration">
      <div className="flex flex-row p-4 pr-1 mr-[-280px] overflow-hidden">
        <div className="flex flex-col z-20">
          <img 
            className="mr-4 ml-0 mt-0 rounded-lg item-image"
            src={`${BACKEND_URL}/static/img/preview/${trade.itemId}`}
            alt="Item image"
            width={200}
            height={200}/>

            <div className="opacity-80 relative bottom-[50px] rounded-b-lg h-[50px] bid-item-overlay text-white bg-white"></div>
        </div>

        {
          trade.offerAccountPreviewImageId ? 
            <img 
              className="mr-4 ml-0 mt-0 avatar-trade-img1 rounded-full relative h-24 w-24 bg-gray-50 p-2 z-30"
              src={`${BACKEND_URL}/static/img/${trade.offerAccountPreviewImageId}`}
              alt="Offer account avatar"
              width={40}
              height={40}/>
            : 
            <img 
              className="mr-4 ml-0 mt-0 avatar-trade-img1 rounded-full relative h-24 w-24 bg-gray-50 p-1 z-30"
              src={`/gropp_logo.png`}
              alt="Offer account avatar"
              width={40}
              height={40}/>
        }
        
        {
          trade.bidAccountPreviewImageId ? 
            <img 
              className="mr-4 ml-0 mt-0 avatar-trade-img2 rounded-full relative h-24 w-24 bg-gray-50 p-2 z-30"
              src={`${BACKEND_URL}/static/img/${trade.bidAccountPreviewImageId}`}
              alt="Bid account avatar"
              width={40}
              height={40}/>
            : 
            <img 
              className="mr-4 ml-0 mt-0 avatar-trade-img2 rounded-full relative h-24 w-24 bg-gray-50 p-1 z-30"
              src={`/gropp_logo.png`}
              alt="Bid account avatar"
              width={40}
              height={40}/>
        }
        
        <div className="mb-2 ml-1 border-2 m-2 p-4 rounded-lg shadow-box max-w-[200px] max-h-[200px] relative left-[-300px] z-1 bid-hover">
          <div className="mb-1 font-bold offer-type">{""}</div>

          <div className="font-bold mr-1">Titel</div>
          <div className="mb-1">{trade.itemTitle}</div>

          <div className="font-bold mr-1">Type</div>
          <div className="mb-1">{trade.isGroup ? "Meerdere items" : productTypeToString(trade.productType)}</div>

          <div className="font-bold mr-1">Tijd</div>
          <div className="mb-1">{utils.renderFriendlyDateTime(trade.createTime)}</div>

          <div className="font-bold mr-1">Aanbieding</div>
          <div className="mb-1">{offerTypeToString(trade.offerType)}</div>

          <div className="text-right mt-[10px]">
            <span className="fake-link">Bekijken</span>
          </div>
        </div>
      </div>

      <div>
        <img 
          className="relative top-[-100px] left-[35px] z-20" 
          src="/gropp_handshake.png"
          width="28" 
          height="28" 
          alt="Grop logo"/>

        <div className="font-bold bid-amount relative left-[0px] top-[-116px] max-w-[200px] text-right z-50">{utils.roundedAmount(trade.amount, 2)}</div>
      </div>
    </a>
  );
}