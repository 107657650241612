enum Carrier {
  DHL = "DHL",
  DPD = "DPD",
  POSTNL = "POSTNL",
  UPS = "UPS"
}

export function carrierFromString(carrier: string): Carrier {
  switch (carrier) {
    case "DHL":
      return Carrier.DHL;
    case "DPD":
      return Carrier.DPD;
    case "POSTNL":
      return Carrier.POSTNL;
    case "UPS":
      return Carrier.UPS;
    default:
      return null;
  }
}

export function carrierToString(carrier: Carrier): string {
  switch (carrier) {
    case Carrier.DHL:
      return "DHL";
    case Carrier.DPD:
      return "DPD";
    case Carrier.POSTNL:
      return "PostNL";
    case Carrier.UPS:
      return "UPS";
    default:
      return "";
  }
}

export const ALL_CARRIERS = [
  Carrier.DHL,
  Carrier.DPD,
  Carrier.POSTNL,
  Carrier.UPS
];

export default Carrier;