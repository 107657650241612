import { useEffect, useState } from "react";
import FavoriteAccountInfoItem from "./favorite.account.info.item";
import FavoriteOfferInfoItem from "./favorite.offer.info.item";
import { useNavigate } from "react-router";
import { BACKEND_URL } from "../../../domain/env";
import { authService, favoritesService, utils } from "../../../services";
import MobileFavorites from "./mobile.favorites";
import Loading from "../../../components/loading";
import FavoriteType from "../../../domain/review/favorite.type";

export default function MyFavorites() {
  const [accountFavorites, setAccountFavorites] = useState<any[]>(null);
  const [offerFavorites, setOfferFavorites] = useState<any[]>(null);

  const loadData = () => {
    favoritesService.getAllFavorites((result) => {
      var filteredAccountFavorites = result
        .filter(f => f.favoriteType == FavoriteType.ACCOUNT);

      var filteredOfferFavorites = result
        .filter(f => f.favoriteType == FavoriteType.OFFER);

      setAccountFavorites(filteredAccountFavorites);
      setOfferFavorites(filteredOfferFavorites);
    });
  }
  
  useEffect(() => {
    if (!accountFavorites) {
      loadData();
    }
  }, null);

  if (!accountFavorites || !offerFavorites) {
    return <Loading/>;
  }

  function renderWeb() {
    return (
      <>
        <div className="w-screen overflow-hidden">
          <h1 className="text-xl pl-10 pt-10">Accounts</h1>

          { 
            accountFavorites && accountFavorites.length > 0 ?
              <>
                <div className="flex flex-row justify-center flex-wrap w-screen mb-18 mt-4">
                  { accountFavorites.map((favorite) => (
                      <div key={favorite.id}>
                        <FavoriteAccountInfoItem favorite={favorite}/>
                      </div>
                  ))}
                </div>
              </>
            : <>
              <div className="ml-16 mt-4">Geen favoriete accounts gevonden.</div>
            </>
          }
          
          <h1 className="text-xl pl-10 pt-10">Items</h1>
          
          {
            offerFavorites && offerFavorites.length > 0 ?
              <>
                <div className="flex flex-row justify-center flex-wrap w-screen mb-18 mt-4 pl-10 pr-10">
                  { offerFavorites.map((favorite) => (
                      <div key={favorite.id}>
                        <FavoriteOfferInfoItem favorite={favorite}/>
                      </div>
                    ))
                  }
                </div>
              </>
            : <>
              <div className="ml-16 mt-4">Geen favoriete items gevonden.</div>
            </>
          }
        </div>
      </>);
  }

  if (utils.isMobile()) {
    return <MobileFavorites 
      accountFavorites={accountFavorites} 
      offerFavorites={offerFavorites}/>
  } else {
    return renderWeb();
  }
}