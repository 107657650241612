export default function Checkbox({ id, label, value, disabled, onChange, className }) {
  return (
    <label>
      <input 
        id={id}
        type="checkbox" 
        className={ "checkbox " + className} 
        disabled={disabled} 
        checked={value} 
        onChange={onChange}
      /> {label}
    </label>);
}