// 2023-10-03T14:06:26.313+02:00[Europe/Amsterdam]
export const DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS ZZ";

export const FRIENDLY_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss";
export const FRIENDLY_PRECISE_DATE_TIME_FORMAT = "DD-MM-YYYY HH:mm:ss.SSS";
export const DATE_FORMAT = "DD-MM-YYYY";
export const PRECISE_TIME_FORMAT = "HH:mm:ss.SSS";

// Amsterdam
export const DEFAULT_MAP_LOCATION = { lat: 52.377988, lng: 4.902240 };

export const MAX_SEARCH_ITEM_PRICE = 5000;
export const DEFAULT_MAP_RADIUS = 10;
export const START_MAP_ZOOM = 6;
export const DEFAULT_ZOOMED_IN_MAP_ZOOM = 10;

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 10;

export const REVIEW_ITEMS_PER_PAGE = 3;
export const MAX_REVIEW_TEXT_SIZE = 1000;

export const ALL_AVATARS = [
  "/avatar/abstractavatar1.png",
  "/avatar/abstractavatar2.png",
  "/avatar/abstractavatar3.png",
  "/avatar/abstractavatar4.png",
  "/avatar/abstractavatar5.png",
  "/avatar/abstractavatar6.png",
  "/avatar/abstractavatar7.png",
  "/avatar/abstractavatar8.png",
  "/avatar/abstractavatar9.png",
  "/avatar/abstractavatar10.png",
  "/avatar/abstractavatar11.png",
  "/avatar/abstractavatar12.png",
  "/avatar/abstractavatar13.png",
  "/avatar/abstractavatar14.png",
  "/avatar/abstractavatar15.png",
  "/avatar/abstractavatar16.png",
  "/avatar/abstractavatar17.png",
  "/avatar/abstractavatar18.png",
  "/avatar/abstractavatar19.png",
  "/avatar/abstractavatar20.png",
  "/avatar/abstractavatar21.png",
  "/avatar/abstractavatar22.png",
  "/avatar/abstractavatar23.png",
  "/avatar/abstractavatar24.png"
];
