import MarketplaceAccount from "./account/marketplace.account";
import MarketplaceBid from "./bid/marketplace.bid";
import MarketplaceOffer from "./offer/marketplace.offer";
import MarketplaceTrade from "./trade/marketplace.trade";

class MarketplaceData {
  public nrAccounts: number;
  public nrOffers: number;
  public nrBids: number;
  public nrTrades: number;

  public accounts: MarketplaceAccount[];
  public offers: MarketplaceOffer[];
  public bids: MarketplaceBid[];
  public trades: MarketplaceTrade[];

	constructor($nrAccounts: number,
              $nrOffers: number,
              $nrBids: number,
              $nrTrades: number,
              $accounts: any[],
              $offers: any[],
              $bids: any[],
              $trades: any[]) {
    this.nrAccounts = $nrAccounts;
    this.nrOffers = $nrOffers;
    this.nrBids = $nrBids;
    this.nrTrades = $nrTrades;

    this.accounts = $accounts.map((account) => new MarketplaceAccount(account));
    this.offers = $offers.map((offer) => new MarketplaceOffer(offer));
    this.bids = $bids.map((bid) => new MarketplaceBid(bid));
    this.trades = $trades.map((trade) => new MarketplaceTrade(trade));
	}
}

export default MarketplaceData;