import * as Ons from "react-onsenui";
import Map from "../../components/map";
import { DEFAULT_MAP_LOCATION, DEFAULT_ZOOMED_IN_MAP_ZOOM } from "../../domain/constants";
import { useEffect, useState } from "react";
import OfferMarker from "./offer.marker";

export default function MobileMapSearch({ data, search }) {
  const [mapZoom, setMapZoom] = useState<number>(DEFAULT_ZOOMED_IN_MAP_ZOOM);
  const [mapLocation, setMapLocation] = useState<any>(DEFAULT_MAP_LOCATION);
  const [mapMarkers, setMapMarkers] = useState<any[]>(null);
  
  useEffect(() => {
    if (!mapMarkers) {
      setMapMarkers(data.map(item => {
        return {
          key: item.offerId,
          lat: item.latitude,
          lng: item.longitude,
          tooltip: false
        }
      }));
    }
  }, null);

  return (
    <Ons.Page className="bg-white">
      <Map
        mapLocation={DEFAULT_MAP_LOCATION} 
        zoom={mapZoom} 
        searchRadius={10}
        markers={mapMarkers}
        markerRenderer={ (marker) => { <OfferMarker offerId={marker.key}></OfferMarker> } }
        width={"calc(100%)"}
        height={"calc(100%)"}/>
    </Ons.Page>);
}