import axios, { AxiosInstance } from "axios";
import Offer from "../domain/offer/offer";
import { authService } from ".";
import { BACKEND_URL } from "../domain/env";

export class OfferService {
  protected readonly instance: AxiosInstance;
  
  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
    });
  }

  public async getAllOffers(): Promise<Offer[]> {
    var url = BACKEND_URL + "/offers/all";
    
    return authService.getSecure(url)
      .then((res) => {
        return res.data;
      });
  }
}