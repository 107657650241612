enum AccountRole {
	BUSINESS = "BUSINESS",
	USER = "USER",
	ADMIN = "ADMIN"
}

export function accountRoleFromString(accountRole: string): AccountRole {
  switch (accountRole) {
    case "BUSINESS":
      return AccountRole.BUSINESS;
    case "USER":
      return AccountRole.USER;
    case "ADMIN":
      return AccountRole.ADMIN;
    default:
      return null;
  }
}

export function accountRoleToString(accountRole: AccountRole): string {
  switch (accountRole) {
    case AccountRole.BUSINESS:
      return "Zakelijk";
    case AccountRole.USER:
      return "Gebruiker";
    case AccountRole.ADMIN:
      return "Beheerder";
    default: 
      return "";
  }
}

export const ALL_ACCOUNT_ROLES = [
  AccountRole.BUSINESS,
  AccountRole.USER,
  AccountRole.ADMIN
];

export default AccountRole;