import moment from "moment";
import GroupItem from "./group.item";
import GroupItemElement from "./group.item.element";
import Item from "./item";
import SingleItem from "./single.item";
import { DATE_TIME_FORMAT } from "../constants";

class ItemBuilder {
  static buildItem(data: any): Item {
    var item = null;

    if (data.itemType == "SINGLE_ITEM") {
      item = new SingleItem(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.title,
        data.description,
        data.ownerId,
        data.itemYear,
        data.brand,
        data.productType,
        data.picture,
        data.visible);
    } else if (data.itemType == "GROUP_ITEM") {
      var elements = data.elements.map((elem) => {
        return new GroupItemElement(elem.itemId, elem.itemCount);
      });

      item = new GroupItem(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.ownerId,
        elements,
        data.groupDescription);
    }

    return item;
  }
}

export default ItemBuilder;