import BidType from "../bid/bid.type";
import Trade from "./trade";
import TradeType from "./trade.type";

class UserTrade extends Trade {
	constructor($id: string,
              $createTime: Date,
              $tradeType: TradeType,
              $amount: string,
              $bidId: string,
              $bidAccountId: string,
              $bidType: BidType,
              $buyerFirstName: string,
              $offerId: string,
              $itemId: string,
              $offerType: string,
              $offerAccountId: string,
              $title: string,
              $incoming: boolean) {
    super($id,
          $createTime,
          $tradeType,
          $amount,
          $bidId,
          $bidAccountId,
          $bidType,
          $buyerFirstName,
          $offerId,
          $itemId,
          $offerType,
          $offerAccountId,
          $title,
          $incoming);
	}
}

export default UserTrade;