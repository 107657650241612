import { Capacitor } from "@capacitor/core";
import { Link } from "react-router-dom";
import { utils } from "./services";

export default function BottomBar() {
  if (utils.isMobile()) {
    return (<></>);
  }
  
  return (
    <>
      <div className="">
        <div className="h-12 navbar z-50 flex flex-row">
          <Link className="white-link text-xs h-12 p-4 grow text-right redhat-display" to="#">Algemene voorwaarden</Link>
        </div>
      </div>
    </>
  );
}