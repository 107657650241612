import * as Ons from "react-onsenui";
import { useState } from "react";
import ProductType, { ALL_PRODUCT_TYPES, productTypeFromString, productTypeToString } from "../../domain/item/product.type";
import { DEFAULT_MAP_LOCATION, DEFAULT_MAP_RADIUS, DEFAULT_PAGE_SIZE } from "../../domain/constants";
import { DatePicker } from "@capacitor-community/date-picker";
import { useFormik } from "formik";
import LocationType from "../../domain/search/location.type";
import Map from "../../components/map";
import OfferType, { ALL_OFFER_TYPES } from "../../domain/offer/offer.type";
import { searchService, utils } from "../../services";
import { SortBy, SortDirection } from "../../domain/search/sort.direction";
import { Utils } from "../../services/utils";
import MobileSearchItemsResultPage from "../items/mobile.search.items.result";
import { DefaultMobileToolbar } from "../default.mobile.toolbar";
import MobileMapSearch from "./mobile.map.search";
import { SearchValidator } from "../../domain/search/form.validators";
import Checkbox from "../../components/checkbox";

export default function MobileSearchFilter() {
  const [offerTypes, setOfferTypes] = useState<OfferType[]>(ALL_OFFER_TYPES);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.CREATE_TIME);
  const [showSelectProductType, setShowSelectProductType] = useState<boolean>(false);
  const [specificProductType, setSpecificProductType] = useState<boolean>(false);
  const [selectedProductTypes, setSelectedProductTypes] = useState<any[]>([]);
  const [onlineSinceString, setOnlineSinceString] = useState<any>(null);
  const [locationType, setLocationType] = useState<LocationType>(LocationType.NO_LOCATION);
  const [cityMapZoom, setCityMapZoom] = useState<number>(6);
  const [cityMapMarkers, setCityMapMarkers] = useState<any[]>([]);
  const [currentRadius, setCurrentRadius] = useState<number>(20);

  function selectDateTime() {
    DatePicker.present({
      mode: "date",
      locale: "nl_NL",
      theme: "light",
      ios: {
        format: "dd-MM-yyyy",
      },
      android: {
        format: "dd-MM-yyyy",
      },
    }).then(date => {
      var parts = date.value.split("-");

      var day = parseInt(parts[0]);
      var month = parseInt(parts[1]);
      var year = parseInt(parts[2]);

      var str = 
        (day < 10 ? "0" + day : day) + "-" +
        (month < 10 ? "0" + month : month) + "-" +
        year;

      setOnlineSinceString(str);
    });
  }

  function changeRadius(e) {
    setCurrentRadius(e.target.value);
  }

  function changeLocationType(locationType) {
    if (locationType == LocationType.NO_LOCATION) {
      setCurrentRadius(DEFAULT_MAP_RADIUS);
    }

    setLocationType(locationType);
  }

  function productTypeSummary() {
    if (!specificProductType || selectedProductTypes.length == ALL_PRODUCT_TYPES.length) {
      return "Alle typen";
    } else {
      return selectedProductTypes
        .map(p => productTypeToString(p)).join(", ");
    }
  }

  function openChangeProductType() {
    setShowSelectProductType(true);

    setTimeout(() => {
      ALL_PRODUCT_TYPES.forEach(p => {
        var id = "checkbox-" + p;
        var elem = document.getElementById(id);
  
        if (elem) {
          var index = selectedProductTypes.findIndex(p2 => p == p2);
          
          if (index == -1) {
            (elem as any).checked = false;
          } else {
            (elem as any).checked = true;
          }
        }
      });  
    }, 10);
  }

  function doSelectProductTypes() {
    var selection = [];

    ALL_PRODUCT_TYPES.forEach(p => {
      var id = "checkbox-" + p;
      var elem = document.getElementById(id);

      if (elem) {
        var checked = (elem as any).checked;

        if (checked) {
          selection.push(p);
        }
      }
    });

    if (selection.length == 0 || selection.length == ALL_PRODUCT_TYPES.length) {
      setSpecificProductType(false);
    }

    setSelectedProductTypes(selection);
    setShowSelectProductType(false);
  }

  function mapCallback(search) {
    searchService.mapSearch(search)
      .then((results) => {
        Utils.mobileNavigator.pushPage({
          page: <MobileMapSearch data={results} search={search}/>,
          name: "search/" + utils.makeId(5),
          renderToolbar: () => {
            return <DefaultMobileToolbar text="Zoekresultaten" backButton={true} showMapIcon={true}/>
          }
        }, { animation: "none" });
      });
  }

  const formik = useFormik({
    initialValues: SearchValidator.initialValues,
    validationSchema: SearchValidator.validationSchema,
    onSubmit: (values, _) => {
      values["locationType"] = locationType;
      values["sortBy"] = sortBy;
      values["sortDirection"] = sortDirection;
      values["distance"] = "" + currentRadius;

      var cleaned = SearchValidator.cleanInput(values);

      Utils.mobileNavigator.pushPage({
        page: <MobileSearchItemsResultPage search={cleaned} searchResults={null}/>,
        name: "search/" + utils.makeId(5),
        renderToolbar: () => {
          return <DefaultMobileToolbar 
            text="Zoekresultaten" 
            backButton={true} showMapIcon={true} 
            mapCallback={() => mapCallback(cleaned)}/>
        }
      }, { animation: "none" });
    }
  });

  function changeOfferTypes(offerType, enabled) {
    var idx = offerTypes.findIndex(x => x == offerType);

    if (enabled) {
      if (idx == -1) {
        offerTypes.push(offerType);
        setOfferTypes(offerTypes);
      }
    } else {
      if (idx != -1) {
        offerTypes.splice(idx, 1);
        setOfferTypes(offerTypes);
      }
    }
  }

  return (
    <Ons.Page className="bg-white">
      <form onSubmit={formik.handleSubmit}>
        <div className="ml-4 mr-4">
          <div className="redhat-display mt-4">Naam:</div>
          <input
            type="text"
            id="name"
            className="search-field redhat-display"
            placeholder=""
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.name}/>

          {
            formik.touched.name && formik.errors.name ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.name}</div>
              : null
          }

          <div className="redhat-display mt-4">Merk:</div>
          <input
            type="text"
            id="brand"
            className="search-field redhat-display"
            placeholder=""
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.brand}/>

          {
            formik.touched.brand && formik.errors.brand ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.brand}</div>
              : null
          }

          <div className="redhat-display mt-4">Prijs:</div>
          <div className="flex flex-row ml-[10px] mr-[20px] mb-2">
            <div className="ml-1 w-[30px] mt-[3px] redhat-display">van:</div>
            <input
              id="minPrice"
              type="text"
              className="search-field redhat-display ml-4"
              placeholder=""
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.minPrice}/>
            <div className="ml-4 w-[30px] mt-[3px] redhat-display">tot:</div>
            <input
              id="maxPrice"
              type="text"
              className="search-field redhat-display ml-4"
              placeholder=""
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.maxPrice}/>
          </div>

          {
            formik.touched.minPrice && formik.errors.minPrice ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.minPrice}</div>
              : null
          }

          {
            formik.touched.maxPrice && formik.errors.maxPrice ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.maxPrice}</div>
              : null
          }

          <div className="redhat-display bottom-border-none mt-6j">Type aanbieding:</div>
          <div>
            <div key="USER_OFFER" className="mt-2 mb-2">
              <label className="left">
                <Checkbox
                  disabled={false}
                  label=""
                  id="checkbox-USER_OFFER"
                  className="relative top-[4px] mr-2"
                  onChange={ e => {
                    changeOfferTypes(OfferType.USER_OFFER, e.target.checked);
                    formik.handleChange(e);
                  }}
                  value={formik.values.offerTypes && formik.values.offerTypes.findIndex(i => i == OfferType.USER_OFFER) != -1}
                />
              </label>
              <label htmlFor={`checkbox-USER_OFFER`} className="center redhat-display">Gebruiker</label>
            </div>

            <div key="BUSINESS_OFFER" className="mt-2 mb-2">
              <label className="left">
                <Checkbox
                  disabled={false}
                  label=""
                  id="checkbox-BUSINESS_OFFER"
                  className="relative top-[4px] mr-2"
                  onChange={ e => {
                    changeOfferTypes(OfferType.BUSINESS_OFFER, e.target.checked);
                    formik.handleChange(e);
                  }}
                  value={formik.values.offerTypes && formik.values.offerTypes.findIndex(i => i == OfferType.BUSINESS_OFFER) != -1}
                />
              </label>
              <label htmlFor={`checkbox-BUSINESS_OFFER`} className="center redhat-display">Winkel</label>
            </div>

            <div key="AUCTION" className="mt-2 mb-2">
              <label className="left">
                <Checkbox
                  disabled={false}
                  label=""
                  id="checkbox-AUCTION"
                  className="relative top-[4px] mr-2"
                  onChange={ e => {
                    changeOfferTypes(OfferType.AUCTION, e.target.checked);
                    formik.handleChange(e);
                  }}
                  value={formik.values.offerTypes && formik.values.offerTypes.findIndex(i => i == OfferType.AUCTION) != -1}
                />
              </label>
              <label htmlFor={`checkbox-AUCTION`} className="center redhat-display">Veiling</label>
            </div>
          </div>

          {
            formik.touched.offerTypes && formik.errors.offerTypes ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.offerTypes}</div>
              : null
          }

          <div className="mt-6 mb-2 redhat-display">Type product: { productTypeSummary() }</div>
          <div className="mb-4 ml-2">
            {
              showSelectProductType ?
                <>
                  <div>
                    <div key="ALL_PRODUCT_TYPES" className="mt-2 mb-2">
                      <label className="left">
                        <input 
                          type="radio"
                          id="checkbox-ALL_PRODUCT_TYPES"
                          className="relative top-[4px]"
                          onChange={() => setSpecificProductType(false)}
                          checked={specificProductType == false}
                        />
                      </label>
                      <label htmlFor={`checkbox-ALL_PRODUCT_TYPES`} 
                        className="center redhat-display">Alle producttypen</label>
                    </div>

                    <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

                    <div key="SPECIFIC_PRODUCT_TYPES" className="mt-2 mb-2">
                      <label className="left">
                        <input
                          type="radio"
                          id="checkbox-SPECIFIC_PRODUCT_TYPES"
                          className="relative top-[4px]"
                          onChange={() => setSpecificProductType(true)}
                          checked={specificProductType == true}
                        />
                      </label>
                      <label htmlFor={`checkbox-SPECIFIC_PRODUCT_TYPES`} 
                          className="center redhat-display">Specifieke typen</label>
                    </div>
                  </div>

                  <div className="mb-4">
                    {
                      specificProductType ?
                        ALL_PRODUCT_TYPES.map((x, _) => (
                          <div key={x} className="mt-2 mb-2">
                            <Checkbox
                              disabled={false}
                              label={productTypeToString(x)}
                              className="mt-2 mb-2 relative top-[-5px] mr-2"
                              id={"checkbox-" + x}
                              onChange={ e => {}}
                              value={null}
                            /> 
                          </div>
                        ))
                      : <></>
                    }
                  </div>

                  <Ons.Button className="link-color mb-4 mt-4 top-[8px]" 
                      onClick={() => doSelectProductTypes()}>
                    <span className="text-sm redhat-display capitalize">Selecteren</span>
                  </Ons.Button>
                </>
                : 
                <>
                  <Ons.Button className="link-color mb-4" 
                      onClick={() => openChangeProductType()}>
                    <span className="text-sm redhat-display capitalize">Selecteren</span>
                  </Ons.Button>
                </>
            }
          </div>

          <div className="redhat-display mt-6">Jaar:</div>

          <div className="flex flex-row ml-[10px] mr-[20px] mb-2">
            <div className="ml-1 w-[30px] mt-[3px] redhat-display">van:</div>
            <input
              id="minYear"
              type="text"
              className="search-field redhat-display ml-4"
              placeholder=""
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.minYear}/>
            <div className="ml-4 w-[30px] mt-[3px] redhat-display">tot:</div>
            <input
              id="maxYear"
              type="text"
              className="search-field redhat-display ml-4"
              placeholder=""
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.maxYear}/>
          </div>

          {
            formik.touched.minYear && formik.errors.minYear ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.minYear}</div>
              : null
          }

          {
            formik.touched.maxYear && formik.errors.maxYear ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.maxYear}</div>
              : null
          }

          <div className="mt-4 redhat-display">Aanbieder:</div>
          <input
            type="text"
            id="provider"
            className="search-field redhat-display"
            placeholder=""
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.provider}/>

          {
            formik.touched.provider && formik.errors.provider ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.provider}</div>
              : null
          }

          <div className="redhat-display mt-6">Locatie:</div>

          <div key="listitem-NO_LOCATION" className="mt-2 mb-2">
            <label className="left">
              <input
                type="radio"
                id="NO_LOCATION"
                checked={locationType == LocationType.NO_LOCATION}
                className="relative top-[4px]"
                onChange={ e => {
                  formik.errors.location = null;
                  formik.values.location = "";
                  formik.values.locationType = LocationType.NO_LOCATION;
                  changeLocationType(LocationType.NO_LOCATION);
                }}
              />
            </label>
            <label htmlFor={`input-NO_LOCATION`} 
                className="center redhat-display">Geen locatie</label>
          </div>

          <div key="listitem-MY_LOCATION" className="mt-2 mb-2">
            <label className="left">
              <input
                type="radio"
                id="MY_LOCATION"
                checked={locationType == LocationType.MY_LOCATION}
                className="relative top-[4px]"
                onChange={ e => {
                  formik.errors.location = null;
                  formik.values.location = "";
                  formik.values.locationType = LocationType.MY_LOCATION;
                  changeLocationType(LocationType.MY_LOCATION);
                }}
              />
            </label>
            <label htmlFor={`input-MY_LOCATION`} 
                className="center redhat-display">Mijn locatie</label>
          </div>

          <div key="listitem-SPECIFIC_LOCATION" className="mt-2 mb-2">
            <label className="left">
            <input
              type="radio"
              id="SPECIFIC_LOCATION"
              checked={locationType == LocationType.SPECIFIC_LOCATION}
              className="relative top-[4px]"
              onChange={ e => {
                formik.values.locationType = LocationType.SPECIFIC_LOCATION;
                changeLocationType(LocationType.SPECIFIC_LOCATION);
              }}
            />
            </label>
            <label htmlFor={`input-SPECIFIC_LOCATION`} 
                className="center redhat-display">Specifieke locatie</label>
          </div>

          {
            locationType == LocationType.SPECIFIC_LOCATION ?
              <input
                id="location"
                type="text"
                className="search-field redhat-display mt-2 ml-2 mr-2"
                placeholder=""
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.location}/>
              : <></>
          }

          {
            formik.touched.location && formik.errors.location ?
              <div className="text-sm ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.location}</div>
              : null
          }

          {
            locationType != LocationType.NO_LOCATION ?
              <>
                <div className="m-1 ml-2 redhat-display">Straal:</div>
                <div className="flex flex-row ml-4 mr-4 mb-4">
                  <Ons.Range
                    className="full-width-important"
                    onInput={(e) => changeRadius(e)}
                    value={20}
                    step={1}
                    min={0}
                    max={500}/>

                  <div className="ml-8 redhat-display w-[70px] text-right">{ currentRadius }&nbsp;km</div>
                </div>
              </>
              : <></>
          }

          { 
            locationType != LocationType.NO_LOCATION ?
              <>
                <Map
                  className="mt-4 mb-4 w-full h-[300px] p-4"
                  mapLocation={DEFAULT_MAP_LOCATION} 
                  zoom={cityMapZoom} 
                  searchRadius={10} 
                  markers={cityMapMarkers}
                  markerRenderer={(marker) => "" }
                  width={"calc(100%)"}
                  height={"calc(100%)"}
                />
              </>
              : <></>
          }

          <div className="mt-6 mb-2 redhat-display">Online sinds: 
            {
              onlineSinceString ? 
                <div className="ml-4 mb-4 mt-[5px] mr-4 redhat-display">{ "onlineSinceString" } </div> 
                : <></>
            }
          </div>
          <div className="mb-2 ml-2">
            <div className="flex flex-row">
              <Ons.Button className="link-color mb-4" onClick={() => selectDateTime()}>
                <span className="text-sm redhat-display capitalize">Selecteren</span>
              </Ons.Button>
            </div>
          </div>

          <div>
            <div className="mt-8 mb-2 redhat-display">Sorteren op:</div>

            <div key="sortBy-CREATE_TIME" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="sortBy-CREATE_TIME"
                  className="relative top-[4px]"
                  onChange={() => setSortBy(SortBy.CREATE_TIME)}
                  checked={sortBy == SortBy.CREATE_TIME}
                />
              </label>
              <label htmlFor={`sortBy-CREATE_TIME`} 
                className="center redhat-display">Datum</label>
            </div>

            <div key="sortBy-PRICE" className="mt-2 mb-2">
              <label className="left">
                <input 
                  type="radio"
                  id="sortBy-PRICE"
                  className="relative top-[4px]"
                  onChange={() => setSortBy(SortBy.PRICE)}
                  checked={sortBy == SortBy.PRICE}
                />
              </label>
              <label htmlFor={`sortBy-PRICE`} 
                  className="center redhat-display">Prijs</label>
            </div>

            <div key="sortBy-NAME" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="sortBy-NAME"
                  className="relative top-[4px]"
                  onChange={() => setSortBy(SortBy.NAME)}
                  checked={sortBy == SortBy.NAME}
                />
              </label>
              <label htmlFor={`sortBy-NAME`} 
                  className="center redhat-display">Naam</label>
            </div>

            <div key="sortBy-YEAR" className="mt-2 mb-2">
              <label className="left">
                <input 
                  type="radio"
                  id="sortBy-YEAR"
                  className="relative top-[4px]"
                  onChange={() => setSortBy(SortBy.YEAR)}
                  checked={sortBy == SortBy.YEAR}
                />
              </label>
              <label htmlFor={`sortBy-YEAR`} 
                  className="center redhat-display">Jaar</label>
            </div>

            <div className="mt-6 mb-2 redhat-display">Volgorde:</div>

            <div key="sortBy-DESCENDING" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="sortBy-DESCENDING"
                  className="relative top-[4px]"
                  onChange={() => setSortDirection(SortDirection.DESC)}
                  checked={sortDirection == SortDirection.DESC}
                />
              </label>
              <label htmlFor={`sortBy-DESCENDING`} 
                className="center redhat-display">Aflopend</label>
            </div>

            <div key="sortBy-ASCENDING" className="mt-2 mb-2">
              <label className="left">
                <input
                  type="radio"
                  id="sortBy-ASCENDING"
                  className="relative top-[4px]"
                  onChange={() => setSortDirection(SortDirection.ASC)}
                  checked={sortDirection == SortDirection.ASC}
                />
              </label>
              <label htmlFor={`sortBy-ASCENDING`} 
                  className="center redhat-display">Oplopend</label>
            </div>
          </div>

          <div className="flex flex-row gap-4 w-full mt-4 justify-center text-center">
            <button className="relative top-[-5px] redhat-display button min-w-[100px] mb-4 link-color" type="submit">
              <span className="text-sm redhat-display">Zoeken</span>
            </button>
          </div>

          <div className="mb-[100px]"></div>
        </div>
      </form>
    </Ons.Page>
  );
}