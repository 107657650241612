enum PaymentStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}

export function paymentStatusToString(p: PaymentStatus): string {
  switch (p) {
    case PaymentStatus.SUCCESS:
      return "Betaling geslaagd";
    case PaymentStatus.FAILED:
      return "Betaling mislukt";
    default:
      return null;
  }
}

export default PaymentStatus;