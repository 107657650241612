enum TradeType {
  BUSINESS_TRADE = "BUSINESS_TRADE",
  USER_TRADE = "USER_TRADE"
}

export default TradeType;

export function tradeTypeFromString(tradeType: string): TradeType {
  switch (tradeType) {
    case "BUSINESS_TRADE": 
      return TradeType.BUSINESS_TRADE;
    case "USER_TRADE": 
      return TradeType.USER_TRADE;
    default:
      return null;
  }
}

export function tradeTypeToString(tradeType: TradeType): string {
  switch (tradeType) {
    case TradeType.BUSINESS_TRADE:
      return "Verkoop";
    case TradeType.USER_TRADE:
      return "Gebruiker";
    default:
      return "";
  }
}

export const ALL_TRADE_TYPES = [
  TradeType.BUSINESS_TRADE,
  TradeType.USER_TRADE
];