import moment from 'moment';
import { DATE_TIME_FORMAT } from '../constants';
import ProductType, { productTypeFromString } from '../item/product.type';
import PriceType from '../offer/offer.type';
import bigDecimal from 'js-big-decimal';

class MarketplaceTrade {
  id: string;
  bidId: string;
  offerId: string;
  itemId: string; 
  offerAccountId: string;
  bidAccountId: string;
  offerAccountPreviewImageId: string;
  bidAccountPreviewImageId: string;
  amount: bigDecimal;
  createTime: Date;
  isGroup: boolean;
  itemTitle: string;
  productType: ProductType;
  offerType: PriceType;

  constructor($trade: any) {
    this.id = $trade.id;
    this.bidId = $trade.bidId;
    this.offerId = $trade.offerId;
    this.itemId = $trade.itemId;
    this.offerAccountId = $trade.offerAccountId;
    this.bidAccountId = $trade.bidAccountId;
    this.offerAccountPreviewImageId = $trade.offerAccountPreviewImageId;
    this.bidAccountPreviewImageId = $trade.bidAccountPreviewImageId;
    this.amount = new bigDecimal($trade.amount);
    this.createTime = moment($trade.createTime, DATE_TIME_FORMAT).toDate();
    this.itemTitle = $trade.itemTitle;
    this.isGroup = $trade.isGroup;
    this.offerType = $trade.offerType;

    if ($trade.productType) {
      this.productType = productTypeFromString($trade.productType);
    }
  }
}

export default MarketplaceTrade;