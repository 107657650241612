import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { STRIPE_PUBLISHABLE_KEY } from "../env";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { 
  ConnectAccountOnboarding, 
  ConnectComponentsProvider 
} from "@stripe/react-connect-js";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

export const stripeCheckout = ({clientSecret}) => {
  const options = {clientSecret};

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={options}>
      <EmbeddedCheckout/>
    </EmbeddedCheckoutProvider>);
}

export const stripeAccountOnboarding = (stripeClientSecret, onExit, colorScheme) => {
  const stripeConnect = loadConnectAndInitialize({
    publishableKey: STRIPE_PUBLISHABLE_KEY,
    fetchClientSecret: () => {
      return stripeClientSecret;
    },
    appearance: {
      overlays: "dialog",
      variables: {
        colorPrimary: colorScheme.colorPrimary,
        colorBackground: colorScheme.colorBackground,
        colorText: colorScheme.colorText,
        fontFamily: "'Red Hat Display', sans-serif"
      }
    },
  });

  return (
    <div className="m-4 mb-24">
      <ConnectComponentsProvider 
          connectInstance={stripeConnect}>
        <ConnectAccountOnboarding onExit={onExit}/>
      </ConnectComponentsProvider>
    </div>);
}