import * as Ons from "react-onsenui";

import Loading from "../../../components/loading";
import AccountBuilder from "../../../domain/account/account.builder";
import ItemBuilder from "../../../domain/item/item.builder";
import moment from "moment";
import AccountPage from "../../accounts/[accountId]/page";
import CountDownTimer from "../../../components/countdown.timer";
import offer from "../../../domain/offer/offer";
import OfferPage from "../../offers/[offerId]/page";
import Favorite from "../../../components/favorite";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "../../../domain/constants";
import { authService, favoritesService, utils } from "../../../services";
import { useEffect, useState } from "react";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { EventBus } from "../../../services/event.bus";
import { useNavigate } from "react-router";
import { accountRoleToString } from "../../../domain/account/account.role";
import { Utils } from "../../../services/utils";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import PriceType, { offerTypeToString } from "../../../domain/offer/offer.type";
import { BACKEND_URL } from "../../../domain/env";

export default function MobileFavorites({ accountFavorites, offerFavorites }) { 
  const navigate = useNavigate();
  
  const [accounts, setAccounts] = useState<any[]>(null);
  const [offers, setOffers] = useState<any[]>(null);
  const [items, setItems] = useState<any[]>(null);

  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(true);
  const [loadingOffers, setLoadingOffers] = useState<boolean>(true);
  const [loadingItems, setLoadingItems] = useState<boolean>(true);

  const unauthorized = () => utils.handleUnauthorized(() => navigate("/login"));

  const loadData = () => {
    Promise.all(accountFavorites.map(a => {
      var url = BACKEND_URL + "/accounts/info/" + a.favoriteEntityId;
      return authService.getSecure(url);
    })).then((result) => {
      var accountList = result.map(r => {
        return AccountBuilder.buildAccount(r.data);
      });

      setAccounts(accountList);
      setLoadingAccounts(false);
    }).catch((_) => {
      unauthorized();
    });

    Promise.all(offerFavorites.map(o => {
      var offerUrl = BACKEND_URL + "/offers/" + o.favoriteEntityId;

      return authService.getSecure(offerUrl);
    })).then((result) => {
      var offerList = result.map(r => {
        return OfferBuilder.buildOffer(r.data);
      });

      setOffers(offerList);
      setLoadingOffers(false);

      Promise.all(offerList.map(offer => {
        var itemUrl = BACKEND_URL + "/items/" + offer.itemId;
        return authService.getSecure(itemUrl);
      })).then((result) => {
        var itemList = result.map(r => {
          return ItemBuilder.buildItem(r.data);
        });
        
        setItems(itemList);
        setLoadingItems(false);
      }).catch((e) => {
        console.error(e);
        unauthorized();
      });
    }).catch((e2) => {
      console.error(e2);
      unauthorized();
    });
  }

  useEffect(() => {
    if (!accounts) {    
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => loadData());
  
      loadData();
    }
  }, null);

  function goToAccount(account) {
    Utils.mobileNavigator.pushPage({
      page: <AccountPage accountId={account.id} readOnly={false}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Account" backButton={true}/>
      }
    }, { animation: "none" });
  }
  
  function goToOffer(offer) {
    Utils.mobileNavigator.pushPage({
      page: <OfferPage offerId={offer.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Item bekijken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function accountFavoriteClick(favorite) {
    var idx = accounts.findIndex(o => o.id == favorite.id);

    if (idx != -1) {
      favoritesService.removeFavorite(
        "/accounts", favorite.id, () => unauthorized());
    
      accounts.splice(idx, 1);

      setAccounts([]);

      setTimeout(() => {
        setAccounts(accounts);
      });
    }
  }

  function offerFavoriteClick(favorite) {
    var idx = offers.findIndex(o => o.id == favorite.id);

    if (idx != -1) {
      favoritesService.removeFavorite(
        "/offers", favorite.id, () => unauthorized());

      offers.splice(idx, 1);

      setOffers([]);

      setTimeout(() => {
        setOffers(offers);
      });
    }
  }
  
  const renderHeader = (nrItems, text) => {
    return (
      <div>
        <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">{ text }</div>
        </Ons.ListHeader>

        { 
          nrItems == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen favoriete {text}.</div>
            </div>
            :
            <div>
              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
          }
      </div>
    );
  }

  const renderFavoriteAccountRow = (row, index) => {
    return (
      <div key={row.id}>
        <Ons.ListItem key={row.id} modifier="nodivider">
          <div className="flex flex-row grow body-style"
              onClick={() => goToAccount(row)}>
            <img 
              className="avatar-img-account-mobile rounded-full h-[100px] w-[100px] bg-gray-50 p-2 z-30 relative left-[-5px] top-[0px]"
              src={`${BACKEND_URL}/static/img/preview/${row.id}`}
              alt="Item image"
              width={40}
              height={40}/>

            <div className="flex flex-col">
              <div className="text-xs font-bold">{ row.firstName }</div>

              <div className="text-xs">
                Sinds { moment(row.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
              </div>

              <div className="text-xs">
                Type: { accountRoleToString(row.accountRole) }
              </div>

              {
                row.city ?
                  <div className="text-xs">
                    { row.city } { utils.getDistance(row.city) }
                  </div>
                  : <></>
              }

              {
                row.nrOffers ?
                  <div className="text-xs">
                    { row.nrOffers } aanbiedingen
                  </div>
                  : <>&nbsp;</>
              }

              {
                row.nrTrades ?
                  <div className="text-xs">
                    { row.nrTrades } transacties
                  </div>
                  : <>&nbsp;</>
              }
            </div>

            <div className="grow"></div>

            <Favorite
              itemType={"account"} 
              favoriteEntityId={row.id} 
              onClickCallback={() => accountFavoriteClick(row)}
              forceEnabled={true}/>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </div>
    );
  }

  const renderFavoriteOfferRow = (row, index) => {
    var item = items.find(item => item.id == row.itemId);

    if (!item) {
      return (<></>);
    }

    var account = accounts.find(account => account.id = row.accountId);

    return (
      <div key={row.id}>
        <Ons.ListItem key={row.id} modifier="nodivider">
          <div className="flex flex-row grow body-style" 
              onClick={() => goToOffer(row)}>
            <img 
              className={`mr-4 ml-0 mt-0 rounded-lg mobile-item-list-image ${ row.offerType == PriceType.AUCTION ? "mobile-auction-image" : "" } ${ row.offerType == PriceType.BUSINESS_OFFER ? "mobile-business-image" : ""}`}
              src={`${BACKEND_URL}/static/img/preview/${item.id}`}
              alt="Item image"
              width={80}
              height={80}/>
            
            <div className="flex flex-col">
              <div className="text-xs font-bold">{ item.title }</div>

              <div className="font-bold bid-amount relative text-left">{
                row.amount ?
                  utils.roundedAmount(row.amount, 2)
                  : "Bieden"
              }</div>

              <div className="text-xs">
                Sinds { moment(row.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
              </div>

              <div className="text-xs">
                Type: { offerTypeToString(row.offerType) }
              </div>

              {
                account && account.city ?
                  <div className="text-xs">
                    { account.city } { utils.getDistance(account) }
                  </div>
                  : <></>
              }
            </div>

            {
              row.offerType == PriceType.AUCTION ? 
                <CountDownTimer offer={offer}
                labelClassName="relative left-[30px] top-[20px] text-xs"
                timerClassName="relative left-[40px] top-[20px] auction-time-mobile"/>
                : <></>
            }

            <div className="grow"></div>

            <Favorite
              itemType={"offer"} 
              favoriteEntityId={row.id}
              onClickCallback={() => offerFavoriteClick(row)}
              forceEnabled={true}/>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </div>);
  }

  if (loadingAccounts || loadingOffers || loadingItems) {
    return <Loading/>;
  }

  return (
    <>
      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={accounts}
        renderRow={(row, index) => renderFavoriteAccountRow(row, index)}
        renderHeader={() => renderHeader(accounts.length, " accounts")}/>

      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={offers}
        renderRow={(row, index) => renderFavoriteOfferRow(row, index)}
        renderHeader={() => renderHeader(offers.length, " aanbiedingen")}/>
    </>);
}