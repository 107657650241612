import { useState } from "react";
import { useFormik } from 'formik';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import * as Yup from 'yup';
import React from 'react';
import { Modal, ModalClose, Radio, Tooltip } from "@mui/joy";
import { PriceType } from "./price.type";
import Spinner from "../../../components/spinner";
import { BACKEND_URL } from "../../../domain/env";
import ProductType, { productTypeFromString, ALL_PRODUCT_TYPES, productTypeToString } from "../../../domain/item/product.type";
import { authService, utils } from "../../../services";
import MobileNewItem from "./mobile.new.item";
import { NewItemValidator } from "../../../domain/search/form.validators";
import Checkbox from "../../../components/checkbox";

export default function NewItem() {
  const [visible, setvisible] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [priceType, setPriceType] = useState<PriceType>(PriceType.FIXED_PRICE);
  const [auctionHasStartPrice, setAuctionHasStartPrice] = useState<boolean>(false);
  const [allowRetractingBids, setAllowRetractingBids] = useState<boolean>(false);
  const [allowBiddingHasStartPrice, setAllowBiddingHasStartPrice] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [productType, setProductType] = useState<ProductType>(ProductType.WINE);
  const [enlargedImage, setEnlargedImage] = useState<any>(null);
  const [enlargeImageOpen, setEnlargeImageOpen] = useState<boolean>(false);
  
  const handleProductTypeChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
    setProductType(productTypeFromString(newValue));
  };

  const handleUploadFiles = (files: any) => {
    var uploaded = [...uploadedFiles];
    
    uploaded = Array.prototype.slice.call(uploaded, 0, 3);
    
    files.some((file: any) => {
      if (uploaded.findIndex((f: any) => f.name == file.name) == -1) {       
        if (uploaded.length < 3) {
          uploaded.push(file);
        }
      }
    });

    //formik.touched.nrImages = false;
    //formik.values.nrImages = uploaded.length;

    setUploadedFiles(uploaded);
  }

  async function uploadImagesToServer() {
    if (uploadedFiles.length == 0) {
      return;
    }

    return Promise.all(uploadedFiles.map((file, i) => {
      const body = new FormData();
      
      body.set("sequenceNr", "" + i);
      body.set("file", uploadedFiles[i]);

      var url = BACKEND_URL + "/static/img";

      return authService.postSecure(url, body)
        .then((result) => {
          var location = result["headers"]["location"] as string;
          var idx = location.lastIndexOf("/");
          var imgId = location.substring(idx + 1);
          
          return imgId;
        });
    }));
  }

  function uploadToServer(data) {
    uploadImagesToServer()
      .then((result) => {
        data["imgIds"] = result;
        var url = BACKEND_URL + "/items";

        authService.postSecure(url, data);
      });
  }

  const handleFileEvent = (e: any) => {
    var chosenFiles = Array.prototype.slice.call(e.target.files, 0, 3);
    handleUploadFiles(chosenFiles);
  }

  const handlevisibleChange = () => {
    setvisible(!visible);
  }

  const handleAuctionHasStartPriceChange = () => {
    setAuctionHasStartPrice(!auctionHasStartPrice);
    formik.values.auctionStartPrice = "";
  }

  const handleAllowRetractingBidsChange = () => {
    setAllowRetractingBids(!allowRetractingBids);
  }

  const handleAllowBiddingHasStartPriceChange = () => {
    setAllowBiddingHasStartPrice(!allowBiddingHasStartPrice);
    formik.values.simpleStartPrice = "";
  }

  const handleRadioPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == "FIXED_PRICE") {
      setPriceType(PriceType.FIXED_PRICE);
    } else if (event.target.value == "AUCTION") {
      setPriceType(PriceType.AUCTION);
    } else if (event.target.value == "ALLOW_BIDDING") {
      setPriceType(PriceType.ALLOW_BIDDING)
    }

    setAllowBiddingHasStartPrice(false);
    setAuctionHasStartPrice(false);
  }

  function validAuctionDuration(f) {
    var auctionDays = f.values.auctionDays;
    var auctionHours = f.values.auctionHours;
    var auctionMinutes = f.values.auctionMinutes;

    if (!auctionDays) {
      auctionDays = "0";
    }

    if (!auctionHours) {
      auctionHours = "0";
    }

    if (!auctionMinutes) {
      auctionMinutes = "0";
    }

    var days = parseInt(auctionDays) * 24 * 60 * 60
    var hours = parseInt(auctionHours) * 60 * 60;
    var minutes = parseInt(auctionMinutes) * 60;

    var auctionDurationSeconds = days + hours + minutes;
    var oneHour = 60 * 60;

    return auctionDurationSeconds >= oneHour;
  }
  
  function validAuctionStartPrice(f) {
    var value = f.values.auctionStartPrice;

    if (auctionHasStartPrice) {
      if (!value || value == "") {
        return false;
      }

      var parsed = parseInt(value);

      if (isNaN(parsed)) {
        return false;
      }

      if (parsed < 0) {
        return false;
      }
    }

    return true;
  }

  function deleteImage(idx) {
    setUploadedFiles(uploadedFiles => uploadedFiles.filter((_, i) => i !== idx));
  }

  function enlargeImage(file) {
    setEnlargedImage(URL.createObjectURL(file));
    setEnlargeImageOpen(true);
  }

  const formik = useFormik({
    initialValues: NewItemValidator.initialValues,
    validationSchema: NewItemValidator.validationSchema,
    onSubmit: (values, _) => {
      var cleaned = NewItemValidator.cleanInput(values);

      // Utils.mobileNavigator.pushPage({
      //   page: <MobileNewItemCreated />,
      //   name: "new-item-created/" + utils.makeId(5),
      //   renderToolbar: () => {
      //   }
      // });
    }
  });

	function renderWeb() {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="max-w-2xl container mx-auto grid auto-rows-auto flex-row place-content-center aling-middle min-h-screen">
            <div>
              <label className="grow-0">Afbeeldingen:</label>

              <div className="flex flex-row gap-2 mt-4">
                {uploadedFiles.map((file: any, i) => (
                  <div key={file.name}>
                    <img 
                      src={URL.createObjectURL(file)}
                      width={100} 
                      height={100}
                      onClick={() => enlargeImage(file)}
                      className="rounded-lg max-w-[100px] max-h-[100px] object-contain cursor-pointer"/>
                    <div className="w-full mx-auto text-center">
                      <a className="text-xs cursor-pointer" onClick={() => deleteImage(i)}>Verwijderen</a>
                    </div>
                  </div>
                ))}

                {[...Array(Math.max(0, 3 - uploadedFiles.length))].map((x, i) => (
                  <div key={x} className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed"></div>
                ))}
              </div>

              <div className="dropZoneContainer mt-2">
                <input type="file"
                  multiple 
                  id="drop_zone" 
                  className="FileUpload" 
                  accept=".jpg,.png"
                  onChange={handleFileEvent}/>
                <div className="dropZoneOverlay rounded-lg">Selecteren</div>
              </div>
            </div>

            <div className="grid grid-rows-3">
              <label className="grow" htmlFor="itemYear">Aantal items:</label>

              <div className="grow">
                <input
                  type="text"
                  id="nrItems"
                  className="min-w-md w-full larger-input"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.nrItems}
                />
              </div>

              {formik.touched.nrItems && formik.errors.nrItems ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.nrItems}</div>
              ) : null }
            </div>

            <div className="grid grid-rows-3">
              <label className="grow" htmlFor="title">Titel:</label>

              <div className="grow">
                <input 
                  type="text"
                  className="min-w-md w-full larger-input" 
                  id="title" 
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
              </div>

              {formik.touched.title && formik.errors.title ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.title}</div>
              ) : null }
            </div>

            <div className="grid grid-rows-3">
              <label className="grow" htmlFor="brand">Merk:</label>

              <div className="grow">
                <input
                  id="brand"
                  className="min-w-md w-full larger-input" 
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.brand}
                />
              </div>

              {formik.touched.brand && formik.errors.brand ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.brand}</div>
              ) : null }
            </div>

            <div className="grid grid-rows-3">
              <label className="grow" htmlFor="productType">Type:</label>

              <Select 
                id="new_item_type"
                onChange={handleProductTypeChange}
                defaultValue="WINE"
                className="w-full mr-2 mt-[-5px]">
                  {ALL_PRODUCT_TYPES.map((productType) => (
                    <Option key={productType} value={productType} className="body-style bg-white redhat-display">{productTypeToString(productType)}</Option>
                  ))}
                </Select>
                
              {formik.touched.productType && formik.errors.productType ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.productType}</div>
              ) : null }
            </div>


            <div className="grid grid-rows-3">
              <label className="grow" htmlFor="itemYear">Jaar:</label>

              <div className="grow">
                <input
                  type="text"
                  id="itemYear"
                  className="min-w-md w-full larger-input"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.itemYear}
                />
              </div>

              {formik.touched.itemYear && formik.errors.itemYear ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.itemYear}</div>
              ) : null }
            </div>

            <div className="flex flex-col">
              <label className="mb-2" htmlFor="description">Omschrijving:</label>

              <div className="grow">
                <textarea 
                  rows={0} 
                  cols={0} 
                  className="min-w-md w-full resize-none textarea text-sm" 
                  id="description" 
                  name="description"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}>
                </textarea>
              </div>

              {formik.touched.description && formik.errors.description ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.description}</div>
              ) : null }
            </div>

            <div className="mt-8">Type aanbieding:</div>

            <div>
              <Tooltip title={
                <div className="body-style">
                  Zet het item online met een vaste prijs zonder mogelijkheid tot bieden.
                </div>
              } variant="plain">
                <Radio 
                  id="radio_fixed_price"
                  className="pt-3 pr-3 body-style redhat-display"
                  checked={priceType == PriceType.FIXED_PRICE}
                  onChange={ e => {
                    formik.handleChange(e);
                    handleRadioPrice(e);
                  }}
                  value="FIXED_PRICE"
                  name="radio-button-price"
                  label="Aanbieden met vaste prijs"
                />
              </Tooltip>

              { 
                priceType == PriceType.FIXED_PRICE ? 
                  <div className="grid grid-rows-3 mb-[-20px]">
                    <label className="grow mt-4 ml-4" htmlFor="price">Prijs:</label>

                    <div className="grow mt-[-20px] mb-[-100px] ml-4">
                      <div className="relative top-[35px] left-[10px] w-[10px] text-sm">&#x20AC;</div>

                      <input 
                        id="fixedPrice"
                        className="min-w-md w-full larger-input pl-6 mt-2" 
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.fixedPrice}
                      />
                    </div>

                    {formik.touched.fixedPrice && formik.errors.fixedPrice ? (
                      <div className="text-xs mt-1 text-right text-red-600">{formik.errors.fixedPrice}</div>
                    ) : null }
                  </div>
                : <></>
              }
            </div>
            
            <div>
              <Tooltip title={
                  <div className="body-style">
                    Maak een veiling aan met een timer.
                    <br/>
                    De hoogste bieding wint.
                  </div>
                } variant="plain">
                <Radio
                  id="radio_auction"
                  className="pt-3 pr-3 body-style"
                  checked={priceType == PriceType.AUCTION}
                  onChange={ e => {
                    formik.handleChange(e);
                    handleRadioPrice(e);
                  }}
                  value="AUCTION"
                  name="radio-button-price"
                  label="Als veiling aanbieden"
                />
              </Tooltip>

              {
                priceType == PriceType.AUCTION ? (
                  <div className="mt-4 ml-4">
                    <div className="mb-2">Duur van de veiling:</div>
                    <div className="mb-4">
                      <input 
                        type="text" 
                        id="auctionDays"
                        className="w-[40px] mr-2"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionDays}/>
                      <span className="text-sm">dagen,&nbsp;&nbsp;</span>

                      <input 
                        type="text" 
                        id="auctionHours"
                        className="w-[40px] mr-2"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionHours}/>
                      <span className="text-sm">uren,&nbsp;&nbsp;</span>

                      <input 
                        type="text" 
                        id="auctionMinutes"
                        className="w-[40px] mr-2"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionMinutes}/>
                      <span className="text-sm">minuten</span>
                    </div>

                    {formik.touched.auctionDays && formik.errors.auctionDays ? (
                      <div className="text-xs mt-1 text-right text-red-600">{formik.errors.auctionDays}</div>
                    ) : null }

                    <div className="relative top-[2px] inline-block">
                      <Checkbox
                        disabled={false}
                        label=""
                        className=""
                        id="auctionHasStartPrice"
                        value={auctionHasStartPrice}
                        onChange={handleAuctionHasStartPriceChange}
                      />
                    </div>
                    <label htmlFor="auctionHasStartPrice" className="mt-4 mr-4 ml-2">Startprijs:</label>

                    <input type="text" 
                      id="auctionStartPrice"
                      disabled={!auctionHasStartPrice} 
                      className="w-full mb-4 mt-2 pl-6"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.auctionStartPrice}/>
                    <div className="relative top-[-41px] left-[10px] w-[10px] text-sm">&#x20AC;</div>

                    {formik.touched.auctionStartPrice && formik.errors.auctionStartPrice ? (
                      <div className="text-xs text-right text-red-600 mt-[-20px]">{formik.errors.auctionStartPrice}</div>
                    ) : null }
                  </div>
                ) : <></>
              }
            </div>

            <div>
              <Tooltip title={
                  <div className="body-style">
                    Laat enkele gebruikers zelf bieden zonder timer.
                    <br/>
                    Accepteer individuele biedingen, eventueel met een startprijs.
                  </div>
                } variant="plain">
                <Radio 
                  id="radio_allow_bidding"
                  className="pt-3 pr-3 body-style"
                  checked={priceType == PriceType.ALLOW_BIDDING}
                  onChange={ e => {
                    formik.handleChange(e);
                    handleRadioPrice(e);
                  }}
                  value="ALLOW_BIDDING"
                  name="radio-button-price"
                  label="Simpel bieden"/>
              </Tooltip>

              {
                priceType == PriceType.ALLOW_BIDDING ? (
                  <div className="mt-4 ml-4">
                    <div className="relative top-[2px] inline-block">
                      <Checkbox
                        disabled={false}
                        id="allowBidding"
                        label=""
                        className=""
                        value={allowBiddingHasStartPrice}
                        onChange={handleAllowBiddingHasStartPriceChange}/>
                    </div>
                    <label htmlFor="allowBidding" className="mt-4 mr-4 ml-2">Startprijs:</label>
                    
                    <input 
                      id="simpleStartPrice"
                      type="text" 
                      disabled={!allowBiddingHasStartPrice} 
                      className="w-full mb-4 mt-2 pl-6"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.simpleStartPrice}/>
                    <div className="relative top-[-41px] left-[10px] w-[10px] text-sm">&#x20AC;</div>

                    {formik.touched.simpleStartPrice && formik.errors.simpleStartPrice ? (
                      <div className="text-xs text-right text-red-600 mt-[-20px]">{formik.errors.simpleStartPrice}</div>
                    ) : null }
                  </div>
                ) : <></>
              }
            </div>

            <div className="flex flex-row mb-4">
              <div className="grow mb-0 mt-4">
                <Tooltip title={
                  <div className="body-style">
                    Je kan het item nu aanmaken en later verder bewerken in &apos;Mijn items&apos;.
                    <br/> 
                    In de tussentijd is het nog niet zichtbaar voor andere gebruikers.
                  </div>
                } variant="plain">
                  <div className="mt-4">
                    <div className="relative top-[2px] inline-block">
                      <Checkbox
                        id="visible"
                        label=""
                        className=""
                        disabled={false}
                        value={visible}
                        onChange={handlevisibleChange}/>
                    </div>
                    <label htmlFor="visible" className="mt-4 mr-4 ml-2">Als concept opslaan</label>
                  </div>
                </Tooltip>
                <div></div>
              </div>
            </div>
            
            <Modal
              open={enlargeImageOpen}
              onClose={() => setEnlargeImageOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <>
                  <ModalClose variant="plain" sx={{ m: 1 }} />
                  <img 
                    src={enlargedImage}
                    width={400} 
                    height={400}
                    className="rounded-lg w-[400px] h-[400px] cursor-pointer object-contain"/>
                </>
            </Modal>

            <div className="flex flex-row-reverse row-cols-2 mb-8 w-full mx-auto">
              <div className="w-full pt-10 pb-7 mr-4 text-center">
                <button className="normal-button" type="submit">Item aanmaken</button>
              </div>
            </div>

            <div className="justify-center mx-auto mt-[0px] mb-2">
              { showSpinner ? <Spinner/> : <></> }

              {
                !formik.isValid && formik.submitCount > 0 ? 
                  <>
                    <div className="text-center text-red-600 w-[300px] ml-2 mr-2 mb-12">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
                  </>
                  : <></>
              }
            </div>
          </div>
        </form>
      </>);
  }

  if (utils.isMobile()) {
    return <MobileNewItem/>;
  } else {
    return renderWeb();
  }
}