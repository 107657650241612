enum BidType {
  PURCHASE_BID = "PURCHASE_BID",
  SIMPLE_BID = "SIMPLE_BID"
}

export default BidType;

export function bidTypeFromString(bidType: string): BidType {
  switch (bidType) {
    case "PURCHASE_BID": 
      return BidType.PURCHASE_BID;
    case "SIMPLE_BID": 
      return BidType.SIMPLE_BID;
    default:
      return null;
  }
}

export function bidTypeToString(bidType: BidType): string {
  switch (bidType) {
    case BidType.PURCHASE_BID:
      return "Koop";
    case BidType.SIMPLE_BID:
      return "Bieding";
    default:
      return "";
  }
}

export const ALL_BID_TYPES = [
  BidType.PURCHASE_BID,
  BidType.SIMPLE_BID
];
