import { Link } from "react-router-dom";
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog, Tooltip } from "@mui/joy";
import { useState } from "react";
import CountDownTimer from "../../components/countdown.timer";
import { DEFAULT_MAP_LOCATION } from "../../domain/constants";
import { productTypeToString, productTypeFromString } from "../../domain/item/product.type";
import { OfferState } from "../../domain/offer/offer.state";
import PriceType, { offerTypeToString } from "../../domain/offer/offer.type";
import { utils } from "../../services";
import Map from "../../components/map";
import { BACKEND_URL } from "../../domain/env";

export default function SearchResult({ item }: { item: any }) {
  function getOwnerLink() {
    if (item && item.ownerId) {
      return "/gropp/accounts/" + item.ownerId;
    } else {
      return "#";
    }
  }

  const [showOwnerLocation, setShowOwnerLocation] = useState<boolean>(false);
  const [ownerMapMarker, setOwnerMapMarker] = useState<any[]>(null);
  const [ownerLocation, setOwnerLocation] = useState<any>(null);

  function showOwnerLocationPopup() {
    if (!item) {
      return;
    }

    var marker = [];
    var location = {};

    if (item.latitude >= -90.0 && item.latitude <= 90.0 &&
        item.longitude >= -180.0 && item.longitude <= 180.0) {
      location = {
        lat: item.latitude, 
        lng: item.longitude
      };

      marker = [{
        key: item.offerId,
        lat: item.latitude,
        lng: item.longitude,
        tooltip: false
      }];
    }

    setOwnerMapMarker(marker);
    setOwnerLocation(location);
    setShowOwnerLocation(true);
  }

  function getOwnerLocationPopup() {
    return (
      <Modal open={showOwnerLocation}>
        <ModalDialog variant="plain" role="alertdialog">
          <DialogTitle>Locatie eigenaar</DialogTitle>
          <Divider />
          <DialogContent>
            <div className="w-[300px] h-[200px] text-center justify-center mx-auto">
              <Map 
                mapLocation={DEFAULT_MAP_LOCATION} 
                zoom={10} 
                searchRadius={0} 
                markers={ownerMapMarker}
                markerRenderer={(marker) => "" }
                width={"100%"}
                height={"100%"}/>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="solid" 
              className="background-accent-color" 
              onClick={() => setShowOwnerLocation(false)}>Sluiten</Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    );
  }

  function goToOffer() {
    if (!item) {
      return;
    }

    // navigate("/gropp/offers/" + item.offerId);
  }

  function getItemPrice() {
    if (item.offerType == PriceType.AUCTION) {
      if (item.offerState == OfferState.AFTER_CLOSE_TIME ||
          item.offerState == OfferState.RETRACTED || 
          item.offerState == OfferState.TRADED ||
          item.offerState == OfferState.FAILED ||
          item.offerState == OfferState.ACCEPTED_BID) {
        return "Veiling gesloten";
      } else if (item.offerState == OfferState.NOT_OPENED_YET) {
        return "Nog niet geopend";
      } else if (item.offerState == OfferState.OPEN) {
        if (item.nrBids == 0) {
          return (
            <div>
              <div>
                <Tooltip title="Dit is een veiling." variant="plain">
                  <img 
                    className="relative top-[5px] left-[60px]" 
                    src="/gropp_hourglass.png"
                    width="13" 
                    height="13" 
                    alt="Grop logo"/>
                </Tooltip>
              </div>
              <div className="relative top-[-15px] left-[20px]">Bieden</div>
            </div>
          );
        }
      }
    } else if (item.offerType == PriceType.USER_OFFER) {
      if (item.allowBidding) {
          return (
            <div>
              <div>
                <Tooltip title="Deze gebruiker staat bieden toe." variant="plain">
                  <img 
                    className="relative top-[3px] left-[60px] z-20" 
                    src="/gropp_hand.png"
                    width="17" 
                    height="17" 
                    alt="Grop logo"/>
                </Tooltip>
              </div>
                <div className="relative top-[-15px] left-[20px]">{
                  !item.price && item.nrBids == 0 ? "Bieden"
                  : <div className="relative left-[10px]">{"" + utils.roundedAmount(item.price, 2)}</div>
                }
                </div>
            </div>);
      }
    }

    return "" + utils.roundedAmount(item.price, 2);
  }

  if (!item) {
    return <></>;
  }
  
  return (
    <div className="flex flex-col text-sm">
      <div className="grid grid-flow-col auto-rows-min">
        <div className="min-w-[200px]">
          <img 
            className={`mr-[-120px] min-w-[200px] ml-0 mt-0 rounded-lg item-image ${item && item.offerType == PriceType.AUCTION ? "auction-border" : ""}`}
            src={`${BACKEND_URL}/static/img/preview/${item.itemId}`}
            alt="Item image"
            width={180}
            height={180}/>

          <div className="opacity-80 relative bottom-[50px] w-[200px] left-[0px] rounded-b-lg h-[50px] text-white bg-white"></div>

          <div className="w-full justify-center text-center">
            <div className="search-amount relative top-[-85px] left-[-45px] z-[110] grow w-full">{getItemPrice()}</div>
          </div>

          { 
            ((item as any).offerType == "AUCTION" && (item as any).offerState == "OPEN") ? 
            <>
              <CountDownTimer 
                offer={item}
                labelClassName="relative left-[-30px] top-[-70px]"
                timerClassName="relative left-[0px] top-[-60px] auction-time"/>
              </>
            : <></>
          }
        </div>

        <div className="grid grid-cols-2 auto-rows-min">
          <div className="text-right font-bold mr-4 p-1">Titel:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{item.title}</div>

          <div className="text-right font-bold mr-4 p-1">Type item:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{productTypeToString(productTypeFromString(item.productType))}</div>

          <div className="text-right font-bold mr-4 p-1">Omschrijving:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{item.description}</div>

          <div className="text-right font-bold mr-4 p-1">Merk:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{item.brand}</div>

          <div className="text-right font-bold mr-4 p-1">Aanbieding:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{offerTypeToString(item.offerType)}</div>

          <div className="text-right font-bold mr-4 p-1">Sinds:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{utils.renderFriendlyDateTime(item.createTime)}</div>

          <div className="text-right font-bold mr-4 p-1">Locatie:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1"><Link to="#" onClick={() => showOwnerLocationPopup()}>bekijk locatie</Link></div>

          { showOwnerLocation ? getOwnerLocationPopup() : <></> }

          <div className="text-right font-bold mr-4 p-1">Eigenaar:</div>
          <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1"><Link to={getOwnerLink()}>bekijk eigenaar</Link></div>
        </div>

        <div className="relative top-[170px] left-[10px]">
          <button className="normal-button" type="submit" onClick={goToOffer}>Bekijken</button>
        </div>
      </div>
    </div>
  );
}