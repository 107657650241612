import { useState } from "react";
import { authService, utils } from "../services";
import { BACKEND_URL, CHAT_WEBSOCKET_URL } from "../domain/env";
import { NotificationBuilder } from "../domain/inbox/notification.builder";
import { Utils } from "../services/utils";

export default function MobileUnreadMessages() {
  const [unreadMessagesLabel, setUnreadMessagesLabel] = useState<string>("0");
  const [websocket, setWebsocket] = useState<any>(null);
  const [connecting, setConnecting] = useState<boolean>(false);

  function connectWebsocket(accountId) {
    utils.getJwt().then((jwt) => {
      var url = CHAT_WEBSOCKET_URL + 
      `?accountId=${accountId}` + 
      `&jwt=${jwt}`;
  
      const ws = new WebSocket(url);
      setWebsocket(ws);
  
      ws.onmessage = function (event) {
        const json = JSON.parse(event.data);
        var notification = NotificationBuilder.buildNotification(json);
  
        if (notification != null) {
          setConnecting(false);
  
          setTimeout(() => {
            var chatElem = document.getElementById("chat-contents");
  
            if (chatElem) {
              chatElem.scrollIntoView(false);
              chatElem.scrollTop = chatElem.scrollHeight;
            }
          }, 100);
  
          var chatMessage = document.getElementById("chat-message");
  
          if (chatMessage) {
            window.setTimeout(function () { 
              document.getElementById('chat-message').focus(); 
            }, 0); 
          }
        }
      };
    });
  }

  async function getNrUnreadMessages() {
    var url = BACKEND_URL + "/notifications/unread-notifications";
    
    await authService.getSecure(url)
      .then((result) => {
        if (result.data > 9) {
          setUnreadMessagesLabel("9+");
        } else if (result.data > 0) {
          setUnreadMessagesLabel("" + result.data);
        } else if (result.data == 0) {
          setUnreadMessagesLabel("0");
        }
      }).catch((_) => {
      
      });
  }

  getNrUnreadMessages();

  return (
    unreadMessagesLabel != "0" ?
      <div className="mobile-unread-msg-counter text-center">
        <span className="relative top-[1px]">{ unreadMessagesLabel }</span>
      </div>
      : <></>);
}