import axios, { AxiosInstance } from "axios";
import Bid from "../domain/bid/bid";

export class BidService {
  protected readonly instance: AxiosInstance;
  
  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
    });
  }

  /**
   * Get a list of bids that have been made on my items.
   */
  public async getAllIncomingBids(): Promise<Bid[]> {
    return this.instance
      .get("/bids/all/incoming")
      .then((res) => {
        return res.data;
      });
  }

  /**
   * Get a list of bids that this account has made on other items.
   */
  public async getAllOutgoingBids(): Promise<Bid[]> {
	return this.instance
		.get("/bids/all/outgoing")
		.then((res) => {
			return res.data;
		});
  }

  /**
   * Create a new bid on a user offer.
   */
  public createNewBidOnUserOffer() {

  }

  /**
   * Create a new bid on a business offer.
   */
  public createNewBidOnBusinessOffer() {

  }

  /**
   * Retract a bid from an offer.
   */
  public retractBid() {

  }

  /**
   * Accept an incoming bid.
   */
  public acceptBid() {

  }

  /**
   * Reject an incoming bid.
   */
  public rejectBid() {

  }
}