import BidState from "../../domain/bid/bid.state";
import MarketplaceBid from "../../domain/bid/marketplace.bid";
import { BACKEND_URL } from "../../domain/env";
import { productTypeToString } from "../../domain/item/product.type";
import { utils } from "../../services";

export default function MarketplaceBidItem({ bid } : { bid: MarketplaceBid }) {
  return (
    <a href={`/gropp/bids/${bid.id}`} className="hover:no-underline no-decoration">
      <div className="flex flex-row pb-10">
        <div className="flex flex-col z-20">
          <img 
            className="mr-4 ml-0 mt-0 rounded-lg item-image"
            src={`${BACKEND_URL}/static/img/preview/${bid.itemId}`}
            alt="Item image"
            width={200}
            height={200}
            />

            <div className="opacity-80 relative bottom-[50px] rounded-b-lg h-[50px] bid-item-overlay text-white bg-white"></div>
        </div>

        <img 
            className="mr-4 ml-0 mt-0 avatar-img-bidder rounded-full relative h-24 w-24 bg-gray-50 p-2 z-30"
            src={`${BACKEND_URL}/static/img/account/${bid.bidAccountId}`}
            alt="Bidder avatar"
            width={80}
            height={80}/>
        
        <div className="mb-2 ml-1 border-2 m-2 p-4 rounded-lg shadow-box max-w-[200px] max-h-[320px] relative left-[-220px] mr-[-150px] z-1 bid-hover">
          <div className="mb-1 font-bold offer-type">{""}</div>

          <div className="font-bold mr-1">Titel</div>
          <div className="mb-1">{bid.itemTitle}</div>

          <div className="font-bold mr-1">Type</div>
          <div className="mb-1">{bid.isGroup ? "Meerdere items" : productTypeToString(bid.productType)}</div>

          <div className="font-bold mr-1">Tijd</div>
          <div className="mb-1">{ utils.renderFriendlyDateTime(bid?.bidCreatedAt) }</div>

          <div className="font-bold mr-1">Aantal biedingen</div>
          <div className="mb-1">{bid.nrBids}</div>

          <div className="font-bold mr-1">Gewonnen</div>
          <div className="mb-1">{bid.bidState == BidState.ACCEPTED ? "ja" : "nee"}</div>

          <div className="text-right mt-[20px]">
            <span className="fake-link">Bekijken</span>
          </div>
        </div>
      </div>

      <div>
        <img 
          className="relative top-[-125px] left-[15px] z-20" 
          src="/gropp_hand.png" 
          width="17" 
          height="17" 
          alt="Grop logo"/>

        <div className="font-bold bid-amount relative text-right left-[-20px] top-[-147px] max-w-[200px] z-50">{utils.roundedAmount(bid.amount, 2)}</div>
      </div>
    </a>
  );
}