import { useEffect, useState } from "react";
import MarketplaceOffer from '../domain/offer/marketplace.offer';
import { OfferState } from '../domain/offer/offer.state';
import { utils } from "../services";
import { EventBus } from "../services/event.bus";

export default function CountDownTimer({ offer, labelClassName, timerClassName }: 
  {
    offer: any,
    labelClassName?: string,
    timerClassName?: string
  }) {
  const [timer, setTimer] = useState<any>(null);
  const [timeStr, setTimeStr] = useState<string>("");
  const [alreadyClosed, setAlreadyClosed] = useState<boolean>(false);

  function getLabelClassName() {
    return (labelClassName ? labelClassName : 
      "relative top-[205px] left-[-200px]");
  }

  function getTimerClassName() {
    return (timerClassName ? timerClassName : 
      "relative font-bold link-color text-xl top-[204px] left-[-181px]");
  }

  useEffect(() => {
    var timeData = utils.getAuctionTimeData(offer);

    if (timeData.alreadyClosed) {
      setAlreadyClosed(true);
      setTimer(null);
    } else if (!timer) {
      var i = setInterval(() => countDown(offer), 1000);
      setTimer(i);
    }
  }, null);

  function countDown(offer) {
    var timeData = utils.getAuctionTimeData(offer);

    if (timeData.seconds <= 1 || timeData.alreadyClosed) {
      if (timer) {
        timer.cancel();
        setTimer(null);
      } else {
        setAlreadyClosed(true);
      }
      
      EventBus.getInstance().dispatch<string>(
        EventBus.AUCTION_TIME_UP, offer.id);

      setTimeStr("");
    } else {
      setTimeStr(utils.getRemainingAuctionTimeStr(offer));
    }
  }

  if (alreadyClosed) {
    return (<></>);
  } else if (timer) {
    return (
      <>
        <div className="min-w-[130px]">
          <div className={getLabelClassName()}>Resterende tijd:</div>
          <div className={getTimerClassName()} suppressHydrationWarning> 
            { timeStr }
          </div>
        </div>
      </>);
  }
}