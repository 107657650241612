import * as Ons from "react-onsenui";
import * as Yup from 'yup';

import { useFormik } from 'formik';
import { Button, DialogActions, DialogContent, DialogTitle, Divider, Modal, ModalDialog } from '@mui/joy';
import { useState } from 'react';
import { authService, utils } from '../../../services';
import { ChangePasswordValidator } from '../../../domain/search/form.validators';
import { BACKEND_URL } from "../../../domain/env";

export default function ChangePassword() {
  const [changedPasswordSuccessful, setChangedPasswordSuccessful] = useState<boolean>(null);

  const formik = useFormik({
    initialValues: ChangePasswordValidator.initialValues,
    validationSchema: ChangePasswordValidator.validationSchema,
    onSubmit: (values, _) => {
      var cleaned = ChangePasswordValidator.cleanInput(values);
      submitNewPassword(cleaned);
    }
  });

  function submitNewPassword(body) {
    var url = BACKEND_URL + "/accounts/change-password";

    authService.postSecure(url, body)
      .then((result) => {
        setChangedPasswordSuccessful(true);
      }).catch((e) => {
        setChangedPasswordSuccessful(false);

        formik.touched.newPassword = true;
        formik.touched.oldPassword = true;
        formik.touched.confirmPassword = true;

        if (e.response.status == 400) {  
          formik.errors.newPassword = "Nieuwe wachtwoord is niet sterk genoeg";
        } else if (e.response.status == 401) {
          formik.errors.oldPassword = "Oude wachtwoord komt niet overeen";
        }
      })
  }

  function doChangePassword() {
    
  }

  function renderMobile() {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className="max-w-2xl container grid auto-rows-auto flex-row p-8 mt-4">
              <div className="grid grid-rows-3">
                <label className="grow redhat-display" htmlFor="title">Oud wachtwoord:</label>

                <div className="grow">
                  <input 
                    type="password"
                    className="min-w-md w-full larger-input redhat-display" 
                    id="oldPassword"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.oldPassword}/>
                </div>

                {
                  formik.touched.oldPassword && formik.errors.oldPassword ? (
                    <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.oldPassword}</div>) 
                    : <div></div>
                }
              </div>

              <div className="grid grid-rows-3">
                <label className="grow redhat-display" htmlFor="brand">Nieuw wachtwoord:</label>

                <div className="grow">
                  <input
                    id="newPassword"
                    type="password"
                    className="min-w-md w-full larger-input redhat-display"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}/>
                </div>

                {
                  formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.newPassword}</div>) 
                    : <div></div>
                }
              </div>

              <div className="grid grid-rows-3">
                <label className="grow redhat-display" htmlFor="brand">Nieuw wachtwoord herhalen:</label>

                <div className="grow">
                  <input
                    id="confirmPassword"
                    type="password"
                    className="min-w-md w-full larger-input redhat-display"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}/>
                </div>

                {
                  formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                    <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.confirmPassword}</div>) 
                    : <div></div>
                }
              </div>

              <div className="w-full mt-4 text-center">
                {
                  changedPasswordSuccessful ?
                    <div className="redhat-display">Wachtwoord gewijzigd.</div>
                  :
                    <button className="redhat-display button min-w-[100px] min-h-[32px] mb-4 link-color" type="submit">
                      <span className="text-sm redhat-display pt-0">Wachtwoord&nbsp;wijzigen</span>
                    </button>
                }
                
              </div>
              
              <div className="mb-24"></div>
            </div>
          </div>
      </form>
    </>);
  }

  function renderWeb() {
    return (
      <>
        <Modal open={true}>
          <ModalDialog variant="plain" role="alertdialog">
            <DialogTitle>Wachtwoord veranderen</DialogTitle>
            <Divider />
            <DialogContent>
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="flex grid-cols-2 text-right justify-center">
                    <div className="w-[200px] pr-4 pt-4 text-right text-sm mt-1">Oud wachtwoord:</div>
                    <div className="pt-4">
                      <input
                        id="oldPassword"
                        type="password"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.oldPassword}
                      />

                      {
                        formik.touched.oldPassword && formik.errors.oldPassword ? (
                          <div className="max-w-[200px] text-xs mt-1 text-right text-red-600">
                            {formik.errors.oldPassword}</div>)
                        : <div></div>
                      }
                    </div>
                  </div>

                  <div className="flex grid-cols-2 text-right justify-center">
                    <div className="w-[200px] pr-4 pt-4 text-right text-sm mt-1">Nieuw&nbsp;wachtwoord:</div>
                    <div className="pt-4">
                      <input
                        id="newPassword"
                        type="password"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                      />

                      {
                        formik.touched.newPassword && formik.errors.newPassword ? (
                          <div className="max-w-[200px] text-xs mt-1 text-right text-red-600">{formik.errors.newPassword}</div>) 
                          : <div></div>
                      }
                    </div>
                  </div>
                  <div className="flex grid-cols-2 text-right justify-center">
                    <div className="w-[200px] pr-4 pt-4 text-right text-sm mt-1">Nieuw&nbsp;wachtwoord&nbsp;herhalen:</div>
                    <div className="pt-4">
                      <input
                        className="w-[200px]"
                        id="confirmPassword"
                        type="password"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                      />

                      <div className="break-keep">
                        {
                          formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className="max-w-[200px] text-xs mt-1 text-right text-red-600">
                              {formik.errors.confirmPassword}</div>) 
                          : <div></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div className="max-w-[200px] text-sm mt-4 text-red-600 mx-auto">{}</div>
            </DialogContent>
            <DialogActions>
              <Button variant="solid" 
                className="background-accent-color" 
                onClick={() => {}}>Veranderen</Button>
              <Button 
                variant="plain" 
                color="neutral" 
                onClick={() => {}}>Annuleren</Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </>);
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}