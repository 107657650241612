import AccountMenu from './login/account.menu';
import { Link } from 'react-router-dom';
import { utils } from './services';
import { ENABLE_CREATE_ACCOUNT } from './domain/env';

export default function NavBar() {
  if (utils.isMobile()) {
    return (<></>);
  }

  return (
    <div>
      <a href="/">
        <img 
          className="ml-3 mt-[13px] navbar-logo" 
          src="/gropp_logo_design.png"
          width="100" 
          height="100" 
          alt="Grop logo"/>
      </a>
      
      <div className="absolute top-0 left-0 right-0 h-16 navbar -z-10"></div>

      <div className="flex flex-row absolute top-0 right-0 p-4 gap-6">
        {
          utils.isLoggedIn() ?
          <>
            <div>
              <img className="ml-3 mt-1 navbar-logo" src="/bottle.png" width="18" alt="Grop logo"/>
            </div>
            <div className="mt-1">
              <Link className="white-link text-sm ml-[-15px] redhat-display" to="/gropp/account/items">Mijn items</Link>
            </div>
          </>
            : <></>
        }
        
        <div>
          <img className="ml-3 mt-2 navbar-logo" src="/magnifying_glass.png" width="22" alt="Search icon"/>
        </div>
        <div className="mt-1">
          <Link className="white-link text-sm  ml-[-15px] redhat-display" to="/gropp/search">Zoeken</Link>
        </div>

        <div className="mt-2">
          {
            utils.isLoggedIn() ?
              <AccountMenu/> 
              : 
              <>
                <div className="flex flex-row gap-2">
                  <div className="mt-[-10px]">
                    <Link to="/login" className="no-link-decoration">
                      <button className="normal-button text-sm min-w-[100px]">
                      Inloggen
                      </button>
                    </Link>
                  </div>

                  {
                    ENABLE_CREATE_ACCOUNT ? 
                      <div className="mt-[-10px]">
                        <Link to="/gropp/account/new" className="no-link-decoration">
                          <button className="normal-button text-sm min-w-[100px]">
                            Aanmelden
                          </button>
                        </Link>
                      </div>
                      : <></>
                  }
                </div>
              </>
          }
        </div>
      </div>
    </div>
  ); 
}
