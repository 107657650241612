import * as Ons from "react-onsenui";

import { useEffect, useState } from "react";
import { BidResponseBuilder } from "../../../domain/bid/bid.response.builder";
import { BID_WEBSOCKET_URL, BACKEND_URL } from "../../../domain/env";
import { utils } from "../../../services";
import Loading from "../../../components/loading";
import { Utils } from "../../../services/utils";
import BidPanel from "../../../components/bid.panel";

export default function BidsItem({ offerId, showBidButton = false }) {
  const [websocket, setWebsocket] = useState<any>(null);
  const [showBidInput, setShowBidInput] = useState<boolean>(false);
  const [bidResponse, setBidResponse] = useState<any>(null);
  const [connectedWebsocket, setConnectedWebsocket] = useState<boolean>(false);
  const [showRetractWarning, setShowRetractWarning] = useState<boolean>(false);

  const [highestBidIndex, setHighestBidIndex] = useState<number>(null);
  const [yourHighestBidIndex, setYourHighestBidIndex] = useState<number>(null);

  const [accountId, setAccountId] = useState<string>(null);

  function connectWebsocket() {
    utils.getJwt().then((jwt) => {
      utils.getLoggedInAccountId().then((result) => {
        setAccountId(result);

        if (!jwt) {
          return;
        }
  
        setConnectedWebsocket(true);
  
        var url = BID_WEBSOCKET_URL + 
          `?offerId=${offerId}` + 
          `&accountId=${result}` +
          `&jwt=${jwt}`;
  
        const ws = new WebSocket(url);
        setWebsocket(ws);
    
        ws.onmessage = function (event) {
          const json = JSON.parse(event.data);
          
          var bidResponse = BidResponseBuilder.buildBidResponse(json);
    
          setHighestIndex(bidResponse);
    
          if (bidResponse != null) {
            setBidResponse(bidResponse);
    
            setTimeout(() => {
              var bidElem = document.getElementById("bid-container");
    
              if (bidElem) {
                bidElem.scrollTop = bidElem.scrollHeight;
              }
            }, 100);
          }
        };
      });
    });
  }

  function setHighestIndex(bidResponse) {
    var highestBidIndex = -1;
    var yourHighestBidIndex = -1;

    var highestBidAmount = -1;
    var yourHighestBidAmount = -1;

    for (var i = 0; i < bidResponse.bids.length; i++) {
      var amount = bidResponse.bids[i].amount;
      var bidAccountId = bidResponse.bids[i].accountId;

      if (amount > highestBidAmount) {
        highestBidAmount = amount;
        highestBidIndex = i;
        
        if (bidAccountId == accountId) {
          yourHighestBidAmount = amount;
          yourHighestBidIndex = i;
        }
      }
    }

    // setHighestBidIndex(highestBidIndex);
    // setYourHighestBidIndex(yourHighestBidIndex);

    setHighestBidIndex(0);
    setYourHighestBidIndex(0);
  }

  useEffect(() => {
    if (!connectedWebsocket) {
      utils.getLoggedInAccountId().then((result) => {
        setAccountId(result);
        connectWebsocket();
      });
    }
  }, null);

  function bidOnItem() {
    var amount = (document.getElementById("bid-amount") as any).value;
    var parsed = parseFloat(amount);

    if (!isNaN(parsed) && parsed > 0) {
      placeBid(amount);
    }

    (document.getElementById("bid-amount") as any).value = ""; 

    toggleBidInput();
  }

  function placeBid(amount: string) {
    try {
      utils.getJwt().then((jwt) => {
        if (amount == null || amount == "") {
          return;
        }
  
        amount = amount.replace(",", ".");
      
        var parsed = parseFloat(amount);
  
        if (parsed <= 0) {
          return;
        }
  
        var toSend = {
          amount: parsed,
          accountId: accountId,
          offerId: offerId,
          jwt: jwt
        };
    
        websocket.send(JSON.stringify(toSend));
      });
    } catch (e) {
      
    }
  }

  function plus10() {
    var maxBid = Math.max(...bidResponse.bids.map(bid => bid.amount));
  }

  function plus100() {
    var maxBid = Math.max(...bidResponse.bids.map(bid => bid.amount));
  }

  function retractBid() {
    setShowRetractWarning(true);
  }

  if (!accountId || !connectedWebsocket || !bidResponse) {
    return <Loading/>;
  }

  function toggleBidInput() {
    var elem = document.getElementById("mobile-bid-list");
    
    if (elem.classList.contains("mobile-bid-list-expanded")) {
      elem.classList.remove("mobile-bid-list-expanded");
    } else {
      elem.classList.add("mobile-bid-list-expanded");
    }

    setShowBidInput(!showBidInput);

    if (!showBidInput) {
      setTimeout(() => {
        (document.getElementById("bid-amount") as any).focus();
      }, 100);
    }
  }

  function renderMobile() {
    const renderHeader = (nrBids) => {
      return (
        <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">{ nrBids } biedingen</div>
        </Ons.ListHeader>
      );
    }

    const renderRow = (row, index) => {
      return (
        <>
          <Ons.ListItem key={index} modifier="nodivider">
            <div className={`flex flex-row grow body-style ${index == 0 || index == yourHighestBidIndex ? "h-[90px] mb-[-20px]" : "" } ${ index == 0 ? "highest-bid" : "" } ${ index != 0 && index == yourHighestBidIndex ? "your-highest-bid" : ""}`}>
              <div className="mt-auto mb-auto redhat-display text-md">
                { row.position + 1 }.&nbsp;&nbsp;&nbsp;
              </div>

              <img 
                className="mr-4 ml-0 avatar-img-account-bid rounded-full w-[50px] h-[50px] min-w-[50px] min-h-[50px] z-30 mt-auto mb-auto"
                src={`${BACKEND_URL}/static/img/account/${row.accountId}`}
                alt="Account avatar"
                width={100}
                height={100}
                onError={() => this.src=BACKEND_URL + "/static/img/not-found"}/>

              <div className="flex flex-col grow mt-auto mb-auto">
                <div>{ row.firstName }</div>
                <div className="text-xs mobile-bid-date-color">{ utils.renderDate(row.time) }</div>
                <div className="text-xs mobile-bid-date-color">{ utils.renderPreciseTime(row.time) }</div>
              </div>

              <div className={`mr-4 mt-auto mb-auto ${row.position == highestBidIndex || row.position == yourHighestBidIndex ? "mr-[3px]" : "" }`}>
                <span className="link-color font-bold text-xl">
                  { "\u20AC" + utils.roundedAmount(row.amount) }
                </span>
              </div>
            </div>
          </Ons.ListItem>
          
          {
            index == 0 && index != yourHighestBidIndex ? 
              <>
                <div className="flex flex-row-reverse mr-[8px] mt-[-30px] mb-[30px]">
                  <div className="background-link-color relative pl-1 pr-1 h-[18px] w-[80px] text-xs redhat-display highest-bid-label">Hoogste&nbsp;bod</div>
                </div>
              </>
              : <></>
          }

          {
            index == yourHighestBidIndex && index != 0 ? 
              <>
                <div className="flex flex-row-reverse mr-[100px] mt-[-40px] mb-[30px]">
                  <div className="bg-white pl-1 pr-1 h-[16px] w-[110px] relative mt-[10px] top-[21px] text-xs right-[-93px] your-highest-bid-label highest-bid-label-corner">Jouw&nbsp;hoogste&nbsp;bod</div>
                </div>
              </>
              : <></>
          }

          {
            index == 0 && index == yourHighestBidIndex ? 
              <>
                <div className="flex flex-row-reverse mr-[100px] mt-[-40px] mb-[30px]">
                  <div className="bg-white pl-1 pr-1 h-[16px] w-[110px] relative mt-[9px] top-[21px] text-xs right-[-12px] your-highest-bid-label">Jouw&nbsp;hoogste&nbsp;bod</div>
                </div>

                <div className="flex flex-row-reverse mr-[8px] mt-[-30px] mb-[15px]">
                  <div className="background-link-color relative pl-1 pr-1 h-[18px] w-[80px] top-[1px] text-xs redhat-display highest-bid-label highest-bid-label-combined">Hoogste&nbsp;bod</div>
                </div>
              </>
              : <></>
          }

          <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
        </>
      );
    }

    return (
      <>
        <Ons.List
          id="mobile-bid-list"
          className={`mobile-bid-list ${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
          dataSource={bidResponse.bids}
          renderRow={(row, index) => renderRow(row, index)}
          renderHeader={() => renderHeader(bidResponse.bids.length)}/>

        {
          showBidInput ?
            <BidPanel 
              plus10={plus10} 
              plus100={plus100}
              retractBid={retractBid}
              toggleBidInput={toggleBidInput}
              bidOnItem={bidOnItem}/>
            :
            <>
              {
                showBidButton ? 
                  <div className="w-full text-center mt-2 mobile-top-border pt-2 absolute bottom-[20px]">
                    <Ons.Button 
                        className="link-color w-[200px]" 
                        onClick={() => toggleBidInput()}>
                      <span className="redhat-display text-sm capitalize">Bieden</span>
                    </Ons.Button>
                  </div>
                  : <></>
              }
            </>
        }
        
        <div className="mb-[80px]"></div>

        <Ons.AlertDialog isOpen={showRetractWarning} cancelable>
          <div className="text-center w-full redhat-display mt-2">
            <span className="alert-dialog-text font-bold">Terugtrekken</span>
          </div>
          
          <div className="alert-dialog-text text-center w-full mt-4 p-4 redhat-display">
            Weet je zeker dat je al je biedingen wil terugtrekken?
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button onClick={() => {
                setShowRetractWarning(false);
                Utils.mobileNavigator.popPage({ animation: "none" });
            }} className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Ja</span>
            </Ons.Button>

            <Ons.Button onClick={() => {setShowRetractWarning(false)}} className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Nee</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>
      </>);
  }

  function renderWeb() {
    return (
      <>
        {
          bidResponse ?
            <div className="flex flex-row">
              <div className="">
              <button 
                className={`normal-button max-w-[150px] ${ bidResponse.bids.length > 0 ? "relative ml-[220px]": "" }`}
                type="submit" 
                onClick={() => setShowBidInput(true)}>Bieden</button>

                {bidResponse.bids.sort((a, b) => {
                    return a.position - b.position
                  }).map((bid) => (
                    <div key={bid.position} className="h-[100px]">
                      <div className={`flex flex-row ${ bidResponse.bids.length > 0 ? "mt-[-45px]" : "" }`}>
                        <div className="">
                          <img 
                            className="mr-4 ml-0 mt-0 avatar-img-account-bid rounded-full w-[50px] h-[50px] p-2 z-30"
                            src={`${BACKEND_URL}/static/img/account/${bid.accountId}`}
                            alt="Account avatar"
                            width={100}
                            height={100}
                            onError={() => this.src=BACKEND_URL + "/static/img/not-found"}
                          />
                        </div>

                        <div className="accent-color font-bold text-2xl">
                          {bid.position + 1}.
                        </div>
                        
                        <div className="grow">
                          <div className="font-bold link-color text-2xl">
                            &nbsp;&nbsp;{utils.roundedAmount(bid.amount, 2)}
                          </div>
                          <div className="text-xs ml-[-15px]">
                            {utils.renderPreciseFriendlyDateTime(bid.time)}
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
            </div>
          : <></>
        }
      </>
    );
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}