import AbstractEntity from "../abstract.entity";
import FavoriteType, { favoriteTypeFromString } from "./favorite.type";

class Favorite extends AbstractEntity {
  favoriteEntityId: string;
  favoriteType: FavoriteType;
  accountId: string;

	constructor($id: string,
              $createTime: Date,
              $favoriteEntityId: string,
              $favoriteType: string,
              $accountId: string) {
    super($id, $createTime);

    this.favoriteEntityId = $favoriteEntityId;
    this.favoriteType = favoriteTypeFromString($favoriteType);
    this.accountId = $accountId;
  }
}

export default Favorite;