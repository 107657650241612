import moment from "moment";
import Notification from "./notification";
import { DATE_TIME_FORMAT } from "../constants";
import { notificationTypeFromString } from "./notification.type";
import { utils } from "../../services";
import { NotificationLinkHelper } from "./notification.link.helper";

export class NotificationBuilder {
  static buildNotification(data: any): Notification {
    var body: string = data.body;

    // if (data.links) {
    //   var pairs = data.links.split(";");

    //   for (let pair of pairs) {
    //     var split = pair.split("=");
        
    //     if (split.length == 2) {
    //       var key: string = split[0];
    //       var value: string = split[1];

    //       var toFind = new RegExp("<" + key + ":(?<buttonText>.*?)>");

    //       var matches = Array.from(body.matchAll(toFind));
          
    //       for (let match of matches) {
    //         if (key.endsWith("_LINK")) {
    //           var buttonText = match.groups["buttonText"];

    //           if (utils.isMobile()) {
    //             value =
    //               "<Ons.Button onClick={() => { goToNotificationLink('" + key.replace("_LINK", "") + "','" + value + "') }} className='button min-w-[110px]'>" +
    //               "   <span className='redhat-display text-sm'>" + buttonText + "</span>" +
    //               "</Ons.Button>";
    //           } else {
    //             value = "<Link to='" + value + "'>" + buttonText + "</Link>";
    //           }
        
    //           body = body.replaceAll(toFind, value);
    //         }
    //       }
    //     }
    //   }
    // }

    return new Notification(
      data.id, 
      moment(data.createTime, DATE_TIME_FORMAT).toDate(),
      notificationTypeFromString(data.notificationType),
      data.accountId,
      data.toAccountId,
      data.chatId,
      data.title,
      body,
      data.read,
      data.properties,
      data.links);
  }
}