import * as Ons from "react-onsenui";
import moment from 'moment';
import Spinner from '../../components/spinner';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChatBuilder } from '../../domain/chat';
import { CHAT_WEBSOCKET_URL } from '../../domain/env';
import { utils } from '../../services';
import { EventBus } from '../../services/event.bus';
import { Utils } from "../../services/utils";

export default function ChatPanelMessages({ fromAccountId, toAccountId }) {
  const [websocket, setWebsocket] = useState<any>(null);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [chat, setChat] = useState<any>(null);
  const [registeredEventBus, setRegisteredEventBus] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [accountId, setAccountId] = useState<string>(null);

  useEffect(() => {
    if (!registeredEventBus) {
      EventBus.getInstance().register(
        EventBus.ON_CHAT_OPEN, () => startConnectingWebSocket());

      utils.getLoggedInAccountId().then((result) => {
        setAccountId(result);
      });
      
      setRegisteredEventBus(true);
    }
  }, null);

  function startConnectingWebSocket() {
    if (!fromAccountId || !toAccountId) {
      setTimeout(() => {
        connectWebsocket(fromAccountId, toAccountId);
      }, 100);
    }

    if (!connecting) {
      setConnecting(true);
      connectWebsocket(fromAccountId, toAccountId);  
    }
  }

  function connectWebsocket(fromAccountId, toAccountId) {
    utils.getJwt().then((jwt) => {
      try {
        var url = CHAT_WEBSOCKET_URL + 
          `?fromAccountId=${fromAccountId}` + 
          `&toAccountId=${toAccountId}` + 
          `&jwt=${jwt}`;
  
        const ws = new WebSocket(url);
        setWebsocket(ws);
  
        ws.onmessage = function (event) {
          const json = JSON.parse(event.data);
          var chat = ChatBuilder.buildChat(json);
  
          if (chat != null) {
            setChat(chat);
            setConnecting(false);
  
            setTimeout(() => {
              var chatElem = document.getElementById("chat-contents");
  
              if (chatElem) {
                chatElem.scrollIntoView(false);
                chatElem.scrollTop = chatElem.scrollHeight;
              }
            }, 100);
  
            var chatMessage = document.getElementById("chat-message");
  
            if (chatMessage) {
              window.setTimeout(function () { 
                document.getElementById('chat-message').focus(); 
              }, 0); 
            }
  
            setShowSpinner(false);
          }
        };
      } catch (e) {
        
      }
    });
  }

  function sendMessage() {
    var elem = document.getElementById("chat-message") as any;
    var message: string = elem.value;
    elem.value = "";

    doSendMessage(message);

    elem.focus();
  }

  function doSendMessage(message: string) {
    utils.getJwt().then((jwt) => {
      var toSend = {
        message: message,
        fromAccountId: fromAccountId,
        toAccountId: toAccountId,
        jwt: jwt
      };
  
      if (message == "") {
        return;
      }
  
      websocket.send(JSON.stringify(toSend));
    });
  }

  function printMessageDate(message): string {
    if (message && message.createTime) {
      return moment(message.createTime).format("DD-MM-yyyy");
    } else {
      return "";
    }
  }

  function printMessageTime(message): string {
    if (message && message.createTime) {
      return moment(message.createTime).format("HH:mm:ss");
    } else {
      return "";
    }
  }

  const processTextAreaKey = (event: React.KeyboardEvent) => {
    var elem = document.getElementById("chat-message") as any;
    var message: string = elem.value;

    if (event.key == "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        
        if (message != "") {
          elem.value = "";
          doSendMessage(message);
        }
      }
    }
  }

  return (
    <>
      <div className="">
        <div id="chat-contents" className="chat-contents">
          {
            chat ? chat.messages.map((message, i) => {
              if (chat.fromAccountId == accountId) {
                return (
                  <div key={i} className="chat-message-right max-w-[200px]">
                    <div>
                      <div className="text-xs italic mb-2 redhat-display">Op {printMessageDate(message)} om {printMessageTime(message)} schreef jij:</div>
                      <div className="break-word redhat-display">{message.message}</div>
                      <div className="text-balloon-triangle-right"></div>
                    </div>
                  </div>);
              } else {
                return (
                  <div key={i} className="chat-message-left">
                    <div>
                      <div className="text-xs italic mb-2 redhat-display">Op {printMessageDate(message)} om {printMessageTime(message)} schreef {chat.toFirstName}:</div>
                      <div className="break-all redhat-display">{message.message}</div>
                      <div className="text-balloon-triangle-left mb-[-20px]"></div>
                    </div>
                  </div>);
              }
            }) 
            : <div key={"0"} className="chat-contents"></div>
          }
        </div>
      </div>

      <div className="justify-center mx-auto scale-50 mt-[-100px]">
        { showSpinner ? <Spinner/> : <></> }
      </div>
      
      <div className="chat-controls">
        <div className="chat-message">
          <textarea 
            id="chat-message"
            rows={4} 
            className={`h-full w-full chat-message-text rounded-none text-sm relative bottom-0 ${ utils.isMobile() ? "border-l-0 border-r-0 resize-none redhat-display" : ""}`} 
            placeholder="Typ een bericht..."
            onKeyDown={(event) => processTextAreaKey(event)}/>
        </div>
        
        { 
          utils.isMobile() ? 
            <>
              <div className="text-right mr-2 chat-button-mobile">
                <Ons.Button 
                    className="link-color p-2 min-w-[150px]" 
                    onClick={() => {sendMessage()}}>
                  <span className="redhat-display text-sm capitalize">Versturen</span>
                </Ons.Button>
              </div>
            </>  
          : <>
              <div className="text-right">
                <Link to="#"
                  className="text-right"
                  onClick={() => sendMessage()}>Versturen</Link>
              </div>
            </>
        }
      </div>
    </>
  );
}