import AbstractEntity from "../abstract.entity";
import BidType from "../bid/bid.type";
import TradeType from "./trade.type";
import bigDecimal from 'js-big-decimal';

abstract class Trade extends AbstractEntity {
  tradeType: TradeType;
  amount: bigDecimal;
  bidId: string;
  bidAccountId: string;
  bidType: BidType;
  buyerFirstName: string;
  offerId: string;
  itemId: string;
  offerType: string;
  offerAccountId: string;
  title: string;
  incoming: boolean;

	constructor($id: string,
              $createTime: Date,
              $tradeType: TradeType,
              $amount: string,
              $bidId: string,
              $bidAccountId: string,
              $bidType: BidType,
              $buyerFirstName: string,
              $offerId: string,
              $itemId: string,
              $offerType: string,
              $offerAccountId: string,
              $title: string,
              $incoming: boolean) {
		super($id, $createTime);
		
    this.tradeType = $tradeType;
    this.amount = new bigDecimal($amount);
		this.bidId = $bidId;
    this.bidAccountId = $bidAccountId;
    this.bidType = $bidType;
    this.buyerFirstName = $buyerFirstName;
		this.offerId = $offerId;
    this.itemId = $itemId;
    this.offerType = $offerType;
    this.offerAccountId = $offerAccountId;
    this.title = $title;
    this.incoming = $incoming;
	}
}

export default Trade;