class GroupItemElement {
  itemId: string;
  itemCount: number;

  constructor($itemId: string,
              $itemCount: number) {
    this.itemId = $itemId;
    this.itemCount = $itemCount;
  }
}

export default GroupItemElement;