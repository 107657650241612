import Bid from "./bid";
import BidState from "./bid.state";
import BidType from "./bid.type";
import bigDecimal from 'js-big-decimal';

class PurchaseBid extends Bid {
  amount: bigDecimal;

	constructor($id: string,
              $bidType: BidType, 
              $createTime: Date,
              $offerId: string,
              $itemId: string,
              $bidAccountId: string,
              $ownerAccountId: string,
              $bidState: BidState,
              $amount: string) {
		super($id, $createTime, $offerId, 
      $itemId, $bidAccountId, $ownerAccountId, 
      $bidState, $bidType, true, 1);

    this.amount = new bigDecimal($amount);
	}
}

export default PurchaseBid;