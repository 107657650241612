import Bid from "../bid/bid";
import SearchResults from "./search.results";

class BidSearchResults extends SearchResults {
  public data: Bid[];

  constructor($pageNumber: number,
              $pageSize: number,
              $nrItemsThisPage: number,
              $totalNrPages: number,
              $itemCount: number,
              $search: any,
              $data: Bid[]) {
      super($pageNumber, $pageSize, $nrItemsThisPage, 
            $totalNrPages, $itemCount, $search);
      
      this.data = $data;
  }
}

export default BidSearchResults;