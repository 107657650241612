import AbstractEntity from "../abstract.entity";
import PriceType from "./offer.type";
import { OfferState } from "./offer.state";

abstract class Offer extends AbstractEntity {
	accountId: string;
	itemId: string;
	bids: string[];
	offerState: OfferState;
  offerType: PriceType;
  closedByOwner: boolean;
	wonBidId: string;

	constructor($id: string,
              $createTime: Date,
              $accountId: string,
              $itemId: string,
              $bids: string[],
              $offerState: OfferState,
              $closedByOwner: boolean,
              $wonBidId: string,
              $offerType: PriceType) {
		super($id, $createTime);

		this.accountId = $accountId;
		this.itemId = $itemId;
		this.bids = $bids;
		this.offerState = $offerState;
    this.closedByOwner = $closedByOwner;
		this.wonBidId = $wonBidId;
    this.offerType = $offerType;
	}

  isUserOffer(): boolean {
    return false;
  }

  isBusinessOffer(): boolean {
    return false;
  }

  isAuction(): boolean {
    return false;
  }
}

export default Offer;