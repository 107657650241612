import moment from "moment";
import Review from "./review";
import { DATE_TIME_FORMAT } from "../constants";

class ReviewBuilder {
  static buildReview(data: any): Review {
    var review = new Review(
      data.id, 
      moment(data.createTime, DATE_TIME_FORMAT).toDate(),
      data.reviewedAccountId,
      data.accountId,
      data.reviewerFirstName,
      data.review);

    return review;
  }
}

export default ReviewBuilder;