import moment from "moment";
import { productTypeFromString } from "../item/product.type";
import { offerTypeFromString } from "./offer.type";
import SearchOffer from "./search.offer";
import { offerStateFromString } from "./offer.state";
import { DATE_TIME_FORMAT } from "../constants";

export default class SearchOfferBuilder {
  static buildSearchOffer(data: any): SearchOffer {
    var result = new SearchOffer(
      data.offerId,
      moment(data.createTime, DATE_TIME_FORMAT).toDate(),
      data.ownerId,
      data.itemId,
      offerTypeFromString(data.offerType),
      data.ownerType,
      productTypeFromString(data.productType),
      data.latitude,
      data.longitude,
      data.openTime ? moment(data.openTime, DATE_TIME_FORMAT).toDate() : null,
      data.auctionDuration,
      offerStateFromString(data.offerState),
      data.startPrice,
      data.titleDescription,
      data.title,
      data.description,
      data.allowBidding,
      data.nrBids,
      data.itemYear,
      data.price,
      data.brand,
      data.provider);

    return result;
  }
}