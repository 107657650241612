import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants";
import Account from "./account";
import { accountRoleFromString } from "./account.role";

export default class AccountBuilder {
  static buildAccount(data: any): Account {
    var account = null;

    account = new Account(
      data.id,
      accountRoleFromString(data.accountRole),
      moment(data.createTime, DATE_TIME_FORMAT).toDate(),
      data.firstName,
      data.lastName,
      data.birthDate,
      data.street,
      data.postalCode,
      data.city,
      data.phoneNumber,
      data.emailAddress,
      data.emailVerified,
      data.idVerified,
      data.latitude,
      data.longitude,
      data.avatarIdx,
      data.nrBids,
      data.nrTrades,
      data.nrOffers,
      data.nrItems,
      data.showLastName,
      data.showAddress,
      data.showPhoneNumber,
      data.showCoordinates,
      data.showBirthDate,
      data.showKvkNumber,
      data.showStatistics,
      data.businessName,
      data.kvkNumber,
      data.businessVerified);
    
    return account;
  }
}
