import { Box, Drawer } from '@mui/joy';
import ChatPanelMessages from './chat.panel.messages';

export default function ChatDrawer({ fromAccountId, toAccountId, isOpen, onCloseChat }) {
  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer open={isOpen} onClose={() => onCloseChat()} anchor="right">
        <ChatPanelMessages
          fromAccountId={fromAccountId}
          toAccountId={toAccountId}/>
      </Drawer>
    </Box>
  );
}