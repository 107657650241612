import * as Ons from "react-onsenui";
import { utils } from "../../../services";
import moment from "moment";
import { DATE_TIME_FORMAT, FRIENDLY_DATE_TIME_FORMAT } from "../../../domain/constants";
import { BACKEND_URL } from "../../../domain/env";
import { Utils } from "../../../services/utils";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import MobileIncomingBid from "./mobile.incoming.bid";
import MobileOutgoingBid from "./mobile.outgoing.bid";

export default function MobileMyBids({ incomingBids, outgoingBids }) {
  function getNrOtherBids(row) {
    if (row.nrBids == 0) {
      return "Geen andere biedingen";
    } else if (typeof row.nrBids == "undefined") {
      return "Verkoop";
    } else if (row.nrBids == 1) {
      return "1 andere bieding";
    } else {
      return row.nrBids + " andere biedingen";
    }
  }

  function goToBid(id, incoming) {
    if (incoming) {
      goToIncomingBid(id);
    } else {
      goToOutgoingBid(id);
    }
  }

  function goToIncomingBid(id) {
    Utils.mobileNavigator.pushPage({
      page: <MobileIncomingBid bidId={id}/>,
      name: "incoming-bid/" + utils.makeId(5),
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Inkomende biedingen" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToOutgoingBid(id) {
    Utils.mobileNavigator.pushPage({
      page: <MobileOutgoingBid bidId={id}/>,
      name: "outgoing-bid/" + utils.makeId(5),
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Uitgaande biedingen" backButton={true}/>
      }
    }, { animation: "none" });
  }

  const renderHeader = (nrItems, text) => {
    return (
      <div>
        <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">{text}</div>
        </Ons.ListHeader>

        { 
          nrItems == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen { text }.</div>
            </div>
            :
            <div>
              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  }

  const renderBidRow = (row, index, incoming) => {
    return (
      <div>
        <Ons.ListItem key={row.id} modifier="nodivider">
          <div className="flex flex-row grow body-style" 
              onClick={() => goToBid(row.id, incoming)}>
            <img 
              className={`mr-4 ml-0 mt-0 rounded-lg mobile-item-list-image`}
              src={`${BACKEND_URL}/static/img/preview/${row.itemId}`}
              alt="Item image"
              width={80}
              height={80}/>

            { 
              incoming ?
                <>
                  <Ons.Icon icon="md-arrow-left" className="relative top-[66px] left-[-95px] link-color font-bold ml-[3px] z-[100]"/>
                  <img 
                    className="relative top-[68px] left-[-93px] z-20 h-[15px] w-[15px]" 
                    src="/gropp_hand.png" 
                    width="17" 
                    height="5" 
                    alt="Grop logo"/>
                </>
                :
                <>
                  <Ons.Icon icon="md-arrow-right" className="relative top-[66px] left-[-73px] link-color font-bold z-[100]"/>
                  <img 
                    className="relative top-[68px] left-[-103px] z-20 h-[15px] w-[15px]" 
                    src="/gropp_hand.png" 
                    width="17" 
                    height="5" 
                    alt="Grop logo"/>
                </>
            }

            <div className={`opacity-80 relative ${ incoming ? "left-[-125px]" : "left-[-122px]" } top-[65px] h-[23px] w-[81px] z-10 rounded-bl-lg rounded-br-lg bg-white`}></div>
            
            <div className="flex flex-col ml-[-90px] mt-[0px]">
              <div className="text-xs font-bold">{ row.title }</div>
            
              <div className="text-xs">
                { moment(row.createTime, DATE_TIME_FORMAT).format(FRIENDLY_DATE_TIME_FORMAT) }
              </div>

              <div className="text-xs">
                {
                  row.isHighestBid ? 
                    "Hoogste bod"
                    : "Niet het hoogste bod"
                }
              </div>

              <div className="text-xs">{ getNrOtherBids(row) }</div>

              <div className="font-bold bid-amount relative text-left mt-1">{ utils.roundedAmount(row.amount, 2) }</div>
            </div>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </div>
    );
  }

  if (!incomingBids || !outgoingBids) {
    return <></>;
  }

  return (
    <>
      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={incomingBids}
        renderRow={(row, index) => renderBidRow(row, index, true)}
        renderHeader={() => renderHeader(incomingBids.length, "inkomende biedingen")}/>

      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={outgoingBids}
        renderRow={(row, index) => renderBidRow(row, index, false)}
        renderHeader={() => renderHeader(outgoingBids.length, "uitgaande biedingen")}/>

      <div className="mb-[80px]"></div>
    </>);
}