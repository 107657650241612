import { useParams } from "react-router";
import OfferPage from "./page";
import BidPage from "./page";
import TradePage from "./page";

export default function TradePageRouteHelper() {
  const { tradeId } = useParams();

  return (
    <>
      <TradePage tradeId={tradeId}/>
    </>
  );
}