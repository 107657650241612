import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { accountRoleToString } from "../../../domain/account/account.role";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";

export default function FavoriteAccountInfoItem({ favorite }) {
  const [account, setAccount] = useState<any>(null);
  const [averageRating, setAverageRating] = useState<number>(0);
  const navigate = useNavigate();
  
  function removeFavoriteAccount() {

  }

  const loadData = () => {
    if (!favorite) {
      return;
    }

    var accountUrl = BACKEND_URL + "/accounts/info/" + favorite.favoriteEntityId;

    authService.getSecure(accountUrl)
      .then((result) => {
        setAccount(result.data);
      });

    var url = BACKEND_URL + "/ratings/" + favorite.favoriteEntityId + "/average";

    authService.getSecure(url)
      .then((result) => {
        setAverageRating(result.data);

        var avgRatingElem = (document.getElementById("average_rating") as any);
        var maxWidth = 79.0;
        var newWidth = (result.data / 5.0) * maxWidth;
        var width = Math.floor(newWidth);
        avgRatingElem.style.width = width + "px";
      }).catch((_) => {
        setAverageRating(0.0);
      })
  }

  function goToAccount() {
    if (!favorite) {
      return;
    }

    navigate("/gropp/accounts/" + favorite.favoriteEntityId);
  }

  useEffect(() => {
    if (!averageRating) {
      loadData();
    }
  }, null);

  if (!account) {
    return <></>;
  }

  return (
    <div className="grid grid-cols-2">
      <div className="mx-auto justify-center w-full text-center mb-12">
        <img 
          className="avatar-img-account-page rounded-full h-24 w-24 bg-gray-50 p-2 mt-[15px] ml-[-50px] justify-center mx-auto"
          src={`${BACKEND_URL}/static/img/account/${account.id}`}
          alt="Account avatar"
          width={150}
          height={150}/>
      </div>

      <div className="grid grid-cols-2 ml-[-100px]">
        <div className="text-right font-bold mr-4 p-1">Type account:</div>
        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{accountRoleToString(account.accountRole)}</div>

        <div className="text-right font-bold mr-4 p-1">Voornaam:</div>
        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{account.firstName}</div>

        <div className="text-right font-bold mr-4 p-1">Plaats:</div>
        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{account.city}</div>

        <div className="font-bold text-right mr-4 p-1">Beoordeling:</div>
        <div className="p-1 text-left">
          <div className="flex flex-row">
            <img 
              className="relative top-[2px]" 
              src={utils.getEmptyStar()}
              width="17" 
              height="17" 
              alt="Grop logo"/>

            <img 
              className="relative top-[2px] left-[-1px]" 
              src={utils.getEmptyStar()}
              width="17" 
              height="17" 
              alt="Grop logo"/>

            <img 
              className="relative top-[2px] left-[-2px]" 
              src={utils.getEmptyStar()}
              width="17" 
              height="17" 
              alt="Grop logo"/>

            <img 
              className="relative top-[2px] left-[-3px]" 
              src={utils.getEmptyStar()}
              width="17" 
              height="17" 
              alt="Grop logo"/>

            <img 
              className="relative top-[2px] left-[-4px]" 
              src={utils.getEmptyStar()}
              width="17" 
              height="17" 
              alt="Grop logo"/>

            <div className="text-sm m-0 p-0 ml-2 h-[10px] mt-[1px]">({averageRating})</div>
          </div>
        </div>

        <div className="relative top-[-60px] left-[350px]">
          <button className="normal-button" type="submit" onClick={goToAccount}>Bekijken</button>
        </div>

        <div className="text-right w-full relative left-[195px] top-[-125px]">
					<Link to="#" onClick={removeFavoriteAccount} className="text-sm">Verwijderen</Link>
				</div>
      </div>
    </div>
  );
}