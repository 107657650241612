import { carrierFromString } from "./carrier";
import OpeningTimes from "./opening.times";
import OpeningTimesBuilder from "./opening.times.builder";
import { ServicePoint } from "./service.point";

export class ServicePointBuilder {
  static buildServicePoint(data: any): ServicePoint {
    var carriers = (data.carriers as string[])
      .map(c => carrierFromString(c));
    
    var openingTimes = OpeningTimesBuilder.buildOpeningTimes(data);

    var servicePoint = new ServicePoint(
      data.id,
      carriers,
      data.name,
      data.street,
      data.houseNumber,
      data.postalCode,
      data.city,
      data.latitude,
      data.longitude,
      data.email,
      data.phone,
      data.homepage,
      openingTimes,
      data.openTomorrow,
      data.openUpcomingWeek);

    return servicePoint;
  }
}