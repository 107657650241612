import { useParams } from "react-router";
import OfferPage from "./page";
import BidPage from "./page";

export default function BidPageRouteHelper() {
  const { bidId, readOnlyStr } = useParams();

  return (
    <>
      <BidPage bidId={bidId}/>
    </>
  );
}