import * as Ons from "react-onsenui";
import AccountBuilder from "../../domain/account/account.builder";
import Loading from "../../components/loading";
import { EditProfileValidator, NewItemValidator } from "../../domain/search/form.validators";
import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { useEffect, useState } from "react";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useFormik } from "formik";
import { authService, utils } from "../../services";
import { BACKEND_URL } from "../../domain/env";
import { ALL_AVATARS } from "../../domain/constants";

export default function MobileEditProfile() {
  const [account, setAccount] = useState<any>(null);
  const [accountImage, setAccountImage] = useState<any>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [avatarIdx, setAvatarIdx] = useState<number>(0);
  const [selectedAvatar, setSelectedAvatar] = useState<number>(1);
  const [useOwnImage, setUseOwnImage] = useState<boolean>(false);
  const [showDeleteImageConfirmation, setShowDeleteImageConfirmation] = useState<boolean>(false);

  async function loadData() {
    utils.getLoggedInAccountId()
      .then((accountId) => {
        var url = BACKEND_URL + "/accounts/" + accountId;
    
        authService.getSecure(url)
          .then((result) => {
            var a = AccountBuilder.buildAccount(result.data);
            setAccount(a);
    
            formik.values.street = a.street;
            formik.values.postalCode = a.postalCode;
            formik.values.city = a.city;
            formik.values.phoneNumber = a.phoneNumber;
            formik.values.emailAddress = a.emailAddress;
            formik.values.showLastName = a.showLastName;
            formik.values.showAddress = a.showAddress;
            formik.values.showBirthDate = a.showBirthDate;
            formik.values.showPhoneNumber = a.showPhoneNumber;
            formik.values.showCoordinates = a.showCoordinates;
            formik.values.showKvkNumber = a.showKvkNumber;
            formik.values.showStatistics = a.showStatistics;
    
            if (a.avatarIdx && a.avatarIdx != -1) {
              setAvatarIdx(a.avatarIdx + 1);
            } else {
              var accountImgUrl = BACKEND_URL + "/static/img/preview/" + a.id;
    
              authService.getSecure(accountImgUrl)
                .then((accountImgResult) => {
                  
                }).catch((_) => {
                  setAvatarIdx(1);
                });
            }
          });
      })
  }

  useEffect(() => {
    if (!account) {
      loadData();
    }
  }, null);

  function askDeleteImage() {

  }

  function doDeleteImage() {

  }

  function cancelEditProfile() {

  }

  function saveProfile() {

  }

  function changeImageTab(e) {
    if (e.target.innerText == "Avatar") {
      setUseOwnImage(false);
    } else {
      setUseOwnImage(true);
    }
  }

  function selectMobilePhoto() {
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 90,
        width: 200,
        height: 200,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      });
    
      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      var imageUrl = image.webPath;

      // Can be set to the src of an image now
      //imageElement.src = imageUrl;
    };

    takePicture();
  }

  const formik = useFormik({
    initialValues: EditProfileValidator.initialValues,
    validationSchema: EditProfileValidator.validationSchema,
    onSubmit: (values, _) => {
      var cleaned = NewItemValidator.cleanInput(values);

      // Utils.mobileNavigator.pushPage({
      //   page: <MobileNewItemCreated />,
      //   name: "new-item-created/" + utils.makeId(5),
      //   renderToolbar: () => {
      //     return <DefaultMobileToolbar text="Nieuw item aangemaakt" backButton={true}/>
      //   }
      // });
    }
  });

  if (!account) {
    return <Loading/>;
  }

  return (
    <>
      <div className="ml-2 mr-2">
        <form onSubmit={formik.handleSubmit}>
          <div className="m-1 ml-2 redhat-display">Afbeelding:</div>
          {
            accountImage ? 
              <></>
              :
              <>
                <Tabs defaultValue={0} 
                    onChange={changeImageTab}
                    className="body-style m-4 rounded-lg mobile-avatar-border">
                  <TabList className="body-style">
                    <Tab className="select-image-grow mobile-avatar-corner-tl">Avatar</Tab>
                    <Tab className="select-image-grow rounded-tr-lg">Eigen&nbsp;afbeelding</Tab>
                    <Tab className="select-image-grow mobile-avatar-corner-tr">Foto&nbsp;maken</Tab>
                  </TabList>

                  <TabPanel value={0}>
                    <div className="grid grid-cols-4 mb-0 mt-4 h-[500px]">
                    {
                      ALL_AVATARS.map((a, i) => (
                        <div key={i + 1}>
                          <img 
                            className="mx-auto mb-4 mt-2 cursor-pointer w-[50px] h-[50px]" 
                            src={a}
                            onClick={() => {setAvatarIdx(i + 1)}}
                            width="50" 
                            height="50" 
                            alt="Avatar"/>
                          {
                            avatarIdx == (i + 1) ? 
                              <div key={i} className="rounded-lg w-[70px] h-[70px] relative top-[-76px] left-[5px] mb-[-70px] border-accent-color border-2 border-dashed"></div>
                              : <></>
                          }
                        </div>
                      ))
                    }
                    </div>
                  </TabPanel>
                  <TabPanel value={1}>
                    <div className="h-[500px]">
                      <div className="dropZoneContainer mt-2">
                        {
                          !uploadedFile ? 
                            <input type="file"
                              multiple 
                              id="drop_zone" 
                              className="FileUpload relative top-[145px]" 
                              accept=".jpg,.png"
                              onChange={() => {}}/>
                          : <></>
                        }
                        {
                          uploadedFile ? 
                            <>
                              <img src={URL.createObjectURL(uploadedFile)} 
                                width={100} 
                                height={100} 
                                className="mx-auto rounded-lg w-[100px] h-[100px] mb-12"/>
                              <div className="w-full mx-auto text-center">
                                <a className="dropZoneOverlay" onClick={askDeleteImage}>Verwijderen</a>
                              </div>
                            </>
                            : <div 
                              className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed mx-auto mb-12"></div>
                        }
                        {
                          !uploadedFile ? 
                            <div className="dropZoneOverlay rounded-lg">Selecteren</div>
                            : <></>
                        }
                        
                        <Ons.AlertDialog id="cancelMessage" isOpen={true} cancelable>
                          <div className="text-center w-full redhat-display mt-2">
                            <span className="font-bold">Annuleren</span>
                          </div>
                          
                          <div className="text-center w-full mt-4 p-4 redhat-display">
                            Weet je zeker dat je wil annuleren? Je raakt je wijzigingen kwijt.
                          </div>

                          <div className="w-full p-4 gap-2 justify-center flex flex-row">
                            <Ons.Button onClick={() => {

                            }} className="button min-w-[110px]">
                              <span className="redhat-display text-sm">Ja</span>
                            </Ons.Button>

                            <Ons.Button onClick={() => {}} className="button min-w-[110px]">
                              <span className="redhat-display text-sm">Nee</span>
                            </Ons.Button>
                          </div>
                        </Ons.AlertDialog>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={2}>
                    <div className="h-[500px] text-center justify-center align-center">
                      <div className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed  bg-white ml-auto mr-auto mt-[38px]"
                        onClick={() => selectMobilePhoto() }>
                        <div className="w-[100px] h-[100px] text-xl">
                          <Ons.Icon icon="md-camera-add" className="relative link-color top-[33px]"/>
                        </div>
                        <div className="link-color mt-4">Foto maken</div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </>
          }

          <div className="w-full ml-auto mr-auto h-[1px] border-solid search-line-mobile"></div>
          
          <div className="m-1 ml-2 redhat-display">Straat:</div>
          <input
            type="text"
            id="street"
            className="search-field w-full redhat-display ml-2 mr-2"
            placeholder=""
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.street}/>

          {
            formik.touched.street && formik.errors.street ?
              <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.street}</div>
              : null
          }

          <div className="w-full ml-auto mr-auto h-[1px] border-solid search-line-mobile"></div>

          <div className="m-1 ml-2 redhat-display">Postcode:</div>
          <input 
            className="w-full search-field uppercase redhat-display ml-2 mr-2" 
            id="postalCode" 
            placeholder="1234 AB" 
            type="text"
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.postalCode}
          />

          {
            formik.touched.postalCode && formik.errors.postalCode ?
              <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.postalCode}</div>
              : null
          }

          <div className="w-full ml-auto mr-auto h-[1px] border-solid search-line-mobile"></div>

          <div className="m-1 ml-2 redhat-display">Stad:</div>
          <input
            type="text"
            id="city"
            className="search-field w-full redhat-display ml-2 mr-2"
            placeholder=""
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.city}/>

          {
            formik.touched.city && formik.errors.city ?
              <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.city}</div>
              : null
          }

          <div className="w-full ml-auto mr-auto h-[1px] border-solid search-line-mobile"></div>

          <div className="m-1 ml-2 redhat-display">Telefoonnummer:</div>
          <input className="w-full search-field redhat-display m-1 ml-2" 
            id="phoneNumber" 
            placeholder="+31612345678" 
            type="text"
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.phoneNumber}
          />

          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.phoneNumber}</div>
          ) : null }

          <div className="w-full ml-auto mr-auto h-[1px] border-solid search-line-mobile"></div>
          
          <div className="m-1 ml-2 redhat-display">Emailadres:</div>
          <input 
            className="w-full search-field redhat-display m-1 ml-2" 
            id="emailAddress" 
            type="text"
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
          />
            
          {formik.touched.emailAddress && formik.errors.emailAddress ? (
            <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.emailAddress}</div>
          ) : null }

          <div className="w-full ml-auto mr-auto h-[1px] border-solid search-line-mobile"></div>
            
          <div className="w-full text-center flex flex-row justify-center mt-4 mb-4 gap-4">
            <Ons.Button className="link-color p-2 min-w-[150px]" onClick={() => cancelEditProfile()}>
              <span className="redhat-display text-sm capitalize">Annuleren</span>
            </Ons.Button>

            <Ons.Button className="link-color p-2 min-w-[150px]" onClick={() => saveProfile()}>
              <span className="redhat-display text-sm capitalize">Opslaan</span>
            </Ons.Button>
          </div>


        </form>
      </div>
    </>);
}