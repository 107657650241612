import * as Ons from "react-onsenui";
import * as Yup from 'yup';
import React from 'react';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Spinner from "../../../../components/spinner";
import Loading from "../../../../components/loading";
import { useEffect, useState } from "react";
import { useFormik } from 'formik';
import { Modal, ModalClose, Radio, Tooltip } from "@mui/joy";
import { BACKEND_URL } from "../../../../domain/env";
import ProductType, { productTypeFromString, ALL_PRODUCT_TYPES, productTypeToString } from "../../../../domain/item/product.type";
import { authService, utils } from "../../../../services";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { EventBus } from "../../../../services/event.bus";
import { NewItemValidator, checkValidAuctionDuration, validAuctionDuration, validSimpleStartPrice } from "../../../../domain/search/form.validators";
import { Utils } from "../../../../services/utils";
import Checkbox from "../../../../components/checkbox";
import Auction from "../../../../domain/offer/auction";
import BusinessOffer from "../../../../domain/offer/business.offer";
import UserOffer from "../../../../domain/offer/user.offer";
import { PriceType } from "../../../items/new/price.type";
import OfferType from "../../../../domain/offer/offer.type";

export default function EditItem({ offer, item }) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);
  const [pictures, setPictures] = useState<any[]>(null);
  const [priceType, setPriceType] = useState<PriceType>(null);
  const [offerType, setOfferType] = useState<OfferType>(null)
  const [auctionHasStartPrice, setAuctionHasStartPrice] = useState<boolean>(false);
  const [allowRetractingBids, setAllowRetractingBids] = useState<boolean>(false);
  const [allowBiddingHasStartPrice, setAllowBiddingHasStartPrice] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [productType, setProductType] = useState<ProductType>(ProductType.WINE);
  const [enlargedImage, setEnlargedImage] = useState<any>(null);
  const [enlargeImageOpen, setEnlargeImageOpen] = useState<boolean>(false);
  const [showSelectProductType, setShowSelectProductType] = useState<boolean>(false);
  const [selectedProductType, setSelectedProductType] = useState<ProductType>(null);
  const [showDeleteImageConfirmation, setShowDeleteImageConfirmation] = useState<boolean>(false);
  const [askPublishConcept, setAskPublishConcept] = useState<boolean>(false);
  const [askRetractPublished, setAskRetractPublished] = useState<boolean>(false);
  const [showPublishConcept, setShowPublishConcept] = useState<boolean>(false);
  const [showRetractPublished, setShowRetractPublished] = useState<boolean>(false);
  const [showCancelWarning, setShowCancelWarning] = useState<boolean>(false);
  const [imageIndexToDelete, setImageIndexToDelete] = useState<number>(null);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState<boolean>(false);
  const [editList, setEditList] = useState<any[]>([]);
  const [registeredEventBus, setRegisteredEventBus] = useState<boolean>(false);
  const [hasSimpleStartPrice, setHasSimpleStartPrice] = useState<boolean>(false);
  const [productTypeLabel, setProductTypeLabel] = useState<string>("Wijn");
  const [showImageError, setShowImageError] = useState<boolean>(false);
  const [blockerStyle, setBlockerStyle] = useState<string>(null);
  const [images, setImages] = useState<any[]>([]);
  
  useEffect(() => {
    if (!pictures) {
      authService.fetchImages(item.id)
        .then((result) => {
          setPictures(result);
        });
    }

    if (!registeredEventBus) {
      setRegisteredEventBus(true);

      EventBus.getInstance().register(
        EventBus.ON_CANCEL, () => {
          if (!showCancelWarning) {
            setShowCancelWarning(true);
          }
        });
      }
  }, null);

  function selectMobilePhoto() {
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 90,
        width: 200,
        height: 200,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      });
      
      setShowImageError(false);

      var newImages = [...images];
    
      newImages = Array.prototype.slice.call(newImages, 0, 3);
      
      let blob = await fetch(image.webPath).then(r => r.blob());

      if (newImages.length < 3) {
        newImages.push(blob);
      }

      setImages(newImages);
    }
    
    takePicture();
  }

  function openEnlargedImage(file) {
    setEnlargedImage(file);
    setEnlargeImageOpen(true);
  }

  function closeEnlargedImage() {
    setEnlargeImageOpen(false);
    setEnlargedImage(null);
  }


  async function uploadImagesToServer() {
    return Promise.all(images.map((file, i) => {
      const body = new FormData();
      
      body.set("sequenceNr", "" + i);
      body.set("type", file.type);
      body.set("file", file);

      var url = BACKEND_URL + "/static/img";

      return authService.postSecure(url, body)
        .then((result) => {
          var location = result["headers"]["location"] as string;
          var idx = location.lastIndexOf("/");
          var imgId = location.substring(idx + 1);
          
          return imgId;
        });
    }));
  }
  
  function uploadToServer(data) {
    uploadImagesToServer()
      .then((result) => {
        data["imgIds"] = result;
        var url = BACKEND_URL + "/items";

        authService.postSecure(url, data)
          .then((result) => {
            if (result.status == 201) {
              setShowSuccessfulMessage(true);
            }
          }).catch((e) => {

          });
      });
  }

  const handleVisibleChange = () => {
    setVisible(!visible);
  }

  const handleAuctionHasStartPriceChange = () => {
    setAuctionHasStartPrice(!auctionHasStartPrice);
    formik.values.auctionStartPrice = "";
  }

  const handleAllowRetractingBidsChange = () => {
    setAllowRetractingBids(!allowRetractingBids);
  }

  const handleAllowBiddingHasStartPriceChange = () => {
    setAllowBiddingHasStartPrice(!allowBiddingHasStartPrice);
    formik.values.simpleStartPrice = "";
  }

  const handleRadioPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == "FIXED_PRICE") {
      setPriceType(PriceType.FIXED_PRICE);
    } else if (event.target.value == "AUCTION") {
      setPriceType(PriceType.AUCTION);
    } else if (event.target.value == "ALLOW_BIDDING") {
      setPriceType(PriceType.ALLOW_BIDDING)
    }

    setAllowBiddingHasStartPrice(false);
    setAuctionHasStartPrice(false);
  }

  function validAuctionStartPrice(f) {
    var value = f.values.auctionStartPrice;

    if (auctionHasStartPrice) {
      if (!value || value == "") {
        return false;
      }

      var parsed = parseInt(value);

      if (isNaN(parsed)) {
        return false;
      }

      if (parsed < 0) {
        return false;
      }
    }

    return true;
  }

  function openChangeProductType() {
    if (!selectedProductType) {
      return;
    }

    setShowSelectProductType(true);

    setTimeout(() => {
      var id = "radio_" + selectedProductType;      
      var elem = document.getElementById(id);

      if (elem) {
        (elem as any).checked = true;
      } else {
        (elem as any).checked = false;
      }
    }, 10);

  }

  function doSelectProductType() {
    setShowSelectProductType(false);
  }

  const handleProductTypeChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
    setProductType(productTypeFromString(newValue));
  };

  function changePriceType(priceType: PriceType) {
    setPriceType(priceType);

    setHasSimpleStartPrice(false);
    setAuctionHasStartPrice(false);

    formik.values.simpleStartPrice = "";
    formik.values.auctionStartPrice = "";

    formik.errors.simpleStartPrice = "";
    formik.errors.auctionStartPrice = "";
  }

  function changeProductType(e) {
    var newProductType = productTypeFromString(e.target.value);
    setProductType(newProductType);
    setProductTypeLabel(productTypeToString(newProductType));
    formik.values.productType = e.target.value;
  }

  function resetPriceFields() {
    formik.values.priceType = PriceType.FIXED_PRICE;
    formik.values.fixedPrice = "";
    formik.values.auctionStartPrice = "";
    formik.values.simpleStartPrice = "";
    formik.values.auctionDays = "";
    formik.values.auctionHours = "";
    formik.values.auctionMinutes = "";

    formik.values.hasSimpleStartPrice = false;
    formik.values.auctionHasStartPrice = false;

    formik.errors.priceType = "";
    formik.errors.fixedPrice = "";
    formik.errors.auctionStartPrice = "";
    formik.errors.simpleStartPrice = "";
    formik.errors.auctionDays = "";
    formik.errors.auctionHours = "";
    formik.errors.auctionMinutes = "";
  }

  const formik = useFormik({
    initialValues: NewItemValidator.initialValues,
    validationSchema: NewItemValidator.validationSchema,
    onSubmit: (values, _) => {
      var cleaned = NewItemValidator.cleanInput(values);

      if (askPublishConcept) {
        setShowPublishConcept(true);  
        setShowRetractPublished(false);
      } else if (askRetractPublished) {
        setShowPublishConcept(false);
        setShowRetractPublished(true);
      }

      setAskPublishConcept(false);
      setAskRetractPublished(false);
    }
  });

  function fillFields(offer, item) {
    formik.values.title = item.title;
    formik.values.brand = item.brand;
    formik.values.itemYear = item.itemYear;
    formik.values.description = item.description;
    formik.values.productType = item.productType;
    formik.values.visible = item.visible;

    if (!item.visible) {
      setAskPublishConcept(true);
    } else {
      setAskRetractPublished(true);
    }

    setVisible(item.visible);

    setSelectedProductType(item.productType);

    var priceType = null;

    if (offer instanceof UserOffer) {
      if (offer.allowBidding) {
        priceType = PriceType.ALLOW_BIDDING;
        formik.values.hasSimpleStartPrice = true;
        formik.values.simpleStartPrice = "" + utils.round(offer.amount);
      } else {
        priceType = PriceType.FIXED_PRICE;
        formik.values.fixedPrice = "" + utils.round(offer.amount);
      }
    } else if (offer instanceof BusinessOffer) {
      priceType = PriceType.FIXED_PRICE;
      formik.values.fixedPrice = "" + utils.round(offer.amount);
    } else if (offer instanceof Auction) {
      priceType = PriceType.AUCTION;
      
      if (offer.startPrice) {
        formik.values.auctionHasStartPrice = true;
        formik.values.auctionStartPrice = "" + utils.round(offer.startPrice);
      }

      formik.values.auctionDays = "" + utils.getAuctionDays(offer);
      formik.values.auctionHours = "" + utils.getAuctionHours(offer);
      formik.values.auctionMinutes = "" + utils.getAuctionMinutes(offer);
    }

    setPriceType(priceType);

    formik.values.priceType = priceType;
    formik.values.nrItems = "1";
  }

  useEffect(() => {
    if (!selectedProductType) {
      fillFields(offer, item);
    }
  }, null);

  function deleteImage(idx) {
    setImageIndexToDelete(idx);
    setShowDeleteImageConfirmation(true);
  }

  function addDeleteImageAction(idx) {
    if (idx < 0 || idx > pictures.length - 1) {
      return;
    }

    var imgName = pictures[idx].name as string;
    var lastDot = imgName.lastIndexOf(".");

    if (lastDot == -1) {
      return;
    }
    
    var imgId = imgName.substring(0, lastDot);

    var deleteImageAction = {
      "action": "delete-image",
      "id":  imgId
    };

    editList.push(deleteImageAction);
    setEditList(editList);

    setImageIndexToDelete(null);
    setShowDeleteImageConfirmation(false);
  }

  function addAddImageAction() {

  }

  function doDeleteImage(imgId) {
    var url = BACKEND_URL + "/static/img/" + imgId;

    authService.deleteSecure(url)
      .then((result) => {

        authService.fetchImages(item.id)
          .then((result) => {
            setPictures([]);

            setTimeout(() => {
              setPictures(result);
            });
          });  
      })
  }

  function doAddImage() {

  }

  function doCancelEdit() {

  }

  function enlargeImage(file) {
    setEnlargedImage(URL.createObjectURL(file));
    setEnlargeImageOpen(true);
  }

  function editItem() {
    return (<></>);
  }

  function showItem() {
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="max-w-2xl container mx-auto grid auto-rows-auto flex-row place-content-center aling-middle min-h-screen">
            <div>
              <label className="grow-0">Afbeeldingen:</label>

              <div className="flex flex-row gap-2 mt-4">
                {pictures.map((file: any, i) => (
                  <div key={file.name}>
                    <img 
                      src={URL.createObjectURL(file)}
                      width={100} 
                      height={100}
                      onClick={() => enlargeImage(file)}
                      className="rounded-lg max-w-[100px] max-h-[100px] object-contain cursor-pointer"/>
                    <div className="w-full mx-auto text-center">
                      <a className="text-xs cursor-pointer redhat-display link-color" onClick={() => deleteImage(i)}>Verwijderen</a>
                    </div>
                  </div>
                ))}
      
                {[...Array(Math.max(0, 3 - pictures.length))].map((x, i) => (
                  <div key={x} className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed" onClick={() => selectMobilePhoto()}/>
                ))}
              </div>
            </div>

            <div className="grid grid-rows-3">
              <label className="" htmlFor="itemYear">Aantal items:</label>

              <div className="">
                <input
                  type="text"
                  id="nrItems"
                  className="mt-2 mr-8 search-field redhat-display"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.nrItems}
                />
              </div>

              {formik.touched.nrItems && formik.errors.nrItems ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.nrItems}</div>
              ) : null }
            </div>

            <div className="grid grid-rows-3">
              <label className="" htmlFor="title">Titel:</label>

              <div className="">
                <input 
                  type="text"
                  className="min-w-md w-full larger-input" 
                  id="title" 
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                />
              </div>

              {formik.touched.title && formik.errors.title ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.title}</div>
              ) : null }
            </div>

            <div className="grid grid-rows-3">
              <label className="" htmlFor="brand">Merk:</label>

              <div className="">
                <input
                  id="brand"
                  className="min-w-md w-full larger-input" 
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.brand}
                />
              </div>

              {formik.touched.brand && formik.errors.brand ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.brand}</div>
              ) : null }
            </div>

            <div className="grid grid-rows-3">
              <label className="" htmlFor="productType">Type:</label>

              <Select 
                id="new_item_type"
                onChange={handleProductTypeChange}
                defaultValue="WINE"
                className="w-full mr-2 mt-[-5px]">
                  {ALL_PRODUCT_TYPES.map((productType) => (
                    <Option key={productType} value={productType} className="body-style bg-white redhat-display">{productTypeToString(productType)}</Option>
                  ))}
                </Select>
              {formik.touched.productType && formik.errors.productType ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.productType}</div>
              ) : null }
            </div>


            <div className="grid grid-rows-3">
              <label className="" htmlFor="itemYear">Jaar:</label>

              <div className="">
                <input
                  type="text"
                  id="itemYear"
                  className="min-w-md w-full larger-input"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.itemYear}
                />
              </div>

              {formik.touched.itemYear && formik.errors.itemYear ? (
                <div className="text-xs mt-1 text-right text-red-600">{formik.errors.itemYear}</div>
              ) : null }
            </div>

            <div className="flex flex-col">
              <label className="mb-2" htmlFor="description">Omschrijving:</label>

              <div className="">
                <textarea 
                  rows={0} 
                  cols={0} 
                  className="min-w-md w-full resize-none textarea text-sm" 
                  id="description" 
                  name="description"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}>
                </textarea>
              </div>

              {formik.touched.description && formik.errors.description ? (
                <div className="text-xs ml-2 mt-1 text-left text-red-600">{formik.errors.description}</div>
              ) : null }
            </div>

            <div className="mt-8">Type aanbieding:</div>

            <div>
              <Tooltip title={
                <div className="body-style">
                  Zet het item online met een vaste prijs zonder mogelijkheid tot bieden.
                </div>
              } variant="plain">
                <Radio 
                  id="radio_fixed_price"
                  className="pt-3 pr-3 body-style redhat-display"
                  checked={priceType == PriceType.FIXED_PRICE}
                  onChange={ e => {
                    formik.handleChange(e);
                    handleRadioPrice(e);
                  }}
                  value="FIXED_PRICE"
                  name="radio-button-price"
                  label="Aanbieden met vaste prijs"
                />
              </Tooltip>

              { 
                priceType == PriceType.FIXED_PRICE ? 
                  <div className="grid grid-rows-3 mb-[-20px]">
                    <label className="grow mt-4 ml-4" htmlFor="price">Prijs:</label>

                    <div className="grow mt-[-20px] mb-[-100px] ml-4">
                      <div className="relative top-[35px] left-[10px] w-[10px] text-sm">&#x20AC;</div>

                      <input 
                        id="fixedPrice"
                        className="min-w-md w-full larger-input mt-2" 
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.fixedPrice}
                      />
                    </div>

                    {formik.touched.fixedPrice && formik.errors.fixedPrice ? (
                      <div className="text-xs mt-1 text-right text-red-600">{formik.errors.fixedPrice}</div>
                    ) : null }
                  </div>
                : <></>
              }
            </div>
            
            <div>
              <Tooltip title={
                  <div className="body-style">
                    Maak een veiling aan met een timer.
                    <br/>
                    De hoogste bieding wint.
                  </div>
                } variant="plain">
                <Radio
                  id="radio_auction"
                  className="pt-3 pr-3 body-style"
                  checked={priceType == PriceType.AUCTION}
                  onChange={ e => {
                    formik.handleChange(e);
                    handleRadioPrice(e);
                  }}
                  value="AUCTION"
                  name="radio-button-price"
                  label="Als veiling aanbieden"
                />
              </Tooltip>

              {
                priceType == PriceType.AUCTION ? (
                  <div className="mt-4 ml-4">
                    <div className="mb-2">Duur van de veiling:</div>
                    <div className="mb-4">
                      <input 
                        type="text" 
                        id="auctionDays"
                        className="w-[40px] mr-2"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionDays}/>
                      <span className="text-sm">dagen,&nbsp;&nbsp;</span>

                      <input 
                        type="text" 
                        id="auctionHours"
                        className="w-[40px] mr-2"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionHours}/>
                      <span className="text-sm">uren,&nbsp;&nbsp;</span>

                      <input 
                        type="text" 
                        id="auctionMinutes"
                        className="w-[40px] mr-2"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionMinutes}/>
                      <span className="text-sm">minuten</span>
                    </div>

                    {formik.touched.auctionDays && formik.errors.auctionDays ? (
                      <div className="text-xs mt-1 text-left text-red-600">{formik.errors.auctionDays}</div>
                    ) : null }

                    <div className="relative top-[2px] inline-block">
                      <Checkbox
                        disabled={false}
                        label=""
                        className="relative top-[4px]"
                        id="auctionHasStartPrice"
                        value={auctionHasStartPrice}
                        onChange={handleAuctionHasStartPriceChange}
                      />
                    </div>
                    <label htmlFor="auctionHasStartPrice" className="mr-4 ml-2">Startprijs:</label>

                    <input type="text" 
                      id="auctionStartPrice"
                      disabled={!auctionHasStartPrice} 
                      className="w-full mb-4 mt-2"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.auctionStartPrice}/>
                    <div className="relative top-[-41px] left-[10px] w-[10px] text-sm">&#x20AC;</div>

                    {formik.touched.auctionStartPrice && formik.errors.auctionStartPrice ? (
                      <div className="text-xs text-left text-red-600 mt-[-20px]">{formik.errors.auctionStartPrice}</div>
                    ) : null }
                  </div>
                ) : <></>
              }
            </div>

            <div>
              <Tooltip title={
                  <div className="body-style">
                    Laat enkele gebruikers zelf bieden zonder timer.
                    <br/>
                    Accepteer individuele biedingen, eventueel met een startprijs.
                  </div>
                } variant="plain">
                <Radio 
                  id="radio_allow_bidding"
                  className="pt-3 pr-3 body-style"
                  checked={priceType == PriceType.ALLOW_BIDDING}
                  onChange={ e => {
                    formik.handleChange(e);
                    handleRadioPrice(e);
                  }}
                  value="ALLOW_BIDDING"
                  name="radio-button-price"
                  label="Simpel bieden"/>
              </Tooltip>

              {
                priceType == PriceType.ALLOW_BIDDING ? (
                  <div className="mt-4 ml-4">
                    <div className="relative top-[2px] inline-block">
                      <Checkbox
                        disabled={false}
                        id="allowBidding"
                        label=""
                        className=""
                        value={allowBiddingHasStartPrice}
                        onChange={handleAllowBiddingHasStartPriceChange}/>
                    </div>
                    <label htmlFor="allowBidding" className="mt-4 mr-4 ml-2">Startprijs:</label>
                    
                    <input 
                      id="simpleStartPrice"
                      type="text" 
                      disabled={!allowBiddingHasStartPrice} 
                      className="w-full mb-4 mt-2"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.simpleStartPrice}/>
                    <div className="relative top-[-41px] left-[10px] w-[10px] text-sm">&#x20AC;</div>

                    {formik.touched.simpleStartPrice && formik.errors.simpleStartPrice ? (
                      <div className="text-xs text-right text-red-600 mt-[-20px]">{formik.errors.simpleStartPrice}</div>
                    ) : null }
                  </div>
                ) : <></>
              }
            </div>
            
            <div className="flex flex-row mb-4">
              <div className="grow mb-0 mt-4">
                <Tooltip title={
                  <div className="body-style">
                    Je kan het item nu aanmaken en later verder bewerken in &apos;Mijn items&apos;.
                    <br/> 
                    In de tussentijd is het nog niet zichtbaar voor andere gebruikers.
                  </div>
                } variant="plain">
                  <div className="mt-4 ml-4">
                    <div className="relative top-[2px] inline-block">
                      <Checkbox
                        id="visible"
                        label=""
                        className=""
                        disabled={false}
                        value={visible}
                        onChange={handleVisibleChange}/>
                    </div>
                    <label htmlFor="visible" className="mt-4 mr-4 ml-2">Als concept opslaan</label>
                  </div>
                </Tooltip>
                <div></div>
              </div>
            </div>
            
            <Modal
              open={enlargeImageOpen}
              onClose={() => setEnlargeImageOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <>
                  <ModalClose variant="plain" sx={{ m: 1 }} />
                  <img 
                    src={enlargedImage}
                    width={400} 
                    height={400}
                    className="rounded-lg w-[400px] h-[400px] cursor-pointer object-contain"/>
                </>
            </Modal>

            <div className="flex flex-row-reverse row-cols-2 mb-8 w-full mx-auto">
              <div className="w-full pt-10 pb-7 mr-4 text-center">
                <button className="normal-button" type="submit">Opslaan</button>
              </div>
            </div>

            <div className="justify-center mx-auto mt-[0px] mb-2">
              { showSpinner ? <Spinner/> : <></> }

              {
                !formik.isValid && formik.submitCount > 0 ? 
                  <>
                    <div className="text-center text-red-600 w-[300px] ml-2 mr-2 mb-12">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
                  </>
                  : <></>
              }
            </div>
          </div>
        </form>
      </>);
  }

  function showCancelMessage() {
    EventBus.getInstance()
      .dispatch<void>(EventBus.ON_CANCEL);
  }

  function closeEditItem() {
    setShowCancelWarning(false);
    Utils.mobileNavigator.popPage({ animation: "none" });
  }

  function getAuctionDurationError() {
    var error = null;

    var weirdBugWorkaround = 
      formik.touched.auctionDays && 
      formik.touched.auctionHours && 
      formik.touched.auctionMinutes && 
      !formik.errors.auctionDays && 
      !formik.errors.auctionHours && 
      !formik.errors.auctionMinutes;

    if (weirdBugWorkaround) {
      var valid = checkValidAuctionDuration(
        formik.values.auctionDays,
        formik.values.auctionHours,
        formik.values.auctionMinutes);

      if (valid == false) {
        error = "Voer een geldige duur in";
      }
    } else if (formik.errors.auctionDays) {
      error = formik.errors.auctionDays;
    } else if (formik.errors.auctionHours) {
      error = formik.errors.auctionHours;
    } else if (formik.errors.auctionMinutes) {
      error = formik.errors.auctionMinutes;
    }

    if (error) {
      return (<div className="text-xs text-left text-red-600 redhat-display mb-2">{error}</div>);
    } else {
      return (<div className="text-xs">&nbsp;</div>);
    }
  }

  if (!item || !offer || !pictures) {
    return <Loading/>;
  }

  function mobileEditItem() {
    return (
      <Ons.Page className="bg-white">
        <form onSubmit={formik.handleSubmit}>
          <div className="m-2">
            <div className="m-1 ml-2 redhat-display">Afbeeldingen:</div>

            <div className="w-full justify-center flex flex-row gap-2 mt-4 mb-4">
              {
                pictures.map((file: any, i) => (
                  <div key={file.name}>
                    <img 
                      src={URL.createObjectURL(file)}
                      width={100} 
                      height={100}
                      onClick={() => enlargeImage(file)}
                      className="rounded-lg max-w-[100px] max-h-[100px] object-contain"/>
                    <div className="w-full mx-auto text-center link-color">
                      <a className="text-xs redhat-display link-color" onClick={() => { deleteImage(i) }}>verwijderen</a>
                    </div>
                  </div>))
              }

              {
                [...Array(Math.max(0, 3 - pictures.length))].map((x, i) => (
                  <div key={x} 
                      className="rounded-lg w-[100px] h-[100px] border border-gray-300 border-dashed text-center align-middle"
                      onClick={() => { selectMobilePhoto() } }>
                    <div className="w-[100px] h-[100px] text-xl">
                      <Ons.Icon icon="md-camera-add" className="relative link-color top-[33px]"/>
                    </div>
                  </div>))
              }
            </div>

            <Modal
              open={enlargeImageOpen}
              onClose={() => setEnlargeImageOpen(false)}
              sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <>
                  <ModalClose variant="plain" sx={{ m: 1 }} />
                  <img 
                    src={enlargedImage}
                    width={400} 
                    height={400}
                    className="w-full cursor-pointer"/>
                </>
            </Modal>

            <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

            <div className=""><span className="redhat-display">Aantal items:</span></div>
            <input
              type="text"
              id="nrItems"
              className="mt-2 search-field redhat-display"
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.nrItems}
            />
            
            {
              formik.touched.nrItems && formik.errors.nrItems ? (
                <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.nrItems}</div>) 
                : <></>
            }

            <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

            <div className=""><span className="redhat-display">Aantal items:</span></div>
            <input 
              type="text"
              className="mt-2 search-field redhat-display"
              id="title" 
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.title}
            />

            {
              formik.touched.title && formik.errors.title ? (
                <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.title}</div>) 
                : <></>
            }
            
            <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

            <div className=""><span className="redhat-display">Merk:</span></div>

            <input
              id="brand"
              className="mt-2 search-field redhat-display"
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.brand}
            />

            {
              formik.touched.brand && formik.errors.brand ? (
                <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.brand}</div>) 
                : <></>
            }

            <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>
            
            <div className="mb-2 redhat-display">Type product: { productTypeToString(selectedProductType) }</div>
            {
              showSelectProductType ?
                <>
                  <Ons.List className="mb-4">
                    {
                      ALL_PRODUCT_TYPES.map((x, _) => (
                        <div key={x}>
                          <label className="left">
                            <input
                              type="radio"
                              id={"radio_" + x}
                              checked={selectedProductType == x}
                              className="relative top-[4px] mt-2 mb-2"
                              name="radio_product_type"
                              onChange={() => setSelectedProductType(x)}
                              value={x}
                            />
                          </label>
                          <label htmlFor={"radio_" + x} 
                              className="center redhat-display">
                            { productTypeToString(x)}
                          </label>
                        </div>
                      ))
                    }
                  </Ons.List>

                  <Ons.Button className="link-color mb-4 max-h-[32px] mt-4 top-[8px]" 
                      onClick={() => doSelectProductType()}>
                    <span className="text-sm redhat-display capitalize">Selecteren</span>
                  </Ons.Button>

                  <div className="h-[20px]"></div>
                </>
                : 
                <>
                  <Ons.Button className="link-color mb-4 max-h-[32px]" 
                      onClick={() => openChangeProductType()}>
                    <span className="text-sm redhat-display capitalize">Selecteren</span>
                  </Ons.Button>
                  <div className="h-[10px]"></div>
                </>
            }

            <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>
            <div className="redhat-display">Jaar:</div>

            <div className="">
              <input
                type="text"
                id="itemYear"
                className="mt-2 search-field redhat-display"
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.itemYear}
              />
            </div>

            {
              formik.touched.itemYear && formik.errors.itemYear ? (
                <div className="text-xs mt-1 text-right text-red-600 redhat-display">{formik.errors.itemYear}</div>) 
                : <></>
            }

            <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

            <div className="mb-2"><span className="redhat-display">Omschrijving:</span></div>

            <textarea 
              rows={0} 
              cols={0} 
              className="min-w-md w-full resize-none textarea redhat-display mobile-textarea" 
              id="description" 
              name="description"
              onChange={ e => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.description}>
            </textarea>

            {
              formik.touched.description && formik.errors.description ? (
                <div className="text-xs ml-2 mt-1 text-left text-red-600 redhat-display">{formik.errors.description}</div>) 
                : <></>
            }

            <div className="mr-auto mt-4 h-[1px] border-solid search-line-mobile"></div>

            <div className="m-1 ml-2 redhat-display">Type aanbieding:</div>
            <div className="ml-2">
              <div id="FIXED_PRICE" key="FIXED_PRICE" 
                  onClick={() => {
                    resetPriceFields();
                    formik.values.priceType = PriceType.FIXED_PRICE;
                    changePriceType(PriceType.FIXED_PRICE);
                  }} className="mb-[10px] mt-2">
                <div className="w-full">
                  <label className="left">
                    <input 
                      type="radio" 
                      id="input-FIXED_PRICE"
                      className="pr-3 body-style redhat-display relative top-[4px]"
                      checked={priceType == PriceType.FIXED_PRICE}/>
                    <label htmlFor="input-FIXED_PRICE"
                      className="center redhat-display mt-2">Aanbieden met vaste prijs</label>
                  </label>
                </div>
              </div>

              { 
                priceType == PriceType.FIXED_PRICE ? 
                  <div className="grid grid-rows-3 mt-[-20px] mb-[-5px]">
                    <label className="grow mt-4 ml-4" htmlFor="price"><span className="redhat-display">Prijs:</span></label>

                    <div className="grow mt-[-20px] mb-[-100px] ml-4">
                      <div className="relative top-[33px] left-[10px] w-[10px] text-sm redhat-display euro-sign">&#x20AC;</div>

                      <input 
                        id="fixedPrice"
                        className="mt-2 pl-[25px] search-field redhat-display"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.fixedPrice}
                      />
                    </div>

                    {
                      formik.touched.fixedPrice && formik.errors.fixedPrice ? (
                        <div className="text-xs ml-4 mt-2 mb-2 mr-4 text-red-600 redhat-display text-left">{formik.errors.fixedPrice}</div>) 
                        : <></>
                    }
                  </div>
                : <></>
              }
              
              <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>
              <div id="AUCTION" key="AUCTION" 
                  onClick={() => {
                    resetPriceFields();
                    formik.values.priceType = PriceType.AUCTION;
                    changePriceType(PriceType.AUCTION);
                  }} className="mb-[10px] mt-2">
                <div className="w-full">
                  <label className="left">
                    <input 
                      type="radio" 
                      id="input-AUCTION"
                      className="pr-3 body-style redhat-display relative top-[4px]"
                      checked={priceType == PriceType.AUCTION}/>
                    <label htmlFor="input-AUCTION"
                      className="center redhat-display mt-2">Als veiling aanbieden</label>
                  </label>
                </div>
              </div>
              
              {
                priceType == PriceType.AUCTION ? (
                  <div className="ml-4 mt-[10px]">
                    <div className="mb-2 redhat-display">Duur van de veiling:</div>
                    <div className="">
                      <input 
                        type="text" 
                        id="auctionDays"
                        className="max-w-[40px] mr-2 mt-2 search-field redhat-display"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionDays}/>
                      <span className="text-sm redhat-display">dagen,&nbsp;&nbsp;</span>

                      <input 
                        type="text" 
                        id="auctionHours"
                        className="max-w-[40px] mr-2 mt-2 search-field redhat-display"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionHours}/>
                      <span className="text-sm redhat-display">uren,&nbsp;&nbsp;</span>

                      <input 
                        type="text" 
                        id="auctionMinutes"
                        className="max-w-[40px] mr-2 mt-2 search-field redhat-display"
                        onChange={ e => {
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.auctionMinutes}/>
                      <span className="text-sm redhat-display">minuten</span>
                    </div>
                    
                    { getAuctionDurationError() }
                
                    <div className="mb-1">
                      <label className="right">
                        <Checkbox
                          id="auctionHasStartPrice"
                          disabled={false}
                          value={auctionHasStartPrice}
                          onChange={handleAuctionHasStartPriceChange} 
                          label="" 
                          className="relative top-[4px]"/>
                      </label>
                      <label htmlFor={`auctionHasStartPrice`} className="mr-4 ml-2 redhat-display">Startprijs:</label>
                    </div>

                    <input
                      type="text" 
                      id="auctionStartPrice"
                      disabled={!auctionHasStartPrice} 
                      className="mt-2 pl-[25px] search-field redhat-display"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.auctionStartPrice}/>
                    <div className="relative top-[-35px] left-[10px] w-[10px] text-sm redhat-display text-left">&#x20AC;</div>

                    {
                      formik.touched.auctionStartPrice && formik.errors.auctionStartPrice ? (
                        <div className="text-xs mt-[-25px] mb-[10px] mr-4 text-red-600 redhat-display text-left">{formik.errors.auctionStartPrice}</div>) 
                        : <div className="text-xs text-right mt-[-30px] mb-[20px]">&nbsp;</div> 
                    }
                  </div>
                ) : <></>
              }

              <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

              <div id="ALLOW_BIDDING" key="ALLOW_BIDDING" 
                  onClick={() => {
                    resetPriceFields();
                    formik.values.priceType = PriceType.ALLOW_BIDDING;
                    changePriceType(PriceType.ALLOW_BIDDING);
                  }} className="mb-[10px] mt-2">
                <div className="w-full">
                  <label className="left">
                    <input 
                      type="radio" 
                      id="input-ALLOW_BIDDING"
                      className="pr-3 body-style redhat-display relative top-[4px]"
                      checked={priceType == PriceType.ALLOW_BIDDING}/>
                    <label htmlFor="input-ALLOW_BIDDING"
                      className="center redhat-display mt-2">Simpel bieden</label>
                  </label>
                </div>
              </div>

              {
                priceType == PriceType.ALLOW_BIDDING ? (
                  <div className="mt-4 ml-4">
                    <div>
                      <label className="right">
                        <Checkbox
                          id="allowBiddingHasStartPrice"
                          label=""
                          className="relative top-[4px]"
                          disabled={false}
                          value={allowBiddingHasStartPrice}
                          onChange={handleAllowBiddingHasStartPriceChange}/>
                      </label>

                      <label 
                        htmlFor="allowBiddingHasStartPrice" 
                        className="mt-4 mr-4 ml-2 redhat-display">Startprijs:</label>
                    </div>
                    
                    <input 
                      id="simpleStartPrice"
                      type="text" 
                      disabled={!allowBiddingHasStartPrice} 
                      className="mt-2 pl-[25px] search-field redhat-display"
                      onChange={ e => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.simpleStartPrice}/>
                    <div className="relative top-[-36px] left-[10px] w-[10px] text-sm redhat-display">&#x20AC;</div>

                    {
                      formik.touched.simpleStartPrice && formik.errors.simpleStartPrice ? (
                        <div className="text-xs text-right mt-[-30px] mb-[20px] mr-4 text-red-600 redhat-display">{formik.errors.simpleStartPrice}</div>) 
                        : <div className="text-xs text-right mt-[-30px] mb-[20px]">&nbsp;</div> 
                    }
                  </div>
                ) : <></>
              }

              <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>
            </div>
            
            <div className="mt-8">
              <label className="right">
                <Checkbox
                  id="visible"
                  label=""
                  className="relative top-[4px] ml-2"
                  disabled={false}
                  value={visible}
                  onChange={handleVisibleChange}/>
              </label>

              <label htmlFor="visible" className="mt-4 mr-4 ml-2">
                <span className="redhat-display">Als concept opslaan</span>
              </label>
              
              <Modal
                open={enlargeImageOpen}
                onClose={() => setEnlargeImageOpen(false)}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <>
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <img 
                      src={enlargedImage}
                      width={400} 
                      height={400}
                      className="rounded-lg w-[400px] h-[400px] cursor-pointer object-contain"/>
                  </>
              </Modal>

              {
                !formik.isValid && formik.submitCount > 0 ? 
                  <>
                    <div className="w-full justify-center text-center text-red-600 text-sm ml-2 mt-4 mr-4 mb-4 redhat-display">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
                  </>
                  : <></>
              }

              <div className="flex flex-row mb-8 mt-4 m-8 gap-4 align-center justify-center">
                <div className="w-full text-center">
                  <button className="button min-w-[150px]" type="button" 
                    onClick={() => showCancelMessage()}>
                      <span className="redhat-display text-sm">Annuleren</span>
                  </button>
                </div>

                <div className="w-full text-center">
                  <button className="button min-w-[150px]" type="submit">
                    <span className="redhat-display text-sm">Opslaan</span>
                  </button>
                </div>
              </div>

              <div className="justify-center mx-auto mt-[-40px] mb-[-60px]">
                { showSpinner ? <Spinner/> : <></> }
              </div>

              <div className="mb-[100px]"></div>
            </div>
          </div>
        </form>
        
        <Ons.AlertDialog isOpen={showDeleteImageConfirmation} cancelable>
          <div className="text-center w-full redhat-display mt-2">
            <span className="font-bold alert-dialog-text">Afbeelding verwijderen</span>
          </div>

          <div className="text-center w-full mt-4 p-4 redhat-display alert-dialog-text">
            Weet je zeker dat je deze afbeelding wil verwijderen?
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button 
                onClick={() => {addDeleteImageAction(imageIndexToDelete)}} 
                className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Ja</span>
            </Ons.Button>

            <Ons.Button 
                onClick={() => {setShowDeleteImageConfirmation(false)}} 
                className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Nee</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>

        <Ons.AlertDialog isOpen={showRetractPublished} cancelable>
          <div className="text-center w-full redhat-display mt-2">
            <span className="font-bold alert-dialog-text">Als concept opslaan</span>
          </div>

          <div className="text-center w-full mt-4 p-4 redhat-display alert-dialog-text">
            U heeft deze aanbieding al online gezet. Als U hem nu als concept opslaat, 
            wordt hij onzichtbaar en worden alle biedingen afgewezen. Weet U zeker dat U wil doorgaan?
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button 
                onClick={() => {}} 
                className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Ja</span>
            </Ons.Button>

            <Ons.Button 
                onClick={() => {setShowRetractPublished(false)}} 
                className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Nee</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>

        <Ons.AlertDialog isOpen={showPublishConcept} cancelable>
          <div className="text-center w-full redhat-display mt-2">
            <span className="font-bold alert-dialog-text">Online zetten</span>
          </div>

          <div className="text-center w-full mt-4 p-4 redhat-display alert-dialog-text">
            Weet je zeker dat je deze aanbieding online wil zetten?
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button 
                onClick={() => {}} 
                className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Ja</span>
            </Ons.Button>

            <Ons.Button 
                onClick={() => setShowPublishConcept(false)} 
                className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Nee</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>

        <Ons.AlertDialog id="cancelMessage" isOpen={showCancelWarning} cancelable>
          <div className="text-center w-full redhat-display mt-2">
            <span className="alert-dialog-text font-bold">Annuleren</span>
          </div>
          
          <div className="alert-dialog-text text-center w-full mt-4 p-4 redhat-display">
            Weet je zeker dat je wil annuleren? Je raakt je wijzigingen kwijt.
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button onClick={() => closeEditItem() } className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Ja</span>
            </Ons.Button>

            <Ons.Button onClick={() => setShowCancelWarning(false)} className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Nee</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>
      </Ons.Page>);
  }

  function renderMobile() {
    return mobileEditItem();
  }

	function renderWeb() {
    if (isEditing) {
      return editItem(); 
    } else {
      return showItem();
    }
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}