import moment from "moment";
import AbstractEntity from "../abstract.entity";
import { DATE_TIME_FORMAT } from "../constants";
import NotificationType, { notificationTypeFromString } from "./notification.type";

export default class Notification extends AbstractEntity {
  accountId: string;
  toAccountId: string;
  notificationType: NotificationType;
  read: boolean;
  delivered: boolean;
  chatId: string;
  title: string;
  body: string;
  properties: string;
  links: string;
  
  constructor($id: string,
              $createTime: Date,
              $notificationType: string,
              $accountId: string,
              $toAccountId: string,
              $chatId: string,
              $title: string,
              $body: string,
              $read: boolean,
              $properties: string,
              $links: string) {
    super($id, $createTime);

    this.notificationType = notificationTypeFromString($notificationType);
    this.accountId = $accountId;
    this.toAccountId = $toAccountId;
    this.chatId = $chatId;
    this.title = $title;
    this.body = $body;
    this.read = $read;
    this.properties = $properties;
    this.links = $links;
  }
}
