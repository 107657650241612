import * as Ons from "react-onsenui";
import { authService, utils } from "../../../services";
import { DATE_TIME_FORMAT, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, FRIENDLY_DATE_TIME_FORMAT } from "../../../domain/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import NotificationType from "../../../domain/inbox/notification.type";
import moment from "moment";
import Loading from "../../../components/loading";
import Spinner from "../../../components/spinner";
import MobilePaginator from "../../../components/mobile.paginator";
import MobileNotification from "./mobile.notification";
import ChatPanelMessages from "../../chat/chat.panel.messages";
import { Utils } from "../../../services/utils";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import { ChatBuilder } from "../../../domain/chat";
import { EventBus } from "../../../services/event.bus";
import { BACKEND_URL } from "../../../domain/env";
import { NotificationBuilder } from "../../../domain/inbox/notification.builder";

export default function MobileInbox() {
  const navigate = useNavigate();

  const [chats, setChats] = useState<any[]>(null);
  const [readMessages, setReadMessages] = useState<any[]>(null);
  const [unreadMessages, setUnreadMessages] = useState<any[]>(null);
  const [loadedData, setLoadedData] = useState<boolean>(false);

  const [totalUnreadCount, setTotalUnreadCount] = useState<number>(0);

  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const [unreadLinks, setUnreadLinks] = useState<any>(null);
  const [unreadPageNumber, setUnreadPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [unreadTotalNrPages, setUnreadTotalNrPages] = useState<number>(0);

  const [selectedTab, setSelectedTab] = useState<number>(0);  

  const loadData = () => {
    if (!loadedData) {
      setLoadedData(true);

      var chatsUrl = BACKEND_URL + "/chats/new";

      authService.getSecure(chatsUrl)
        .then((result) => {
          var array = result.data.map((chat) => {
            return ChatBuilder.buildChat(chat);
          });

          setChats(array);

          var unreadUrl = BACKEND_URL + "/notifications/unread";

          authService.getSecure(unreadUrl)
            .then((result) => {
              var unread = result.data.messages.map(n => {
                return NotificationBuilder.buildNotification(n);
              });

              unread.sort(function(a, b) {
                return b.date - a.date;
              });

              setTotalUnreadCount(result.data.itemCount);
              setUnreadLinks(utils.parseLinkHeaders(result.headers.link));
              setUnreadPageNumber(1);
              setUnreadTotalNrPages(result.data.totalNrPages);
              setUnreadMessages(unread);

              var readUrl = BACKEND_URL + "/notifications/read";

              authService.getSecure(readUrl)
                .then((result) => {
                  var read = result.data.messages.map(n => {
                    return NotificationBuilder.buildNotification(n);
                  });

                  read.sort(function(a, b) {
                    return b.date - a.date;
                  });

                  setReadMessages(read);
                }).catch((e) => {
                  utils.handleUnauthorized(() => navigate("/login"));
                });
            }).catch((e) => {
              utils.handleUnauthorized(() => navigate("/login"));
            });
        }).catch((e) => {
          utils.handleUnauthorized(() => navigate("/login"));
        });
      }
  };

  useEffect(() => {
    if (!readMessages) {
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => loadData());
  
      loadData();
    }
  }, null);

  if (!unreadMessages || !readMessages || !chats) {
    return <Loading/>;
  }
  
  function goToInboxPage(url, unread) {
    authService.getSecure(url)
      .then((result) => {
        var headers = utils.parseLinkHeaders(result.headers.link);
        
        var messages = result.data.messages.map(n => {
          return NotificationBuilder.buildNotification(n);
        });

        messages.sort(function(a, b) {
          return b.date - a.date;
        });

        if (unread) {
          setUnreadPageNumber(result.data.pageNr);
          setUnreadMessages(messages);
          setUnreadLinks(headers);
        } else {
          setReadMessages(messages);
        }
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  function unreadFirst() {
    if (unreadLinks.first && unreadPageNumber > 1) {
      goToInboxPage(unreadLinks.first, true);
    }
  }

  function unreadPrevious() {
    if (unreadLinks.prev && unreadPageNumber > 1) {
      goToInboxPage(unreadLinks.prev, true);
    }
  }

  function unreadNext() {
    if (unreadLinks.next && unreadPageNumber < unreadTotalNrPages) {
      goToInboxPage(unreadLinks.next, true);
    }
  }

  function unreadLast() {
    if (unreadLinks.last) {
      goToInboxPage(unreadLinks.last, true);
    }
  }

  function goToChat(row) {
    var idx = -1;
    idx = chats.findIndex(m => m.id == row.id);

    if (idx != -1) {
      var url = BACKEND_URL + "/chats/" + chats[idx].id;

      // Marks the chat as read
      authService.postSecure(url, {})
        .then((result) => {
          var title = "Chat met ";

          utils.getLoggedInAccountId().then((accountId) => {
            if (accountId == row.fromAccountId) {
              title += row.toFirstName;
            } else if (accountId == row.toAccountId) {
              title += row.fromFirstName;
            }
  
            setChats([]);
            
            setTimeout(() => {
              setChats(chats.splice(idx, 1));
            });
  
            Utils.mobileNavigator.pushPage({
              page: <ChatPanelMessages 
                fromAccountId={row.fromAccountId} 
                toAccountId={row.toAccountId}/>,
              renderToolbar: () => {
                return <DefaultMobileToolbar text={title} backButton={true}/>
              }
            }, { animation: "none" });
  
            setTimeout(() => {
              EventBus.getInstance()
                .dispatch<void>(EventBus.ON_CHAT_OPEN);
            }, 500);
          });
        });
    }
  }

  function goToMessage(row, unread) {
    var idx = -1;
    var msgId = null;

    if (unread) {
      idx = unreadMessages.findIndex(m => m.id == row.id);

      if (idx != -1) {
        msgId = unreadMessages[idx].id;
        var url = BACKEND_URL + "/notifications/" + msgId;

        authService.postSecure(url, {})
          .then((_) => {
            loadData();

            Utils.mobileNavigator.pushPage({
              page: <MobileNotification notification={row}/>,
              renderToolbar: () => {
                return <DefaultMobileToolbar text="Bericht" backButton={true}/>
              }
            }, { animation: "none" });
          });
      }
    } else {
      idx = readMessages.findIndex(m => m.id == row.id);

      if (idx != -1) {
        msgId = readMessages[idx].id;

        Utils.mobileNavigator.pushPage({
          page: <MobileNotification notification={row}/>,
          renderToolbar: () => {
            return <DefaultMobileToolbar text="Bericht" backButton={true}/>
          }
        }, { animation: "none" });
      }
    }
  }

  function getLogo(notificationType, unread) {
    const getImg = (filename, position) => {
      return (<img
        className={`z-20 inline-block ${position}`}
        src={filename}
        width="5"
        height="5"
        alt="Grop logo" />);
    };

    var icon = null;
    var pos = null;

    switch (notificationType) {
      case NotificationType.NEW_BID_ITEM:
        icon = unread ? "/gropp_hand.png" : "/gropp_hand_read.png";
        pos = "h-[12px] w-[12px] mt-[-4px]";
        break;
      case NotificationType.NEW_BID_FAVORITE_ITEM:
      case NotificationType.FAVORITE_ACCOUNT_PLACED_ITEM:
        icon = unread ? "/gropp_hand.png" : "/gropp_hand_read.png";
        pos = "h-[10px] w-[10px] mt-[-4px]";
        break;
      case NotificationType.TRANSACTION_FAVORITE_ITEM:
        icon = unread ? "/gropp_handshake.png" : "/gropp_handshake_read.png";
        pos = "h-[12px] w-[18px] mt-[-2px]";
        break;
      case NotificationType.SOLD_ITEM_SHIPPED:
      case NotificationType.BOUGHT_ITEM_SHIPPED:
        return (<Ons.Icon icon="md-local-shipping" className={`tabbar-icons ${ unread ? "unread-notification" : "read-notification" } ons-icon`}/>);
      case NotificationType.NEW_CHAT_MESSAGE:
        return (<Ons.Icon icon="md-comment-text" className={`tabbar-icons ${ unread ? "unread-notification" : "read-notification" } ons-icon`}/>);
      case NotificationType.ADVERTISEMENT:
        return (<Ons.Icon icon="md-brightness-auto" className={`tabbar-icons ${ unread ? "unread-notification" : "read-notification" } ons-icon`}/>);
      case NotificationType.SYSTEM_NOTIFICATION:
        return (<Ons.Icon icon="md-laptop" className={`tabbar-icons ${ unread ? "unread-notification" : "read-notification" } ons-icon`}/>);
      default:
        return (<></>);
    }

    return getImg(icon, pos);
  }

  function getMessageTitle() {
    
  }

  function getMessageBody() {

  }

  function renderChatHeader() {
    var headerText = "";
    
    if (chats.length == 0) {
      headerText = "Geen nieuwe berichten";
    } else if (chats.length == 1) {
      headerText = "1 nieuw bericht";
    } else {
      headerText = chats.length + " nieuwe berichten";
    }

    return (
      <div>
        <Ons.ListHeader className={`${utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">
            { headerText }
          </div>
        </Ons.ListHeader>

        <div className="mt-2"></div>

        { 
          chats.length == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen ongelezen chatberichten.</div>
            </div>
            :
            <div>
              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  };

  function renderReadHeader() {
    var headerText = "Laatste gelezen meldingen";

    return (
      <div>
        <Ons.ListHeader className={`${utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">
            { headerText }
          </div>
        </Ons.ListHeader>

        <div className="mt-2"></div>

        { 
          readMessages.length == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-4 w-full redhat-display">Geen ongelezen meldingen.</div>
            </div>
            :
            <div>
              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  };

  function getNewMessageHeader(row, unread) {
    var shortenedBody: string = row.body;

    if (shortenedBody.length > 15) {
      shortenedBody = shortenedBody.substring(0, 10);
      shortenedBody += "...";
    }

    if (unread) {
      return "Nieuw bericht: " + shortenedBody;
    } else {
      return shortenedBody;
    }
  }

  function renderUnreadHeader() {
    var headerText = "";

    if (totalUnreadCount == 0) {
      headerText = "ongelezen meldingen";
    } else if (totalUnreadCount == 1) {
      headerText = "1 ongelezen melding";
    } else {
      headerText = totalUnreadCount + " ongelezen meldingen";
    }
  
    return (
      <div>
        <Ons.ListHeader className={`${utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">
            { headerText }
          </div>
        </Ons.ListHeader>

        <div className="mt-2"></div>

        { 
          totalUnreadCount == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-4 w-full redhat-display">Geen ongelezen meldingen.</div>
            </div>
            :
            <div>
              <MobilePaginator
                pageNumber={unreadPageNumber}
                pageSize={DEFAULT_PAGE_SIZE}
                totalNrPages={unreadTotalNrPages}
                first={unreadFirst}
                previous={unreadPrevious}
                next={unreadNext}
                last={unreadLast}
                isTop={true}/>

              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  };

  function getInbox() {
    const renderChatRow = (row, index) => {
      return (
        <div key={row.id}>
          <Ons.ListItem modifier="nodivider">
            <div className="flex flex-row grow body-style p-0" 
                onClick={() => goToChat(row) }>
              {
                row.text
              }

              <div className="flex flex-row p-0">
                <Ons.Icon icon="md-comment-alt-text" className="tabbar-icons read-notification m-4 ml-2" />

                <div className="grid grid-row-2 p-0">
                  <div className="text-sm p-0">
                    Nieuw bericht van { row.toFirstName }
                  </div>

                  <div className="text-sm p-0">
                    { moment(row.createTime, DATE_TIME_FORMAT).format(FRIENDLY_DATE_TIME_FORMAT) }
                  </div>
                </div>
              </div>
            </div>
          </Ons.ListItem>

          <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
        </div>
      );
    };

    const renderInboxRow = (row, index, unread) => {
      return (
        <div key={row.id}>
          <Ons.ListItem modifier="nodivider">
            <div className="flex flex-row grow body-style p-0" 
                onClick={() => goToMessage(row, unread) }>
              {
                row.text
              }

              <div className="flex flex-row p-0">
                { row.read ?
                    <Ons.Icon icon="md-email-open" className="tabbar-icons read-notification m-4 ml-2" />
                  : <Ons.Icon icon="md-email" className="tabbar-icons unread-notification m-4 ml-2" /> }

                <div className="grid grid-row-2 p-0">
                  <div className={`text-sm p-0 ${row.read ? "" : "font-bold"}`}>
                    { getNewMessageHeader(row, unread)} &nbsp;&nbsp;{getLogo(row.notificationType, unread) }
                  </div>

                  <div className="text-sm p-0">
                    { moment(row.createTime, DATE_TIME_FORMAT).format(FRIENDLY_DATE_TIME_FORMAT) }
                  </div>
                </div>
              </div>
            </div>
          </Ons.ListItem>

          <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
        </div>
      );
    };

    return (
      <div>
        {
          showSpinner ?
            <div key={"spinner"} className="justify-center mt-[100px]">
              <Spinner/>
            </div>
            :
            <div>
              <div className="w-full grid grid-cols-2 justify-center text-center redhat-display text-sm inbox-selector">
                <div className={ selectedTab == 0 ? "selected-inbox-tab" : ""}
                    onClick={() => setSelectedTab(0)}>
                  <div className="mt-1 mb-1">Berichten</div>
                </div>
                
                <div className={ selectedTab == 1 ? "selected-inbox-tab" : ""}
                    onClick={() => setSelectedTab(1)}>
                  <div className="mt-1 mb-1">Meldingen</div>
                </div>
              </div>

              {
                selectedTab == 0 ? 
                  <Ons.List
                    className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
                    dataSource={chats}
                    renderRow={(row, index) => renderChatRow(row, index)}
                    renderHeader={() => renderChatHeader()} />
                :
                <>
                  <Ons.List
                    className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
                    dataSource={unreadMessages}
                    renderRow={(row, index) => renderInboxRow(row, index, true)}
                    renderHeader={() => renderUnreadHeader()} />

                  <Ons.List
                    className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
                    dataSource={readMessages}
                    renderRow={(row, index) => renderInboxRow(row, index, false)}
                    renderHeader={() => renderReadHeader()} />  
                </>
              }
              
              <div className="mb-[80px]"></div>
            </div>
        }
      </div>);
  }

  if (!readMessages || !unreadMessages || !chats) {
    return <Loading/>;
  }

  return getInbox();
}
