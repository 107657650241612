import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BidBuilder from "../../../domain/bid/bid.builder";
import { bidStateToString } from "../../../domain/bid/bid.state";
import BidType, { bidTypeToString } from "../../../domain/bid/bid.type";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";

export default function BidPage({ bidId }: { bidId: string }) {
  const navigate = useNavigate();

  const [bid, setBid] = useState<any>(null);

  const loadData = () => {
    var url = BACKEND_URL + "/bids/" + bidId;

    authService.getSecure(url)
      .then((result) => {
        var b = BidBuilder.buildBid(result.data);
        setBid(b);
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      })
  }

  useEffect(() => {
    if (!bid) {
      loadData();
    }
  }, null);

  function goToBid() {
    if (!bid) {
      return;
    }

    navigate("/gropp/bids/" + bid.id);
  }

  function getOwnerLink() {
    if (!bid) {
      return "#";
    }

    return "/gropp/accounts/" + bid.ownerAccountId;
  }

  function getBidderLink() {
    if (!bid) {
      return "#";
    }

    return "/gropp/accounts/" + bid.bidAccountId;
  }

  function getOfferLink() {
    if (!bid) {
      return "#";
    }

    return "/gropp/offers/" + bid.offerId;
  }

  function getAmount(): string {
    if (!bid) {
      return "";
    }

    return utils.roundedAmount(bid.amount, 2);
  }

  if (!bid) {
    return <></>;
  }

  return (
    <>
      <div className="flex flex-row mx-auto justify-center align-middle w-full text-center">
        <img 
          className="mr-4 ml-0 mt-0 rounded-lg item-image-bid-info mx-auto"
          src={`${BACKEND_URL}/static/img/preview/${bid.itemId}`}
          alt="Item image"
          width={150}
          height={150}
          />
      </div>
      
      <div className="flex flex-row mx-auto justify-center align-middle w-full text-center">
        <img 
          className="mr-4 ml-0 mt-0 avatar-img-bid-page rounded-full h-24 w-24 bg-gray-50 p-2 z-30 mx-auto"
          src={`${BACKEND_URL}/static/img/account/${bid.bidAccountId}`}
          alt="Bidder avatar"
          width={20}
          height={20}/>
      </div>

      <div className="mb-10 mx-auto justify-center mt-[-50px]">
        <div className="grid grid-cols-2 gap-4 justify-center">
          <div className="text-right font-bold ">Type:</div>
          <div className="">{bidTypeToString(bid.bidType)}</div>

          <div className="text-right font-bold ">Status:</div>
          <div className="">{bidStateToString(bid.bidState)}</div>

          <div className="text-right font-bold ">Item:</div>
          <div className="">
            <Link to={getOfferLink()}>bekijk item</Link>
          </div>

          <div className="text-right font-bold ">Eigenaar:</div>
          <div className="">
            <Link to={getOwnerLink()}>bekijk eigenaar</Link>
          </div>

          <div className="text-right font-bold ">Bieder:</div>
          <div className="">
            <Link to={getBidderLink()}>bekijk bieder</Link>
          </div>

          <div className="text-right font-bold ">Datum:</div>
          <div className="">{utils.renderFriendlyDateTime(bid.createTime)}</div>

          {
            bid.bidType == BidType.PURCHASE_BID ?
              <></>
              : <>
                <div className="text-right font-bold ">Hoogste bod:</div>
                <div className="">{bid.isHighestBid ? "ja" : "nee" }</div>
              </>
          }
          
          {
            bid.bidType == BidType.PURCHASE_BID ?
              <></>
              : <>
                <div className="text-right font-bold ">Aantal biedingen:</div>
                <div className="">{bid.nrBids}</div>
              </>
          }

          <div className="text-right font-bold mt-auto mb-auto">Bedrag:</div>
          <div className="offer-price text-2xl">{ getAmount() }</div>
        </div>
      </div>
    </>
  );
}