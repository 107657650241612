enum ShippingFunctionality {
  AGE_CHECK_18 = "AGE_CHECK_18",
  SIGNATURE = "SIGNATURE"
}

export function shippingFunctionalityFromString(f: string): ShippingFunctionality {
  switch (f) {
    case "AGE_CHECK_18": 
      return ShippingFunctionality.AGE_CHECK_18;
    case "SIGNATURE": 
      return ShippingFunctionality.SIGNATURE;
    default:
      return null;
  }
}

export function shippingFunctionalityToString(f: ShippingFunctionality): string {
  switch (f) {
    case ShippingFunctionality.AGE_CHECK_18:
      return "Leeftijdscontrole 18+";
    case ShippingFunctionality.SIGNATURE:
      return "Handtekening";
  }
}

export default ShippingFunctionality;