import { DEFAULT_PAGE_NUMBER } from "../../domain/constants";
import SearchResults from "../../domain/search/search.results";
import SearchResult from "./search.result";
import { Select, Option } from "@mui/joy";
import { Link } from "react-router-dom";

export function PageOverview(
    { 
      data, 
      baseLink,
      pageSize, 
      handleItemsPerPageChange,
      firstPage, 
      previousPage, 
      nextPage, 
      lastPage
    }: 
    { 
      data: SearchResults,
      baseLink: string,
      pageSize: string,
      handleItemsPerPageChange: (event: React.SyntheticEvent | null, newValue: string | null) => void,
      firstPage: () => void,
      previousPage: () => void,
      nextPage: () => void,
      lastPage: () => void
    }) {
  function getFirstLink() {
    return baseLink + "?pageNr=1&pageSize=" + data.pageSize;
  }

  function getPreviousLink() {
    return baseLink + "?" + 
      "pageNr=" + (data.pageNumber == DEFAULT_PAGE_NUMBER ? "" + DEFAULT_PAGE_NUMBER : (data.pageNumber as number - 1)) + 
      "&pageSize=" + data.pageSize;
  }

  function getNextLink() {
    return baseLink + "?" + 
      "pageNr=" + ((data.pageNumber as number) + 1)  + 
      "&pageSize=" + data.pageSize;
  }

  function getLastLink() {
    return baseLink + "?" + 
      "pageNr=" + data.totalNrPages +
      "&pageSize=" + data.pageSize;
  }

  return (
    <>
      <div className="mb-4">
        <div className="flex flex-row justify-center mt-2">
          <div className="mr-4">{ 
            data.pageNumber > 1 ?
            <Link to={getFirstLink()} onClick={() => firstPage()}>eerste</Link> : "eerste" }
          </div>

          <div className="mr-4">{ 
            data.pageNumber > 1 ? 
            <Link to={getPreviousLink()} onClick={() => previousPage()}>vorige</Link> : "vorige" }
          </div>

          <div>
            <span className="font-bold">Pagina:</span>
            &nbsp;{data.pageNumber}&nbsp;/&nbsp;{data.totalNrPages}
          </div>

          <div className="ml-4">{ 
            data.pageNumber < data.totalNrPages ?
            <Link to={getNextLink()} onClick={() => nextPage()}>volgende</Link> : "volgende" }</div>

          <div className="ml-4">{ 
            data.pageNumber < data.totalNrPages ?
            <Link to={getLastLink()} onClick={() => lastPage()}>laatste</Link> : "laatste" }</div>
        </div>

        <div className="flex flex-row justify-center mt-4 mb-[-40px]">
          <div className="mr-16">
            <span className="font-bold mr-4">Totaal:</span>
            &nbsp;{data.itemCount} items gevonden
          </div>

          <div className="ml-4">
            <div className="flex flex-row">
              <div className="mr-2">Items per pagina:</div>
              <Select 
                id="search_sort" 
                defaultValue="10"
                className="w-[100px] mr-2 mt-[-5px]"
                value={pageSize}
                onChange={handleItemsPerPageChange}>
                <Option value="10">10</Option>
                <Option value="20">20</Option>
                <Option value="50">50</Option>
                <Option value="100">100</Option>
                <Option value="all">Alles</Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </>);
}

export default function SearchResultsPage(
    { 
      data, 
      pageNumber, 
      pageSize,
      firstPage,
      previousPage,
      nextPage,
      lastPage,
      handleItemsPerPageChange
    }: { 
      data: SearchResults,
      pageNumber: number,
      pageSize: string,
      firstPage: () => void,
      previousPage: () => void,
      nextPage: () => void,
      lastPage: () => void,
      handleItemsPerPageChange: (event: React.SyntheticEvent | null, newValue: string | null) => void
    }) {
  return (
    <>
      { 
        data ? (
          <>
            <div className="grid grid-cols-1 text-center">
              <PageOverview 
                data={data} 
                baseLink={"/gropp/offers"}
                pageSize={pageSize}
                handleItemsPerPageChange={handleItemsPerPageChange}
                firstPage={firstPage}
                previousPage={previousPage}
                nextPage={nextPage}
                lastPage={lastPage}/>

              <div className="grid grid-cols-1 w-full mr-10 mt-10">
                {(data as any).data.map((x, i) => (
                  <div key={x.id}>
                    <div className={`grow w-full mb-4 mt-8 ${ (x as any).offerType == "AUCTION" ? "h-[280px]" : "h-[240px]"}`} key={i}>
                      <SearchResult item={x}/>
                    </div>
                    <div className="w-full h-[1px] border-solid search-line"></div>
                  </div>
                ))}
              </div>

              <PageOverview 
                data={data} 
                baseLink={"/gropp/offers"}
                pageSize={pageSize}
                handleItemsPerPageChange={handleItemsPerPageChange}
                firstPage={firstPage}
                previousPage={previousPage}
                nextPage={nextPage}
                lastPage={lastPage}/>
              <div className="mb-20"></div>
            </div>
          </>) 
        : <></>
      }
  </>);
}