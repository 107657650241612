import * as Ons from "react-onsenui";
import { Utils } from "../../../services/utils";
import { utils } from "../../../services";

export default function MobileCreatedAccount() {
  function goToLoginScreen() {
    Utils.mobileNavigator.pushPage({
      name: "login/" + utils.makeId(5)
    }, { animation: "none" });
  }

  return (
    <>
      <div className="w-full text-center">
        <div className="text-xl mx-auto mt-8 mb-8 redhat-display">Uw account is aangemaakt.</div>

        <p className="redhat-display text-sm m-4">Welkom bij Gropp.nl!</p>
        <p className="redhat-display text-sm m-4">U heeft een validatie-email toegestuurd gekregen op uw opgegeven emailadres. U moet eerst op de link klikken in deze email voordat u kunt inloggen.</p>

        <div className="text-center mt-8 mb-20">
          <Ons.Button onClick={() => {goToLoginScreen()}} id="btnele" className="button min-w-[110px] pt-2 pb-2 mr-4 mt-8" type="submit">
            <span className="redhat-display text-sm">Inloggen</span>
          </Ons.Button>
        </div>
      </div>
  </>);
}