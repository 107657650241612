import { utils } from "../../../../services";
import { Utils } from "../../../../services/utils";

export default function PasswordReset() {
  function goToLogin() {
    Utils.mobileNavigator.pushPage({
      name: "login/" + utils.makeId(5)
    }, { animation: "none" });
  }

  return (
    <>
      <div className="justify-center text-center text-sm redhat-display m-8">
        Als je account bij ons bekend is ontvang je een email om je wachtwoord te herstellen.
      </div>

      <div className="text-center mt-8 mb-20">
        <button 
            className="button min-w-[100px] max-h-[40px] mb-4 link-color" 
            type="button"
            onClick={() => goToLogin()}>
          <span className="text-sm redhat-display">Inloggen</span>
        </button>
      </div>
    </>);
}