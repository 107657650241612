import AbstractEntity from "../abstract.entity";
import BidState from "./bid.state";
import BidType from "./bid.type";

abstract class Bid extends AbstractEntity {
	offerId: string;
  itemId: string;
	bidAccountId: string;
  ownerAccountId: string;
  bidState: BidState;
  bidType: BidType;
  isHighestBid: boolean;
  nrBids: number;

	constructor($id: string,
              $createTime: Date,
              $offerId: string,
              $itemId: string,
              $bidAccountId: string,
              $ownerAccountId: string,
              $bidState: BidState,
              $bidType: BidType,
              $isHighestBid: boolean,
              $nrBids: number) {
		super($id, $createTime);

		this.offerId = $offerId;
    this.itemId = $itemId;
		this.bidAccountId = $bidAccountId;
    this.ownerAccountId = $ownerAccountId;
    this.bidState = $bidState;
    this.bidType = $bidType;
    this.isHighestBid = $isHighestBid;
    this.nrBids = $nrBids;
	}
}

export default Bid;