import * as Ons from "react-onsenui";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { useEffect, useState } from "react";
import ShippingMethod, { shippingMethodToString } from "../../domain/shipping/shipping.method";
import ServicePointMap from "./service.point.map";
import Carrier from "../../domain/shipping/carrier";
import Checkbox from "../../components/checkbox";
import { BACKEND_URL } from "../../domain/env";
import { authService, utils } from "../../services";
import ShippingServiceDetailsBuilder from "../../domain/shipping/shipping.service.details.builder";

export default function ShippingConfiguration({ tradeId, isBuyer }) {
  const [shippingMethod, setShippingMethod] = useState<ShippingMethod>(ShippingMethod.SERVICE_POINT);
  const [servicePointDetails, setServicePointDetails] = useState<any>(null);
  const [shippingServiceDetailsList, setShippingServiceDetailsList] = useState<any[]>(null);
  const [shippingServiceDetailsIdx, setShippingServiceDetailsIdx] = useState<number>(-1);
  const [showAddNewDeliveryAddress, setShowAddNewDeliveryAddress] = useState<any>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<any>(null);
  const [carrier, setCarrier] = useState<Carrier>(Carrier.POSTNL);
  const [weight, setWeight] = useState<number>(-1);
  const [ageCheck18, setAgeCheck18] = useState<boolean>(false);
  const [signature, setSignature] = useState<boolean>(false);
  const [showServicePointMap, setShowServicePointMap] = useState<boolean>(false);
  const [showLabelCreated, setShowLabelCreated] = useState<boolean>(false);

  var timeoutId = null;

  function goToChat() {

  }

  useEffect(() => {
    if (!deliveryAddress) {
      fetchTrade();
    }
  }, null);

  function fetchTrade() {
    var url = BACKEND_URL + "/trades/" + tradeId;

    authService.getSecure(url).then((result) => {
      fetchDeliveryAddress(result.data.bidAccountId);
    })
  }

  function fetchDeliveryAddress(accountId) {
    var url = BACKEND_URL + "/accounts/delivery-address/" 
      + accountId + "?tradeId=" + tradeId;

    authService.getSecure(url).then((result) => {
      setDeliveryAddress(result.data);
    });
  }

  function changeDeliveryAddress() {

    setShowAddNewDeliveryAddress(true);
  }

  function addDeliveryAddress() {
    setShowAddNewDeliveryAddress(true);
  }

  function saveDeliveryAddress() {
    setShowAddNewDeliveryAddress(false);
  }

  function changeCarrier(newCarrier) {
    setCarrier(newCarrier);
  }

  function updateWeight(e) {
    var newWeight = parseInt(e.target.value);

    if (!isNaN(newWeight)) {
      setWeight(newWeight);
    } else {
      setWeight(-1);
    }
  }

  function showDeliveryOptions() {
    var url = BACKEND_URL + "/shipping/service-details?" + 
      "carriers=" + carrier.toLowerCase();

    if (weight != -1) {
      url += "&weight=" + weight;
    }

    if (ageCheck18) {
      url += "&ageCheck18=true";
    }

    authService.getSecure(url).then((result) => {
      var details = result.data.map(r => {
        return ShippingServiceDetailsBuilder.buildShippingServiceDetails(r);
      });

      setShippingServiceDetailsList(details);
    });
  }

  function changeAgeCheck18() {
    setAgeCheck18(!ageCheck18);
  }

  function changeSignature() {
    setSignature(!signature);
  }

  function createLabel() {
    if (shippingServiceDetailsList.length == 0) {
      return;
    }
    
    var details = shippingServiceDetailsList[shippingServiceDetailsIdx];

    if (shippingMethod == ShippingMethod.HOME_PICKUP) {
      
    } else if (shippingMethod == ShippingMethod.SERVICE_POINT) {
      var body = {
        "shippingMethod": shippingMethod,
        "deliveryAddress": deliveryAddress,
        "carrier": carrier,
        "shippingServiceDetailsId": details.id,
        "weight": weight,
        "tradeId": tradeId
      }
      
      var url = BACKEND_URL + "/shipping";
      
      authService.postSecure(url, body).then((result) => {
        setShowLabelCreated(true);
      });
    }
  }

  const renderShippingServiceDetailsHeader = (nrItems, text) => {
    return (
      <div>
        <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">{text}</div>
        </Ons.ListHeader>

        { 
          nrItems == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen { text }.</div>
            </div>
            :
            <div>
              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  }

  const renderShippingServiceDetailsRow = (row, index) => {
    return (
      <div className="redhat-display mt-2">
        <div className="flex flex-row">
          <div className="mt-auto mb-auto">
            <input 
              type="radio" 
              id={"radio-DETAILS-" + index}
              className="pr-3 body-style redhat-display relative top-[4px]"
              checked={shippingServiceDetailsIdx == index}
              onClick={() => setShippingServiceDetailsIdx(index)}/>
          </div>

          <div className="flex flex-col grow" 
              onClick={() => setShippingServiceDetailsIdx(index)}>
            <div className="flex flex-row">
              <img 
                className="rounded-lg m-2 max-w-[35px] max-h-[35px] mt-auto mb-auto"
                src={ row.carrier.toLowerCase() + "_logo.png" }
                alt="carrier logo"
                width={35}
                height={35}/>

              <div className="flex flex-col">
                <div className="break-keep text-sm font-bold">{ row.name }</div>
                <div className="font-bold bid-amount">&#x20AC;{ row.price }</div>
              </div>
            </div>
            <div className="text-sm">
              <Ons.Icon icon="fa-weight-hanging" className="service-details-icons m-1"/>
              { parseInt("" + (row.minWeight - 1) / 1000.0) }kg - { parseInt("" + (row.maxWeight - 1) / 1000.0) }kg
            </div>
            <div className="text-sm">
              <Ons.Icon icon="fa-ruler" className="service-details-icons m-1 mb-2"/>
              { row.maxLength }cm x { row.maxWidth }cm x { row.maxHeight }cm (LxBxH)
            </div>
            {
              row.ageCheck18 ? 
              <>age check 18</>
              : <></>
            }
            {
              row.signature ? 
              <>signature</>
              : <></>
            }
          </div>
        </div>

        <div className="w-full h-[1px] ml-[10px] mt-[-2px] border-solid search-line mb-2"></div>
      </div>);
  }

  return (
    <div className="ml-2 mr-2">
      <div id="listitem-DO_IT_YOURSELF" key="listitem-DO_IT_YOURSELF" 
          onClick={() => {
            setShippingMethod(ShippingMethod.DO_IT_YOURSELF);
          }} className="mb-[10px] mt-2 ml-2">
        <div className="w-full">
          <label className="left">
            <input 
              type="radio" 
              id="input-DO_IT_YOURSELF"
              className="pr-3 body-style redhat-display relative top-[4px]"
              checked={shippingMethod == ShippingMethod.DO_IT_YOURSELF}/>
            <label htmlFor="input-DO_IT_YOURSELF"
              className="center redhat-display mt-2">Zelf regelen</label>
          </label>
        </div>
      </div>

      <div key="listitem-SERVICE_POINT"
          onClick={() => {
            setShippingMethod(ShippingMethod.SERVICE_POINT)
          }} className="mb-[10px] ml-2">
        <div>
          <label className="left">
            <input 
              type="radio" 
              id="input-SERVICE_POINT"
              className="pr-3 body-style redhat-display relative top-[4px]"
              checked={shippingMethod == ShippingMethod.SERVICE_POINT}
              value="SERVICE_POINT"/>
            
            <label htmlFor="input-SERVICE_POINT"
              className="center redhat-display mt-2">Wegbrengen naar servicepunt</label>
          </label>
        </div>
      </div>
      

      <div key="listitem-HOME_PICKUP"
          onClick={() => {
            setShippingMethod(ShippingMethod.HOME_PICKUP)
          }} className="mb-[10px] ml-2">
        <div>
          <label className="left">  
            <input type="radio"
              id="input-HOME_PICKUP"
              className="pr-3 body-style redhat-display relative top-[4px]"
              checked={shippingMethod == ShippingMethod.HOME_PICKUP}
              value="HOME_PICKUP"/>
            <label 
              htmlFor="input-HOME_PICKUP"
              className="center redhat-display mt-2">Thuis laten ophalen</label>
          </label>
        </div>
      </div>

      {
        shippingMethod == ShippingMethod.DO_IT_YOURSELF ? 
          <div className="ml-2 redhat-display">
            <Ons.Button className="link-color mb-4 mt-4 top-[8px]" 
                onClick={() => goToChat()}>
              <span className="text-sm redhat-display">Chatten&nbsp;met&nbsp;verkoper</span>
            </Ons.Button>
          </div>
          : <></>
      }

      {
        shippingMethod == ShippingMethod.SERVICE_POINT ? 
          <>
            <div className="ml-2 redhat-display">
              <div className="mb-2">Bezorgadres:</div>

              <div className="ml-2 redhat-display">
                <div className="">
                  { 
                    deliveryAddress ? 
                      <>
                        {
                          !showAddNewDeliveryAddress ? 
                            <>
                              <div className="mt-2 mb-4">
                                <div>{ deliveryAddress.firstName } { deliveryAddress.lastName }</div>
                                <div>{ deliveryAddress.street }</div>
                                <div>{ deliveryAddress.postalCode} { deliveryAddress.city }</div>
                              </div>

                              <Ons.Button className="link-color mt-4 mb-8" 
                                onClick={() => changeDeliveryAddress()}>
                                <span className="text-sm redhat-display">Adres&nbsp;wijzigen</span>
                              </Ons.Button>
                            </>
                            : 
                            <></>
                        }
                      </>
                      :
                      <>
                        {
                          !showAddNewDeliveryAddress ? 
                            <Ons.Button className="link-color mt-4 mb-8" 
                                onClick={() => addDeliveryAddress()}>
                              <span className="text-sm redhat-display">Adres&nbsp;toevoegen</span>
                            </Ons.Button>   
                            : <></>
                        }
                      </> 
                  }
                </div>
              </div>

              {
                showAddNewDeliveryAddress ? 
                  <>
                    <div className="mt-4">
                      <div>
                        <div>Voornaam:</div>
                        <div>
                          <input 
                            className="w-full search-field redhat-display ml-2 mr-2" 
                            id="deliveryAddressFirstName" 
                            type="text"
                            value={deliveryAddress && deliveryAddress.firstName ? deliveryAddress.firstName : "" }/>
                        </div>

                        <div>Achternaam:</div>
                        <div>
                          <input 
                            className="w-full search-field redhat-display ml-2 mr-2" 
                            id="deliveryAddressLastName" 
                            type="text"
                            value={deliveryAddress && deliveryAddress.lastName ? deliveryAddress.lastName : "" }/>
                        </div>

                        <div>Straat en huisnummer:</div>
                        <div>
                          <input 
                            className="w-full search-field redhat-display ml-2 mr-2" 
                            id="deliveryAddressStreet" 
                            type="text"
                            value={deliveryAddress && deliveryAddress.street ? deliveryAddress.street : "" }/>
                        </div>

                        <div>Postcode:</div>
                        <div>
                          <input 
                            className="w-full search-field redhat-display ml-2 mr-2" 
                            id="deliveryAddressPostalCode" 
                            type="text"
                            value={deliveryAddress && deliveryAddress.street ? deliveryAddress.street : "" }/>
                        </div>

                        <div>Stad:</div>
                        <div>
                          <input 
                            className="w-full search-field redhat-display ml-2 mr-2" 
                            id="deliveryAddressCity" 
                            type="text"
                            value={deliveryAddress && deliveryAddress.city ? deliveryAddress.city : "" }/>
                        </div>
                      </div>
                    </div>

                    <Ons.Button className="link-color mt-4 mb-8" 
                        onClick={() => saveDeliveryAddress()}>
                      <span className="text-sm redhat-display">Adres&nbsp;opslaan</span>
                    </Ons.Button>
                  </>
                  :
                  <></>
              }
            </div>

            <div className="ml-2 redhat-display">
              <div className="mb-4 mt-4">Bezorgdienst:</div>

              <div className="flex flex-row w-full justify-center gap-6 mb-8">
                <img 
                  className={`rounded-lg ${ carrier == Carrier.POSTNL ? "selected-carrier-border" : "" }`}
                  src="/postnl_logo.png"
                  alt="PostNL"
                  width={70}
                  height={70}
                  onClick={() => changeCarrier(Carrier.POSTNL)}/>

                <img 
                  className={`rounded-lg ${ carrier == Carrier.DHL ? "selected-carrier-border" : "" }`}
                  src="/dhl_logo.png"
                  alt="DHL"
                  width={70}
                  height={70}
                  onClick={() => changeCarrier(Carrier.DHL)}/>
                  
                <img 
                  className={`rounded-lg ${ carrier == Carrier.DPD ? "selected-carrier-border" : "" }`}
                  src="/dpd_logo.png"
                  alt="DPD"
                  width={70}
                  height={70}
                  onClick={() => changeCarrier(Carrier.DPD)}/>
                  
                <img 
                  className={`rounded-lg ${ carrier == Carrier.UPS ? "selected-carrier-border" : "" }`}
                  src="/ups_logo.png"
                  alt="UPS"
                  width={70}
                  height={70}
                  onClick={() => changeCarrier(Carrier.UPS)}/>
              </div>
            </div>

            <div className="ml-2 redhat-display">
              <div className="mb-2">Gewicht (kg, schatting):</div>
              <input 
                className="w-full search-field redhat-display" 
                id="weight" 
                type="text"
                onChange={(e) => updateWeight(e)}/>

              <div className="flex flex-col">
                <div className="flex flex-row">
                  <Checkbox
                    disabled={false}
                    id="ageCheck18"
                    label=""
                    className="mt-4 mb-2"
                    value={ageCheck18}
                    onChange={() => changeAgeCheck18()}/>
                  <label htmlFor="ageCheck18" className="ml-2 mt-[12px] mb-2">Leeftijdscontrole&nbsp;18+</label>
                </div>

                <div className="flex flex-row">
                  <Checkbox
                    disabled={false}
                    id="signature"
                    label=""
                    className="mt-4 mb-8"
                    value={signature}
                    onChange={() => changeSignature()}/>
                  <label htmlFor="signature" className="ml-2 mt-[12px] mb-8">Handtekening&nbsp;voor&nbsp;ontvangst</label>
                </div>
              </div>

              <div className="ml-2 mr-2 w-full text-center mb-4">
                <Ons.Button className="link-color mt-4 mb-8" 
                    onClick={() => showDeliveryOptions()}>
                  <span className="text-sm redhat-display">Opties&nbsp;tonen</span>
                </Ons.Button>
              </div>

              {
                shippingServiceDetailsList && shippingServiceDetailsList.length == 0 ?
                  <div>Geen resultaten gevonden</div>
                  : 
                  <></>
              }
              
              {
                shippingServiceDetailsList && shippingServiceDetailsList.length > 0 ? 
                <>
                  <div className="w-full text-center mb-4 redhat-display">Selecteer je bezorgoptie</div>
                  <div className="max-h-[300px] overflow-y-scroll mb-8">
                    <Ons.List
                      className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
                      dataSource={shippingServiceDetailsList}
                      renderRow={(row, index) => renderShippingServiceDetailsRow(row, index)}
                      renderHeader={() => renderShippingServiceDetailsHeader(shippingServiceDetailsList ? shippingServiceDetailsList.length : "Geen", " bezorgopties")}/>
                  </div>

                  {
                    showServicePointMap ?
                      <>
                        <div className="redhat-display" onClick={() => setShowServicePointMap(false)}>Verberg kaart
                          <Ons.Icon icon="md-chevron-left" class="link-color ml-2 mr-2 relative top-[3px] ons-icon zmdi zmdi-chevron-left"></Ons.Icon>
                        </div>
                        <div className="">
                          <div className="mt-4">Beschikbare&nbsp;servicepunten:</div>
                          <div className="text-xs mb-2">(Klik op een item voor meer informatie)</div>
                            <ServicePointMap 
                              carriers={[ carrier ]}
                              markerClickCallback={(marker, markerElement, infoWindow) => {
                                setServicePointDetails(marker);
                              }}/>
                        </div>
                      </>
                      :
                      <div className="redhat-display" onClick={() => setShowServicePointMap(true) }>Toon servicepunten op kaart
                        <Ons.Icon icon="md-chevron-right" class="link-color ml-2 mr-2 relative top-[3px] ons-icon zmdi zmdi-chevron-right"></Ons.Icon>
                      </div>
                  }

                  <div className="w-full text-center mt-8 mb-8">
                    <Ons.Button className="link-color mt-8 mb-8" 
                      onClick={() => createLabel()}>
                      <span className="text-sm redhat-display">Maak&nbsp;label&nbsp;aan</span>
                    </Ons.Button>
                  </div>
                </>
                :
                <></>
              }
            </div>
          </>
          : <></>
      }
      
      {
        shippingMethod == ShippingMethod.HOME_PICKUP ? 
          <>
            <div className="ml-2 redhat-display">
              <div className="mb-4">Bezorgdienst:</div>

              <div className="flex flex-row w-full justify-center gap-6 mb-8">
                
              <img 
                className={`rounded-lg ${ carrier == Carrier.DHL ? "selected-carrier-border" : "" }`}
                src="/dhl_logo.png"
                alt="DHL"
                width={70}
                height={70}
                onClick={() => setCarrier(Carrier.DHL)}/>
                
              <img 
                className={`rounded-lg ${ carrier == Carrier.UPS ? "selected-carrier-border" : "" }`}
                src="/ups_logo.png"
                alt="UPS"
                width={70}
                height={70}
                onClick={() => setCarrier(Carrier.UPS)}/>
              </div>
            </div>

            <div className="mb-4">Tijdvak:</div>
            
          </>
          : <></>
      }

      <Ons.AlertDialog isOpen={showLabelCreated} cancelable>
        <div className="text-center w-full mt-2 redhat-display">
          <span className="alert-dialog-text font-bold">Label aangemaakt</span>
        </div>
        
        <div className="text-center w-full mt-4 p-4 alert-dialog-text redhat-display text-sm">
          <span className="">Het label is naar uw e-mailadres verstuurd. Print het label uit en plak het op de verzending.</span>
        </div>

        <div className="w-full p-4 gap-2 justify-center flex flex-row">
          <Ons.Button onClick={() => setShowLabelCreated(false)} className="button min-w-[110px]">
            <span className="redhat-display text-sm">Sluiten</span>
          </Ons.Button>
        </div>
      </Ons.AlertDialog>
    </div>);
}