import MarketplaceData from "./marketplace.data";

export default class MarketplaceDataBuilder {
  static buildMarketplaceData(data: any): MarketplaceData {
    var result = new MarketplaceData(
      data.nrAccounts,
      data.nrOffers,
      data.nrBids,
      data.nrTrades,
      data.accounts,
      data.offers,
      data.bids,
      data.trades);

    return result;
  }
}