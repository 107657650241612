import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants";
import BidResponse, { BidPosition } from "./bid.response";
import { bidStateFromString } from "./bid.state";

export class BidResponseBuilder {
  static buildBidResponse(data: any): BidResponse {
    return new BidResponse(
      moment(data.time, DATE_TIME_FORMAT).toDate(),
      data.bids.map((bid) => {
        return new BidPosition(
          moment(bid.time, DATE_TIME_FORMAT).toDate(),
          bid.accountId, 
          bid.firstName,
          bid.amount, 
          bid.position);
      }),
      bidStateFromString(data.bidState),
      data.highestBid);
  }
}
