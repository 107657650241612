abstract class AbstractEntity {
  id: string;
	createTime: Date;
  
	constructor($id: string,
              $createTime: Date) {
		this.id = $id;
		this.createTime = $createTime;
	}
}

export default AbstractEntity;