import * as Ons from "react-onsenui";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../../../domain/constants";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import { TradeBuilder } from "../../../domain/trade/trade.builder";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { Utils } from "../../../services/utils";
import MobilePaginator from "../../../components/mobile.paginator";
import Loading from "../../../components/loading";
import BidBuilder from "../../../domain/bid/bid.builder";
import AccountBuilder from "../../../domain/account/account.builder";
import ItemBuilder from "../../../domain/item/item.builder";
import Trade from "./[tradeId]/page";

export function MobileTrades() {
  const [incomingTrades, setIncomingTrades] = useState<any[]>(null);
  const [outgoingTrades, setOutgoingTrades] = useState<any[]>(null);

  const [incomingTradeLinks, setIncomingTradeLinks] = useState<any>(null);
  const [incomingTradePageNumber, setIncomingTradePageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [incomingTradeTotalNrPages, setIncomingTradeTotalNrPages] = useState<number>(0);

  const [outgoingTradeLinks, setOutgoingTradeLinks] = useState<any>(null);
  const [outgoingTradePageNumber, setOutgoingTradePageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [outgoingTradeTotalNrPages, setOutgoingTradeTotalNrPages] = useState<number>(0);

  const [loggedInImg, setLoggedInImg] = useState<any>(null);
  const [loadedData, setLoadedData] = useState<boolean>(false);

  const loadData = () => {
    utils.getLoggedInAccountId().then((accountId) => {
      var incomingTradesUrl = BACKEND_URL + "/trades/all?incoming=true";

      authService.getSecure(incomingTradesUrl)
        .then(async (tradesResult) => {
          var result = tradesResult.data.data
            .filter(t => {
              return t.offerAccountId == accountId;
            }).map(t => {
              return TradeBuilder.buildTrade(t);
            });
  
          setIncomingTrades(result);
          
          setIncomingTradePageNumber(1);
          setIncomingTradeTotalNrPages(tradesResult.data.totalNrPages);
          setIncomingTradeLinks(utils.parseLinkHeaders(tradesResult.headers.link));
        }).catch((_) => {
          
        });
  
      var outgoingTradesUrl = BACKEND_URL + "/trades/all?incoming=false";
  
      authService.getSecure(outgoingTradesUrl)
        .then(async (tradesResult) => {
          var result = tradesResult.data.data
            .filter(t => {
              return t.bidAccountId == accountId;
            }).map(t => {
              return TradeBuilder.buildTrade(t);
            });
  
          setOutgoingTrades(result);
          
          setOutgoingTradePageNumber(1);
          setOutgoingTradeTotalNrPages(tradesResult.data.totalNrPages);
          setOutgoingTradeLinks(utils.parseLinkHeaders(tradesResult.headers.link));
        }).catch((_) => {
          
        });
    });
  }

  useEffect(() => {
    if (!loadedData) {
      setLoadedData(true);
      loadData();
    }
  }, null);

  function goToTrade(row) {
    Utils.mobileNavigator.pushPage({
      page: <Trade tradeId={row.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Transactie bekijken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function goToIncomingTradePage(url, unread) {
    authService.getSecure(url)
      .then((result) => {
        var headers = utils.parseLinkHeaders(result.headers.link);
        
        var trades = result.data.trades.map(m => {
          return null;
        });

        setIncomingTradePageNumber(result.data.pageNr);
        setIncomingTradeLinks(headers);
      }).catch((e) => {
        
      });
  }

  function goToOutgoingTradePage(url, unread) {
    authService.getSecure(url)
      .then((result) => {
        var headers = utils.parseLinkHeaders(result.headers.link);
        
        var trades = result.data.trades.map(m => {
          return null;
        });

        
        setOutgoingTradePageNumber(result.data.pageNr);
        
        setOutgoingTradeLinks(headers);
      }).catch((e) => {
        
      });
  }
  
  function incomingTradeFirst() {
    if (incomingTradeLinks.first && incomingTradePageNumber > 1) {
      goToIncomingTradePage(incomingTradeLinks.first, true);
    }
  }

  function incomingTradePrevious() {
    if (incomingTradeLinks.prev && incomingTradePageNumber > 1) {
      goToIncomingTradePage(incomingTradeLinks.prev, true);
    }
  }

  function incomingTradeNext() {
    if (incomingTradeLinks.next && incomingTradePageNumber < incomingTradeTotalNrPages) {
      goToIncomingTradePage(incomingTradeLinks.next, true);
    }
  }

  function incomingTradeLast() {
    if (incomingTradeLinks.last) {
      goToIncomingTradePage(incomingTradeLinks.last, true);
    }
  }

  function outgoingTradeFirst() {
    if (outgoingTradeLinks.first && outgoingTradePageNumber > 1) {
      goToOutgoingTradePage(outgoingTradeLinks.first, true);
    }
  }

  function outgoingTradePrevious() {
    if (outgoingTradeLinks.prev && outgoingTradePageNumber > 1) {
      goToOutgoingTradePage(outgoingTradeLinks.prev, true);
    }
  }

  function outgoingTradeNext() {
    if (outgoingTradeLinks.next && outgoingTradePageNumber < outgoingTradeTotalNrPages) {
      goToOutgoingTradePage(outgoingTradeLinks.next, true);
    }
  }

  function outgoingTradeLast() {
    if (outgoingTradeLinks.last) {
      goToOutgoingTradePage(outgoingTradeLinks.last, true);
    }
  }

  if (!incomingTrades || !outgoingTrades) {
    return <Loading/>;
  }

  function renderTradeRow(row, index, incoming) {
    return (
      <div key={row.id}>
        <Ons.ListItem modifier="nodivider" onClick={() => goToTrade(row)}>
          <div className="flex flex-row max-h-[80px]">
            <div>
              <img 
                className="mr-4 ml-0 mt-0 rounded-lg item-image"
                src={`${BACKEND_URL}/static/img/preview/${row.itemId}`}
                alt="Item image"
                width={80}
                height={80}/>
            
              <div className="opacity-80 relative bottom-[20px] rounded-b-lg h-[20px] bid-item-overlay text-white bg-white"></div>

              { 
                incoming ?
                <>
                  <Ons.Icon icon="md-arrow-left" className="relative top-[-40px] left-[4px] link-color font-bold ml-[3px] z-[100]"/>
                  <img 
                    className="relative top-[-55px] left-[22px] z-20" 
                    src="/gropp_handshake.png"
                    width="18" 
                    height="18" 
                    alt="Grop logo"/>
                </>
                  : 
                <>
                  <Ons.Icon icon="md-arrow-right" className="relative top-[-40px] left-[8px] link-color font-bold z-[100]"/>
                  <img 
                    className="relative top-[-55px] left-[22px] z-20" 
                    src="/gropp_handshake.png"
                    width="18" 
                    height="18" 
                    alt="Grop logo"/>
                </>
              }
            </div>

            <div className="flex flex-col">
              <div className="redhat-display text-sm">
                <span className="font-bold">{ row.title }</span>
              </div>

              <div className="redhat-display text-md">
                <span className="font-bold link-color">{ utils.roundedAmount(row.amount, 2) }</span>
              </div>

              <div className="redhat-display">
                <span className="text-sm">{ 
                  incoming ? 
                    "Gekocht van " + row.buyerFirstName :
                    "Verkocht aan " + row.buyerFirstName }</span>
              </div>

              <div className="text-sm p-0 redhat-display">
                { utils.renderPreciseFriendlyDateTime(row.createTime) }
              </div>
                
              <img 
                className="mr-4 ml-0 mt-0 avatar-trade-img2 rounded-full relative h-24 w-24 bg-gray-50 p-2 z-30"
                src={`${BACKEND_URL}/static/img/preview/${row.bidAccountId}`}
                alt="Bid account avatar"
                width={40}
                height={40}/>
            </div>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </div>
    );
  };

  function renderTradeHeader(title, incoming) {
    var trades = incoming ? incomingTrades : outgoingTrades;

    return (
      <div>
        <Ons.ListHeader className={`${utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">
            { title }
          </div>
        </Ons.ListHeader>

        <div className="mt-2"></div>

        { 
          !trades || trades.length == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen transacties.</div>
            </div>
            :
            <div>
              {
                incoming ? 
                  <MobilePaginator
                    pageNumber={incomingTradePageNumber}
                    pageSize={DEFAULT_PAGE_SIZE}
                    totalNrPages={incomingTradeTotalNrPages}
                    first={incomingTradeFirst}
                    previous={incomingTradePrevious}
                    next={incomingTradeNext}
                    last={incomingTradeLast}
                    isTop={true}/>
                :
                  <MobilePaginator
                    pageNumber={outgoingTradePageNumber}
                    pageSize={DEFAULT_PAGE_SIZE}
                    totalNrPages={outgoingTradeTotalNrPages}
                    first={outgoingTradeFirst}
                    previous={outgoingTradePrevious}
                    next={outgoingTradeNext}
                    last={outgoingTradeLast}
                    isTop={true}/>
              }

              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  };

  function getTrades() {
    return (
      <>
        <Ons.List
          className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
          dataSource={incomingTrades}
          renderRow={(row, index) => renderTradeRow(row, index, true)}
          renderHeader={() => renderTradeHeader("Gekocht door mij", true)} />

        <Ons.List
          className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
          dataSource={outgoingTrades}
          renderRow={(row, index) => renderTradeRow(row, index, false)}
          renderHeader={() => renderTradeHeader("Verkocht aan anderen", false)} />
      </>);
  }

  return getTrades();
}