import { Filesystem, Directory, Encoding } from "@capacitor/filesystem";
import { colorSchemeFromString } from "../domain/color.scheme";
import { NotificationSettings, PrivacySettings } from "../domain/account/settings";
import ColorScheme from "../domain/color.scheme";
import { authService, utils } from ".";
import { BACKEND_URL } from "../domain/env";
import { EventBus } from "./event.bus";

export default class Settings {
  public static SETTINGS_FILENAME = "settings.json";
  public static loaded: boolean = false;

  public colorScheme: ColorScheme = ColorScheme.DARK;
  public privacySettings: PrivacySettings = PrivacySettings.DEFAULT;
  public notificationSettings: NotificationSettings = NotificationSettings.DEFAULT;
  
  private stylePath = "";

  public save() {
    this.writeSettingsFile();
  }

  public load() {
    if (!Settings.loaded) {
      this.readSettingsFile();

      var url = BACKEND_URL + "/accounts/settings";

      authService.getSecure(url)
        .then((result) => {
          if (result && result.data) {
            this.privacySettings = PrivacySettings.build(
              result.data["privacySettings"]);

            this.notificationSettings = NotificationSettings.build(
              result.data["notificationSettings"]);
          }
        });

      Settings.loaded = true;
    }
  }

  public applySettings(settings: any) {
    var deserialized = JSON.parse(settings);

    this.colorScheme = colorSchemeFromString(
      deserialized["colorScheme"]);
    
    this.updateStyleSheet(this.colorScheme);
  }

  private updateStyleSheet(newColorScheme) {
    if (newColorScheme == ColorScheme.BEIGE) {
      this.removeDarkStyleSheet();
      this.addBeigeStyleSheet();
    } else if (newColorScheme == ColorScheme.DARK) {
      this.removeBeigeStylesheet();
      this.addDarkStyleSheet();
    }
  }
  
  private serializeSettings() {
    var settings = {
      colorScheme: "" + this.colorScheme,
      notificationSettings: this.notificationSettings.serialize()
    };

    return JSON.stringify(settings);
  }

  private async settingsFileExists(): Promise<boolean> {
    try {
      await Filesystem.stat({
        path: Settings.SETTINGS_FILENAME,
        directory: Directory.Documents
      });

      return true;
    } catch (e) {
      return false;
    }
  }

  private async readSettingsFile() {
    var exists = await this.settingsFileExists();

    if (exists) {
      await Filesystem.readFile({
        path: Settings.SETTINGS_FILENAME,
        directory: Directory.Documents,
        encoding: Encoding.UTF8
      }).then((result) => {
        this.applySettings(result.data);
      });
    } else {
      await this.writeSettingsFile()
        .then((_) => {
          this.readSettingsFile();
        });
    }
  }
  
  private async writeSettingsFile() {
    var settings = this.serializeSettings();

    await Filesystem.writeFile({
      path: Settings.SETTINGS_FILENAME,
      data: settings,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    });
  };
  
  private async deleteSettingsFile() {
    await Filesystem.deleteFile({
      path: Settings.SETTINGS_FILENAME,
      directory: Directory.Documents
    });
  };

  private addBeigeStyleSheet() {
    this.addStyleSheet("beige.css");
  }

  private addDarkStyleSheet() {
    if (utils.getPlatform() == "web") {
      this.addStyleSheet("web-dark.css");
    } else {
      this.addStyleSheet("dark.css");
    }
  }

  private addStyleSheet(name) {
    var link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/" + name;

    document.head.appendChild(link);
  }

  private removeDarkStyleSheet() {
    this.removeStyleSheet("web-dark.css");
    this.removeStyleSheet("dark.css");
  }

  private removeBeigeStylesheet() {
    this.removeStyleSheet("beige.css");
  }

  private removeStyleSheet(name) {
    var links = document.head.querySelectorAll("link");    
    var styleLink = null;

    links.forEach(link => {
      if (link.href.endsWith("/" + name)) {
        styleLink = link;
      }
    });

    if (styleLink) {
      document.head.removeChild(styleLink);
    }
  }

  public updateColorScheme(newColorScheme) {
    this.updateStyleSheet(newColorScheme);
    
    this.colorScheme = newColorScheme;

    this.save();
  }
}