import { utils } from "../../../services";
import MobileSettings from "./mobile.settings";

export default function Settings() {
  function renderWeb() {
    return (<></>);
  }

  if (utils.isMobile()) {
    return <MobileSettings/>;
  } else {
    return renderWeb();
  }
}