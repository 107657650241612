import Slider from '@mui/joy/Slider';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Radio from '@mui/joy/Radio';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { ChangeEvent, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Checkbox } from '@mui/joy';
import Map from "../../components/map";
import { Dayjs } from 'dayjs';
import 'dayjs/locale/nl';
import { useFormik } from "formik";
import { DEFAULT_MAP_LOCATION, DEFAULT_MAP_RADIUS, DEFAULT_ZOOMED_IN_MAP_ZOOM, MAX_SEARCH_ITEM_PRICE, START_MAP_ZOOM } from '../../domain/constants';
import ProductType, { ALL_PRODUCT_TYPES, productTypeToString } from '../../domain/item/product.type';
import OfferType, { ALL_OFFER_TYPES, offerTypeToString } from '../../domain/offer/offer.type';
import { authService, mapService, utils } from '../../services';
import SearchType from '../../domain/search/search.type';
import LocationType from '../../domain/search/location.type';
import { SearchValidator } from '../../domain/search/form.validators';
import { SortDirection } from '../../domain/search/sort.direction';
import { BACKEND_URL } from '../../domain/env';

export default function SearchBar({ onClick, searchType, changeSearchTypeCallback }) {
  const [allOfferTypes, setAllOfferTypes] = useState<boolean>(true);
  const [offerTypes, setOfferTypes] = useState<OfferType[]>(ALL_OFFER_TYPES);

  const [allproductTypes, setAllproductTypes] = useState<boolean>(true);
  const [productTypes, setproductTypes] = useState<ProductType[]>(ALL_PRODUCT_TYPES);

  const [locationType, setLocationType] = useState<LocationType>(LocationType.NO_LOCATION);
  const [priceValue, setPriceValue] = useState<number[]>([0, MAX_SEARCH_ITEM_PRICE]);
  const [yearValue, setYearValue] = useState<number[]>([1900, new Date().getFullYear()]);
  const [onlineSince, setOnlineSince] = useState<Dayjs | null>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC);
  const [sortBy, setSortBy] = useState<string>("createTime");

  const [myLocation, setMyLocation] = useState<any>(DEFAULT_MAP_LOCATION);
  const [mapZoom, setMapZoom] = useState<number>(START_MAP_ZOOM);

  const defaultSearchRadius = {
    show: false,
    radius: DEFAULT_MAP_RADIUS,
    center: {
      lat: null,
      lng: null,
    }
  }

  const [searchRadius, setSearchRadius] = useState<any>(defaultSearchRadius);

  const handleSortDirectionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value == "DESC") {
      setSortDirection(SortDirection.DESC);
    } else {
      setSortDirection(SortDirection.ASC);
    }
  };

  const handleLocationTypeChange = (event) => {
    if (event.target.value == "NO_LOCATION") {
      setLocationType(LocationType.NO_LOCATION);
      // setMyLocation(DEFAULT_MAP_LOCATION);
      // setMapZoom(START_MAP_ZOOM);
      // setSearchRadius(defaultSearchRadius);
    } else if (event.target.value == "MY_LOCATION") {
      setLocationType(LocationType.MY_LOCATION);

      // var accountId = utils.getLoggedInAccountId();

      // // Get the currently stored city from the backend account information
      // authService.getSecure(BACKEND_URL + "/accounts/" + accountId)
      //   .then((result) => {
      //     if (result.data && result.data.city) {  
      //       var location = { lat: result.data.latitude, lng: result.data.longitude };
      //       setMyLocation(location);

      //       var newSearchRadius = {
      //         show: true,
      //         radius: searchRadius.radius,
      //         center: {
      //           lat: location.lat,
      //           lng: location.lng
      //         }
      //       };

      //       setSearchRadius(newSearchRadius);
      //       setMapZoom(DEFAULT_ZOOMED_IN_MAP_ZOOM);
      //     }
      //   });
    } else {
      setLocationType(LocationType.SPECIFIC_LOCATION);
    }
  }

  const handleSpecificLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationType(LocationType.SPECIFIC_LOCATION);

    var address = event.target.value;

    mapService.getAddressLocation(address, (location) => {
      setMyLocation(location);

      var newSearchRadius = {
        show: true,
        radius: searchRadius.radius,
        center: {
          lat: location.lat,
          lng: location.lng
        }
      };

      setSearchRadius(newSearchRadius);
      setMapZoom(10);
    });
  }

  const handleYearValueChange = (event: Event, newValue: number | number[]) => {
    setYearValue(newValue as number[]);
  };
  
  const handlePriceValueChange = (event: Event, newValue: number | number[]) => {
    setPriceValue(newValue as number[]);
  };

  const handleDistanceChange = (event: Event, newValue: any) => {
    var newSearchRadius = {
      show: searchRadius.show,
      radius: newValue,
      center: {
        lat: searchRadius.center.lat,
        lng: searchRadius.center.lng
      }
    };

    setSearchRadius(newSearchRadius);
  };

  const handleSortByChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
    setSortBy(newValue);
  };

  function hasAllOfferTypes(offerTypes: OfferType[]): boolean {
    return (
      (offerTypes.findIndex(i => i == OfferType.AUCTION) != -1) &&
      (offerTypes.findIndex(i => i == OfferType.USER_OFFER) != -1) &&
      (offerTypes.findIndex(i => i == OfferType.BUSINESS_OFFER) != -1));
  }

  function handleOfferTypeChange(offerType: OfferType, e: ChangeEvent<HTMLInputElement>) {
    var index = offerTypes.findIndex(t => t == offerType);
    var newOfferTypes = [...offerTypes];

    if (e.target.checked) {
      if (index == -1) {
        newOfferTypes.push(offerType);
      }
    } else {
      if (index != -1) {
        newOfferTypes.splice(index, 1);
      }
    }

    setOfferTypes(newOfferTypes);
    var hasAll = hasAllOfferTypes(newOfferTypes);
    setAllOfferTypes(hasAll);
  }

  function toggleAllOfferTypes() {
    if (allOfferTypes) {
      setAllOfferTypes(false);
      setOfferTypes([]);
    } else {
      setAllOfferTypes(true);
      setOfferTypes(ALL_OFFER_TYPES);
    }
  }

  function hasAllproductTypes(productTypes: ProductType[]): boolean {
    for (var productType of ALL_PRODUCT_TYPES) {
      if (productTypes.findIndex(i => i == productType) == -1) {
        return false;
      }
    }

    return true;
  }

  function handleproductTypeChange(productType: ProductType, e: ChangeEvent<HTMLInputElement>) {
    var index = productTypes.findIndex(t => t == productType);
    var newproductTypes = [...productTypes];

    if (e.target.checked) {
      if (index == -1) {
        newproductTypes.push(productType);
      }
    } else {
      if (index != -1) {
        newproductTypes.splice(index, 1);
      }
    }

    setproductTypes(newproductTypes);
    var hasAll = hasAllproductTypes(newproductTypes);
    setAllproductTypes(hasAll);
  }

  function toggleAllproductTypes() {
    if (allproductTypes) {
      setAllproductTypes(false);
      setproductTypes([]);
    } else {
      setAllproductTypes(true);
      setproductTypes(ALL_PRODUCT_TYPES);
    }
  }

  function search() {
    var title = (document.getElementById("search_name") as any).value;
    var brand = (document.getElementById("search_brand") as any).value;
    var provider = (document.getElementById("search_provider") as any).value;
    var searchSpecificLocation = document.getElementById("search_specific_location") as any;

    var otherLocation = null;
    if (searchSpecificLocation) {
      otherLocation = searchSpecificLocation.value;
    }
    
    var search = {
      title: title ? title : SearchValidator.initialValues.name,
      offerTypes: offerTypes.length > 0 ? offerTypes : SearchValidator.initialValues.offerTypes,
      yearFrom: yearValue && yearValue[0] >= 1900 ? yearValue[0] : SearchValidator.initialValues.minYear,
      yearTo: yearValue && yearValue[1] <= new Date().getFullYear() ? yearValue[1] : SearchValidator.initialValues.maxYear,
      priceFrom: priceValue[0] >= 0 ? priceValue[0] : SearchValidator.initialValues.minPrice,
      priceTo: priceValue[1] > -1 ? priceValue[1] : SearchValidator.initialValues.maxPrice,
      locationType: locationType ? locationType : SearchValidator.initialValues.locationType,
      location: otherLocation ? otherLocation : SearchValidator.initialValues.location,
      distance: searchRadius.radius >= -1 ? searchRadius.radius : SearchValidator.initialValues.distance,
      brand: brand ? brand : SearchValidator.initialValues.brand,
      productTypes: productTypes ? productTypes : SearchValidator.initialValues.productTypes,
      provider: provider ? provider : SearchValidator.initialValues.provider,
      onlineSince: onlineSince ? onlineSince : SearchValidator.initialValues.onlineSince,
      sortBy: sortBy ? sortBy : SearchValidator.initialValues.sortBy,
      sortDirection: sortDirection
    };

    onClick(search);
  }

  const formik = useFormik({
    initialValues: SearchValidator.initialValues,
    validationSchema: SearchValidator.validationSchema,
    onSubmit: (values, { setSubmitting }) => {
    
    }
  });

	return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className={`border-2 bg-slate-100 ${ searchType == SearchType.NORMAL_SEARCH ? "search-border" : "search-border-map"} rounded-lg p-2 min-w-[300px] m-10 min-h-[300px]`}>
            <div>
              <div className="p-2 text-sm flex flex-row w-full justify-center">
                  <div className="pr-4">
                  {
                    searchType == SearchType.NORMAL_SEARCH ?
                      <div className="redhat-display text-sm text-gray-600">Normaal zoeken</div>
                      : <a href="#" onClick={() => changeSearchTypeCallback(SearchType.NORMAL_SEARCH)}>Normaal zoeken</a>
                  }
                </div>
                <div>

                  {
                    searchType == SearchType.MAP_SEARCH ?
                      <div className="redhat-display text-sm text-gray-600">Zoeken op kaart</div>
                      : <a href="#" onClick={() => changeSearchTypeCallback(SearchType.MAP_SEARCH)}>Zoeken op kaart</a>
                  }
                </div>
              </div>

              <AccordionGroup sx={{ padding: 0, margin: 0 }}>
                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Naam</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <input type="text" id="search_name" className="web-border-input w-full h-[35px] redhat-display"/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Type aanbieding</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Checkbox
                        size="sm"
                        label="Alle typen"
                        checked={allOfferTypes}
                        onChange={toggleAllOfferTypes}
                        id="search_offer_type_ALL"
                        className="ml-4 mr-2 redhat-display"/>
                    </div>

                    {ALL_OFFER_TYPES.map((offerType) => (
                        <div key={offerType}>
                          <Checkbox 
                            size="sm"
                            className="ml-4 mr-2 redhat-dipslay"
                            id={`${"search_offer_type_" + offerType}`}
                            label={ `${offerTypeToString(offerType)}` }
                            checked={offerTypes.findIndex(i => i == offerType) != -1}
                            onChange={(e) => handleOfferTypeChange(offerType, e)} 
                          />
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Jaar</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full text-center">
                      <Slider 
                        id="search_slider_year"
                        valueLabelDisplay="on"
                        className="mt-6 mb-1 max-w-[85%] redhat-display"
                        getAriaLabel={() => "Year range"}
                        value={yearValue}
                        onChange={handleYearValueChange}
                        min={1900}
                        max={new Date().getFullYear()}/>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Prijs</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-full text-center">
                      <Slider 
                        id="search_slider_price"
                        className="mt-6 mb-1 max-w-[85%] redhat-display"
                        getAriaLabel={() => "Price range"}
                        valueLabelDisplay="on"
                        value={priceValue}
                        onChange={handlePriceValueChange}
                        min={0}
                        max={MAX_SEARCH_ITEM_PRICE}/>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Merk</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <input type="text" id="search_brand" className="web-border-input w-full h-[35px] redhat-display"/>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Type</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="grid grid-cols-2">
                      <div>
                      <Checkbox
                        size="sm"
                        label="Alle typen"
                        checked={allproductTypes}
                        onChange={toggleAllproductTypes}
                        id="search_item_type_ALL"
                        className="ml-4 mr-2"/>
                    </div>

                    {ALL_PRODUCT_TYPES.map((productType) => (
                      <div key={productType}>
                        <Checkbox 
                          size="sm"
                          className="checkbox ml-4 mr-2"
                          id={`${"search_item_type_" + productType}`}
                          label={ `${productTypeToString(productType)}` }
                          checked={productTypes.findIndex(i => i == productType) != -1}
                          onChange={(e) => handleproductTypeChange(productType, e)} 
                        />
                      </div>
                    ))}
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Aanbieder</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <input type="text" 
                      id="search_provider"
                      className="w-full h-[35px] redhat-display web-border-input"></input>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Locatie</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col">
                      <div>
                        <input
                          type="radio" 
                          id="no_location"
                          className="relative top-[4px] mb-4"
                          checked={locationType == LocationType.NO_LOCATION}
                          onChange={handleLocationTypeChange}
                          value="NO_LOCATION"
                          name="location-radio"/>
                        <label htmlFor="no_location" className="redhat-display">Geen locatie</label>
                      </div>

                      <div>
                        <input
                          type="radio" 
                          id="my_location"
                          className="relative top-[4px] mb-4"
                          checked={locationType == LocationType.MY_LOCATION}
                          onChange={handleLocationTypeChange}
                          value="MY_LOCATION"/>
                        <label htmlFor="my_location" className="redhat-display">Mijn locatie</label>
                      </div>

                      <div>
                        <input
                          type="radio"
                          id="specific_location"
                          className="relative top-[4px] mb-4"
                          checked={locationType == LocationType.SPECIFIC_LOCATION}
                          onChange={handleLocationTypeChange}
                          value="SPECIFIC_LOCATION"
                          name="location-radio"/>
                        <label htmlFor="specific_location" className="redhat-display">Andere locatie</label>
                      </div>
                    </div>

                    <Map 
                      mapLocation={myLocation} 
                      zoom={mapZoom} 
                      searchRadius={searchRadius} 
                      markerRenderer={(marker) => "" }
                      markers={[]}
                      width={"calc(100%)"}
                      height={"calc(100%)"}/>

                    {locationType == LocationType.SPECIFIC_LOCATION ? 
                      <div>
                        <div className="redhat-display">Locatie zoeken:</div>
                        <input type="text" 
                          id="search_specific_location"
                          disabled={locationType != LocationType.SPECIFIC_LOCATION}
                          className="w-full h-[35px] mt-2 mb-2 web-border-input"
                          onBlur={handleSpecificLocationChange}/>
                        </div>
                      : <></>}

                    <div>Straal:</div>
                    <div className="w-full text-center">
                      <Slider
                        disabled={locationType == LocationType.NO_LOCATION}
                        className="max-w-[230px] mt-6"
                        valueLabelDisplay="on"
                        defaultValue={DEFAULT_MAP_RADIUS}
                        onChange={handleDistanceChange}
                        step={10}
                        min={0}
                        max={500}/>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Online sinds</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nl">
                      <DateTimePicker
                        label="" 
                        sx={{ '& input': {backgroundColor: 'white', fontFamily: 'Red Hat Display, Sans-Serif'} }}
                        value={onlineSince}
                        onChange={(newValue) => setOnlineSince(newValue)}/>
                    </LocalizationProvider>
                  </AccordionDetails>
                </Accordion>

                <Accordion>
                  <AccordionSummary>
                    <span className="redhat-display">Sorteren</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <Select 
                        id="search_sort" 
                        defaultValue="createTime"
                        className="redhat-display"
                        onChange={handleSortByChange}>
                        <Option value="createTime"><span className="redhat-display">Datum</span></Option>
                        <Option value="price"><span className="redhat-display">Prijs</span></Option>
                        <Option value="title"><span className="redhat-display">Naam</span></Option>
                        <Option value="itemYear"><span className="redhat-display">Jaar</span></Option>
                      </Select>
                    </div>

                    <div className="flex flex-row w-full mt-3 justify-center align-center text-center">
                      <div className="grow">
                        <input
                          type="radio" 
                          id="search_desc"
                          className="pr-3"
                          checked={sortDirection == SortDirection.DESC}
                          onChange={handleSortDirectionChange}
                          value="DESC"
                          name="radio-buttons"/>
                        <label htmlFor="search_desc" className="redhat-display">Aflopend</label>
                      </div>

                      <div className="grow">
                        <input
                          type="radio"
                          id="search_asc"
                          className="pr-3"
                          checked={sortDirection == SortDirection.ASC}
                          onChange={handleSortDirectionChange}
                          value="ASC"
                          name="radio-buttons"/>
                        <label htmlFor="search_asc" className="redhat-display">Oplopend</label>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </AccordionGroup>
            </div>

            <div className="flex flex-row-reverse row-cols-2">
              <div className="pt-5 mr-2 mb-2">
                <button className="normal-button" type="submit" onClick={search}>Zoeken</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}