import { BACKEND_URL } from "../domain/env";

import { AuthService } from "./auth.service";
import { BidService } from "./bid.service";
import { ChatService } from "./chat.service";
import { ItemService } from "./item.service";
import { OfferService } from "./offer.service";
import { TradeService } from "./trade.service";
import { SearchService } from "./search.service";
import { FavoritesService } from "./favorites.service";
import MapService from "./map.service";
import { Utils } from "./utils";
import Settings from "./settings";

export const authService = new AuthService(BACKEND_URL);
export const bidService = new BidService(BACKEND_URL);
export const chatService = new ChatService(BACKEND_URL);
export const itemService = new ItemService(BACKEND_URL);
export const offerService = new OfferService(BACKEND_URL);
export const tradeService = new TradeService(BACKEND_URL);
export const searchService = new SearchService(BACKEND_URL);
export const favoritesService = new FavoritesService(BACKEND_URL);
export const mapService = new MapService();
export const settings = new Settings();
export const utils = new Utils();
