import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants";
import Auction from "./auction";
import BusinessOffer from "./business.offer";
import Offer from "./offer";
import PriceType from "./offer.type";
import UserOffer from "./user.offer";
import { offerStateFromString } from "./offer.state";

export class OfferBuilder {
  static buildOffer(data: any): Offer {
    var offer = null;

    if (data["offerType"] == PriceType.AUCTION) {
      offer = new Auction(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.ownerId,
        data.itemId,
        !data.bids ? [] : data.bids,
        offerStateFromString(data.offerState),
        data.closedByOwner,
        !data.wonBidId ? null : data.wonBidId,
        data.auctionDuration,
        moment(data.auctionEnd, DATE_TIME_FORMAT).toDate(),
        data.opened,
        moment(data.openTime, DATE_TIME_FORMAT).toDate(),
        data.startPrice);
    } else if (data["offerType"] == PriceType.USER_OFFER) {
      offer = new UserOffer(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.accountId,
        data.itemId,
        !data.bids ? [] : data.bids,
        offerStateFromString(data.offerState),
        data.closedByOwner,
        !data.wonBidId ? null : data.wonBidId,
        data.amount,
        data.allowBidding,
        data.startPrice);
    } else if (data["offerType"] == PriceType.BUSINESS_OFFER) {
      offer = new BusinessOffer(
        data.id,
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.ownerId,
        data.itemId,
        !data.bids ? [] : data.bids,
        offerStateFromString(data.offerState),
        data.closedByOwner,
        !data.wonBidId ? null : data.wonBidId,
        data.amount);
    }

    return offer;
  }
}
