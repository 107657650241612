import * as Ons from "react-onsenui";
import { authService, utils } from "../../../services";
import MobilePaginator from "../../../components/mobile.paginator";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../../../domain/constants";
import { BACKEND_URL } from "../../../domain/env";
import { useEffect, useState } from "react";
import Loading from "../../../components/loading";
import { ChatBuilder } from "../../../domain/chat";

export function MobileChats() {
  const [chats, setChats] = useState<any[]>(null);

  const [links, setLinks] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [totalNrPages, setTotalNrPages] = useState<number>(0);

  function goToChat(row) {
    
  }

  function goToPage(link) {

  }

  function first() {
    if (links.first && pageNumber > 1) {
      goToPage(links.first);
    }
  }

  function previous() {
    if (links.prev && pageNumber > 1) {
      goToPage(links.prev);
    }
  }

  function next() {
    if (links.next && pageNumber < totalNrPages) {
      goToPage(links.next);
    }
  }

  function last() {
    if (links.last) {
      goToPage(links.last);
    }
  }

  function loadData() {
    var url = BACKEND_URL + "/chats";

    authService.getSecure(url)
      .then((result) => {
        var array = result.data.data.map((chat) => {
          return ChatBuilder.buildChat(chat);
        });

        setPageNumber(1);
        setTotalNrPages(result.data.totalNrPages);
        setChats(array);
      });
  }

  useEffect(() => {
    if (!chats) {
      loadData();
    }
  }, null);

  function getLastMessage(row) {
    if (row && row.messages && row.messages.length > 0) {
      var msg = row.messages[row.messages.length - 1].message;

      if (msg.length > 15) {
        return msg.substring(0, 15) + "...";
      } else {
        return msg;
      }
    } else {
      return "";
    }
  }

  if (!chats) {
    return <Loading/>;
  }

  function renderChatRow(row, index) {
    return (
      <div key={row.id}>
        <Ons.ListItem modifier="nodivider" onClick={() => goToChat(row)}>
          <div className="flex flex-row max-h-[70px]">
            <div>
              <img 
                className="avatar-img-account-mobile rounded-full h-[100px] w-[100px] bg-gray-50 p-2 z-30 relative left-[-5px] top-[-15px]"
                src={`${BACKEND_URL}/static/img/preview/${row.toAccountId}`}
                alt="Item image"
                width={50}
                height={50}/>
            </div>

            <div className="flex flex-col">
              <div className="text-sm p-0 redhat-display font-bold">
                <span className="font-bold">{ row.toFirstName }</span>
              </div>

              <div className="redhat-display text-sm">
                <span className="font-bold">{ row.title }</span>
              </div>

              <div className="text-sm p-0 redhat-display">
                { utils.renderFriendlyDateTime(row.createTime) }
              </div>

              <div className="text-sm p-0 redhat-display">
                <span className="italic">{ getLastMessage(row) }</span>
              </div>
            </div>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </div>
    );
  };

  function renderChatHeader(title) {
    return (
      <div>
        <Ons.ListHeader className={`${utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">
            { title }
          </div>
        </Ons.ListHeader>

        <div className="mt-2"></div>

        { 
          !chats || chats.length == 0 ?
            <div>
              <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen transacties.</div>
            </div>
            :
            <div>
              <MobilePaginator
                pageNumber={pageNumber}
                pageSize={DEFAULT_PAGE_SIZE}
                totalNrPages={totalNrPages}
                first={first}
                previous={previous}
                next={next}
                last={last}
                isTop={true}/>
            
              <div className="w-full h-[1px] border-solid search-line"></div>
            </div>
        }
      </div>
    );
  };

  return (
    <>
      <Ons.List
        className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
        dataSource={chats}
        renderRow={(row, index) => renderChatRow(row, index)}
        renderHeader={() => renderChatHeader("Chats")} />
    </>
  );
}