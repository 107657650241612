enum ColorScheme {
  BEIGE = "BEIGE",
  DARK = "DARK"
}

export function colorSchemeFromString(colorScheme: string): ColorScheme {
  switch (colorScheme) {
    case "BEIGE":
      return ColorScheme.BEIGE;
    case "DARK":
      return ColorScheme.DARK;
    default:
      return null;
  }
}

export function colorSchemeToString(colorScheme: ColorScheme): string {
  switch (colorScheme) {
    case ColorScheme.BEIGE:
      return "Beige";
    case ColorScheme.DARK:
      return "Donker";
    default:
      return "";
  }
}

export const ALL_COLOR_SCHEMES = [
  ColorScheme.BEIGE,
  ColorScheme.DARK
];

export default ColorScheme;