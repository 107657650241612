import Account from "../account/account";
import SearchResults from "./search.results";

class AccountSearchResults extends SearchResults {
  public data: Account[];

  constructor($pageNumber: number,
              $pageSize: number,
              $nrItemsThisPage: number,
              $totalNrPages: number,
              $itemCount: number,
              $search: any,
              $data: Account[]) {
      super($pageNumber, $pageSize, $nrItemsThisPage, 
            $totalNrPages, $itemCount, $search);

      this.data = $data;
  }
}

export default AccountSearchResults;