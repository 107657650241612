import Review from "../review/review";
import SearchResults from "./search.results";

class ReviewSearchResults extends SearchResults {
  public data: Review[];

  constructor($pageNumber: number,
              $pageSize: number,
              $nrItemsThisPage: number,
              $totalNrPages: number,
              $itemCount: number,
              $data: Review[]) {
      super($pageNumber, $pageSize, $nrItemsThisPage, 
            $totalNrPages, $itemCount, null);

      this.data = $data;
  }
}

export default ReviewSearchResults;