export default class OpeningTimes {
  private openingDays: OpeningDay[];

  constructor() {
    this.openingDays = [];
  }

  addDay(openingDay: OpeningDay) {
    this.openingDays.push(openingDay);
  }
}

export class OpeningDay {
  private blocks: OpeningTime[];

  constructor() {
    this.blocks = [];
  }

  addBlock(startHours: number, startMinutes: number, 
           endHours: number, endMinutes: number) {
    this.blocks.push(new OpeningTime(
      startHours, startMinutes, 
      endHours, endMinutes));
  }
}

export class OpeningTime {
  private startHours: number;
  private startMinutes: number;
  private endHours: number;
  private endMinutes: number;

  constructor($startHours: number,
              $startMinutes: number,
              $endHours: number,
              $endMinutes: number) {
    this.startHours = $startHours;
    this.startMinutes = $startMinutes;
    this.endHours = $endHours;
    this.endMinutes = $endMinutes;
  }
}