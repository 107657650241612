import Item from "./item";
import ProductType from "./product.type";

class SingleItem extends Item {
	itemYear: number;
	brand: string;
	productType: ProductType;
	picture: string;
  visible: boolean;

	constructor($id: string,
              $createTime: Date,
              $title: string,
              $description: string,
              $ownerId: string,
              $itemYear: number,
              $brand: string,
              $productType: ProductType,
              $picture: string,
              $visible: boolean) {
		super($id, $createTime, $title,
			$description, $ownerId, false);
		
		this.itemYear = $itemYear;
		this.brand = $brand;
		this.productType = $productType;
		this.picture = $picture;
    this.visible = $visible;
	}
}

export default SingleItem;