import Offer from "./offer";
import { OfferState } from "./offer.state";
import PriceType from "./offer.type";
import bigDecimal from 'js-big-decimal';

class Auction extends Offer {
  auctionDuration: number;
	auctionEnd: Date;
  opened: boolean;
  openTime: Date;
	startPrice: bigDecimal;

	constructor($id: string,
              $createTime: Date,
              $accountId: string,
              $itemId: string,
              $bids: string[],
              $offerState: OfferState,
              $closedByOwner: boolean,
              $wonBidId: string,
              $auctionDuration: number,
              $auctionEnd: Date,
              $opened: boolean,
              $openTime: Date,
              $startPrice: string) {
		super($id, $createTime, 
      $accountId, $itemId,
			$bids, $offerState, $closedByOwner, 
      $wonBidId, PriceType.AUCTION);

    this.auctionDuration = $auctionDuration;
		this.auctionEnd = $auctionEnd;
    this.opened = $opened;
    this.openTime = $openTime;
		this.startPrice = new bigDecimal($startPrice);
	}
}

export default Auction;