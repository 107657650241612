import { useState, useEffect } from 'react'
import axios, { AxiosInstance } from "axios";

export class TradeService {
  protected readonly instance: AxiosInstance;

  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 30000,
      timeoutErrorMessage: "Time out!",
    });
  }

  // public fetchAllTrades() {
  //   useEffect(() => {
  //     this.instance.get("/trades/all")
  //       .then((res) => {
  //         setData(res.data);
  //         setLoading(false);
  //       });
  //   }, [])
  // }

  // public getAllTrades(): Trade[] {
  //   return data;
  // }
}