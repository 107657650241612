import * as Ons from "react-onsenui";
import moment from "moment";
import { Utils } from "../../../services/utils";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import { utils } from "../../../services";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "../../../domain/constants";
import NewItem from "../../items/new/page";
import CountDownTimer from "../../../components/countdown.timer";
import PriceType, { offerTypeToString } from "../../../domain/offer/offer.type";
import { OfferState, offerStateToString } from "../../../domain/offer/offer.state";
import { BACKEND_URL } from "../../../domain/env";
import OfferPage from "../../offers/[offerId]/page";
import Auction from "../../../domain/offer/auction";

export default function MobileAccountItems({ data }: { data: any }) {
  function goToNewItem() {
    Utils.mobileNavigator.pushPage({
      page: <NewItem/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Nieuw item" backButton={false}/>
      }
    });
  }
  
  function goToOffer(offer) {
    Utils.mobileNavigator.pushPage({
      page: <OfferPage offerId={offer.id}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Item bekijken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  function getOfferAmount(item) {
    var amount = "";

    if (item.offer instanceof Auction) {
      var timeData = utils.getAuctionTimeData(item.offer);

      if (timeData.alreadyClosed || item.offer.offerState != OfferState.OPEN) {
        amount = "Veiling gesloten";
      } else if (item.offer.amount) {
        amount = "\u20AC" + utils.round(item.offer.amount);
      } else {
        amount = "Bieden";
      }
    } else {
      amount = "\u20AC" + utils.round(item.offer.amount);
    }

    return amount;
  }

  function getMyAccountItems() {
    const renderHeader = (nrOffers) => {
      return (
        <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
          <div className="body-style">{data.length} items</div>
        </Ons.ListHeader>
      );
    }
    
    const renderRow = (row, index) => {
      var amount = getOfferAmount(row);

      return (
        <>
          <Ons.ListItem key={index} modifier="nodivider">
            <div className="flex flex-row grow body-style" 
                onClick={() => goToOffer(row.offer)}>
              <img 
                className={`mr-4 ml-0 mt-0 rounded-lg mobile-item-list-image ${ row.offer.offerType == PriceType.AUCTION ? "mobile-auction-image" : "" } ${ row.offer.offerType == PriceType.BUSINESS_OFFER ? "mobile-business-image" : ""}`}
                src={`${BACKEND_URL}/static/img/preview/${row.offer.itemId}`}
                alt="Item image"
                width={80}
                height={80}/>
              
              <div className="flex flex-col break-keep min-w-[140px]">
                <div className="text-xs font-bold secondary-color">{ row.item.title }</div>

                <div className="text-xs">
                  Sinds { moment(row.offer.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
                </div>

                <div className="text-xs">
                  Type: { offerTypeToString(row.offer.offerType) }
                </div>

                <div className="text-xs">
                  <span className="">Status: { offerStateToString(row.offer.offerState) }</span>
                </div>

                <div className="font-bold bid-amount relative text-left">{ amount }</div>

                {
                  row.offer.city ?
                    <div className="text-xs">
                      { row.offer.city } { utils.getDistance(row.offer) }
                    </div>
                    : <></>
                }
              </div>

              {
                row.offer.offerType == PriceType.AUCTION &&
                row.offer.offerState == OfferState.OPEN &&
                row.offer.auctionDuration && 
                row.offer.openTime ? 
                  <CountDownTimer offer={row.offer}
                    labelClassName="relative left-[0px] top-[20px] text-xs"
                    timerClassName="relative left-[0px] top-[20px] auction-time-mobile"/>
                  : <></>
              }
            </div>
          </Ons.ListItem>
        </>
      );
    }

    if (data.length == 0) {
      return (
        <div>
          <div key={"no-items"} className="text-center m-8 mt-4 redhat-display">Geen items gevonden. Upload nu je eerste item!</div>
        </div>);
    } else {
      return (
        <>
          <Ons.List
            className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
            dataSource={data}
            renderRow={(row, index) => renderRow(row, index)}
            renderHeader={() => renderHeader(data.nrOffers)}/>

          <div className="mb-[80px]"></div>
        </>);
    }
  }

  return getMyAccountItems();
}