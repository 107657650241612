import * as Ons from "react-onsenui";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../../domain/env";
import { authService, utils } from "../../../../services";
import { TradeBuilder } from "../../../../domain/trade/trade.builder";
import Loading from "../../../../components/loading";
import { tradeTypeToString } from "../../../../domain/trade/trade.type";

export default function Trade({ tradeId }: { tradeId: string }) {
  const [trade, setTrade] = useState<any>(null);
  
  const loadData = () => {
    var tradeUrl = BACKEND_URL + "/trades/" + tradeId;

    authService.getSecure(tradeUrl)
      .then((result) => {
        setTrade(TradeBuilder.buildTrade(result.data));
      });
  }

  useEffect(() => {
    if (!trade) {
      loadData();
    }
  }, null);

  if (!trade) {
    return <Loading/>;
  }

  function renderMobile() {    
    return (
      <>
        <div className="ml-4 mt-4 redhat-display">
          <div className="justify-center w-[150px] ml-auto mr-auto">
            <img 
              className="mt-0 rounded-lg item-image ml-auto mr-auto"
              src={`${BACKEND_URL}/static/img/preview/${trade.itemId}`}
              alt="Item image"
              width={150}
              height={150}/>
          
            <div className="opacity-80 relative bottom-[30px] rounded-b-lg h-[30px] bid-item-overlay-mobile-transaction text-white bg-white w-[150px]"></div>

            {
              trade.incoming ?
                <>
                  <Ons.Icon icon="md-arrow-left" className="relative top-[-57px] left-[7px] link-color font-bold ml-[3px] z-[100]"/>
                  <img 
                    className="relative top-[-76px] left-[25px] z-20" 
                    src="/gropp_handshake.png"
                    width="23" 
                    height="23" 
                    alt="Grop logo"/>
                </>
                : 
                <>
                  <Ons.Icon icon="md-arrow-right" className="relative top-[-57px] left-[10px] link-color font-bold z-[100]"/>
                  <img 
                    className="relative top-[-77px] left-[25px] z-20" 
                    src="/gropp_handshake.png"
                    width="23" 
                    height="23" 
                    alt="Grop logo"/>
                </>
            }
          </div>

          <div className="font-bold">Titel</div>
          <div className="mb-4">{ trade.title }</div>

          <div className="font-bold">Type</div>
          <div className="mb-4">{ trade.incoming ? "Koop" : "Verkoop" }</div>

          <div className="font-bold">{ trade.incoming ? "Gekocht van" : "Verkocht aan" }</div>
          <div className="mb-4">{ trade.buyerFirstName }</div>

          <div className="font-bold">Bedrag</div>
          <div className="font-bold link-color text-xl mb-4">{ utils.roundedAmount(trade.amount, 2) }</div>

          <div className="font-bold">Tijd</div>
          <div className="mb-4">{ utils.renderFriendlyDateTime(trade.createTime) }</div>

          <div className="font-bold">Type gebruiker</div>
          <div className="mb-4">{ tradeTypeToString(trade.tradeType) }</div>

          <div className="font-bold">{ trade.incoming ? "Verkoper" : "Koper" }</div>

          {
            trade.incoming ? 
              <div className="mt-4">
                <Ons.Button className="link-color" onClick={() => {}}>
                  <span className="text-sm redhat-display normal-case">Bekijk verkoper</span>
                </Ons.Button>
              </div>
              : 
              <div className="mt-4">
                <Ons.Button className="link-color mt-4 mb-4" onClick={() => {}}>
                  <span className="text-sm redhat-display normal-case">Bekijk koper</span>
                </Ons.Button>
              </div>
          }
          
          <br></br>
      </div>
    </>);
  }

  function renderWeb() {
    return (<></>);
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}