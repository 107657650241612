export interface NotificationSettingsFlags {
  BID_REJECTED: boolean,
  NEW_BID_ITEM: boolean,
  NEW_BID_FAVORITE_ITEM: boolean,
  FAVORITE_ACCOUNT_PLACED_ITEM: boolean,
  TRANSACTION_FAVORITE_ITEM: boolean,
  NEW_CHAT_MESSAGE: boolean,
  SOLD_ITEM_SHIPPED: boolean,
  BOUGHT_ITEM_SHIPPED: boolean,
  ADVERTISEMENT: boolean,
  SYSTEM_NOTIFICATION: boolean
};

export class NotificationSettings {
  private appSettings: NotificationSettingsFlags = null;
  private emailSettings: NotificationSettingsFlags = null;

  public static DEFAULT = new NotificationSettings(
    {
      BID_REJECTED: true,
      NEW_BID_ITEM: true,
      NEW_BID_FAVORITE_ITEM: true,
      FAVORITE_ACCOUNT_PLACED_ITEM: true,
      TRANSACTION_FAVORITE_ITEM: true,
      NEW_CHAT_MESSAGE: true,
      SOLD_ITEM_SHIPPED: true,
      BOUGHT_ITEM_SHIPPED: true,
      ADVERTISEMENT: true,
      SYSTEM_NOTIFICATION: true
    },
    {
      BID_REJECTED: true,
      NEW_BID_ITEM: true,
      NEW_BID_FAVORITE_ITEM: false,
      FAVORITE_ACCOUNT_PLACED_ITEM: false,
      TRANSACTION_FAVORITE_ITEM: false,
      NEW_CHAT_MESSAGE: false,
      SOLD_ITEM_SHIPPED: true,
      BOUGHT_ITEM_SHIPPED: true,
      ADVERTISEMENT: false,
      SYSTEM_NOTIFICATION: true
    });

  constructor($appSettings, $emailSettings) {
    this.appSettings = $appSettings;
    this.emailSettings = $emailSettings;
  }
  
  public isEnabled(appOrEmail, notificationType): boolean {
    if (appOrEmail == "app") {
      return this.appSettings[notificationType] == true;
    } else {
      return this.emailSettings[notificationType] == true;
    }
  }

  public enable(appOrEmail, notificationType) {
    if (appOrEmail == "app") {
      this.appSettings[notificationType] = true;
    } else {
      this.emailSettings[notificationType] = true;
    }
  }

  public disable(appOrEmail, notificationType) {
    if (appOrEmail == "app") {
      this.appSettings[notificationType] = false;
    } else {
      this.emailSettings[notificationType] = false;
    }
  }

  public toggle(appOrEmail, notificationType) {
    if (appOrEmail == "app") {
      if (this.appSettings[notificationType] == true) {
        this.appSettings[notificationType] = false;
      } else {
        this.appSettings[notificationType] = true;
      }
    } else {
      if (this.emailSettings[notificationType] == true) {
        this.emailSettings[notificationType] = false;
      } else {
        this.emailSettings[notificationType] = true;
      }
    }
  }

  public serialize(): any {
    return {
      "app": {
        "BID_REJECTED": this.appSettings.BID_REJECTED,
        "NEW_BID_ITEM": this.appSettings.NEW_BID_ITEM,
        "NEW_BID_FAVORITE_ITEM": this.appSettings.NEW_BID_FAVORITE_ITEM,
        "FAVORITE_ACCOUNT_PLACED_ITEM": this.appSettings.FAVORITE_ACCOUNT_PLACED_ITEM,
        "TRANSACTION_FAVORITE_ITEM": this.appSettings.TRANSACTION_FAVORITE_ITEM,
        "NEW_CHAT_MESSAGE": this.appSettings.NEW_CHAT_MESSAGE,
        "SOLD_ITEM_SHIPPED": this.appSettings.SOLD_ITEM_SHIPPED,
        "BOUGHT_ITEM_SHIPPED": this.appSettings.BOUGHT_ITEM_SHIPPED,
        "ADVERTISEMENT": this.appSettings.ADVERTISEMENT,
        "SYSTEM_NOTIFICATION": this.appSettings.SYSTEM_NOTIFICATION
      },
      "email": {
        "BID_REJECTED": this.emailSettings.BID_REJECTED,
        "NEW_BID_ITEM": this.emailSettings.NEW_BID_ITEM,
        "NEW_BID_FAVORITE_ITEM": this.emailSettings.NEW_BID_FAVORITE_ITEM,
        "FAVORITE_ACCOUNT_PLACED_ITEM": this.emailSettings.FAVORITE_ACCOUNT_PLACED_ITEM,
        "TRANSACTION_FAVORITE_ITEM": this.emailSettings.TRANSACTION_FAVORITE_ITEM,
        "NEW_CHAT_MESSAGE": this.emailSettings.NEW_CHAT_MESSAGE,
        "SOLD_ITEM_SHIPPED": this.emailSettings.SOLD_ITEM_SHIPPED,
        "BOUGHT_ITEM_SHIPPED": this.emailSettings.BOUGHT_ITEM_SHIPPED,
        "ADVERTISEMENT": this.emailSettings.ADVERTISEMENT,
        "SYSTEM_NOTIFICATION": this.emailSettings.SYSTEM_NOTIFICATION
      }
    };
  }

  public static build(settings: any): NotificationSettings {
    var appSettings: NotificationSettingsFlags = {
      BID_REJECTED: settings["app"]["BID_REJECTED"],
      NEW_BID_ITEM: settings["app"]["NEW_BID_ITEM"],
      NEW_BID_FAVORITE_ITEM: settings["app"]["NEW_BID_FAVORITE_ITEM"],
      FAVORITE_ACCOUNT_PLACED_ITEM: settings["app"]["FAVORITE_ACCOUNT_PLACED_ITEM"],
      TRANSACTION_FAVORITE_ITEM: settings["app"]["TRANSACTION_FAVORITE_ITEM"],
      NEW_CHAT_MESSAGE: settings["app"]["NEW_CHAT_MESSAGE"],
      SOLD_ITEM_SHIPPED: settings["app"]["SOLD_ITEM_SHIPPED"],
      BOUGHT_ITEM_SHIPPED: settings["app"]["BOUGHT_ITEM_SHIPPED"],
      ADVERTISEMENT: settings["app"]["ADVERTISEMENT"],
      SYSTEM_NOTIFICATION: settings["app"]["SYSTEM_NOTIFICATION"]
    };

    var emailSettings: NotificationSettingsFlags = {
      BID_REJECTED: settings["email"]["BID_REJECTED"],
      NEW_BID_ITEM: settings["email"]["NEW_BID_ITEM"],
      NEW_BID_FAVORITE_ITEM: settings["email"]["NEW_BID_FAVORITE_ITEM"],
      FAVORITE_ACCOUNT_PLACED_ITEM: settings["email"]["FAVORITE_ACCOUNT_PLACED_ITEM"],
      TRANSACTION_FAVORITE_ITEM: settings["email"]["TRANSACTION_FAVORITE_ITEM"],
      NEW_CHAT_MESSAGE: settings["email"]["NEW_CHAT_MESSAGE"],
      SOLD_ITEM_SHIPPED: settings["email"]["SOLD_ITEM_SHIPPED"],
      BOUGHT_ITEM_SHIPPED: settings["email"]["BOUGHT_ITEM_SHIPPED"],
      ADVERTISEMENT: settings["email"]["ADVERTISEMENT"],
      SYSTEM_NOTIFICATION: settings["email"]["SYSTEM_NOTIFICATION"]
    };

    var result = new NotificationSettings(appSettings, emailSettings);

    return result;
  }
}

export interface PrivacySettingsFlags {
  showLastName: boolean,
  showAddress: boolean,
  showBirthDate: boolean,
  showPhoneNumber: boolean,
  showCoordinates: boolean,
  showStatistics: boolean,
}

export class PrivacySettings {
  private settings: PrivacySettingsFlags = null;

  public static DEFAULT = new PrivacySettings({
    showLastName: false,
    showAddress: false,
    showBirthDate: false,
    showPhoneNumber: false,
    showCoordinates: false,
    showStatistics: false
  });

  constructor($settings) {
    this.settings = $settings;
  }

  public serialize() {
    return this.settings;
  }

  public static build(settings: any): PrivacySettings {
    var flags = {
      showLastName: settings["showLastName"],
      showAddress: settings["showAddress"],
      showBirthDate: settings["showBirthDate"],
      showPhoneNumber: settings["showPhoneNumber"],
      showCoordinates: settings["showCoordinates"],
      showStatistics: settings["showStatistics"]
    };

    return new PrivacySettings(flags);
  }
}


