import * as Ons from "react-onsenui";
import * as Yup from 'yup';

import moment from "moment";
import { useFormik } from "formik";
import { authService, searchService, utils } from "../../../services";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MobilePaginator from "../../../components/mobile.paginator";
import { MAX_REVIEW_TEXT_SIZE, REVIEW_ITEMS_PER_PAGE } from "../../../domain/constants";
import ReviewBuilder from "../../../domain/review/review.builder";
import { ReviewValidator } from "../../../domain/search/form.validators";
import Loading from "../../../components/loading";
import { BACKEND_URL } from "../../../domain/env";

export default function Reviews({ accountId, readOnly = false}, {accountId: string, readOnly: boolean}) {
  const navigate = useNavigate();
  const unauthorized = () => utils.handleUnauthorized(() => navigate("/login"));

  const [editingReview, setEditingReview] = useState<boolean>(false);
  const [updatingReview, setUpdatingReview] = useState<boolean>(false);
  const [showReviewCancelling, setShowReviewCancelling] = useState<boolean>(false);
  const [showReviewDeleting, setShowReviewDeleting] = useState<boolean>(false);
  
  const [myReview, setMyReview] = useState<any>(null);
  const [expandedReviewIndex, setExpandedReviewIndex] = useState<number>(-1);
  const [itemCount, setItemCount] = useState<number>(null);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalNrPages, setTotalNrPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<any>(null);
  const pageSize = "3";

  const loadData = () => {
    goToResultsPage(1);
  }

  function goToResultsPage(pageNumber) {
    searchService.searchReviews(accountId, pageNumber, pageSize)
      .then((result) => {
        setItemCount(result.itemCount);
        setPageNumber(1);
        setTotalNrPages(result.totalNrPages);
        setCurrentPage(result);
      }).catch((_) => {
        unauthorized();
      });
  }

  useEffect(() => {
    if (!currentPage) {
      loadData();
    }
  }, null);

  function getReviewText(review, i) {
    if (expandedReviewIndex == i) {
      return review.review;
    } else if (review.review.length > MAX_REVIEW_TEXT_SIZE) {
      return review.review.substring(0, MAX_REVIEW_TEXT_SIZE) + "...";
    } else {
      return review.review;
    }
  }

  function printMessageDate(review): string {
    if (review && review.createTime) {
      return moment(review.createTime).format("DD-MM-yyyy");
    } else {
      return "";
    }
  }

  function printMessageTime(review): string {
    if (review && review.createTime) {
      return moment(review.createTime).format("HH:mm:ss");
    } else {
      return "";
    }
  }

  function firstReviewPage() {
    setPageNumber(1);
    goToResultsPage(1);
  }

  function previousReviewPage() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      goToResultsPage(pageNumber - 1);
    }
  }

  function nextReviewPage() {
    if (pageNumber < totalNrPages) {
      setPageNumber(pageNumber + 1);
      goToResultsPage(pageNumber + 1);
    }
  }

  function lastReviewPage() {
    setPageNumber(totalNrPages);
    goToResultsPage(totalNrPages);
  }

  function writeReview() {
    formik.values.reviewText = "";
    setEditingReview(true);

    setTimeout(() => {
      // (document.getElementsByName("reviewText")[0] as any).focus();
    });
  }

  function cancelEditReview() {
    setShowReviewCancelling(true);
  }

  function doCancelEditReview() {
    setShowReviewCancelling(false);
    setEditingReview(false);

    formik.errors.reviewText = null;
    formik.values.reviewText = "";
  }

  function deleteReview() {
    setShowReviewDeleting(true);
  }

  function doDeleteReview() {
    if (!myReview) {
      setMyReview(null);
      setEditingReview(false);
      setShowReviewDeleting(false);
      return;
    }

    var url = BACKEND_URL + "/reviews/" + myReview.id;

    authService.deleteSecure(url)
      .then((_) => {
        formik.values.reviewText = "";

        setMyReview(null);
        setEditingReview(false);
        setShowReviewDeleting(false);
        setUpdatingReview(false);

        loadData();
      });
  }

  function changeReview() {
    setEditingReview(true);
    setUpdatingReview(true);
    formik.values.reviewText = (myReview as any).review;

    setTimeout(() => {
      // (document.getElementsByName("reviewText")[0] as any).focus();
    });
  }

  function updateReview(reviewText) {
    doSaveReview(reviewText, true);
  }

  function saveReview(reviewText) {
    doSaveReview(reviewText, false);
  }

  function doSaveReview(reviewText, updating) {
    if (!accountId || reviewText.length == 0) {
      setEditingReview(false);
      return;
    }

    var url = BACKEND_URL + "/reviews/account/" + accountId;

    utils.getLoggedInAccountId().then((result) => {
      if (!result) {
        return;
      }

      var body = {
        reviewedAccountId: accountId,
        accountId: result,
        review: reviewText
      };
  
      if (updating) {
        authService.putSecure(url, body)
          .then((_) => {
            // setEditingReview(false);
            // fetchMyReview(accountId);
            // fetchReviews(accountId);
          }).catch((_) => {
            setEditingReview(false);
          });
      } else {
        authService.postSecure(url, body)
          .then((_) => {
            // setEditingReview(false);
            // setUpdatingReview(false);
            // fetchMyReview(accountId);
            // fetchReviews(accountId);
          }).catch((_) => {
            setEditingReview(false);
            setUpdatingReview(false);
          });
      }
    });
  }

  function sliceIntoChunks(arr, chunkSize) {
    const res = [];

    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }

    return res;
  }

  function fetchMyReview(accountId) {
    if (!accountId) {
      return false;
    }

    var url = BACKEND_URL + "/reviews/my-review/" + accountId;

    authService.getSecure(url)
      .then((result) => {
        var review = ReviewBuilder.buildReview(result.data);
        setMyReview(review);
      }).catch((_) => {
        setMyReview(null);
      });
  }

  const formik = useFormik({
    initialValues: ReviewValidator.initialValues,
    validationSchema: ReviewValidator.validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      
    },
  });

  function renderWeb() {
    return (<></>);
  }
  
  function renderMobile() {
    const renderHeader = (nrReviews, text) => {
      return (
        <div>
          <Ons.ListHeader className={`${utils.getPlatform() == "android" ? "" : ""}`}>
            <div className="body-style">
              { nrReviews == 0 ? text : `${nrReviews} ${text}` }
            </div>
          </Ons.ListHeader>

          <div className="mt-2"></div>

          { 
            nrReviews == 0 ?
              <div>
                <div key={"no-items"} className="text-center m-4 mt-2 w-full redhat-display">Geen {text}.</div>
              </div>
              :
              <div>
                <MobilePaginator
                  pageNumber={pageNumber}
                  pageSize={REVIEW_ITEMS_PER_PAGE}
                  totalNrPages={totalNrPages}
                  first={firstReviewPage}
                  previous={previousReviewPage}
                  next={nextReviewPage}
                  last={lastReviewPage}
                  isTop={true}/>

                <div className="w-full h-[1px] border-solid search-line"></div>
              </div>
          }
        </div>
      );
    }

    const renderReviewRow = (row, index) => {
      return (
        <div key={row.id}>
          <Ons.ListItem modifier="nodivider">
            <div className="flex flex-row grow body-style p-0" 
                onClick={() => {} }>
              <div className="flex flex-row p-0">
              
                <div key={row.id} 
                    className="break-all w-full cursor-pointer" 
                    onClick={() => setExpandedReviewIndex(index)}>
                  <div className="">
                    <div className="text-xs italic mb-2">Op {printMessageDate(row)} om {printMessageTime(row)} schreef {row.firstName}:</div>
                    <div className="text-sm">{getReviewText(row, index)}</div>
                  </div>
                </div>
              </div>
            </div>
          </Ons.ListItem>

          <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
        </div>
      );
    };

    return (
      <>
        {
          editingReview ?
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <textarea 
                  rows={0} 
                  cols={0} 
                  className={`min-w-md h-[200px] w-full chat-message-text rounded-none review-textarea text-sm relative bottom-0 ${ utils.isMobile() ? "border-l-0 border-r-0 resize-none redhat-display" : ""}`} 
                  id="reviewText"
                  name="reviewText"
                  placeholder="Schrijf een review..."
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.reviewText}>
                </textarea>
                
                {formik.touched.reviewText && formik.errors.reviewText ? (
                  <div className="text-xs mt-1 mr-2 text-right text-red-600">{formik.errors.reviewText}</div>
                ) : <div className="text-xs mt-1 mr-2">&nbsp;</div> }

                {
                  updatingReview ? 
                    <Link 
                      className="text-sm mt-2 mr-4"
                      to="#" 
                      onClick={deleteReview}>Verwijderen</Link>
                    : <></>
                }

                <div className="w-full flex flex-row gap-2 justify-center pt-4 pb-2">
                  <div className="">
                    <button className="button min-w-[130px]" type="button"
                        onClick={() => cancelEditReview()}>
                      <span className="text-sm redhat-display">Annuleren</span>
                    </button>
                  </div>
                  
                  <div className="">
                    <button className="button min-w-[130px]" type="submit">
                      <span className="text-sm redhat-display">Opslaan</span>
                    </button>
                  </div>
                </div>
            </div>
          </form>
          : <></>
        }

        {/* 
              <Modal open={showReviewDeleting}>
                <ModalDialog variant="plain" role="alertdialog">
                  <DialogTitle>Verwijderen</DialogTitle>
                  <Divider />
                  <DialogContent>
                    Weet je zeker dat je je review wil verwijderen?
                  </DialogContent>
                  <DialogActions>
                    <Button variant="solid" 
                      className="background-accent-color" 
                      onClick={() => setShowReviewDeleting(false)}>Nee</Button>
                    <Button variant="plain" color="neutral" onClick={() => doDeleteReview()}>Ja</Button>
                  </DialogActions>
                </ModalDialog>
              </Modal> */}

      <Ons.List
        className={`${utils.getPlatform() == "android" ? "relative top-[10px]" : ""}`}
        dataSource={currentPage.data}
        renderRow={(row, index) => renderReviewRow(row, index)}
        renderHeader={() => renderHeader(itemCount, "reviews")} />

      {
        !readOnly ? (editingReview ? <></> : (myReview ? 
          <Link className="text-sm" to="#" onClick={changeReview}>Pas je review aan</Link>
          :
          <div className="w-full text-center mt-8">
            <Ons.Button className="link-color min-w-[150px]"
                onClick={() => { writeReview()}}>
              <span className="redhat-display text-sm normal-case">Schrijf&nbsp;een&nbsp;review</span>
            </Ons.Button>
          </div>))
          : <></>
      }

      <Ons.AlertDialog isOpen={showReviewCancelling} cancelable>
        <div className="text-center w-full redhat-display mt-2">
          <span className="alert-dialog-text font-bold">Annuleren</span>
        </div>
        
        <div className="text-center w-full mt-4 p-4 alert-dialog-text redhat-display text-sm">
          Weet je zeker dat je wil annuleren? Je raakt je wijzigingen kwijt.
        </div>

        <div className="w-full p-4 gap-2 justify-center flex flex-row">
          <Ons.Button onClick={() => doCancelEditReview() } className="button min-w-[110px]">
            <span className="redhat-display text-sm">Ja</span>
          </Ons.Button>

          <Ons.Button onClick={() => {setShowReviewCancelling(false)}} className="button min-w-[110px]">
            <span className="redhat-display text-sm">Nee</span>
          </Ons.Button>
        </div>
      </Ons.AlertDialog>
    </>);
  }

  if (!currentPage) {
    return <Loading/>;
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}