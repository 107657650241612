import GroupItemElement from "./group.item.element";
import Item from "./item";

class GroupItem extends Item {
	elements: GroupItemElement[];
  groupDescription: string;
  
	constructor($id: string,
              $createTime: Date,
              $ownerId: string,
              $elements: GroupItemElement[],
              $groupDescription: string) {
		super($id, $createTime, null, null, $ownerId, true);

		this.elements = $elements;
    this.groupDescription = $groupDescription;
	}
}

export default GroupItem;