import * as Ons from "react-onsenui";

import { accountRoleToString } from "../../domain/account/account.role";
import { DefaultMobileToolbar } from "../default.mobile.toolbar";
import { Utils } from "../../services/utils";
import ChangePassword from "./password/change";
import MobileEditProfile from "./mobile.edit.profile";
import { Login } from "@mui/icons-material";

export default function MobileProfileOverview({ account }) {
  if (!account) {
    return (<></>);
  }

  function changePassword() {
    Utils.mobileNavigator.pushPage({
      page: <Login />,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Inloggen" backButton={false}/>
      }
    }, { animation: "none" });
  }

  function doEditProfile() {
    Utils.mobileNavigator.pushPage({
      page: <MobileEditProfile/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Profiel bewerken" backButton={true}/>
      }
    }, { animation: "none" });
  }

  return (
    <>
      <div className="mt-4 p-4 pt-0 body-style">
        <div className="font-bold">Afbeelding</div>
        <div className="mb-6">{ account.ownerId }</div>

        <div className="font-bold">Type account</div>
        <div className="mb-6">{ accountRoleToString(account.accountRole) }</div>

        <div className="font-bold">Voornaam</div>
        <div className="mb-6">{ account.firstName }</div>

        <div className="font-bold">Achternaam</div>
        <div className="mb-6">
          { account.lastName }
        
          { 
            account.idVerified ? 
              <img 
                className="z-20 inline-block relative left-[10px] top-[-1px]" 
                src="/gropp_verified.png" 
                width="17" 
                height="17" 
                alt="Grop logo"/>
              : <></>
          }
        </div>

        <div className="font-bold">Geboortedatum</div>
        <div className="mb-6">{ account.birthDate }</div>

        <div className="font-bold">Straat</div>
        <div className="mb-6">{ account.street }</div>

        <div className="font-bold">Postcode</div>
        <div className="mb-6">{ account.postalCode }</div>
        
        <div className="font-bold">Mobiel telefoonnummer</div>
        <div className="mb-6">{ account.phoneNumber }</div>

        <div className="font-bold">Emailadres</div>
        <div className="mb-6">
          { account.emailAddress }
        
          { 
            account.emailVerified ? 
              <img 
                className="z-20 inline-block relative left-[10px] top-[-1px]" 
                src="/gropp_verified.png"
                width="17" 
                height="17" 
                alt="Grop logo"/>
              : <></>
          }
        </div>

        <div className="font-bold">Wachtwoord</div>
        <div className="mb-6">******</div>

        <div className="mt-[-10px]">
          <Ons.Button className="link-color min-w-[150px]"
              onClick={() => { changePassword() }}>
            <span className="redhat-display text-sm normal-case">Wachtwoord&nbsp;wijzigen</span>
          </Ons.Button>
        </div>
        <div className="mb-6"></div>

        <div className="font-bold">Zichtbaar</div>
        <div className="mb-6">{ account.visible ? "ja" : "nee" }</div>

        <div className="font-bold">Aantal items</div>
        <div className="mb-6">{ account.nrItems }</div>

        <div className="font-bold">Aantal aanbiedingen</div>
        <div className="mb-6">{ account.nrOffers }</div>

        <div className="font-bold">Aantal biedingen</div>
        <div className="mb-6">{ account.nrBids }</div>

        <div className="font-bold">Aantal transacties</div>
        <div className="mb-6">{ account.nrTrades }</div>
        
        <div className="w-full text-center justify-center mb-12">
          <Ons.Button className="link-color p-2 min-w-[150px]" 
            onClick={() => doEditProfile()}>
              <span className="redhat-display text-sm normal-case">Profiel&nbsp;bewerken</span></Ons.Button>
        </div>

        <div className="mb-24"></div>
      </div>
    </>);
}