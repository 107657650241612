import moment from 'moment';
import { useEffect, useState } from "react";
import { Modal, Button, ModalDialog, DialogTitle, Divider, DialogContent, DialogActions } from "@mui/joy";
import { ChatBuilder } from "../../../domain/chat";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import { MobileChats } from "./mobile.chats";
import ChatPanelMessages from "../../chat/chat.panel.messages";
import Loading from '../../../components/loading';

export default function MyChats() {
  const [selectedChat, setSelectedChat] = useState<any>(null);
  const [selectedIdx, setSelectedIdx] = useState<number>(-1);
  const [allChats, setAllChats] = useState<any[]>(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [accountId, setAccountId] = useState<string>(null);

  const loadData = () => {
    var url = BACKEND_URL + "/chats";

    authService.getSecure(url)
      .then((result) => {
        var array = result.data.data.map((chat) => {
          return ChatBuilder.buildChat(chat);
        });

        setAllChats(array);

        utils.getLoggedInAccountId()
          .then((result) => {
            setAccountId(result);
          });
      });
  }

  useEffect(() => {
    if (!allChats) {
      loadData();
    }
  }, null);

  function onCloseChat() {
    
  }

  function openChat(chatId, i) {
    setSelectedChat(null);

    var chat = allChats.find((chat) => chat.id == chatId);

    setSelectedChat(chat);
    setSelectedIdx(i);
  }

  function printChatDate(chat): string {
    if (chat && chat.createTime) {
      return moment(chat.createTime).format("DD-MM-yyyy");
    } else {
      return "";
    }
  }

  function printChatTime(chat): string {
    if (chat && chat.createTime) {
      return moment(chat.createTime).format("HH:mm:ss");
    } else {
      return "";
    }
  }

  function deleteChat() {
    setShowDeleteConfirmation(true);
  }

  function doDeleteChat(chatId) {
    var url = BACKEND_URL + "/chats/" + chatId;

    authService.deleteSecure(url)
      .then((result) => {
        setShowDeleteConfirmation(false);
        loadData();
      })
      .catch((error) => {

      });
  }

  if (!accountId) {
    return <Loading/>;
  }

  if (allChats && allChats.length == 0) {
    return (
      <div className="w-screen text-center pt-10 redhat-display">
        <div className="m-8">Er zijn nog geen chats. Ga naar een account of een item om een nieuwe chat te beginnen.</div>
      </div>
    );
  }

  function renderWeb() {
    return (
      <>
        <div className="w-screen chat-screen max-h-screen">
          <div className="flex flex-row">
            <div className="p-0 min-w-[250px] chat-overview-panel overflow-y-scroll">
              {
                allChats ? 
                  allChats.map((chat, i) => (
                    <div key={chat.id}>
                      <div className={`chat-close-panel max-h-screen min-h-[100px] flex flex-col cursor-pointer ${ selectedIdx == i ? "bg-slate-100" : "" }`} 
                          onClick={() => openChat(chat.id, i)}>
                        <div className="flex flex-row mx-auto justify-center w-full text-center mt-auto mb-auto">
                          <img 
                            className="avatar-img-account-page rounded-full h-16 w-16 bg-gray-50 p-2 ml-[10px]"
                            src={`${BACKEND_URL}/static/img/account/${accountId == chat.fromAccountId ? chat.toAccountId : chat.fromAccountId }`}
                            alt="Account avatar"
                            width={80}
                            height={80}/>

                          <div className="grow flex flex-col justify-start align-middle mt-auto mb-auto">
                            <div className="ml-4 text-left text-ellipsis overflow-hidden break-keep max-w-[100px]">
                              { accountId == chat.fromAccountId ? chat.toFirstName : chat.fromFirstName }
                            </div>

                            <div className="ml-4 text-left">
                              {printChatDate(chat)} {printChatTime(chat)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="chat-close-button relative top-[-75px] left-[230px] text-sm opacity-0">
                        <a href="#" onClick={() => deleteChat()}>x</a>
                      </div>

                      <Modal open={showDeleteConfirmation}>
                        <ModalDialog variant="plain" role="alertdialog">
                          <DialogTitle>Verwijderen</DialogTitle>
                          <Divider />
                          <DialogContent>
                            Weet je zeker dat je deze chat wil verwijderen?
                          </DialogContent>
                          <DialogActions>
                            <Button variant="solid" 
                              className="background-accent-color" 
                              onClick={() => setShowDeleteConfirmation(false)}>Nee</Button>
                            <Button variant="plain" color="neutral" onClick={() => doDeleteChat(chat.id)}>Ja</Button>
                          </DialogActions>
                        </ModalDialog>
                      </Modal>
                    </div>
                ))
                : <></>
              }
            </div>

            <div className="p-10 pt-0 pb-0 w-full">
              { 
                selectedChat ?
                  <ChatPanelMessages 
                    fromAccountId={selectedChat.fromAccountId} 
                    toAccountId={selectedChat.toAccountId}/> 
                : <></>
              }
            </div>
          </div>
        </div>
      </>);
  };

  if (utils.isMobile()) {
    return <MobileChats/>
  } else {
    return renderWeb();
  }
}