import * as Ons from "react-onsenui";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loading from "../../components/loading";
import BidsItem from "./bids/page";
import { utils } from "../../services";
import { OfferState } from "../../domain/offer/offer.state";
import PriceType from "../../domain/offer/offer.type";

export default function MobileBidPage({ offer }) {
  const [remainingAuctionTime, setRemainingAuctionTime] = useState<string>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isOpenAuction, setIsOpenAuction] = useState<boolean>(false);
  
  function loadData() {
    setLoaded(true);

    setIsOpenAuction(
      offer && 
      offer.offerType == PriceType.AUCTION && 
      offer.offerState == OfferState.OPEN);

    decreaseSeconds();
  }

  function decreaseSeconds() {
    if (!isOpenAuction) {
      return;
    }
    
    setRemainingAuctionTime(utils.getRemainingAuctionTimeStr(offer));

    setTimeout(() => {
      decreaseSeconds();
    }, 1000);
  }

  useEffect(() => {
    if (!loaded) {
      loadData();
    }
  }, null);

  if (!offer) {
    return <Loading/>;
  }

  var header = isOpenAuction ?
    <>
      <div className="pb-1 text-center w-full">
        <div className="flex flex-row justify-center grow gap-4">
          <div className="flex flex-col">
            <div className="redhat-display text-white text-sm">Resterende&nbsp;tijd:</div>
            <div className="auction-time-bid-screen mt-[-5px]" suppressHydrationWarning>
              <span className="text-sm">{remainingAuctionTime}</span>
            </div>
          </div>

          <div>
            <div className="redhat-display text-white text-sm">Veiling&nbsp;sluit:</div>
            <div className="auction-time-bid-screen mt-[-5px]">
              <span className="text-sm">{ utils.getAuctionCloseTime(offer) }</span>
            </div>
          </div>

          <div>
            <div className="redhat-display text-white text-sm">Totale&nbsp;duur:</div>
            <div className="auction-time-bid-screen mt-[-5px]">
              <span className="text-sm">{ utils.getAuctionDuration(offer) }</span>
            </div>
          </div>
        </div>
      </div>
    </>
    : <></>;

  return (
    <>
      { header }

      <BidsItem offerId={offer.id} showBidButton={true}/>

      <div className="mb-[80px]"/>
    </>
  );
}