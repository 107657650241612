import * as Ons from "react-onsenui";
import { useState } from "react";
import { stripeAccountOnboarding } from "../../../domain/payment/stripe.connect";

export default function StripeOnboarding({ 
    accountId, 
    stripeAccountId, 
    stripeClientSecret, 
    colorScheme, 
    callback 
  }) {
  const [clickedNext, setClickedNext] = useState<boolean>(false);
  
  function stripeAccountOnboardingExit() {
    console.log("stripeAccountOnboardingExit");
    callback(accountId, stripeAccountId, stripeClientSecret);
  }

  return (
    <>
      {
        clickedNext ?
          <>
            {
              stripeAccountOnboarding(
                stripeClientSecret, 
                () => stripeAccountOnboardingExit(), 
                colorScheme)
            }
          </>
          :
          <>
            <div className="redhat-display mt-0 m-4 text-center">Om betalingen te kunnen doen, wordt je aangemeld bij Stripe, onze betaalprovider. In het volgende scherm, kies "No URL" en vul daarna "gropp" in als omschrijving:</div>

            <div className="text-center w-full">
              <img 
                className="mb-4 ml-auto mr-auto w-[220px] stripe-example-border" 
                src="/stripe_instruction1.png"
                alt="Stripe instruction 1"/>

              <img 
                className="ml-auto mr-auto mb-4 w-[220px] stripe-example-border" 
                src="/stripe_instruction2.png"
                alt="Stripe instruction 2"/>
            </div>
            
            <div className="text-center w-full mt-8">
              <Ons.Button className="link-color p-2 min-w-[150px]" onClick={() => setClickedNext(true)}>
                <span className="redhat-display text-sm capitalize">Verder</span>
              </Ons.Button>
            </div>
          </>
      }
    </>
  );
}