import * as Ons from "react-onsenui";
import { Utils } from "../services/utils";
import { EventBus } from "../services/event.bus";
import { settings } from "../services";
import ColorScheme from "../domain/color.scheme";

export function DefaultMobileToolbar(
  {
    text = "",
    backButton = true, 
    showMapIcon = false, 
    mapCallback=() => {},
    showBackConfirmation = false,
    showLogo = false 
  }: 
  {
    text?: string,
    backButton?: boolean,
    showMapIcon?: boolean,
    mapCallback?: any,
    showBackConfirmation?: boolean,
    showLogo?: boolean
  }) {
  function toggleCancelWarning() {
    if (showBackConfirmation) {
      EventBus.getInstance().dispatch<void>(EventBus.ON_CANCEL);
    } else {
      Utils.mobileNavigator.popPage({ animation: "none" });
      EventBus.getInstance().dispatch<void>(EventBus.ON_BACK);
    }
  }

  function getBackButton() {
    return (
      <div className="link-color relative top-[-24px]" onClick={() => { toggleCancelWarning() }}>
        <Ons.Icon icon="md-chevron-left" className="link-color ml-2 mr-2 relative top-[3px]"/>
        <span className="text-sm redhat-display capitalize">Terug</span>
      </div>
    );
  }

  return (
    <>
      <Ons.Toolbar modifier="material" className="ons-toolbar">
        <div className="center">
          <div className="body-style">
            {
              showLogo ?
                <>
                  <div className="w-full mt-3">
                    <img 
                      className="w-[100px] ml-auto mr-auto"
                      src={settings.colorScheme == ColorScheme.DARK ? "/gropp_logo_transparent_dark.png" : "/gropp_logo_transparent_beige.png" }
                      width="100" 
                      height="100" 
                      alt="Grop logo"/>
                  </div>
                </>
                :
                <>
                  <div className="flex flex-row w-full h-full">
                    <div className="">{ backButton ? getBackButton() : <></> }</div>
                      <div className={`text-lg mt-[9px] ml-[10px] grow ${ backButton ? " relative top-[-17px]": ""}`}>{ text }</div>

                      {
                        showMapIcon ? 
                          <div className="min-h-[28px] min-w-[28px] mt-[3px] mr-2" onClick={() => mapCallback() }>
                            <Ons.Icon icon="md-globe" className="search-icons"/>
                          </div>
                          : <></>
                      }
                  </div>

                  <div className={`w-full h-[1px] ml-[10px] mt-[-3px] border-solid search-line ${backButton ? " relative top-[-23px]" : ""}`}></div>
                </>
            }
          </div>
        </div>
      </Ons.Toolbar>
    </>);
}