enum DocumentType {
  PASSPORT = "PASSPORT",
  ID_CARD = "ID_CARD",
  DRIVERS_LICENSE = "DRIVERS_LICENSE"
}

export function documentTypeFromString(documentType: string): DocumentType {
  switch (documentType) {
    case "PASSPORT":
      return DocumentType.PASSPORT;
    case "ID_CARD":
      return DocumentType.ID_CARD;
    case "DRIVERS_LICENSE":
      return DocumentType.DRIVERS_LICENSE;
    default:
      return null;
  }
}

export function documentTypeToString(documentType: DocumentType): string {
  switch (documentType) {
    case DocumentType.PASSPORT:
      return "Paspoort";
    case DocumentType.ID_CARD:
      return "ID-kaart";
    case DocumentType.DRIVERS_LICENSE:
      return "Rijbewijs";
    default: 
      return "";
  }
}

export const ALL_DOCUMENT_TYPES = [
  DocumentType.PASSPORT,
  DocumentType.ID_CARD,
  DocumentType.DRIVERS_LICENSE
];

export default DocumentType;