import * as Yup from 'yup';
import * as Ons from "react-onsenui";
import { Radio } from "@mui/joy";
import { useNavigate, useLoaderData } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import AccountBuilder from "../../../domain/account/account.builder";
import Map from "../../../components/map";
import DeliveryMethod from "../../../domain/trade/delivery.method";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { authService, utils } from "../../../services";
import { DEFAULT_MAP_LOCATION } from "../../../domain/constants";
import { BACKEND_URL } from "../../../domain/env";
import ShippingConfiguration from '../../shipping/page';
import { Utils } from '../../../services/utils';
import MyBids from '../../account/bids/page';
import { DefaultMobileToolbar } from '../../default.mobile.toolbar';
import MobilePaymentPage from '../../payment/mobile.payment.page';
import PaymentStatus from '../../../domain/payment/payment.result';
import OfferType from '../../../domain/offer/offer.type';

export default function NewTrade({ offerId }: { offerId: string }) {
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(DeliveryMethod.MANUAL);
  const [useAccountAddress, setUseAccountAddress] = useState<boolean>(true);
  const [accountCoords, setAccountCoords] = useState<any>(null);
  const [ownerCoords, setOwnerCoords] = useState<any>(null);
  const [mapMarkers, setMapMarkers] = useState<any[]>(null);
  const [offer, setOffer] = useState<any>(null);
  const [accountId, setAccountId] = useState<string>(null);
  const [enableDelivery, setEnableDelivery] = useState<boolean>(false);
  const [enableFinish, setEnableFinish] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>();
  const [isBuyer, setIsBuyer] = useState<boolean>();

  const navigate = useNavigate();
  const params: any = useLoaderData();

  const loadData = (offerId: string) => {
    utils.getLoggedInAccountId().then((result) => {
      setAccountId(result);

      var url = BACKEND_URL + "/offers/" + offerId;

      authService.getSecure(url)
        .then((result) => {
          var offer = OfferBuilder.buildOffer(result.data);

          if (offer != null) {
            setOffer(offer);
  
            fetchAccountInfo(result,
              (info) => {
                setAccountCoords({
                  latitude: info.latitude,
                  longitude: info.longitude
                });
              });
  
            fetchAccountInfo(offer.accountId,
              (info) => {
                setOwnerCoords({
                  latitude: info.latitude,
                  longitude: info.longitude
                });
              });
          }
        });
    });
  }

  function fetchAccountInfo(ownerId: string, callback: (Account) => void) {
    var url = BACKEND_URL + "/accounts/info/" + ownerId;

    authService.getSecure(url)
      .then((result) => {
        var account = AccountBuilder.buildAccount(result.data);      
        callback(account);
      });
  }

  useEffect(() => {
    if (!offer) {
      loadData(offerId);
    }
  }, [null]);
  
  function payNow() {
    // First, create a bid on this offer various checks 
    // have to be performed whether this is allowed, if 
    // this can directly result in a trade, a trade id is returned.
    var url = BACKEND_URL + "/bids/" + 
      (offer.offerType == OfferType.USER_OFFER ? "user" : "business") + 
      "?offerId=" + offer.id;

    var body = {
      "accountId": accountId,
      "offerId": offer.id,
      "amount": offer.amount
    };

    authService.postSecure(url, body)
      .then((result) => {
        if (result.status == 201) {
          // This should never happen if the 
          // bid creation was successful
          if (!result.data.tradeId) {
            return;
          }

          // Bid creation successful, the system 
          // should directly return a trade id
          utils.getStripeAccountId().then((stripeAccountId) => {
            utils.getStripeClientSecret().then((stripeClientSecret) => {
              var tradeId = result.data.tradeId;

              var url = BACKEND_URL + "/payments/checkout-session?tradeId=" + tradeId;

              console.log(url);
              
              var body = {};
              
              // Create a Stripe checkout session
              authService.postSecure(url, body)
                .then((result) => {
                  console.log(result.data);

                  if (result.status == 201) {
                    Utils.mobileNavigator.pushPage({
                      page: <MobilePaymentPage 
                          tradeId={tradeId}
                          stripeAccountId={stripeAccountId}
                          stripeClientSecret={stripeClientSecret}
                          paymentFinishedCallback={paymentFinishedCallback}/>,
                      renderToolbar: () => {
                        return <DefaultMobileToolbar text="Betalen" backButton={true}/>
                      }
                    }, { animation: "none" }); 
                  }
                }).catch((e) => {
                  console.log(e);
                });
            });
          });
        }
      }).catch((e) => {
        console.error(e);
      });
  }

  function paymentFinishedCallback(status, tradeId, error?) {
    if (status == PaymentStatus.SUCCESS) {
      setPaymentSuccessful(true);
      setEnableDelivery(true);
      setErrorMessage(null);
    } else {
      console.error("Payment failed: ", error);
      setPaymentSuccessful(false);
      setEnableDelivery(false);
      setEnableFinish(false);
      setErrorMessage(error);
    }
  }

  function deliveryMethodSetCallback(success, error) {
    if (success) {
      setEnableFinish(true);
      setErrorMessage(null);
    } else {
      console.error("Choosing delivery method failed");
      setEnableDelivery(false);
      setEnableFinish(false);
      setErrorMessage(error);
    }
  }

  const handleDeliveryMethodChange = (e) => {
    if (e.target.value == "MANUAL") {
      setDeliveryMethod(DeliveryMethod.MANUAL);
    } else if (e.target.value == "PICKUP_HOME") {
      setDeliveryMethod(DeliveryMethod.PICKUP_HOME);
    } else if (e.target.value == "SERVICE_POINT") {
      setDeliveryMethod(DeliveryMethod.SERVICE_POINT);
    }
  }

  function finishTrade() {

  }
  
  const formik = useFormik({
		initialValues: {
		  title: ""
    },
    validationSchema: Yup.object({
			title: Yup.string()
			  .required('Vul een titel in')
    }),
    onSubmit: (values, { setSubmitting }) => {
    
    }
  });

  if (!offer) {
    return (<></>);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="redhat-display ml-4 mr-4 mt-4">
        <div><span className="font-bold link-color">1.&nbsp;</span>Betalen</div>

        {
          paymentSuccessful ?
            <div className="mt-4">U heeft betaald.</div>
            : 
            <>
              <div className="mt-4">Prijs:&nbsp;&nbsp;
                <span className="font-bold bid-amount">{"\u20AC" + utils.roundedAmount(offer.amount, 2)}</span>
              </div>

              <div className="mt-4 mb-8">
                <Ons.Button className="link-color min-w-[150px]" onClick={payNow}>
                  <span className="redhat-display text-sm normal-case">Betalen</span>
                </Ons.Button>
              </div>
            </>
        }

        { 
          enableDelivery ?
            <>
              <div className="mt-4"><span className="font-bold link-color">2.&nbsp;</span>Bezorging</div>
              <div className="mb-8">
                <div className="mt-4 mb-4">
                  <p>Selecteer uw bezorgmethode:</p>
                  <ShippingConfiguration tradeId={"b7859c76-14d8-4d97-8932-7cadbd91aca7"} isBuyer={isBuyer}/>
                </div>
              </div>
            </>
            : 
            <>
              <div className="mt-4"><span className="font-bold link-color">2.&nbsp;</span>Bezorging</div>
            </>
        }
    
        {
          enableFinish ?
            <>
              <div className="mt-4"><span className="font-bold link-color">3.&nbsp;</span>Afronden</div>
              <div>
                <div className="pt-4 pb-7 mr-4">
                  <div className="mt-4 mb-4 min-w-[400px]">
                    <div className="mt-2 text-xs text-slate-100">Als U deze transactie voltooid gaat U akkoord met de algemene voorwaarden van Gropp.nl en verklaart U 18 jaar of ouder te zijn.</div>
                  </div>
                  <button className="normal-button" onClick={() => finishTrade()}>Afronden</button>
                </div>
              </div>
            </>
            : 
            <>
              <div className="mt-4"><span className="font-bold link-color">3.&nbsp;</span>Afronden</div>
            </>
        }

        { 
          errorMessage ? 
            <>
            </>
            : 
            <>
            </>
        }
      </div>
    </form>
  );
}