import * as Ons from "react-onsenui";
import { authService, settings, utils } from "../../../services";
import { useEffect, useState } from "react";
import { ALL_NOTIFICATION_TYPES, notificationTypeToString } from "../../../domain/inbox/notification.type";
import { NotificationSettings, PrivacySettings } from "../../../domain/account/settings";
import { Utils } from "../../../services/utils";
import { BACKEND_URL } from "../../../domain/env";
import ColorScheme from "../../../domain/color.scheme";
import Checkbox from "../../../components/checkbox";

export default function MobileSettings() {
  const [colorScheme, setColorScheme] = useState<any>(null);

  const [enabledNotifications, setEnabledNotifications] = 
    useState<NotificationSettings>(NotificationSettings.DEFAULT);

  const [privacySsettings, setPrivacySettings] = 
    useState<PrivacySettings>(PrivacySettings.DEFAULT);
  
  useEffect(() => {
    settings.load();
    setColorScheme(settings.colorScheme);
    setEnabledNotifications(settings.notificationSettings);
    setPrivacySettings(settings.privacySettings);
  }, null);

  function changeColorScheme(colorScheme) {
    setColorScheme(colorScheme);
    settings.updateColorScheme(colorScheme);
  }

  function cancelSettings() {
    Utils.mobileNavigator.popPage({ animation: "none" });
  }

  function saveSettings() {
    utils.getLoggedInAccountId().then((accountId) => {
      settings.save();

      var url = BACKEND_URL + "/accounts/settings";
  
      var body = {
        "accountId": accountId,
        "privacySettings": settings.privacySettings.serialize(),
        "notificationSettings": settings.notificationSettings.serialize()
      }
  
      authService.postSecure(url, body);
  
      Utils.mobileNavigator.popPage({ animation: "none" });
    });
  }
  
  return (
    <>
      <div className="w-full p-4 redhat-display">
        <div>Kleurenschema:</div>
        
        <div className="mt-4 mb-4">
          <div key="BEIGE" className="mt-2 mb-2">
            <label className="left">
              <input type="radio"
                id="checkbox-BEIGE"
                className="relative top-[4px]"
                onChange={() => changeColorScheme(ColorScheme.BEIGE)}
                checked={colorScheme && colorScheme == ColorScheme.BEIGE}/>
            </label>
            <label htmlFor={`checkbox-BEIGE`} className="center redhat-display">Beige</label>
          </div>

          <div key="DARK" className="mt-2 mb-2">
            <label className="left">
              <input type="radio"
                id="checkbox-DARK"
                className="relative top-[4px]"
                onChange={() => changeColorScheme(ColorScheme.DARK)}
                checked={colorScheme && colorScheme == ColorScheme.DARK}/>
            </label>
            <label htmlFor={`checkbox-DARK`} className="center redhat-display">Donker</label>
          </div>
        </div>

        <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

        <div className="mt-2">Meldingen:</div>

        <div className="w-full ml-[-30px]">
          <div className="flex flex-col justify-center">
            <div className="w-full flex flex-row">
              <div className="grow text-right mr-[10px]">Melding</div>
              <div className="min-w-[40px]">App</div>
              <div className="min-w-[40px]">E-mail</div>
            </div>

            {
              ALL_NOTIFICATION_TYPES.map(nt => (
                <div className="w-full flex flex-row mt-2">
                  <div className="grow text-right mr-4">{ notificationTypeToString(nt) }</div>
                  <div className="min-w-[40px] mb-2">
                    <Checkbox
                      id={"notification-app-" + nt}
                      label=""
                      className=""
                      value={enabledNotifications.isEnabled("app", nt)}
                      disabled={false}
                      onChange={() => enabledNotifications.toggle("app", nt)}
                    />
                  </div>
                  <div className="min-w-[40px] mb-2">
                    <Checkbox
                      disabled={false}
                      id={"notification-email-" + nt}
                      label=""
                      className=""
                      onChange={() => enabledNotifications.toggle("email", nt)}
                      value={enabledNotifications.isEnabled("email", nt)}
                    />
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

      <div className="m-4 ml-2 redhat-display">Privacy:</div>
      <div className="flex flex-col m-1 ml-2">
        <div className="mb-4">
          <label className="right">
            <Checkbox
              disabled={false}
              id="showLastName"
              label=""
              className="relative top-[4px]"
              onChange={ e => {
                
              }}
              value={null}
            />
          </label>
          <label htmlFor={`showLastNameInputId`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;achternaam</label>
        </div>
        
        <div className="mb-4">
          <label className="right">
            <Checkbox
              disabled={false}
              id="showAddress"
              label=""
              className="relative top-[4px]"
              onChange={ e => {
                
              }}
              value={null}
            />
          </label>
          <label htmlFor={`showAddressInputId`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;adres</label>
        </div>
        
        <div className="mb-4">
          <label className="right">
            <Checkbox
              disabled={false}
              id="showBirthDate"
              label=""
              className="relative top-[4px]"
              onChange={ e => {
                
              }}
              value={null}
            />
          </label>
          <label htmlFor={`showBirthDateInputId`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;geboortedatum</label>
        </div>

        <div className="mb-4">
          <label className="right">
            <Checkbox
              disabled={false}
              id="ShowPhoneNumber"
              label=""
              className="relative top-[4px]"
              onChange={ e => {
                
              }}
              value={null}
            />
          </label>
          <label htmlFor={`ShowPhoneNumberInputId`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;telefoonnummer</label>
        </div>

        <div className="mb-4">
          <label className="right">
            <Checkbox
              disabled={false}
              id="showCoordinates"
              label=""
              className="relative top-[4px]"
              onChange={ e => {
                
              }}
              value={null}
            />
          </label>
          <label htmlFor={`ShowCoordinatesInputId`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;locatie&nbsp;op&nbsp;kaart</label>
        </div>
        
        <div className="mb-4">
          <label className="right">
            <Checkbox
              disabled={false}
              id="showStatistics"
              label=""
              className="relative top-[4px]"
              onChange={ e => {
                
              }}
              value={null}
            />
          </label>
          <label htmlFor={`ShowStatisticsInputId`} className="mt-4 mr-4 ml-2 redhat-display">Toon&nbsp;statistieken</label>
        </div>
      </div>

      <div className="mr-auto h-[1px] border-solid search-line-mobile"></div>

      <div className="flex flex-row gap-4 w-full mt-8 mb-8 justify-center text-center">
        <button className="relative top-[-5px] redhat-display button min-w-[100px] max-h-[32px] mb-4 link-color"      
            onClick={() => cancelSettings()}>
          <span className="relative top-[-5px] text-sm redhat-display pt-0">Annuleren</span>
        </button>

        <button className="relative top-[-5px] redhat-display button min-w-[100px] max-h-[32px] mb-4 link-color"  
            onClick={() => saveSettings()}>
          <span className="relative top-[-5px] text-sm redhat-display pt-0">Opslaan</span>
        </button>
      </div>
    </div>
  </>);
}