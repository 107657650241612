import moment from "moment";
import { DATE_TIME_FORMAT } from "../constants";
import Bid from "./bid";
import { bidStateFromString } from "./bid.state";
import { bidTypeFromString } from "./bid.type";
import PurchaseBid from "./purchase.bid";
import SimpleBid from "./simple.bid";

class BidBuilder {
  static buildBid(data: any): Bid {
    var bid = null;

    if (data.bidType == "SIMPLE_BID") {
      bid = new SimpleBid(
        data.id,
        bidTypeFromString(data.bidType),
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.offerId,
        data.itemId,
        data.bidAccountId,
        data.ownerAccountId,
        bidStateFromString(data.bidState),
        data.amount,
        data.isHighestBid,
        data.nrBids);
    } else if (data.bidType == "PURCHASE_BID") {
      bid = new PurchaseBid(
        data.id,
        bidTypeFromString(data.bidType),
        moment(data.createTime, DATE_TIME_FORMAT).toDate(),
        data.offerId,
        data.itemId,
        data.bidAccountId,
        data.ownerAccountId,
        bidStateFromString(data.bidState),
        data.amount);
    }

    return bid;
  }
}

export default BidBuilder;