enum ShippingMethod {
  DO_IT_YOURSELF = "DO_IT_YOURSELF",
  SERVICE_POINT = "SERVICE_POINT",
  HOME_PICKUP = "HOME_PICKUP"
}

export function shippingMethodFromString(str: string): ShippingMethod {
  switch (str) {
    case "DO_IT_YOURSELF":
      return ShippingMethod.DO_IT_YOURSELF;
    case "SERVICE_POINT":
      return ShippingMethod.SERVICE_POINT;
    case "HOME_PICKUP":
      return ShippingMethod.HOME_PICKUP;
    default:
      return null;
  }
}

export function shippingMethodToString(shippingMethod: ShippingMethod) {
  switch (shippingMethod) {
    case ShippingMethod.DO_IT_YOURSELF:
      return "Zelf regelen";
    case ShippingMethod.SERVICE_POINT:
      return "Ophalen bij servicepunt";
    case ShippingMethod.HOME_PICKUP:
      return "Thuis laten ophalen door bezorgdienst";
    default:
      return null;
  }
}

export default ShippingMethod;