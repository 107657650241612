import OpeningTimes, { OpeningDay } from "./opening.times";

export default class OpeningTimesBuilder {
  static buildOpeningTimes(data: any): OpeningTimes {
    var result = new OpeningTimes();

    for (let day of Object.keys(data.openingTimes)) {
      var thisDay = data.openingTimes[day];

      for (let block of thisDay) {
        var startTime = block["startTime"].split(":");
        var endTime = block["endTime"].split(":");

        var startTimeHours = startTime[0];
        var startTimeMinutes = startTime[1];

        var endTimeHours = endTime[0];
        var endTimeMinutes = endTime[1];

        var openingDay = new OpeningDay();

        openingDay.addBlock(
          parseInt(startTimeHours), parseInt(startTimeMinutes),
          parseInt(endTimeHours), parseInt(endTimeMinutes));
      }

      result.addDay(openingDay);
    }

    return result;
  }
}