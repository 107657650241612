import * as Ons from "react-onsenui";

export default function BidPanel({ 
    plus10 = () => {}, 
    plus100 = () => {}, 
    retractBid = () => {}, 
    toggleBidInput = () => {}, 
    bidOnItem = () => {}}) {
  return (
    <div className="w-full justify-center mt-[6px] mobile-top-border mobile-bid-panel pt-2 z-[100] absolute bottom-[20px]">
      <div className="grid grid-rows-1">
        <div className="flex flex-col w-full">
          <div>
            <input
              id="bid-amount"
              type="text"
              className="search-field redhat-display w-full ml-2 mr-2 pl-6"
              placeholder=""/>
            
            <div className="relative top-[-35px] left-[17px] w-[10px] text-sm euro-sign redhat-display">&#x20AC;</div>
          </div>

          <div className="grid grid-rows-2 justify-center mt-[15px]">
            <div className="grid grid-cols-2 w-full justify-center mt-[-30px]">
              <div className="redhat-display text-md font-bold white max-w-[80px] ml-8"
                  onClick={() => plus10()}>
                <img 
                  className="relative top-[-2px] h-[15px] w-[15px] mr-1 inline-block" 
                  src="/gropp_hand.png" 
                  width="17" 
                  height="5" 
                  alt="Grop logo"/>+&#x20AC;10
              </div>

              <div className="redhat-display text-md font-bold white min-w-[105px]"
                  onClick={() => retractBid()}>
                <Ons.Icon icon="md-undo" className="tabbar-icons link-color mr-1"/>Terugtrekken
              </div>
            </div>

            <div className="grid grid-cols-2 w-full justify-center">
              <div className="redhat-display text-md font-bold white max-w-[80px] ml-8 mt-[-10px]" 
                  onClick={() => plus100()}>
                <img 
                  className="relative top-[-2px] h-[15px] w-[15px] mr-1 inline-block" 
                  src="/gropp_hand.png" 
                  width="17" 
                  height="5" 
                  alt="Grop logo"/>+&#x20AC;100
              </div>

              <div className="redhat-display text-md font-bold white min-w-[60px] mt-[-10px]" 
                  onClick={() => toggleBidInput()}>
                <Ons.Icon icon="md-close" className="tabbar-icons link-color mr-1"/>Sluiten</div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full text-center mt-4">
        <Ons.Button 
            className="link-color w-[200px]"
            onClick={() => bidOnItem()}>
          <span className="redhat-display text-sm capitalize">Bieden</span>
        </Ons.Button>
      </div>
    </div>
  );
}