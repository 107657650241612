import * as Ons from "react-onsenui";
import * as Yup from 'yup';

import { useFormik } from "formik";
import { useState } from "react";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import { useNavigate } from "react-router-dom";
import { Utils } from "../../../services/utils";
import MobileNewItem from "../../items/new/mobile.new.item";
import { DefaultMobileToolbar } from "../../default.mobile.toolbar";
import MobilePasswordReset from "./reset/mobile.password.reset";

export default function ResetPassword() {
  const [emailAddress, setEmailAddress] = useState<string>(null);
  const navigate = useNavigate();

  function resetPassword() {
    var url = BACKEND_URL + "/accounts/reset-password";
    
    var body = {
      "emailAddress": emailAddress
    }

    authService.post(url, body)
      .then((_) => {
        navigate("/gropp/account/password/reset")
      });
  }

  const formik = useFormik({
		initialValues: {
		  emailAddress: "",
		},
		validationSchema: Yup.object({
			emailAddress: Yup.string()
        .email('Ongeldig emailadres')
        .required('Vul een emailadres in')
		}),
		onSubmit: (values, { setSubmitting }) => {
			Utils.mobileNavigator.pushPage({
        page: <MobilePasswordReset/>,
        name: "new-item/" + utils.makeId(5),
        renderToolbar: () => {
          return <DefaultMobileToolbar text="Wachtwoord herstellen"/>
        }
      }, { animation: "none" });
		}
  });

  function renderMobile() {
    return (
      <div className="p-2">
        <form onSubmit={formik.handleSubmit}>
          <div className="m-1 ml-2 redhat-display">Emailadres:</div>
          <input
            type="text"
            id="emailAddress"
            className="search-field redhat-display m-1 ml-2"
            placeholder=""
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}/>

          {
            formik.touched.emailAddress && formik.errors.emailAddress ?
              <div className="text-xs ml-[10px] mb-2 mt-[-5px] text-red-600 redhat-display">{formik.errors.emailAddress}</div>
              : null
          }

          <div className="w-full justify-center text-center mt-4">
            <button className="relative top-[-5px] button max-h-[40px] mb-4 link-color" type="submit">
              <span className="text-sm redhat-display pt-0">Wachtwoord&nbsp;herstellen</span>
            </button>
          </div>
        </form>
      </div>);  
  }

  function renderWeb() {
    return (
      <form onSubmit={formik.handleSubmit}>
        <div className="justify-center text-center mt-8">
          Vul je emailadres in om je wachtwoord te herstellen.
          <br/>
          Je krijgt een email toegestuurd met een link om je wachtwoord te herstellen.
          <br/>
          
          <div className="flex flex-row justify-center mt-8">
            <label className="mr-4 text-right" htmlFor="emailAddress">E-mail:</label>

            <div className="grid grid-rows-2">
              <input 
                type="text" 
                id="emailAddress" 
                data-testid="forgot-password-email" 
                className=""
                onChange={ e => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.emailAddress}
                />

                {
                  formik.touched.emailAddress && formik.errors.emailAddress ? (
                    <div className="text-xs text-red-600 text-right mt-1">{formik.errors.emailAddress}</div>) 
                    : <div></div>
                }
            </div>
          </div>
        </div>

        <div className="text-center mb-20">
          <div className="pt-6 pb-7 mr-4">
          <button className="relative top-[-5px] button max-h-[40px] mb-4 link-color" type="submit">
              <span className="text-sm redhat-display pt-0">Wachtwoord&nbsp;herstellen</span>
            </button>
          </div>
        </div>

        <div className="h-[400px]"></div>
      </form>
    );
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}