import * as Ons from "react-onsenui";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { OfferState, offerStateToString } from "../../../domain/offer/offer.state";
import { TradeBuilder } from "../../../domain/trade/trade.builder";
import { Utils } from "../../../services/utils";
import Loading from "../../../components/loading";
import BidBuilder from "../../../domain/bid/bid.builder";
import AccountPage from "../../accounts/[accountId]/page";
import OfferPage from "../../offers/[offerId]/page";
import AccountBuilder from "../../../domain/account/account.builder";
import Auction from "../../../domain/offer/auction";

export default function MobileOutgoingBid({ bidId }) {
  const [bid, setBid] = useState<any>(null);
  const [account, setAccount] = useState<any>(null);
  const [offer, setOffer] = useState<any>(null);
  const [showBidRetractedMessage, setShowBidRetractedMessage] = useState<boolean>(false);
  const [remainingAuctionTime, setRemainingAuctionTime] = useState<string>(null);
  
  function decreaseSeconds() {
    if (!offer) {
      return;
    }

    setRemainingAuctionTime(utils.getRemainingAuctionTimeStr(offer));

    setTimeout(() => {
      decreaseSeconds();
    }, 1000);
  }

  function getOfferState(offer) {
    var state = offerStateToString(offer.offerState);

    if (state == "Bod geaccepteerd") {
      state = "Ander bod geaccepteerd";
    }

    return state;
  }

  function rejectBid(bid) {
    var url = BACKEND_URL + "/bids/reject/" + bid.id;

    authService.postSecure(url, {})
      .then((result) => {
        if (result.status == 200) {
          setTimeout(() => {
            setShowBidRetractedMessage(true);
          }, 100);
        } else {
          console.error("Failed to reject bid");
        }
      });
  }

  function acceptBid(bid) {
    var url = BACKEND_URL + "/bids/accept/" + bid.id;

    authService.postSecure(url, {})
      .then((result) => {
        var trade = TradeBuilder.buildTrade(result.data);
        
      });
  }

  function closeBidRejectedDialog() {
    setShowBidRetractedMessage(false);
    Utils.mobileNavigator.popPage({ animation: "none" });
  }

  const loadData = () => {
    if (!bidId) {
      return;
    }

    var bidUrl = BACKEND_URL + "/bids/" + bidId;
    
    authService.getSecure(bidUrl)
      .then((bidResult) => {
        var b = BidBuilder.buildBid(bidResult.data);
        setBid(b);

        var accountUrl = BACKEND_URL + "/accounts/info/" + b.bidAccountId;

        authService.getSecure(accountUrl)
          .then((accountResult) => {
            var a = AccountBuilder.buildAccount(accountResult.data);
            setAccount(a);
          });

        var offerUrl = BACKEND_URL + "/offers/" + b.offerId;

        authService.getSecure(offerUrl)
          .then((offerResult) => {
            var o = OfferBuilder.buildOffer(offerResult.data);
            setOffer(o);

            if (o instanceof Auction) {
              decreaseSeconds();
            }
          });
      });
  }

  function changeBid(bid) {
    
  }

  useEffect(() => {
    if (!bid) {
      loadData();
    }
  }, null);

  if (!bid || !account || !offer) {
    return <Loading/>;
  }

  return (
    <>
      <div className="ml-4 mt-4 redhat-display">
        <div className="text-md underline font-bold mb-8">Bieding</div>
        
        <div className="font-bold">Bedrag</div>
        <div className="font-bold link-color text-xl mb-4">{ utils.roundedAmount(bid.amount, 2) }</div>

        <div className="font-bold">Hoogste bieding</div>
        <div className="mb-4">{ bid.isHighestBid ? "ja" : "nee" }</div>

        <div className="font-bold">Tijd</div>
        <div className="mb-4">{ utils.renderPreciseFriendlyDateTime(bid.createTime) }</div>

        {
          offer.allowBidding && offer.amount ?
            <>
              <div className="font-bold">Jouw bod</div>
              <div className="font-bold link-color text-xl mb-4">{ utils.roundedAmount(offer.amount, 2) }</div>
            </>
            : <></>
        }

        {
          offer instanceof Auction ? 
            <>
              <div className="font-bold">Status&nbsp;veiling</div>
              <div className="mb-4">{ getOfferState(offer) }</div>

              {
                offer.offerState == OfferState.OPEN ?
                  <>
                    <div className="font-bold">Resterende&nbsp;veilingtijd</div>
                    <div className="mb-4" suppressHydrationWarning>{ utils.getRemainingAuctionTimeStr(offer) }</div>
                  </>
                  : <></>
              }
              
              <div className="font-bold">Totale&nbsp;veilingduur</div>
              <div className="mb-4">{ utils.getAuctionDuration(offer) }</div>

              { 
                offer.offerState == OfferState.OPEN ?
                  <>
                    <div className="font-bold">Sluiting&nbsp;veiling</div>
                    <div className="mb-4">{ utils.getAuctionCloseTime(offer) }</div>
                  </>
                  : 
                  <>
                    <div className="font-bold">Sluiting&nbsp;veiling</div>
                    <div className="mb-4">{ getOfferState(offer) }</div>
                  </>
              }
              
              {
                offer.startPrice ? 
                  <>
                    <div className="font-bold">Startprijs</div>
                    <div className="font-bold link-color text-xl mb-4">{ utils.roundedAmount(offer.startPrice, 2) }</div>
                  </>
                  : <></>
              }
            </>
            : <></>
        }

        <div className="font-bold">Aantal andere biedingen</div>
        <div className="mb-4">{ bid.nrBids - 1 }</div>
        
        <div className="w-full text-center mb-4">
          <button className="button link-color p-2 min-w-[150px]" onClick={() => changeBid(bid)}>
            <span className="redhat-display text-sm">Aanpassen</span>
          </button>
        </div>
      </div>

      <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

      <div className="max-h-[300px] overflow-y-scroll mobile-embedded-border">
        <div className="text-md underline font-bold">Item</div>

        <OfferPage offerId={offer.id} readOnly={true}/>
      </div>

      <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

      <div className="max-h-[300px] overflow-y-scroll mobile-embedded-border">
        <div className="text-md underline font-bold">Bieder</div>
        <AccountPage accountId={account.id} readOnly={true}/>
      </div>

      <div className="mr-auto ml-[20px] h-[1px] border-solid search-line-mobile"></div>

      <Ons.AlertDialog isOpen={showBidRetractedMessage} cancelable>
        <div className="text-center w-full redhat-display mt-2">
          <span className="alert-dialog-text font-bold">Bieding teruggetrokken</span>
        </div>

        <div className="alert-dialog-text text-center w-full mt-4 p-4 redhat-display">
          De bieding is teruggetrokken. De aanbieder is ingelicht.
        </div>

        <div className="w-full p-4 gap-2 justify-center flex flex-row">
          <Ons.Button 
              onClick={() => {closeBidRejectedDialog()}} 
              className="button min-w-[110px]">
            <span className="redhat-display text-sm">OK</span>
          </Ons.Button>
        </div>
      </Ons.AlertDialog>
    </>);
}