import { Link } from "react-router-dom";
import { BACKEND_URL } from "../../domain/env";
import { useEffect, useState } from "react";
import { authService, utils } from "../../services";
import BidType from "../../domain/bid/bid.type";
import Loading from "../../components/loading";
import MarketplaceDataBuilder from "../../domain/marketplace.data.builder";
import MarketplaceAccountItem from "./marketplace.account.item";
import MarketplaceBidItem from "./marketplace.bid.item";
import MarketplaceOfferItem from "./marketplace.offer.item";
import MarketplaceTradeItem from "./marketplace.trade.item";
import MarketplaceAccount from "../../domain/account/marketplace.account";
import MarketplaceBid from "../../domain/bid/marketplace.bid";
import MarketplaceOffer from "../../domain/offer/marketplace.offer";
import MarketplaceTrade from "../../domain/trade/marketplace.trade";
import MobileMarketplace from "./mobile.marketplace";

export default function Marketplace() {
  const [data, setData] = useState<any>(null);

  function loadData() {
    var url = BACKEND_URL + "/offers/marketplace";

    authService.get(url)
      .then((result) => {
        var marketplaceData = MarketplaceDataBuilder
          .buildMarketplaceData(result.data);
        
        setData(marketplaceData);
      }).catch((e) => {
        console.error(e);
      });
  }

  useEffect(() => {
    if (!data) {
      loadData();
    }
  }, null);

  function renderWebMarketplace() {
    return (
      <div className="w-screen">
        <h1 className="text-xl pl-10 pt-10">Aanbod ({data.nrOffers})&nbsp;
          <Link to="/gropp/offers">Alles bekijken</Link>
        </h1>

        <div className="flex flex-row justify-center flex-wrap w-screen mb-18 mt-4">
          { data.offers
            .slice(0, 8)
            .map((offer: MarketplaceOffer) => (
              <div key={offer.id as any} className="text-xs zoom-in-hover">
                <MarketplaceOfferItem offer={offer}></MarketplaceOfferItem>
              </div>
          ))}
          { data.offers
            .slice(9, 10)
            .map((offer: MarketplaceOffer) => (
              <div key={offer.id as any} className="text-xs">
                <div className="blur-sm">
                  <MarketplaceOfferItem offer={offer}></MarketplaceOfferItem>
                </div>

                <div className="">
                  <button type="submit" className="normal-button relative left-[100px] top-[-220px]">
                    <Link to="/gropp/offers" className="no-link-decoration">Alles bekijken</Link>
                  </button>
                </div>
              </div>
            ))}
        </div>
        
        <h1 className="text-xl pl-10 pt-10 pb-5">Biedingen ({data.nrBids})&nbsp;
          <Link to="/gropp/bids">Alles bekijken</Link>
        </h1>
        
        <div className="flex flex-row justify-center flex-wrap w-screen mb-18 mt-4 pl-10 pr-10">
          { data.bids
              .filter(bid => bid.bidType != BidType.PURCHASE_BID)
              .slice(0, 8)
              .map((bid: MarketplaceBid) => (
                <div key={bid.id as any} className="text-xs zoom-in-hover">
                  <MarketplaceBidItem bid={bid}></MarketplaceBidItem>
                </div>
              ))}
          { data.bids
              .filter(bid => bid.bidType != BidType.PURCHASE_BID)
              .slice(9, 10)
              .map((bid: MarketplaceBid) => (
                <div key={bid.id as any} className="text-xs zoom-in-hover">
                  <div className="blur-sm">
                    <MarketplaceBidItem bid={bid}></MarketplaceBidItem>
                  </div>

                  <div className="">
                    <button type="submit" className="normal-button relative left-[40px] top-[-230px]">
                      <Link to="/gropp/bids" className="no-link-decoration">Alles bekijken</Link>
                    </button>
                  </div>
                </div>
              ))}
        </div>
        
        <h1 className="text-xl pl-10 pt-10">Transacties ({data.nrTrades})&nbsp;
          <Link to="/gropp/trades">Alles bekijken</Link>
        </h1>
        
        <div className="flex flex-row justify-center flex-wrap w-screen mb-18 mt-4 mr-[-200px] pl-10 pr-10 overflow-hidden">
          { data.trades
            .slice(0, 8)
            .map((trade: MarketplaceTrade) => (
              <div key={trade.id as any} className="text-xs zoom-in-hover">
                <MarketplaceTradeItem trade={trade}></MarketplaceTradeItem>
              </div>
            ))}
          { data.trades
            .slice(9, 10)
            .map((trade: MarketplaceTrade) => (
              <div key={trade.id as any} className="text-xs zoom-in-hover">
                <div className="blur-sm">
                  <MarketplaceTradeItem trade={trade}></MarketplaceTradeItem>
                </div>

                <div className="">
                  <button type="submit" className="normal-button text-sm relative left-[80px] top-[-210px]">
                    <Link to="/gropp/trades" className="no-link-decoration">Alles bekijken</Link>
                  </button>
                </div>
              </div>
            ))}
        </div>

        <h1 className="text-xl pl-10 pt-10 pb-10">Accounts ({data.nrAccounts})&nbsp;
          <Link to="/gropp/accounts">Alles bekijken</Link>
        </h1>

        <div className="flex flex-row justify-center flex-wrap w-screen mb-18 mt-12 pl-5 pr-5">
          { data.accounts
            .slice(0, 7)
            .map((account: MarketplaceAccount) => (
              <div key={account.id as any} className="text-xs zoom-in-hover">
                <MarketplaceAccountItem account={account}></MarketplaceAccountItem>
              </div>
            ))}
          { data.accounts
            .slice(8, 9)
            .map((account: MarketplaceAccount) => (
              <div key={account.id as any} className="text-xs zoom-in-hover">
                <div className="blur-sm">
                  <MarketplaceAccountItem account={account}></MarketplaceAccountItem>
                </div>

                <div className="">
                  <button type="submit" className="normal-button relative left-[60px] top-[-330px]">
                    <Link to="/gropp/accounts" className="no-link-decoration">Alles bekijken</Link>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>);
  }

  if (utils.isMobile()) {
    return <MobileMarketplace data={data}/>;
  } else {
    if (!data) {
      return <Loading fullHeight={true}/>;
    }
  
    return renderWebMarketplace();
  }
}

