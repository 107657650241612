export default class MapSearchItem {
  offerId: string;
  latitude: number;
  longitude: number;

  constructor($offerId: string,
              $latitude: number,
              $longitude: number) {
    this.offerId = $offerId;
    this.latitude = $latitude;
    this.longitude = $longitude;
  }
}