import Carrier from "./carrier";
import OpeningTimes from "./opening.times";

export class ServicePoint {
  private id: string;
	private carrier: Carrier[];
	private name: string;
	private street: string;
	private houseNumber: string;
	private postalCode: string;
	private city: string;
	private latitude: string;
	private longitude: string;
	private email: string;
	private phone: string;
	private homepage: string;
	private openingTimes: OpeningTimes;
	private openTomorrow: boolean;
	private openUpcomingWeek: boolean;

  constructor($id: string,
              $carrier: Carrier[],
              $name: string,
              $street: string,
              $houseNumber: string,
              $postalCode: string,
              $city: string,
              $latitude: string,
              $longitude: string,
              $email: string,
              $phone: string,
              $homepage: string,
              $openingTimes: OpeningTimes,
              $openTomorrow: boolean,
              $openUpcomingWeek: boolean) {
    this.id = $id;
    this.carrier = $carrier;
    this.name = $name;
    this.street = $street;
    this.houseNumber = $houseNumber;
    this.postalCode = $postalCode;
    this.city = $city;
    this.latitude = $latitude;
    this.longitude = $longitude;
    this.email = $email;
    this.phone = $phone;
    this.homepage = $homepage;
    this.openingTimes = $openingTimes;
    this.openTomorrow = $openTomorrow;
    this.openUpcomingWeek = $openUpcomingWeek;
  }
}