import * as Ons from "react-onsenui";

import Map from "../../components/map";
import { DEFAULT_MAP_LOCATION, START_MAP_ZOOM } from "../../domain/constants";

export default function MobileSingleLocation({ marker }) {
  return (
    <>
      <Ons.Page className="bg-white">
        <Map
          className="w-full map-search pb-8"
          mapLocation={DEFAULT_MAP_LOCATION} 
          zoom={START_MAP_ZOOM} 
          searchRadius={10}
          markers={[marker]}
          markerRenderer={(marker) => "" }
          width={"calc(100%)"}
          height={"calc(100%)"}/>
      </Ons.Page>
    </>);
}