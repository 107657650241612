import moment from "moment";
import { useEffect, useState } from "react";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "../../../domain/constants";
import { authService, utils } from "../../../services";
import ChatDrawer from "../../chat/chat.drawer";
import AccountBuilder from "../../../domain/account/account.builder";
import AccountRole, { accountRoleToString } from "../../../domain/account/account.role";
import Favorite from "../../../components/favorite";
import Reviews from '../review/page';
import ChatPanelMessages from "../../chat/chat.panel.messages";
import { EventBus } from "../../../services/event.bus";
import { BACKEND_URL } from "../../../domain/env";
import { useNavigate } from "react-router";

export default function AccountPage({ accountId, readOnly = false }: 
      { accountId: string, readOnly?: boolean }) {
  const [account,  setAccount] = useState<any>(null);

  const [averageRating, setAverageRating] = useState<number>(0);
  const [myRating, setMyRating] = useState<number>(0);

  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [loggedInAccountId, setLoggedInAccountId] = useState<string>(null);

  const navigate = useNavigate();
  
  function chat() {
    setChatOpen(true);    
  }

  function rate(nrStars) {
    if (readOnly) {
      return;
    }

    if (nrStars == 1 && myRating == 1) {
      setMyRating(0);
      removeRating();
      return;
    } else {
      setMyRating(nrStars);
    }

    var url = BACKEND_URL + "/ratings";
    
    utils.getLoggedInAccountId().then((result) => {
      setLoggedInAccountId(result);

      var body = {
        reviewedAccountId: accountId,
        nrStars: nrStars,
        accountId: result
      };
  
      authService.postSecure(url, body);
    })
  }

  function removeRating() {
    if (readOnly) {
      return;

    }
    var url = BACKEND_URL + "/ratings/" + accountId;
    authService.deleteSecure(url);
  }

  function fetchAverageRating(accountId) {
    var url = BACKEND_URL + "/ratings/" + accountId + "/average";

    authService.getSecure(url)
      .then((result) => {
        setAverageRating(result.data);

        var avgRatingElem = (document.getElementById("average_rating") as any);
        var maxWidth = 79.0;
        var newWidth = (result.data / 5.0) * maxWidth;
        var width = Math.floor(newWidth);
        avgRatingElem.style.width = width + "px";
      }).catch((_) => {
        setAverageRating(0.0);
      })
  }

  function fetchMyRating(accountId) {
    var url = BACKEND_URL + "/ratings/my-rating/" + accountId;

    authService.getSecure(url)
      .then((result) => {
        setMyRating(result.data);
      })
  }

  function getAverageRating() {
    var rating = `${averageRating}`;
    return rating.substring(0, 3);
  }

  const loadData = (accountId: string) => {
    if (!accountId) {
      return;
    }

    var url = BACKEND_URL + "/accounts/info/" + accountId;

    authService.getSecure(url)
      .then((result) => {
        var account = AccountBuilder.buildAccount(result.data);

        if (account != null) {
          setAccount(account);
        }
        
        fetchMyRating(accountId);
        fetchAverageRating(accountId);
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  useEffect(() => {
    if (!account) {
      loadData(accountId);
    }
  }, null);

  function isFullyValidAccount() {
    if (!account) {
      return false;
    }
    
    if (account.accountRole == AccountRole.USER) {
      return account.idVerified && account.emailVerified;
    } else if (account.accountRole == AccountRole.BUSINESS) {
      return account.businessVerified && account.emailVerified;
    }

    return false;
  }

  function closeChat() {
    setChatOpen(false);
  }

  if (!account) {
    return <></>;
  }

  function getMobileAccountInfoTab() {
    return (
      <div className="p-4">
        <div className="mx-auto justify-center w-full text-center mb-12 pt-8">
          <img 
            className="avatar-img-account-page rounded-full h-24 w-24 bg-gray-50 p-2 justify-center mx-auto"
            src={`${BACKEND_URL}/static/img/account/${account.id}`}
            alt="Account avatar"
            width={100}
            height={100}/>
        </div>

        <div className="font-bold">Type account:</div>
        <div className="mb-6">{ accountRoleToString(account.accountRole) }</div>

        <div className="font-bold">Voornaam:</div>
        <div className="mb-6">{ account.firstName }</div>

        { 
          account.showLastName ?
            <>
              <div className="font-bold">Achternaam:</div>
              <div className="mb-6">{ account.lastName }</div>
            </>
          : <></>
        }

        <div className="font-bold">Emailadres:</div>
        <div className="mb-6">
          {
            account.emailVerified ?
              <div>Geverifi&#235;erd
                <img 
                  className="z-20 inline-block relative left-[10px] top-[-1px]" 
                  src="/gropp_verified.png" 
                  width="17" 
                  height="17" 
                  alt="Grop logo"/>
              </div>
              : 
              <div>
                <span className="text-red-600 redhat-display">Niet geverifi&#235;erd</span>
              </div>
          }
        </div>

        <div className="font-bold">Identiteit:</div>
        {
          account.accountRole == AccountRole.USER ?
            <div className="mb-6">
              {
                account.idVerified ?
                  <div>Geverifi&#235;erd
                    <img 
                      className="z-20 inline-block relative left-[10px] top-[-1px]" 
                      src="/gropp_verified.png" 
                      width="17" 
                      height="17" 
                      alt="Grop logo"/>
                  </div>
                  : 
                  <div>
                    <span className="text-red-600 redhat-display">Niet geverifi&#235;erd</span>
                  </div>
              }
            </div>
          :
          <div className="mb-6">
            {
              account.businessVerified ?
                <div>Geverifi&#235;erd
                  <img 
                    className="z-20 inline-block relative left-[10px] top-[-1px]" 
                    src="/gropp_verified.png" 
                    width="17" 
                    height="17" 
                    alt="Grop logo"/>
                </div>
                : 
                <div>
                  <span className="text-red-600 redhat-display">Niet geverifi&#235;erd</span>
                </div>
            }
          </div>
        }
        
        {
          account.showAddress ? 
          <>
            <div className="font-bold">Straat:</div>
            <div className="mb-6">{ account.street }</div>

            <div className="font-bold">Postcode:</div>
            <div className="mb-6">{ account.postalCode }</div>
          </>
          : <></>
        }
        
        {
          account.showBirthDate ? 
            <>
              <div className="font-bold">Geboortedatum:</div>
              <div className="mb-6">{ 
                moment(account.birthDate, DATE_TIME_FORMAT).format(DATE_FORMAT)
                // utils.renderFriendlyDateTime(account.birthDate) 
              }</div>
            </>
          : <></>
        }
        
        {
          account.showPhoneNumber ? 
            <>
              <div className="font-bold">Telefoonnummer:</div>
              <div className="mb-6">{ account.phoneNumber }</div>
            </>
          : <></>
        }

        {
          account.showEmailAddress ? 
            <>
              <div className="font-bold">Emailadres:</div>
              <div className="mb-6">{ account.emailAddress }</div>
            </>
          : <></>
        }
        
        {
          account.showLocation ? 
            <>
              <div className="font-bold">Locatie:</div>
              <div className="mb-6">{ account.latitude }</div>
            </>
          : <></>
        }

        {
          account.showStatistics ? 
            <>
              <div className="font-bold">Aantal items:</div>
              <div className="mb-6">{ account.nrOffers }</div>

              <div className="font-bold">Aantal biedingen:</div>
              <div className="mb-6">{ account.nrBids }</div>

              <div className="font-bold">Aantal transacties:</div>
              <div className="mb-6">{ account.nrTrades }</div>
            </>
            : <></>
        }

        { 
          !readOnly ?
            <>
              <div className="font-bold">Favoriet:</div>
              <div className="mb-6">
                <Favorite itemType="account" favoriteEntityId={account.id}/>
              </div>
            </>
          : <></>
        }

        <div className="font-bold">Beoordeling:</div>
        <div className="mb-6">
          <div className="flex flex-row">
            <img 
              className={`relative top-[2px] ${ !readOnly ? "cursor-pointer" : "" }`}
              src={myRating >= 1 ? utils.getFullStar() : utils.getEmptyStar()} 
              width="17" 
              height="17" 
              alt="Grop logo"
              onClick={() => rate(1)}/>

            <img 
              className={`relative top-[2px] left-[-1px] ${ !readOnly ? "cursor-pointer" : "" }`}
              src={myRating >= 2 ? utils.getFullStar() : utils.getEmptyStar()} 
              width="17" 
              height="17" 
              alt="Grop logo"
              onClick={() => rate(2)}/>

            <img 
              className={`relative top-[2px] left-[-2px] ${ !readOnly ? "cursor-pointer" : "" }`}
              src={myRating >= 3 ? utils.getFullStar() : utils.getEmptyStar()} 
              width="17" 
              height="17" 
              alt="Grop logo"
              onClick={() => rate(3)}/>

            <img 
              className={`relative top-[2px] left-[-3px] ${ !readOnly ? "cursor-pointer" : "" }`}
              src={myRating >= 4 ? utils.getFullStar() : utils.getEmptyStar()} 
              width="17" 
              height="17" 
              alt="Grop logo"
              onClick={() => rate(4)}/>

            <img 
              className={`relative top-[2px] left-[-4px] ${ !readOnly ? "cursor-pointer" : "" }`}
              src={myRating >= 5 ? utils.getFullStar() : utils.getEmptyStar()} 
              width="17" 
              height="17" 
              alt="Grop logo"
              onClick={() => rate(5)}/>
            <div className="text-sm m-0 p-0 ml-2 h-[10px] mt-[1px]"></div>
          </div>

          <div id="average_rating" 
            className={`average-rating h-[15px] relative left-[0px] top-[-14px] z-[-10] w-0  ${ !readOnly ? "cursor-pointer" : "" }`}>
          </div>
        </div>
      </div>);
  }

  function getMobileChatTab() {
    return (
      <ChatPanelMessages 
        fromAccountId={loggedInAccountId} 
        toAccountId={accountId}/>);
  }

  function getMobileReviewTab() {
    return (<Reviews accountId={accountId} readOnly={readOnly}/>);
  }

  function goToChat() {
    setSelectedTab(2);

    setTimeout(() => {
      EventBus.getInstance()
        .dispatch<void>(EventBus.ON_CHAT_OPEN);
    }, 500);
  }

  function renderMobile() {
    return (
      <>
        <div className="pt-0 body-style">
          <div className={`w-full grid ${ readOnly ? "grid-cols-2": "grid-cols-3" } justify-center text-center redhat-display text-sm inbox-selector`}>
            <div className={ selectedTab == 0 ? "selected-inbox-tab" : ""}
              onClick={() => setSelectedTab(0)}>
              <div className="mt-1 mb-1">Info</div>
            </div>
            
            <div className={ selectedTab == 1 ? "selected-inbox-tab" : ""}
              onClick={() => setSelectedTab(1)}>
              <div className="mt-1 mb-1">Reviews</div>
            </div>

            {
              !readOnly ? 
                <>
                  <div className={ selectedTab == 2 ? "selected-inbox-tab" : ""}
                    onClick={() => goToChat()}>
                    <div className="mt-1 mb-1">Chatten</div>
                  </div>
                </>
              : <></>
            }
          </div>

          { selectedTab == 0 ? getMobileAccountInfoTab() : <></> }
          { selectedTab == 1 ? getMobileReviewTab() : <></> }
          { selectedTab == 2 ? getMobileChatTab() : <></> }

          <div className="mb-[100px]"></div>
        </div>
      </>);
  }

  function renderWeb() {
    return (
      <>
        <div className="mb-10">
          <div className="mx-auto justify-center w-full text-center mb-12">
            <img 
              className="avatar-img-account-page rounded-full h-24 w-24 bg-gray-50 p-2 justify-center mx-auto"
              src={`${BACKEND_URL}/static/img/account/${accountId}`}
              alt="Account avatar"
              width={100}
              height={100}/>
          </div>

          <div className="grid grid-cols-2 gap-4 justify-center">
            <div className="font-bold text-right">Type account:</div>
            <div>{ accountRoleToString(account.accountRole) }</div>

            <div className="font-bold text-right">Voornaam:</div>
            <div>{ account.firstName }</div>

            { 
              account.showLastName ?
                <>
                  <div className="font-bold text-right">Achternaam:</div>
                  <div>{ account.lastName }</div>
                </>
              : <></>
            }

            {
              account.showAddress ? 
              <>
                <div className="font-bold text-right">Straat:</div>
                <div>{ account.street }</div>

                <div className="font-bold text-right">Postcode:</div>
                <div>{ account.postalCode }</div>
              </>
              : <></>
            }
            
            {
              account.showBirthDate ? 
                <>
                  <div className="font-bold text-right">Geboortedatum:</div>
                  <div>{ utils.renderDateTime(account.birthDate) }</div>
                </>
              : <></>
            }
            
            {
              account.showPhoneNumber ? 
                <>
                  <div className="font-bold text-right">Telefoonnummer:</div>
                  <div>{ account.phoneNumber }</div>
                </>
              : <></>
            }

            {
              account.showEmailAddress ? 
                <>
                  <div className="font-bold text-right">Emailadres:</div>
                  <div>{ account.emailAddress }</div>
                </>
              : <></>
            }
            
            {
              account.showLocation ? 
                <>
                  <div className="font-bold text-right">Locatie:</div>
                  <div>{ account.latitude }</div>
                </>
              : <></>
            }

            {
              account.showStatistics ? 
                <>
                  <div className="font-bold text-right">Aantal items:</div>
                  <div>{ account.nrOffers }</div>

                  <div className="font-bold text-right">Aantal biedingen:</div>
                  <div>{ account.nrBids }</div>

                  <div className="font-bold text-right">Aantal transacties:</div>
                  <div>{ account.nrTrades }</div>
                </>
                : <></>
            }

            <div className="font-bold text-right">Favoriet:</div>
            <div>
              <Favorite itemType="account" favoriteEntityId={account.id}/>
            </div>

            <div className="font-bold text-right">Beoordeling:</div>
            <div>
              <div className="flex flex-row">
                <img 
                  className="relative top-[2px] cursor-pointer" 
                  src={myRating >= 1 ? utils.getFullStar() : utils.getEmptyStar()} 
                  width="17" 
                  height="17" 
                  alt="Grop logo"
                  onClick={() => rate(1)}/>

                <img 
                  className="relative top-[2px] left-[-1px] cursor-pointer" 
                  src={myRating >= 2 ? utils.getFullStar() : utils.getEmptyStar()} 
                  width="17" 
                  height="17" 
                  alt="Grop logo"
                  onClick={() => rate(2)}/>

                <img 
                  className="relative top-[2px] left-[-2px] cursor-pointer" 
                  src={myRating >= 3 ? utils.getFullStar() : utils.getEmptyStar()} 
                  width="17" 
                  height="17" 
                  alt="Grop logo"
                  onClick={() => rate(3)}/>

                <img 
                  className="relative top-[2px] left-[-3px] cursor-pointer" 
                  src={myRating >= 4 ? utils.getFullStar() : utils.getEmptyStar()} 
                  width="17" 
                  height="17" 
                  alt="Grop logo"
                  onClick={() => rate(4)}/>

                <img 
                  className="relative top-[2px] left-[-4px] cursor-pointer" 
                  src={myRating >= 5 ? utils.getFullStar() : utils.getEmptyStar()} 
                  width="17" 
                  height="17" 
                  alt="Grop logo"
                  onClick={() => rate(5)}/>
                <div className="text-sm m-0 p-0 ml-2 h-[10px] mt-[1px]">({getAverageRating()})</div>
              </div>

              <div id="average_rating" 
                className="average-rating h-[15px] relative left-[0px] top-[-14px] z-[-10] w-0 cursor-pointer">
              </div>
            </div>

            <Reviews accountId={accountId} readOnly={readOnly}/>
          </div>

          {
            isFullyValidAccount() && !readOnly ? 
              <div className="w-full flex flex-row-reverse row-cols-2 mb-8 justify-center"> 
                <div className="pt-10 pb-7 mr-4">
                  <button className="normal-button" type="submit" onClick={chat}>Chatten</button>
                </div>
              </div>
            : <></>
          }
        </div>
        
        {
          accountId && !readOnly ?
            <>
              <ChatDrawer
                fromAccountId={loggedInAccountId}
                toAccountId={accountId}
                isOpen={chatOpen}
                onCloseChat={closeChat}/>
            </>
          : <></>
        }
      </>
    );
  }

  if (utils.isMobile()) {
    return renderMobile();
  } else {
    return renderWeb();
  }
}