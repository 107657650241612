import { authService, utils } from "../../../services";
import { useEffect, useState } from "react";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "../../../domain/constants";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { productTypeToString, productTypeFromString } from "../../../domain/item/product.type";
import { offerStateFromString, offerStateToString } from "../../../domain/offer/offer.state";
import { Modal, ModalDialog, DialogTitle, Divider, DialogContent, DialogActions, Button } from "@mui/joy";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { EventBus } from "../../../services/event.bus";
import { BACKEND_URL } from "../../../domain/env";
import MobileAccountItems from "./mobile.account.items";
import PriceType from "../../../domain/offer/offer.type";
import moment from "moment";
import ItemBuilder from "../../../domain/item/item.builder";
import MyItem from "../../../domain/item/my.item";
import Loading from "../../../components/loading";

export default function MyAccountItems() {
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  function loadData() {
    var url = BACKEND_URL + "/items/my-items";

    authService.getSecure(url)
      .then((result) => {
        var filtered = result.data
          .filter(i => {
            return i.item && i.offer && i.item.itemType == "SINGLE_ITEM";
          })
          .map(i => {
            if (i.item && i.offer) {
              var item = ItemBuilder.buildItem(i.item);
              var offer = OfferBuilder.buildOffer(i.offer);

              return new MyItem(item, offer);
            } else {
              return null;
            }
          })
          .filter(i => {
            return i != null;
          })
          .sort((a, b) => {
            return b.item.createTime - a.item.createTime;
          });

        setData(filtered);
      }).catch((e) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }
  
  useEffect(() => {
    EventBus.getInstance().register(
      EventBus.ON_LOGIN_SUCCESSFUL, () => loadData());

    if (!data) {
      loadData();
    }
  }, null);

  if (!data) {
    return (<Loading/>);
  }

  function getAmount(myItem) {
    if (myItem && myItem.offer && myItem.offer.amount) {
      return "\u20AC" + utils.round(myItem.offer.amount);
    } else {
      return "";
    }
  }

  function doDeleteMyItem(myItem) {
    setShowDeleteConfirmation(false);
  }

  function goToEditItem(myItem) {
    var url = "/gropp/account/items/edit" + 
      "?itemId=" + myItem.item.id + 
      "&offerId=" + myItem.offer.id;

    navigate(url);
  }

  function renderWebMyItems() {
    return (
      <>
        <div className="w-full flex flex-row-reverse row-cols-2 mb-8 justify-center"> 
          <div className="pt-10 pb-7 mr-4">
            <button className="normal-button" type="submit" onClick={() => {}}>Nieuw&nbsp;item</button>
          </div>
        </div>
        
        <div className="justify-center w-full text-center">
          <div className="flex flex-col gap-4 w-1/2 ml-auto mr-auto">
            {
              data.map((myItem) => (
                <div key={myItem.item.id} className="mb-8">
                  <div className="flex flex-col text-sm">
                    <div className="grid grid-flow-col auto-rows-min">
                      <div className="min-w-[150px]">
                        <img 
                          className={`min-w-[150px] ml-0 mt-0 rounded-lg item-image ${myItem.item && myItem.item.offerType == PriceType.AUCTION ? "auction-border" : ""}`}
                          src={`${BACKEND_URL}/static/img/preview/${myItem.item.id}`}
                          alt="Item image"
                          width={150}
                          height={150}/>
                      </div>

                      <div className="grid grid-cols-2 auto-rows-min">
                        <div className="text-right font-bold mr-1 p-1">Titel:</div>
                        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{myItem.item.title}</div>

                        <div className="text-right font-bold mr-1 p-1">Status:</div>
                        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{myItem.offer.offerState}</div>

                        <div className="text-right font-bold mr-1 p-1">Type item:</div>
                        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{productTypeToString(productTypeFromString(myItem.item.productType))}</div>

                        <div className="text-right font-bold mr-1 p-1">Merk:</div>
                        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{myItem.item.brand}</div>

                        <div className="text-right font-bold mr-1 p-1">Sinds:</div>
                        <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{
                          moment(myItem.item.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT)
                        }</div>

                        {
                          myItem.offer && myItem.offer.amount ?
                            <>
                              <div className="text-right font-bold mr-1 p-1">Vraagprijs:</div>
                              <div className="text-ellipsis text-left overflow-hidden whitespace-nowrap p-1">{
                                <div className="search-amount relative top-[0px] left-[0px]">{ getAmount(myItem) }</div>
                              }</div>
                            </>
                            : <></>
                        }
                      </div>

                      <div className="flex flex-col-reverse gap-4 align-bottom">
                        <div className="text-right mr-4">
                          <button className="normal-button" type="submit" onClick={() => goToEditItem(myItem) }>Bekijken</button>
                        </div>

                        <div className="text-right mr-4">
                          <Link to="#" onClick={() => setShowDeleteConfirmation(true)}>Verwijderen</Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-[1px] border-solid search-line mt-8"></div>
                </div>
              ))
            }
          </div>
        </div>
        
        <Modal open={showDeleteConfirmation}>
          <ModalDialog variant="plain" role="alertdialog">
            <DialogTitle>Verwijderen</DialogTitle>
            <Divider />
            <DialogContent>
              Weet je zeker dat je dit item wil verwijderen?
            </DialogContent>
            <DialogActions>
              <Button variant="solid" 
                className="" 
                onClick={() => setShowDeleteConfirmation(false)}>Nee</Button>
              <Button variant="plain" 
                color="neutral" 
                onClick={() => doDeleteMyItem(null)}>Ja</Button>
            </DialogActions>
          </ModalDialog>
        </Modal>
      </>
    );
  }

  if (utils.isMobile()) {
    return <MobileAccountItems data={data}/>;
  } else {
    return renderWebMyItems();
  }
}