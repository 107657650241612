enum ProductType {
  WINE = "WINE",
	WHISKEY = "WHISKEY",
	BEER = "BEER",
	PORT = "PORT",
	VODKA = "VODKA",
	BRANDY = "BRANDY",
	RUM = "RUM",
	GIN = "GIN",
	TEQUILA = "TEQUILA",
	BOURBON = "BOURBON",
	SCOTCH = "SCOTCH",
	VERMOUTH = "VERMOUTH",
	COGNAC = "COGNAC",
	ABSINTHE = "ABSINTHE",
	CIDER = "CIDER"
}

export function productTypeFromString(productType: string): ProductType {
  switch (productType) {
    case "WINE": 
      return ProductType.WINE;
	  case "WHISKEY":
      return ProductType.WHISKEY;
    case "BEER":
      return ProductType.BEER;
    case "PORT":
      return ProductType.PORT;
    case "VODKA":
      return ProductType.VODKA;
    case "BRANDY":
      return ProductType.BRANDY;
    case "RUM":
      return ProductType.RUM;
    case "GIN":
      return ProductType.GIN;
    case "TEQUILA":
      return ProductType.TEQUILA;
    case "BOURBON":
      return ProductType.BOURBON;
    case "SCOTCH":
      return ProductType.SCOTCH;
    case "VERMOUTH":
      return ProductType.VERMOUTH;
    case "COGNAC":
      return ProductType.COGNAC;
    case "ABSINTHE":
      return ProductType.ABSINTHE;
    case "CIDER":
      return ProductType.CIDER;
    default:
      return null;
  }
}

export function productTypeToString(productType: ProductType): string {
  switch (productType) {
    case ProductType.WINE: 
      return "Wijn";
	  case ProductType.WHISKEY: 
      return "Whiskey";
    case ProductType.BEER:
      return "Bier";
    case ProductType.PORT: 
      return "Port";
    case ProductType.VODKA: 
      return "Wodka";
    case ProductType.BRANDY: 
      return "Brandy";
    case ProductType.RUM: 
      return "Rum";
    case ProductType.GIN: 
      return "Gin";
    case ProductType.TEQUILA: 
      return "Tequila";
    case ProductType.BOURBON:
     return "Bourbon";
    case ProductType.SCOTCH:
      return "Scotch";
    case ProductType.VERMOUTH: 
      return "Vermouth";
    case ProductType.COGNAC: 
      return "Cognac";
    case ProductType.ABSINTHE: 
      return "Absinthe";
    case ProductType.CIDER: 
      return "Cider";
    default:
      return "";
  }
}

export function productTypeFromSearchQuery(query: string): ProductType {
  switch (query) {
    case "Wijn": 
      return ProductType.WINE;
    case "Whiskey":
      return ProductType.WHISKEY;
    case "Bier":
      return ProductType.BEER;
    case "Port":
      return ProductType.PORT;
    case "Wodka":
      return ProductType.VODKA;
    case "Brandy":
      return ProductType.BRANDY;
    case "Rum":
      return ProductType.RUM;
    case "Gin":
      return ProductType.GIN;
    case "Tequila":
      return ProductType.TEQUILA;
    case "Bourbon": 
      return ProductType.BOURBON;
    case "Scotch":
      return ProductType.SCOTCH;
    case "Vermouth":
      return ProductType.VERMOUTH;
    case "Cognac":
      return ProductType.COGNAC;
    case "Absinthe":
      return ProductType.ABSINTHE;
    case "Cider":
      return ProductType.CIDER;
    default:
      return null;
  }
}

export const ALL_PRODUCT_TYPES: ProductType[] = [
  ProductType.WINE,
	ProductType.WHISKEY,
	ProductType.BEER,
  ProductType.VODKA,
  ProductType.COGNAC,
  ProductType.RUM,
  ProductType.PORT,
  ProductType.TEQUILA,
	ProductType.BRANDY,
	ProductType.GIN,
	ProductType.BOURBON,
	ProductType.SCOTCH,
	ProductType.VERMOUTH,
	ProductType.ABSINTHE,
	ProductType.CIDER
];

export default ProductType;