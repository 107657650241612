import Tooltip from "@mui/joy/Tooltip";
import { BACKEND_URL } from "../../domain/env";
import { productTypeToString } from "../../domain/item/product.type";
import MarketplaceOffer from "../../domain/offer/marketplace.offer";
import { OfferState } from "../../domain/offer/offer.state";
import PriceType, { offerTypeToString } from "../../domain/offer/offer.type";
import { utils } from "../../services";
import CountDownTimer from "../../components/countdown.timer";

export default function MarketplaceOfferItem({ offer }: { offer: MarketplaceOffer }) {
  function getAmount() {
    var amount = <></>;

    var showBidIcon = offer.offerType == PriceType.USER_OFFER &&
      offer.allowBidding &&
      offer.nrBids > 0;

    var amountLabelClassName = `text-xl relative ${ showBidIcon ? "left-[40px] top-[-7px]" : "left-[15px] top-[12px]"}`;

    var bidText = <></>;

    var openAuction = (offer.offerType == PriceType.AUCTION && 
      offer.offerState == OfferState.OPEN);
    
    var biddingLabelClassName = "relative left-[40px] top-[-2px]";
    var biddingLabel = "Bieden";

    if (offer.allowBidding) {
      if (offer.startPrice) {
        biddingLabel = "Bieden vanaf";
        biddingLabelClassName = "relative left-[35px] top-[-40px] text-xs";
        amountLabelClassName = `text-xl relative ${ showBidIcon ? "left-[40px] top-[-7px]" : "left-[32px] top-[2px]"}`;
      }
    } 

    if (offer.amount) {
      amount = (<div className={amountLabelClassName}>{
        utils.roundedAmount(offer.amount, 2)
      }</div>);
    }

    var userOfferAllowBiddingNoBids = (
      offer.offerType == PriceType.USER_OFFER && 
      offer.allowBidding && 
      offer.nrBids == 0 && 
      !offer.amount);

    var showBidText = openAuction || userOfferAllowBiddingNoBids;
      
    if (showBidText) {
      bidText = (
        <Tooltip title="Er heeft nog niemand geboden." variant="plain">
          <div className={biddingLabelClassName}>{biddingLabel}</div>
        </Tooltip>
      );
    }

    var bidIcon = <></>;

    if (showBidIcon) {
      bidIcon = (
        <Tooltip title="Deze gebruiker staat bieden toe." variant="plain">
          <img 
            className="relative top-[15px] left-[15px] z-20" 
            src="/gropp_hand.png"
            width="17" 
            height="17" 
            alt="Grop logo"/>
        </Tooltip>);
    }

    return (<>
      {bidIcon} {amount} {bidText}
    </>);
  }

  return (
    <a href={`/gropp/offers/${offer.id}`} className="hover:no-underline no-decoration">
      <div suppressHydrationWarning className="flex flex-row p-4 pr-1 mb-10">
        <div className="flex flex-col offer-hover">
          <img 
            className="mr-4 ml-0 mt-0 -z-10 rounded-lg item-image"
            src={`${BACKEND_URL}/static/img/preview/${offer.itemId}`}
            alt="Item image"
            width={200}
            height={200}
            />

          <div className="opacity-80 relative bottom-[50px] rounded-b-lg h-[50px] bid-item-overlay text-white bg-white">
            <div>
              <div className="font-bold bid-amount z-[110]">
                {
                  offer.offerType == PriceType.AUCTION && 
                  offer.offerState == OfferState.OPEN ? 
                      <Tooltip title="Dit is een veiling." variant="plain">
                        <img 
                          className="relative top-[15px] left-[15px]" 
                          src="/gropp_hourglass.png"
                          width="13" 
                          height="13" 
                          alt="Grop logo"/>
                      </Tooltip>
                  : <></>
                }

                { getAmount() }
              </div>
            </div>
          </div>
        </div>
        
        {
          offer.offerType == PriceType.AUCTION && offer.offerState == OfferState.OPEN ? 
            <CountDownTimer 
              offer={offer}
              labelClassName="relative left-[-190px] top-[215px]"
              timerClassName="relative left-[-170px] top-[225px] auction-time"/> 
          : <div></div>
        }
        
        <div className={`mb-2 ml-1 border-2 m-2 p-4 rounded-lg shadow-box shadow-box-offer ${ offer.offerType == PriceType.AUCTION ? "auction-border left-[-170px] mr-[-120px]" : "left-[-100px] mr-[-50px]" } max-w-[200px] min-w-[200px]`}>
          <div className="mb-1 font-bold offer-type">{ `${offerTypeToString(offer.offerType)}` }</div>

          <div className="font-bold mr-1 secondary-color">Titel</div>
          <div className="mb-1">{offer.title}</div>

          <div className="font-bold mr-1">Merk</div>
          <div className="mb-1">{offer.brand}</div>

          <div className="font-bold mr-1">Type</div>
          <div className="mb-1">{productTypeToString(offer.productType)}</div>

          {
            offer.offerType != PriceType.AUCTION ? 
              <>
                <div className="font-bold mr-1">Aangemaakt</div>
                <div className="mb-1">{utils.renderFriendlyDateTime(offer.createTime)}</div>
              </>
              : <></>
          }

          {
            (offer.offerType == PriceType.AUCTION && 
             offer.offerState == OfferState.OPEN) 
            || (offer.offerType == PriceType.USER_OFFER && 
             offer.offerState == OfferState.OPEN &&
             offer.allowBidding)
             ?
              <>
                <div className="font-bold mr-1">Biedingen</div>
                <div className="mb-1">{offer.nrBids}</div>
              </>
              : <></>
          }

          <div className="font-bold mr-1">Plaats</div>
          <div className="mb-1">{offer.city}</div>
          
          {
            offer.startPrice ? 
              <>
                <div className="font-bold mr-1">Startprijs</div>
                <div className="mb-1">{utils.roundedAmount(offer.startPrice)}</div>
              </>
              : <></>
          }

          <div className="text-right mt-[5px]">
            <span className="fake-link">Bekijken</span>
          </div>
        </div>
      </div>
    </a>
  );
}