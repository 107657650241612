enum FavoriteType {
  ACCOUNT = "ACCOUNT",
  OFFER = "OFFER"
}

export default FavoriteType;

export function favoriteTypeFromString(favoriteType: string): FavoriteType {
  switch (favoriteType) {
    case "ACCOUNT":
      return FavoriteType.ACCOUNT;
    case "OFFER":
      return FavoriteType.OFFER;
    default:
      return null;
  }
}