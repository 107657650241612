import bigDecimal from 'js-big-decimal';

export default class SearchOffer {
  offerId: string;
	createTime: Date;
	ownerId: string;
	itemId: string;
	offerType: string;
	ownerType: string;
	productType: string;
	latitude: number;
	longitude: number;
	openTime: Date;
	auctionDuration: number;
	offerState: string;
	startPrice: bigDecimal;
	titleDescription: string;
	title: string;
	description: string;
	allowBidding: boolean;
	nrBids: number;
	itemYear: number;
	price: bigDecimal;
	brand: string;
	provider: string;

  constructor($offerId: string,
              $createTime: Date,
              $ownerId: string,
              $itemId: string,
              $offerType: string,
              $ownerType: string,
              $productType: string,
              $latitude: number,
              $longitude: number,
              $openTime: Date,
              $auctionDuration: number,
              $offerState: string,
              $startPrice: any,
              $titleDescription: string,
              $title: string,
              $description: string,
              $allowBidding: boolean,
              $nrBids: number,
              $itemYear: number,
              $price: any,
              $brand: string,
              $provider: string) {
    this.offerId = $offerId;
    this.createTime = $createTime;
    this.ownerId = $ownerId;
    this.itemId = $itemId;
    this.offerType = $offerType;
    this.ownerType = $ownerType;
    this.productType = $productType;
    this.latitude = $latitude;
    this.longitude = $longitude;
    this.openTime = $openTime;
    this.auctionDuration = $auctionDuration;
    this.offerState = $offerState;
    this.startPrice = new bigDecimal($startPrice);
    this.titleDescription = $titleDescription;
    this.title = $title;
    this.description = $description;
    this.allowBidding = $allowBidding;
    this.nrBids = $nrBids;
    this.itemYear = $itemYear;
    this.price = new bigDecimal($price);
    this.brand = $brand;
    this.provider = $provider;
  }
}