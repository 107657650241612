import AbstractEntity from "../abstract.entity";
import AccountRole from "./account.role";

class Account extends AbstractEntity {
  accountRole: AccountRole;
  firstName: string;
  lastName: string;
  birthDate: Date;
	street: string;
	postalCode: string;
	city: string;
	phoneNumber: string;
	emailAddress: string;
	emailVerified: boolean;
  idVerified: boolean;
  latitude: number;
  longitude: number;
  avatarIdx: number;
  nrBids: number;
  nrTrades: number;
  nrOffers: number;
  nrItems: number;

  businessName?: string;
  kvkNumber?: string;
  businessVerified?: boolean;

  showLastName: boolean;
  showAddress: boolean;
  showPhoneNumber: boolean;
  showCoordinates: boolean;
  showBirthDate: boolean;
  showKvkNumber: boolean;
  showStatistics: boolean;

	constructor($id: string,
              $accountRole: AccountRole,
              $createTime: Date,
              $firstName: string,
              $lastName: string,
              $birthDate: Date,
              $street: string, 
              $postalCode: string, 
              $city: string, 
              $phoneNumber: string, 
              $emailAddress: string,
              $emailVerified: boolean,
              $idVerified: boolean,
              $latitude: number,
              $longitude: number,
              $avatarIdx: number,
              $nrBids: number,
              $nrTrades: number,
              $nrOffers: number,
              $nrItems: number,
              $showLastName: boolean,
              $showAddress: boolean,
              $showPhoneNumber: boolean,
              $showCoordinates: boolean,
              $showBirthDate: boolean,
              $showKvkNumber: boolean,
              $showStatistics: boolean,
              $businessName?: string,
              $kvkNumber?: string,
              $businessVerified?: boolean) {
		super($id, $createTime);

    this.firstName = $firstName;
    this.lastName = $lastName;
    this.birthDate = $birthDate;
    this.accountRole = $accountRole;
		this.street = $street;
		this.postalCode = $postalCode;
		this.city = $city;
		this.phoneNumber = $phoneNumber;
		this.emailAddress = $emailAddress;
		this.emailVerified = $emailVerified;
    this.idVerified = $idVerified;
    this.latitude = $latitude;
    this.longitude = $longitude;
    this.avatarIdx = $avatarIdx;
    this.nrBids = $nrBids;
    this.nrTrades = $nrTrades;
    this.nrOffers = $nrOffers;
    this.nrItems = $nrItems;
    this.showLastName = $showLastName;
    this.showAddress = $showAddress;
    this.showPhoneNumber = $showPhoneNumber;
    this.showCoordinates = $showCoordinates;
    this.showBirthDate = $showBirthDate;
    this.showKvkNumber = $showKvkNumber;
    this.showStatistics = $showStatistics;
    this.businessName = $businessName;
    this.kvkNumber = $kvkNumber;
    this.businessVerified = $businessVerified;
	}
}

export default Account;