enum OfferType {
  AUCTION = "AUCTION",
  USER_OFFER = "USER_OFFER",
  BUSINESS_OFFER = "BUSINESS_OFFER"
}

export default OfferType;

export function offerTypeFromString(offerType: string): OfferType {
  switch (offerType) {
    case "AUCTION": 
      return OfferType.AUCTION;
    case "USER_OFFER": 
      return OfferType.USER_OFFER;
    case "BUSINESS_OFFER":
      return OfferType.BUSINESS_OFFER;
    default:
      return null;
  }
}

export function offerTypeToString(offerType: OfferType): string {
  switch (offerType) {
    case OfferType.AUCTION:
      return "Veiling";
    case OfferType.BUSINESS_OFFER:
      return "Winkel";
    case OfferType.USER_OFFER:
      return "Gebruiker";
    default:
      return "";
  }
}

export const ALL_OFFER_TYPES = [
  OfferType.AUCTION,
  OfferType.USER_OFFER,
  OfferType.BUSINESS_OFFER
];