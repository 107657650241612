enum NotificationType {
  BID_REJECTED = "BID_REJECTED",
  NEW_BID_ITEM = "NEW_BID_ITEM",
	NEW_BID_FAVORITE_ITEM = "NEW_BID_FAVORITE_ITEM",
	FAVORITE_ACCOUNT_PLACED_ITEM = "FAVORITE_ACCOUNT_PLACED_ITEM",
	TRANSACTION_FAVORITE_ITEM = "TRANSACTION_FAVORITE_ITEM",
	NEW_CHAT_MESSAGE = "NEW_CHAT_MESSAGE",
	SOLD_ITEM_SHIPPED = "SOLD_ITEM_SHIPPED",
	BOUGHT_ITEM_SHIPPED = "BOUGHT_ITEM_SHIPPED",
	ADVERTISEMENT = "ADVERTISEMENT",
	SYSTEM_NOTIFICATION = "SYSTEM_NOTIFICATION"
}

export default NotificationType;

export function notificationTypeFromString(notificationType: string): NotificationType {
  switch (notificationType) {
    case "BID_REJECTED":
      return NotificationType.BID_REJECTED;
    case "NEW_BID_ITEM":
      return NotificationType.NEW_BID_ITEM;
	  case "NEW_BID_FAVORITE_ITEM":
      return NotificationType.NEW_BID_FAVORITE_ITEM;
    case "FAVORITE_ACCOUNT_PLACED_ITEM":
      return NotificationType.FAVORITE_ACCOUNT_PLACED_ITEM;
    case "TRANSACTION_FAVORITE_ITEM":
      return NotificationType.TRANSACTION_FAVORITE_ITEM;
    case "NEW_CHAT_MESSAGE":
      return NotificationType.NEW_CHAT_MESSAGE;
    case "SOLD_ITEM_SHIPPED":
      return NotificationType.SOLD_ITEM_SHIPPED;
    case "BOUGHT_ITEM_SHIPPED":
      return NotificationType.BOUGHT_ITEM_SHIPPED;
    case "ADVERTISEMENT":
      return NotificationType.ADVERTISEMENT;
	  case "SYSTEM_NOTIFICATION":
      return NotificationType.SYSTEM_NOTIFICATION;
    default:
      return null;
  }
}

export function notificationTypeToString(notificationType: NotificationType): string {
  switch (notificationType) {
    case NotificationType.BID_REJECTED:
      return "Bod afgewezen";
    case NotificationType.NEW_BID_ITEM:
      return "Nieuwe bieding op je item";
	  case NotificationType.NEW_BID_FAVORITE_ITEM:
      return "Nieuwe bieding op favoriet";
    case NotificationType.FAVORITE_ACCOUNT_PLACED_ITEM:
      return "Nieuw item geplaatst";
    case NotificationType.TRANSACTION_FAVORITE_ITEM:
      return "Favoriet item verkocht";
    case NotificationType.NEW_CHAT_MESSAGE:
      return "Nieuw chatbericht";
    case NotificationType.SOLD_ITEM_SHIPPED:
      return "Verkocht item verstuurd";
    case NotificationType.BOUGHT_ITEM_SHIPPED:
      return "Gekocht item verstuurd";
    case NotificationType.ADVERTISEMENT:
      return "Advertentie";
	  case NotificationType.SYSTEM_NOTIFICATION:
      return "Systeemmelding";
    default:
      return "";
  }
}

export const ALL_NOTIFICATION_TYPES = [
  NotificationType.BID_REJECTED,
  NotificationType.NEW_BID_ITEM,
  NotificationType.NEW_BID_FAVORITE_ITEM,
	NotificationType.FAVORITE_ACCOUNT_PLACED_ITEM,
	NotificationType.TRANSACTION_FAVORITE_ITEM,
	NotificationType.NEW_CHAT_MESSAGE,
	NotificationType.SOLD_ITEM_SHIPPED,
	NotificationType.BOUGHT_ITEM_SHIPPED,
	NotificationType.ADVERTISEMENT,
	NotificationType.SYSTEM_NOTIFICATION
];