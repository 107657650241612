import Offer from "./offer";
import { OfferState } from "./offer.state";
import PriceType from "./offer.type";
import bigDecimal from 'js-big-decimal';

class UserOffer extends Offer {
	amount: bigDecimal;
	allowBidding: boolean;
  startPrice: bigDecimal;

	constructor($id: string,
              $createTime: Date,
              $accountId: string,
              $itemId: string,
              $bids: string[],
              $offerState: OfferState,
              $closedByOwner: boolean,
              $wonBidId: string,
              $amount: string,
              $allowBidding: boolean,
              $startPrice: string) {
		super($id, $createTime, $accountId, $itemId,
			$bids, $offerState, $closedByOwner, $wonBidId, 
      PriceType.USER_OFFER);
		
    if ($amount) {
      this.amount = new bigDecimal($amount);
    }

		this.allowBidding = $allowBidding;

    if ($startPrice) {
      this.startPrice = new bigDecimal($startPrice);
    }
	}

  isUserOffer(): boolean {
    return true;
  }

  isBusinessOffer(): boolean {
    return false;
  }

  isAuction(): boolean {
    return false;
  }
}

export default UserOffer;