import { useParams } from "react-router";
import OfferPage from "./page";
import BidPage from "./page";
import AccountOverviewPage from "../../account/page";
import AccountPage from "./page";

export default function AccountPageRouteHelper() {
  const { accountId } = useParams();

  return (
    <>
      <AccountPage accountId={accountId}/>
    </>
  );
}