import { DATE_TIME_FORMAT } from "../constants";
import ProductType, { productTypeFromString } from "../item/product.type";
import BidState, { bidStateFromString } from "./bid.state";
import BidType from "./bid.type";
import bigDecimal from 'js-big-decimal';
import moment from 'moment';

class MarketplaceBid {
  id: string;
  bidCreatedAt: Date;
  bidType: BidType;
  amount: bigDecimal;
  bidState: BidState;
  nrBids: number;
  bidAccountId: string;
  offerAccountId: string;
  itemId: string;
  offerId: string;
  isGroup: boolean;
  productType: ProductType;
  itemTitle: string;

  constructor($bid: any) {
    this.id = $bid.id;
    this.bidCreatedAt = moment($bid.bidCreatedAt, DATE_TIME_FORMAT).toDate();
    this.bidType = $bid.bidType;
    this.amount = new bigDecimal($bid.amount);
    this.bidState = bidStateFromString($bid.bidState);
    this.nrBids = $bid.nrBids;
    this.bidAccountId = $bid.bidAccountId;
    this.offerAccountId = $bid.offerAccountId;
    this.itemId = $bid.itemId;
    this.offerId = $bid.offerId;
    this.isGroup = $bid.isGroup;
    this.itemTitle = $bid.itemTitle;

    if ($bid.productType) {
      this.productType = productTypeFromString($bid.productType);
    }
  }
}

export default MarketplaceBid;