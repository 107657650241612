import { useParams } from "react-router";
import MobilePaymentResult from "./mobile.payment.result";

export default function MobilePaymentResultRouteHelper() {
  const { status } = useParams();

  return (
    <>
      <MobilePaymentResult status={status}/>
    </>
  );
}