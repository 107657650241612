enum DeliveryMethod {
	MANUAL = "MANUAL",
	PICKUP_HOME = "PICKUP_HOME",
	SERVICE_POINT = "SERVICE_POINT"
}

export function DeliveryMethodFromString(deliveryMethod: string): DeliveryMethod {
  switch (deliveryMethod) {
    case "MANUAL":
      return DeliveryMethod.MANUAL;
    case "PICKUP_HOME":
      return DeliveryMethod.PICKUP_HOME;
    case "SERVICE_POINT":
      return DeliveryMethod.SERVICE_POINT;
    default:
      return null;
  }
}

export function DeliveryMethodToString(deliveryMethod: DeliveryMethod): string {
  switch (deliveryMethod) {
    case DeliveryMethod.MANUAL:
      return "Zelf regelen";
    case DeliveryMethod.PICKUP_HOME:
      return "Laten ophalen door bezorgdienst";
    case DeliveryMethod.SERVICE_POINT:
      return "Wegbrengen naar pakketpunt";
    default: 
      return "";
  }
}

export const ALL_DELIVERY_METHODS = [
  DeliveryMethod.MANUAL,
  DeliveryMethod.PICKUP_HOME,
  DeliveryMethod.SERVICE_POINT
];

export default DeliveryMethod;