import { useState } from 'react';
import SearchBar from './search.bar';
import SearchResultsPage from './search.results';
import SearchType from '../../domain/search/search.type';
import Spinner from '../../components/spinner';
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, DEFAULT_MAP_LOCATION } from '../../domain/constants';
import SearchResults from '../../domain/search/search.results';
import { searchService, utils } from '../../services';
import Map from "../../components/map";
import { useNavigate } from 'react-router';

export default function Search() {
  const [data, setData] = useState<SearchResults>(null);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState<string>("" + DEFAULT_PAGE_SIZE);
  const [searchType, setSearchType] = useState<SearchType>(SearchType.NORMAL_SEARCH);  
  const [mapMarkers, setMapMarkers] = useState<any[]>([]);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const navigate = useNavigate();

  function performSearch(search) {
    if (searchType == SearchType.NORMAL_SEARCH) {
      setShowSpinner(true);

      searchService.searchOffers(search)
        .then((result) => {
          setShowSpinner(false);
          setData(result);
        }).catch((e) => {
          // setShowSpinner(false);
          utils.handleUnauthorized(() => navigate("/login"));
        });
    } else if (searchType == SearchType.MAP_SEARCH) {
      setShowSpinner(false);

      searchService.mapSearch(search)
        .then((result) => {
          var markers = result
            .filter(i => {
              // Bounding box of NL
              return (
                i.latitude >= 50.518244 && 
                i.latitude <= 53.600425 && 
                i.longitude >= 3.173744 && 
                i.longitude <= 7.345201);
            }).map(i => {
            return {
              key: i["offerId"],
              lat: i["latitude"],
              lng: i["longitude"],
              tooltip: true
            };
          });

          setMapMarkers(markers);
        }).catch((e) => {

        });
    }
  }

  function firstPage() {

  }

  function previousPage() {

  }

  function nextPage() {

  }

  function lastPage() {

  }

  const handleItemsPerPageChange = (event: React.SyntheticEvent | null, newValue: string | null) => {

  }

  function changeSearchTypeCallback(searchType) {
    setData(null);
    setSearchType(searchType);
  }

	return (
    <>
      <div className="flex flex-row">
        <div className="max-w-[200px]">
          <SearchBar 
            onClick={performSearch} 
            searchType={searchType}
            changeSearchTypeCallback={changeSearchTypeCallback}/>
        </div>

      {
        searchType == SearchType.NORMAL_SEARCH ? 
          <SearchResultsPage 
              data={data} 
              pageNumber={pageNumber}
              pageSize={pageSize}
              firstPage={firstPage}
              previousPage={previousPage}
              nextPage={nextPage}
              lastPage={lastPage}
              handleItemsPerPageChange={handleItemsPerPageChange}/>
          :
          <div className="bg-slate-100 p-2 m-10 pb-4 ml-[140px] w-full full-screen-border-map">
            <Map 
              mapLocation={DEFAULT_MAP_LOCATION} 
              zoom={10} 
              searchRadius={10} 
              markers={mapMarkers}
              markerRenderer={(marker) => "" }
              width={"calc(100% - 20px)"}
              height={"calc(100% - 20px)"}/>
          </div>
      }
      </div>

      <div className="justify-center mx-auto scale-50 mt-[0px]">
        { showSpinner ? <Spinner/> : <></> }
      </div>
    </>
  );
}