import { useEffect, useState } from "react";
import BidItem from "./bid.item";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import MobileMyBids from "./mobile.bids";

export default function MyBids() {
  const [incomingBids, setIncomingBids] = useState<any[]>(null);
  const [outgoingBids, setOutgoingBids] = useState<any[]>(null);

  const loadData = () => {
    var incomingUrl = BACKEND_URL + "/bids/all/incoming?bidState=OPEN";

    authService.getSecure(incomingUrl)
      .then((result) => {
        setIncomingBids(result.data);

        var outgoingUrl = BACKEND_URL + "/bids/all/outgoing?bidState=OPEN";

        authService.getSecure(outgoingUrl)
          .then((result2) => {
            setOutgoingBids(result2.data);
          });
      });
  }

  useEffect(() => {
    if (!incomingBids) {
      loadData();
    }
  }, null);

  function renderWeb() {
    return (
      <>
        <div className="text-md text-center mt-12">
          <a href="#incoming_bids">Inkomende biedingen {incomingBids ? "(" + incomingBids.length + ")" : ""}</a>
        </div>
  
        <div className="text-md text-center">
          <a href="#outgoing_bids">Uitgaande biedingen {outgoingBids ? "(" + outgoingBids.length + ")" : ""}</a>
        </div>
  
        <div className="">
          {
            incomingBids == null || incomingBids.length == 0 ?
            <>
              <h1 className="text-xl pt-16 pb-4 text-center">Inkomende biedingen:</h1>
              <p className="pt-4 text-center">Geen biedingen ontvangen.</p> 
            </>
            : 
            <>
              <a id="incoming_bids"></a>
              <h1 className="text-xl pt-16 pb-4 text-center">Inkomende biedingen ({ incomingBids.length }):</h1>
              <div>
                {
                  incomingBids.map((bid) => (
                    <div key={bid.id}>
                      <BidItem bid={bid}/>
                    </div>
                  ))
                }
              </div>
            </>
          }
        </div>
  
        <div className="mt-16">
          {
            outgoingBids == null || outgoingBids.length == 0 ? 
              <>
                <h1 className="text-xl pt-10 pb-4 text-center">Uitgaande biedingen:</h1>
                <p className="pt-4 text-center">Geen biedingen gedaan.</p> 
              </>
              : 
              <>
                <a id="outgoing_bids"></a>
                <h1 className="text-xl pt-10 pb-4 text-center">Uitgaande biedingen ({ outgoingBids.length }):</h1>
                <div className="">
                  { 
                    outgoingBids.map((bid, i) => (
                      <div key={i}>
                        <BidItem bid={bid}/>
                      </div>
                    ))
                  }
                </div>
              </>
          }
        </div>
      </>);
  }

  if (utils.isMobile()) {
    return <MobileMyBids 
      incomingBids={incomingBids} 
      outgoingBids={outgoingBids}/>
  } else {
    return renderWeb();
  }
}