import Bid from "./bid";
import BidState from "./bid.state";
import BidType from "./bid.type";
import bigDecimal from 'js-big-decimal';

class SimpleBid extends Bid {
	amount: bigDecimal;

	constructor($id: string,
              $bidType: BidType,
              $createTime: Date,
              $offerId: string,
              $itemId: string,
              $bidAccountId: string,
              $ownerAccountId: string,
              $bidState: BidState,
              $amount: string,
              $isHighestBid: boolean,
              $nrBids: number) {
		super($id, $createTime, $offerId, 
      $itemId, $bidAccountId, $ownerAccountId, 
      $bidState, $bidType, $isHighestBid, 
      $nrBids);

		this.amount = new bigDecimal($amount);
	}
}

export default SimpleBid;