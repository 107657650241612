import * as Ons from "react-onsenui";

import Spinner from "../../../components/spinner";
import ProductType, { ALL_PRODUCT_TYPES, productTypeFromString, productTypeToString } from "../../../domain/item/product.type";
import Checkbox from "../../../components/checkbox";
import { Modal, ModalClose } from "@mui/joy";
import { useEffect, useState } from "react";
import { PriceType } from "./price.type";
import { BACKEND_URL } from "../../../domain/env";
import { authService, utils } from "../../../services";
import { useNavigate } from "react-router";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { EventBus } from "../../../services/event.bus";
import { useFormik } from "formik";
import { NewItemValidator } from "../../../domain/search/form.validators";

export default function MobileNewItem() {
  const navigate = useNavigate();
  
  const [images, setImages] = useState<any[]>([]);
  const [priceType, setPriceType] = useState<PriceType>(PriceType.FIXED_PRICE);
  const [hasAuctionStartPrice, setHasAuctionStartPrice] = useState<boolean>(false);
  const [hasSimpleStartPrice, setHasSimpleStartPrice] = useState<boolean>(false);
  const [productType, setProductType] = useState<ProductType>(ProductType.WINE);
  const [productTypeLabel, setProductTypeLabel] = useState<string>("Wijn");
  const [showSelectProductType, setShowSelectProductType] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(true);

  const [enlargedImage, setEnlargedImage] = useState<any>(null);
  const [enlargeImageOpen, setEnlargeImageOpen] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [nrImages, setNrImages] = useState<number>(0);
  const [showImageError, setShowImageError] = useState<boolean>(false);
  const [blockerStyle, setBlockerStyle] = useState<string>(null);
  const [showDeleteImageWarning, setShowDeleteImageWarning] = useState<boolean>(false);
  const [showSuccessfulMessage, setShowSuccessfulMessage] = useState<boolean>(false);
  const [imageToDelete, setImageToDelete] = useState<number>(-1);

  const [loadedData, setLoadedData] = useState<number>(-1);

  async function loadData() {
    // This is here to trigger the login screen 
    // when the person is not logged in, we are not 
    // actually interested in the number of unread messages
    
    var url = BACKEND_URL + "/notifications/unread-notifications";
    
    await authService.getSecure(url)
      .then((_) => {
        setLoadedData(1);
      }).catch((_) => {
        setLoadedData(0);
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  useEffect(() => {
    if (loadedData == -1) {
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => loadData());

      loadData();
    }
  }, null);

  function selectMobilePhoto() {
    Camera.checkPermissions().then((result) => {
      
    });
    
    const takePicture = async () => {
      const image = await Camera.getPhoto({
        quality: 90,
        width: 200,
        height: 200,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      });
      
      setShowImageError(false);

      var newImages = [...images];
    
      newImages = Array.prototype.slice.call(newImages, 0, 3);
      
      let blob = await fetch(image.webPath).then(r => r.blob());

      if (newImages.length < 3) {
        newImages.push(blob);
      }

      setImages(newImages);
    }
    
    takePicture();
  }

  function openEnlargedImage(file) {
    setEnlargedImage(file);
    setEnlargeImageOpen(true);
  }

  function closeEnlargedImage() {
    setEnlargeImageOpen(false);
    setEnlargedImage(null);
  }

  function updatePriceType(newPriceType) {
    setPriceType(newPriceType);
    setTimeout(() => {
      document.getElementById("listitem-FIXED_PRICE").style.transition = "none";
    }, 10);
  }

  const formik = useFormik({
    initialValues: NewItemValidator.initialValues,
    validationSchema: NewItemValidator.validationSchema,
    onSubmit: (values, _) => {
      if (showImageError) {
        return;
      }

      if (images.length == 0) {
        setShowImageError(true);
        return;
      }

      var cleaned = NewItemValidator.cleanInput(values);

      uploadToServer(cleaned);
    }
  });

  async function uploadImagesToServer() {
    return Promise.all(images.map((file, i) => {
      const body = new FormData();
      
      body.set("sequenceNr", "" + i);
      body.set("type", file.type);
      body.set("file", file);

      var url = BACKEND_URL + "/static/img";

      return authService.postSecure(url, body)
        .then((result) => {
          var location = result["headers"]["location"] as string;
          var idx = location.lastIndexOf("/");
          var imgId = location.substring(idx + 1);

          return imgId;
        });
    }));
  }
  
  function uploadToServer(data) {
    uploadImagesToServer()
      .then((result) => {
        data["imgIds"] = result;
        var url = BACKEND_URL + "/items";

        authService.postSecure(url, data)
          .then((result) => {
            if (result.status == 201) {
              setShowSuccessfulMessage(true);
            }
          }).catch((e) => {
            
          });
      });
  }

  function goToMyAccountItems() {
    setShowSuccessfulMessage(false);

    setTimeout(() => {
      EventBus.getInstance()
        .dispatch<void>(EventBus.ON_GOTO_MY_ITEMS);
    }, 50);
  }

  function changePriceType(priceType: PriceType) {
    setPriceType(priceType);

    setHasSimpleStartPrice(false);
    setHasAuctionStartPrice(false);

    formik.values.simpleStartPrice = "";
    formik.values.auctionStartPrice = "";

    formik.errors.simpleStartPrice = "";
    formik.errors.auctionStartPrice = "";
  }

  function changeProductType(e) {
    var newProductType = productTypeFromString(e.target.value);
    setProductType(newProductType);
    setProductTypeLabel(productTypeToString(newProductType));
    formik.values.productType = e.target.value;
  }

  function resetPriceFields() {
    formik.values.priceType = PriceType.FIXED_PRICE;
    formik.values.fixedPrice = "";
    formik.values.auctionStartPrice = "";
    formik.values.simpleStartPrice = "";
    formik.values.auctionDays = "";
    formik.values.auctionHours = "";
    formik.values.auctionMinutes = "";

    formik.values.hasSimpleStartPrice = false;
    formik.values.auctionHasStartPrice = false;

    formik.errors.priceType = "";
    formik.errors.fixedPrice = "";
    formik.errors.auctionStartPrice = "";
    formik.errors.simpleStartPrice = "";
    formik.errors.auctionDays = "";
    formik.errors.auctionHours = "";
    formik.errors.auctionMinutes = "";
  }

  function selectProductType() {
    setShowSelectProductType(false);
  }

  function deleteImage(i) {
    setImageToDelete(i);
    setShowDeleteImageWarning(true);
  }

  function doDeleteImage() {
    if (imageToDelete != -1) {
      if (imageToDelete <= images.length - 1) {
        images.splice(imageToDelete, 1);

        setImages([]);
        
        setTimeout(() => {
          setImages(images);
        });

        setNrImages(nrImages - 1);
      }

      setImageToDelete(-1);
      setShowDeleteImageWarning(false);
    }
  }
  
  return (
    <Ons.Page className="bg-white">
      <form onSubmit={formik.handleSubmit}>
        <div className="ml-2 mr-2">
          <div className="m-1 ml-2 redhat-display">Afbeeldingen:</div>
          <div className="flex flex-row gap-2 mt-4 mb-4 justify-center">
            {images.map((file: any, i) => (
              <div key={file.name}>
                <div className="min-h-[100px]">
                  <img 
                    src={URL.createObjectURL(file)}
                    width={100} 
                    height={100}
                    onClick={() => {openEnlargedImage(URL.createObjectURL(file))}}
                    className="rounded-lg max-w-[100px] max-h-[100px] object-contain cursor-pointer"/>
                </div>

                <div className="w-full mx-auto text-center">
                  <a className="text-xs cursor-pointer redhat-display link-color" 
                    onClick={() => {deleteImage(i)}}>verwijderen</a>
                </div>
              </div>
            ))}

            {[...Array(Math.max(0, 3 - images.length))].map((x, i) => (
              <div key={x}
                  className="rounded-lg w-[100px] h-[100px] text-center align-middle bg-white mobile-take-picture-background"
                  onClick={() => selectMobilePhoto() }>
                <div className="w-[100px] h-[100px] text-xl">
                  <Ons.Icon icon="md-camera-add" className="camera-icon relative link-color top-[33px]"/>
                </div>
              </div>
            ))}
          </div>

        {
          showImageError ?
            <div className="text-xs ml-2 mt-0 mb-2 text-red-600 redhat-display">Selecteer tenminste 1 afbeelding</div>
            : null
        }

        <div className="m-1 ml-2 redhat-display">Titel:</div>
        <input 
          type="text"
          className="search-field redhat-display ml-2 mr-2"
          id="title"
          onChange={ e => {
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.title}/>

        {
          formik.touched.title && formik.errors.title ? (
            <div className="text-xs ml-2 mt-0 mb-2 text-red-600 redhat-display">{formik.errors.title}</div>)
            : null
        }

        <div className="m-1 ml-2 redhat-display">Merk:</div>
        <input
          id="brand"
          type="text"
          className="search-field redhat-display ml-2 mr-2"
          onChange={ e => {
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.brand}/>

        {
          formik.touched.brand && formik.errors.brand ? (
            <div className="text-xs ml-2 mt-0 mb-2 text-red-600 redhat-display">{formik.errors.brand}</div>) 
            : null
        }

        <div className="m-1 ml-2 redhat-display">Aantal items:</div>
        <input
          id="nrItems"
          type="text"
          className="search-field redhat-display ml-2 mr-2"
          onChange={ e => {
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.nrItems}
        />

        {
          formik.touched.nrItems && formik.errors.nrItems ? (
            <div className="text-xs ml-2 mt-0 mb-2 text-red-600 redhat-display">{formik.errors.nrItems}</div>) 
            : null
        }

        <div className="m-1 ml-2 mt-4 mr-2 redhat-display inline-block">Type: { productTypeLabel }</div>
        {
          showSelectProductType ? 
            <div className="mb-4 ml-2">
              <div>
                {
                  ALL_PRODUCT_TYPES.map((x, _) => (
                    <div key={x}>
                      <label className="left">
                        <input type="radio"
                          id="productType"
                          className="relative top-[4px] mb-2 mt-2"
                          checked={productType == x}
                          value={"" + x}
                          onBlur={formik.handleBlur}
                          onChange={ e => {
                            formik.handleChange(e);
                            changeProductType(e);
                          }}
                        />
                      </label>
                      <label htmlFor={"productType-" + x}
                          className="center redhat-display">
                        {productTypeToString(x)}
                      </label>
                    </div>))
                }
              </div>

              <div className="mb-4 ml-4 mt-4">
                <Ons.Button className="link-color max-w-[100px] inline-block" 
                    onClick={() => selectMobilePhoto()}>
                  <span className="text-sm redhat-display capitalize">Selecteren</span>
                </Ons.Button>
              </div>
            </div>
            : 
            <>
              <div className="mb-4 ml-4">
                <Ons.Button className="link-color max-w-[100px] inline-block" 
                    onClick={() => setShowSelectProductType(true)}>
                  <span className="text-sm redhat-display capitalize">Selecteren</span>
                </Ons.Button>
              </div>
            </>
        }

        {
          formik.touched.productType && formik.errors.productType ? (
            <div className="text-xs ml-2 mt-0 mb-2 text-red-600 redhat-display">{formik.errors.productType}</div>) 
            : null
        }

        <div className="m-1 ml-2 redhat-display">Jaar:</div>
        <input
          type="text"
          id="itemYear"
          className="search-field redhat-display ml-2 mr-2"
          onChange={ e => {
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          value={formik.values.itemYear}/>

        {
          formik.touched.itemYear && formik.errors.itemYear ? (
            <div className="text-xs ml-2 mt-0 mb-2 text-red-600 redhat-display">{formik.errors.itemYear}</div>) 
            : null
        }

        <div className="m-1 ml-2 redhat-display">Omschrijving:</div>
        <div className="flex flex-row justify-center text-center">
          <textarea
            rows={0}
            cols={0}
            className="resize-none textarea text-xs redhat-display new-item-description"
            id="description"
            name="description"
            onChange={ e => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.description}/>
        </div>

        {
          formik.touched.description && formik.errors.description ? (
            <div className="text-xs ml-2 mt-2 mb-2 text-red-600 redhat-display">{formik.errors.description}</div>) 
            : null 
        }

        <div className="m-1 ml-2 redhat-display">Type aanbieding:</div>
        <div className="ml-2">
          <div id="listitem-FIXED_PRICE" key="listitem-FIXED_PRICE" 
              onClick={() => {
                resetPriceFields();
                formik.values.priceType = PriceType.FIXED_PRICE;
                changePriceType(PriceType.FIXED_PRICE);
              }} className="mb-[10px]">
            <div className="w-full">
              <label className="left">
                <input 
                  type="radio" 
                  id="input-FIXED_PRICE"
                  className="pr-3 body-style redhat-display relative top-[4px]"
                  checked={priceType == PriceType.FIXED_PRICE}/>
                <label htmlFor="input-FIXED_PRICE"
                  className="center redhat-display mt-2">Aanbieden met vaste prijs</label>
              </label>
            </div>
          </div>

          { 
            priceType == PriceType.FIXED_PRICE ? 
              <div className="ml-4 mb-[-20px]">
                <div className="m-1 redhat-display">Prijs:</div>
                <input 
                  id="fixedPrice"
                  className="mt-2 pl-6 mr-8 search-field redhat-display"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.fixedPrice}/>

                <div className="relative top-[-35px] left-[10px] w-[10px] text-sm euro-sign redhat-display">&#x20AC;</div>

                {
                  formik.touched.fixedPrice && formik.errors.fixedPrice ? (
                    <div className="text-xs ml-2 mt-[-20px] mb-[25px] text-red-600 redhat-display text-left">{formik.errors.fixedPrice}</div>) 
                    : null
                }
              </div>
            : <></>
          }
        
          <div key="listitem-AUCTION"
              onClick={() => {
                resetPriceFields();
                formik.values.priceType = PriceType.AUCTION;
                changePriceType(PriceType.AUCTION);
              }} className="mb-[10px]">
            <div>
              <label className="left">
                <input 
                  type="radio" 
                  id="input-AUCTION"
                  className="pr-3 body-style redhat-display relative top-[4px]"
                  checked={priceType == PriceType.AUCTION}
                  value="AUCTION"
                  name="radio-button-price"/>
                
                <label htmlFor="input-AUCTION"
                  className="center redhat-display mt-2">Als veiling aanbieden</label>
              </label>
            </div>
          </div>
          
          {
            priceType == PriceType.AUCTION ? (
              <div className="ml-4 mb-[-10px]">
                <div className="mb-2 mt-4 redhat-display">Duur van de veiling:</div>
                <div className="mb-2">
                  <input 
                    type="text" 
                    id="auctionDays"
                    className="w-[40px] mr-2 larger-input redhat-display"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.auctionDays}/>
                  <span className="text-sm redhat-display">dagen,&nbsp;&nbsp;</span>

                  <input 
                    type="text" 
                    id="auctionHours"
                    className="w-[40px] mr-2 larger-input redhat-display"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.auctionHours}/>
                  <span className="text-sm redhat-display">uren,&nbsp;&nbsp;</span>

                  <input 
                    type="text" 
                    id="auctionMinutes"
                    className="w-[40px] mr-2 larger-input redhat-display"
                    onChange={ e => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.auctionMinutes}/>
                  <span className="text-sm redhat-display">minuten</span>
                </div>

                {
                  formik.touched.auctionDays && formik.errors.auctionDays ? (
                    <div className="text-xs mt-0 mb-2 text-red-600 redhat-display text-left">{formik.errors.auctionDays}</div>)
                    : <div className="text-xs ml-2 mt-1 text-red-600 redhat-display">&nbsp;</div>
                }

                <div className="relative top-[2px] inline-block mb-2">
                  <label className="left">
                    <Checkbox
                      id="auctionHasStartPrice"
                      disabled={false}
                      className="relative top-[4px]"
                      onChange={ e => {
                        formik.handleChange(e);
                        setHasAuctionStartPrice(!hasAuctionStartPrice);
                      }}
                      value={formik.values.auctionHasStartPrice}
                      label=""/>
                    <label
                      htmlFor="auctionHasStartPrice" 
                      className="mt-4 mr-4 ml-2 redhat-display">Startprijs:</label>
                  </label>
                </div>
                
                <input type="text" 
                  id="auctionStartPrice"
                  disabled={!hasAuctionStartPrice} 
                  className="mb-4 mt-2 pl-6 search-field redhat-display"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.auctionStartPrice}/>
                <div className="relative top-[-35px] left-[10px] w-[10px] text-sm euro-sign redhat-display">&#x20AC;</div>
                
                {
                  formik.touched.auctionStartPrice && formik.errors.auctionStartPrice ? (
                    <div className="text-xs text-red-600 redhat-display mt-[-25px] mb-[25px]">{formik.errors.auctionStartPrice}</div>) 
                    : <div className="text-xs redhat-display mt-[-25px] mb-[10px]">&nbsp;</div>
                }
              </div>
            ) : <></>
          } 

          <div key="listitem-ALLOW_BIDDING"
              onClick={() => {
                resetPriceFields();
                formik.values.priceType = PriceType.ALLOW_BIDDING;
                setPriceType(PriceType.ALLOW_BIDDING);
              }} className="mb-[10px]">
            <div>
              <label className="left">  
                <input type="radio"
                  id="input-ALLOW_BIDDING"
                  className="pr-3 body-style redhat-display relative top-[4px]"
                  checked={priceType == PriceType.ALLOW_BIDDING}
                  value="ALLOW_BIDDING"
                  name="radio-button-price"/>
                <label 
                  htmlFor="input-ALLOW_BIDDING"
                  className="center redhat-display mt-2">Simpel bieden</label>
              </label>
            </div>
          </div>
          
          {
            priceType == PriceType.ALLOW_BIDDING ? (
              <div className="mt-4 ml-4">
                <div className="relative top-[2px] inline-block mb-2">
                  <label className="left">
                    <Checkbox
                      id="hasSimpleStartPrice"
                      disabled={false}
                      className="relative top-[4px]"
                      onChange={ e => {
                        setHasSimpleStartPrice(!hasSimpleStartPrice);
                        formik.handleChange(e);
                      }}
                      value={formik.values.hasSimpleStartPrice}
                      label=""/>
                    <label 
                      htmlFor="hasSimpleStartPrice" 
                      className="mt-4 mr-4 ml-2 redhat-display">Startprijs:</label>
                  </label>
                </div>

                <input
                  id="simpleStartPrice"
                  type="text" 
                  disabled={!hasSimpleStartPrice} 
                  className="w-full mb-4 mt-2 pl-6 search-field redhat-display"
                  onChange={ e => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.simpleStartPrice}/>
                <div className="relative top-[-35px] left-[10px] w-[10px] text-sm euro-sign redhat-display">&#x20AC;</div>

                {
                  formik.touched.simpleStartPrice && formik.errors.simpleStartPrice ? (
                    <div className="text-xs  mb-2 text-red-600 redhat-display mt-[-25px]">{formik.errors.simpleStartPrice}</div>) 
                    : <div className="text-xs mt-[-30px] redhat-display">&nbsp;</div>
                }
              </div>
            ) : <></>
          }
        </div>

        <div className="flex flex-row mb-4 ml-4">
          <div className="grow mb-0 mt-4">
            <div className="">
              <div className="relative top-[2px] inline-block">
                <Checkbox
                  id="visible"
                  label=""
                  disabled={false}
                  className=""
                  onChange={ e => {
                    setVisible(!visible);
                    formik.handleChange(e);
                  }}
                  value={formik.values.visible}/>
              </div>
              <label
                htmlFor="visible" 
                className="mt-4 mr-4 ml-2 redhat-display">Zichtbaar</label>
            </div>
            
            <div></div>
          </div>
        </div>
        
        <Modal
          open={enlargeImageOpen}
          onClose={() => closeEnlargedImage()}
          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <>
              <ModalClose variant="plain" sx={{ m: 1 }} />
              <img 
                src={enlargedImage}
                width={400} 
                height={400}
                className="rounded-lg w-[400px] h-[400px] cursor-pointer object-contain"/>
            </>
        </Modal>
                  
        <Ons.AlertDialog isOpen={showDeleteImageWarning} cancelable>
          <div className="text-center w-full mt-2 redhat-display">
            <span className="alert-dialog-text font-bold alert-dialog-text">Afbeelding verwijderen</span>
          </div>
          
          <div className="text-center w-full mt-4 p-4 alert-dialog-text redhat-display text-sm">
            <span className=" alert-dialog-text">Weet je zeker dat je deze afbeelding wil verwijderen?</span>
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button onClick={() => doDeleteImage() } className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Ja</span>
            </Ons.Button>

            <Ons.Button onClick={() => setShowDeleteImageWarning(false)} className="button min-w-[110px]">
              <span className="redhat-display text-sm capitalize">Nee</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>

        <Ons.AlertDialog id="successfulMessage" isOpen={showSuccessfulMessage}>
          <div className="text-center w-full redhat-display mt-2">
            <span className="font-bold alert-dialog-text">Item aangemaakt</span>
          </div>
          
          <div className="text-center w-full mt-4 p-4 redhat-display alert-dialog-text">
            Je item is succesvol aangemaakt.
          </div>

          <div className="w-full p-4 gap-2 justify-center flex flex-row">
            <Ons.Button onClick={() => {
              goToMyAccountItems();
            }} className="button min-w-[110px]">
              <span className="redhat-display text-sm">OK</span>
            </Ons.Button>
          </div>
        </Ons.AlertDialog>
        
        <div className="w-full text-center pt-4 pb-4">
          <button className="button link-color p-2 min-w-[150px]" type="submit">
            <span className="redhat-display text-sm capitalize">Opslaan</span>
          </button>
        </div>

        {
          (!formik.isValid && formik.submitCount > 0) || showImageError ? 
            <>
              <div className="text-center text-sm text-red-600 redhat-display mt-2 ml-4 mr-4">Er ontbreken nog enkele gegegevens, vul de velden met foutmeldingen opnieuw in.</div>
            </>
            : <></>
        }
        <div className="justify-center mx-auto mt-[0px] mb-2">
          { showSpinner ? <Spinner/> : <></> }
        </div>

        <div className="mb-24"></div>
      </div>
    </form>
  </Ons.Page>);
}