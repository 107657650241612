export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC"
};

export enum SortBy {
  CREATE_TIME = "CREATE_TIME",
  PRICE = "PRICE",
  NAME = "NAME",
  YEAR = "YEAR"
}