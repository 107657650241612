import axios, { AxiosInstance } from "axios";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "../domain/constants";
import OfferSearchResults from "../domain/search/offer.search.results";
import BidSearchResults from "../domain/search/bid.search.results";
import AccountSearchResults from "../domain/search/account.search.results";
import TradeSearchResults from "../domain/search/trade.search.results";
import { authService, utils } from ".";
import MapSearchItem from "../domain/search/map.search.item";
import AccountBuilder from "../domain/account/account.builder";
import AccountRole from "../domain/account/account.role";
import ReviewBuilder from "../domain/review/review.builder";
import ReviewSearchResults from "../domain/search/review.search.results";
import { BACKEND_URL } from "../domain/env";
import { OfferBuilder } from "../domain/offer/offer.builder";
import SearchOfferBuilder from "../domain/offer/search.offer.builder";

export class SearchService {
  protected readonly instance: AxiosInstance;
  
  public constructor(url: string) {
    this.instance = axios.create({
      baseURL: url,
      timeout: 3000,
      timeoutErrorMessage: "Time out!",
    });
  }

  public mapSearch(search: any): Promise<MapSearchItem[]> {
    var url = BACKEND_URL + "/offers/all/map";

    return authService.postSecure(url, search)
      .then((result) => {
        return result.data.map((item) => {
          return new MapSearchItem(item.offerId, item.latitude, item.longitude);
        });
      });
  }

  public searchOffers(search: any,
                      pageNr?: number,
                      pageSize?: string): Promise<OfferSearchResults> {
    var url = BACKEND_URL + "/offers/all";

    if (!pageNr) {
      pageNr = DEFAULT_PAGE_NUMBER;
    }

    if (!pageSize) {
      pageSize = "" + DEFAULT_PAGE_SIZE;
    }

    url += "?pageNr=" + pageNr + "&pageSize=" + pageSize;

    return authService.postSecure(url, search)
      .then((results) => {
        var searchOffers = results.data.data.map(d => SearchOfferBuilder.buildSearchOffer(d));
      
        var links = utils.parseLinkHeaders(results.headers.link);
        
        var data = new OfferSearchResults(
          results.data.pageNr,
          results.data.pageSize,
          results.data.nrItemsThisPage,
          results.data.totalNrPages,
          results.data.itemCount,
          results.data.search,
          searchOffers,
          links);

        return data;
      });
  }

  public mobileQuickSearch(search: any,
                           pageNr?: number,
                           pageSize?: string): Promise<OfferSearchResults> {
    var url = BACKEND_URL + "/offers/all/mobile";

    if (!pageNr) {
      pageNr = DEFAULT_PAGE_NUMBER;
    }

    if (!pageSize) {
      pageSize = "" + DEFAULT_PAGE_SIZE;
    }

    url += "?pageNr=" + pageNr + "&pageSize=" + pageSize;

    return authService.postSecure(url, search)
      .then((results) => {
        var data = new OfferSearchResults(
          results.data.pageNr,
          results.data.pageSize,
          results.data.nrItemsThisPage,
          results.data.totalNrPages,
          results.data.itemCount,
          results.data.search,
          results.data.data);

        return data;
      });
  }

  public searchBids(pageNr?: number,
                    pageSize?: string): Promise<BidSearchResults> {
    var url = BACKEND_URL + "/bids/all";

    if (!pageNr) {
      pageNr = DEFAULT_PAGE_NUMBER;
    }

    if (!pageSize) {
      pageSize = "" + DEFAULT_PAGE_SIZE;
    }

    url += "?pageNr=" + pageNr + "&pageSize=" + pageSize;

    return authService.getSecure(url)
      .then((results) => {
        var data = new BidSearchResults(
          results.data.pageNr,
          results.data.pageSize,
          results.data.nrItemsThisPage,
          results.data.totalNrPages,
          results.data.itemCount,
          results.data.search,
          results.data.data);

      return data;
    });
  }

  public searchReviews(accountId: string,
                       pageNr?: number,
                       pageSize?: string): Promise<ReviewSearchResults> {
    var url = BACKEND_URL + "/reviews/all/" + accountId;

    if (!pageNr) {
      pageNr = DEFAULT_PAGE_NUMBER;
    }
    
    if (!pageSize) {
      pageSize = "3";
    }

    url += "?pageNr=" + pageNr + "&pageSize=" + pageSize;

    return authService.getSecure(url)
      .then((results) => {
        var reviews = results.data.data.map(review => {
          return ReviewBuilder.buildReview(review);
        });

        var data = new ReviewSearchResults(
          results.data.pageNr,
          results.data.pageSize,
          results.data.nrItemsThisPage,
          results.data.totalNrPages,
          results.data.itemCount,
          reviews);

        return data;
      });              
  }

  public searchAccounts(pageNr?: number,
                        pageSize?: string): Promise<AccountSearchResults> {
    var url = BACKEND_URL + "/accounts/all";
    
    if (!pageNr) {
      pageNr = DEFAULT_PAGE_NUMBER;
    }
    
    if (!pageSize) {
      pageSize = "" + DEFAULT_PAGE_SIZE;
    }

    url += "?pageNr=" + pageNr + "&pageSize=" + pageSize;

    return authService.getSecure(url)
      .then((results) => {
        var accounts = results.data.data.map(account => {
          return AccountBuilder.buildAccount(account);
        }).filter(account => {
          return account.accountRole != AccountRole.ADMIN;
        });

        var data = new AccountSearchResults(
          results.data.pageNr,
          results.data.pageSize,
          results.data.nrItemsThisPage,
          results.data.totalNrPages,
          results.data.itemCount,
          results.data.search,
          accounts);

        return data;
      })
  }

  public searchTrades(pageNr?: number,
                      pageSize?: string): Promise<TradeSearchResults> {
    var url = BACKEND_URL + "/trades/all";

    if (!pageNr) {
      pageNr = DEFAULT_PAGE_NUMBER;
    }

    if (!pageSize) {
      pageSize = "" + DEFAULT_PAGE_SIZE;
    }

    url += "?pageNr=" + pageNr + "&pageSize=" + pageSize;

    return authService.getSecure(url)
      .then((results) => {
        var data = new TradeSearchResults(
          results.data.pageNr,
          results.data.pageSize,
          results.data.nrItemsThisPage,
          results.data.totalNrPages,
          results.data.itemCount,
          results.data.search,
          results.data.data);

       return data;
    });
  }
}