import Offer from "../offer/offer";
import Item from "./item";

class MyItem {
  item: Item;
  offer: Offer;

  constructor($item: Item,
              $offer: Offer) {
    this.item = $item;
    this.offer = $offer;
  }
}

export default MyItem;