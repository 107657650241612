import moment from "moment";
import * as Ons from "react-onsenui";
import { useState, useEffect } from "react";
import { DATE_TIME_FORMAT, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE, DATE_FORMAT } from "../../domain/constants";
import PriceType, { offerTypeToString } from "../../domain/offer/offer.type";
import { searchService, utils } from "../../services";
import { useNavigate } from "react-router";
import { EventBus } from "../../services/event.bus";
import { BACKEND_URL } from "../../domain/env";
import { Utils } from "../../services/utils";
import { DefaultMobileToolbar } from "../default.mobile.toolbar";
import OfferPage from "../offers/[offerId]/page";
import MobilePaginator from "../../components/mobile.paginator";
import Loading from "../../components/loading";
import CountDownTimer from "../../components/countdown.timer";
import { OfferBuilder } from "../../domain/offer/offer.builder";
import Favorite from "../../components/favorite";

export default function MobileSearchItemsResultPage({ search, searchResults }) {
  const navigate = useNavigate();
  
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);
  const [totalNrPages, setTotalNrPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<any>(null);
  const [itemCount, setItemCount] = useState<number>(null);
  
  function loadData() {
    goToResultsPage(search, pageNumber, pageSize);
  }

  useEffect(() => {
    if (!currentPage) {
      EventBus.getInstance().register(
        EventBus.ON_LOGIN_SUCCESSFUL, () => loadData());

      if (!searchResults) {
        loadData();
      } else {
        setTotalNrPages(searchResults.totalNrPages);
        setCurrentPage(searchResults);
      }
    }
  }, null);

  function goToResultsPage(search, pageNumber, pageSize) {
    searchService.searchOffers(search, pageNumber, "" + pageSize)
      .then((result) => {
        setItemCount(result.itemCount);
        setTotalNrPages(result.totalNrPages);

        setCurrentPage(result.data);
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  function first() {
    setPageNumber(1);
    goToResultsPage(search, 1, pageSize);
  }

  function previous() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      goToResultsPage(search, pageNumber - 1, pageSize);
    }
  }

  function next() {
    if (pageNumber < totalNrPages) {
      setPageNumber(pageNumber + 1);
      goToResultsPage(search, pageNumber + 1, pageSize);
    }
  }

  function last() {
    setPageNumber(totalNrPages);
    goToResultsPage(search, totalNrPages, pageSize);
  }

  if (!currentPage) {
    return <Loading/>;
  }

  const renderHeader = (text) => {
    return (
      <Ons.ListHeader className={`${ utils.getPlatform() == "android" ? "" : ""}`}>
        <div className="body-style">{text}</div>
      </Ons.ListHeader>
    );
  }

  function goToOffer(offerId) {  
    Utils.mobileNavigator.pushPage({
      page: <OfferPage offerId={offerId}/>,
      renderToolbar: () => {
        return <DefaultMobileToolbar text="Item bekijken"/> 
      }
    }, { animation: "none" });
  }
  
  const renderRow = (row, index) => {    
    return (
      <>
        <Ons.ListItem key={row.id} modifier="nodivider">
          <div className="flex flex-row grow body-style" onClick={() => {goToOffer(row.offerId)}}>
            <img 
              className={`mr-4 ml-0 mt-0 rounded-lg mobile-item-list-image ${ row.offerType == PriceType.AUCTION ? "mobile-auction-image" : "" } ${ row.offerType == PriceType.BUSINESS_OFFER ? "mobile-business-image" : ""}`}
              src={`${BACKEND_URL}/static/img/preview/${row.itemId}`}
              alt="Item image"
              width={80}
              height={80}/>
            
            <div className="flex flex-col">
              <div className="text-xs font-bold secondary-color">{ row.title }</div>

              <div className="font-bold bid-amount relative text-left">{
                row.amount ?
                  utils.roundedAmount(row.amount, 2)
                  : "Bieden"
              }</div>

              <div className="text-xs">
                Sinds { moment(row.createTime, DATE_TIME_FORMAT).format(DATE_FORMAT) }
              </div>

              <div className="text-xs">
                Type: { offerTypeToString(row.offerType) }
              </div>

              {
                row.city ?
                  <div className="text-xs">
                    { row.city } { utils.getDistance(row) }
                  </div>
                  : <></>
              }
            </div>

            {
              row.offerType == PriceType.AUCTION ? 
                <CountDownTimer 
                  offer={row}
                  labelClassName="relative left-[30px] top-[20px] text-xs"
                  timerClassName="relative left-[40px] top-[20px] auction-time-mobile"/>
                : <></>
            }

            <div className="grow"></div>
            
            <Favorite itemType={"offer"} favoriteEntityId={row.offerId}/>
          </div>
        </Ons.ListItem>

        <div className="w-full h-[1px] ml-[15px] border-solid search-line"></div>
      </>);
  }

  return (
    <>
      <MobilePaginator
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalNrPages={totalNrPages}
        first={first}
        previous={previous}
        next={next}
        last={last}
        isTop={true}/>

      <Ons.List
        className={`${ utils.getPlatform() == "android" ? "relative top-[10px]" : "" }`}
        dataSource={currentPage}
        renderRow={(row, index) => renderRow(row, index)}
        renderHeader={() => renderHeader(itemCount + " items")}/>

      <MobilePaginator
        pageNumber={pageNumber}
        pageSize={pageSize}
        totalNrPages={totalNrPages}
        first={first}
        previous={previous}
        next={next}
        last={last}
        isTop={false}/>

      <div className="mb-[80px]"/>
    </>
  );
}