import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BidBuilder from "../../../domain/bid/bid.builder";
import { BACKEND_URL } from "../../../domain/env";
import { OfferBuilder } from "../../../domain/offer/offer.builder";
import { TradeBuilder } from "../../../domain/trade/trade.builder";
import { tradeStateToString } from "../../../domain/trade/trade.state";
import TradeType, { tradeTypeToString } from "../../../domain/trade/trade.type";
import { authService, utils } from "../../../services";

export default function TradePage({ tradeId }: { tradeId: string }) {
  const navigate = useNavigate();

  const [trade, setTrade] = useState<any>(null);
  const [bid, setBid] = useState<any>(null);
  const [offer, setOffer] = useState<any>(null);

  const loadData = () => {
    var url = BACKEND_URL + "/trades/" + tradeId;

    authService.getSecure(url)
      .then((result) => {
        var b = TradeBuilder.buildTrade(result.data);
        setTrade(b);

        fetchBid(b);
        fetchOffer(b);
      }).catch((_) => {
        utils.handleUnauthorized(() => navigate("/login"));
      });
  }

  function fetchBid(b) {
    var url = BACKEND_URL + "/bids/" + b.bidId;

    authService.getSecure(url)
      .then((result) => {
        setBid(BidBuilder.buildBid(result.data));
      })
  }

  function fetchOffer(b) {
    var url = BACKEND_URL + "/offers/" + b.offerId;

    authService.getSecure(url)
      .then((result) => {
        setOffer(OfferBuilder.buildOffer(result.data));
      })
  }

  useEffect(() => {
    if (!trade) {
      loadData();
    }
  }, null);

  function goTotrade() {
    if (!trade) {
      return;
    }

    navigate("/gropp/trades/" + trade.id);
  }

  function getOwnerLink() {
    if (!trade) {
      return "#";
    }

    return "/gropp/accounts/" + trade.ownerAccountId;
  }

  function gettradederLink() {
    if (!trade) {
      return "#";
    }

    return "/gropp/accounts/" + trade.tradeAccountId;
  }

  function getOfferLink() {
    if (!trade) {
      return "#";
    }

    return "/gropp/offers/" + trade.offerId;
  }

  function getAmount(): string {
    if (!trade) {
      return "";
    }

    return utils.roundedAmount(trade.amount, 2);
  }

  if (!trade) {
    return <></>;
  }

  return (
    <>
      <div className="flex flex-row mx-auto justify-center align-middle w-full text-center">
        <img 
          className="mr-4 ml-0 mt-0 rounded-lg item-image-trade-info mx-auto"
          src={`${BACKEND_URL}/static/img/preview/${trade.itemId}`}
          alt="Item image"
          width={150}
          height={150}
          />
      </div>
      
      <div className="flex flex-row mx-auto justify-center align-middle w-full text-center">
        <img 
          className="mr-4 ml-0 mt-0 avatar-img-trade-page rounded-full h-24 w-24 bg-gray-50 p-2 z-30 mx-auto"
          src={`${BACKEND_URL}/static/img/account/${trade.tradeAccountId}`}
          alt="tradeder avatar"
          width={20}
          height={20}/>
      </div>

      <div className="mb-10 mx-auto justify-center mt-[-50px]">
        <div className="grid grid-cols-2 gap-4 justify-center">
          <div className="text-right font-bold ">Type:</div>
          <div className="">{tradeTypeToString(trade.tradeType)}</div>

          <div className="text-right font-bold">Status:</div>
          <div className="">{tradeStateToString(trade.tradeState)}</div>

          <div className="text-right font-bold">Item:</div>
          <div className="">
            <Link to={getOfferLink()}>bekijk item</Link>
          </div>

          <div className="text-right font-bold">Eigenaar:</div>
          <div className="">
            <Link to={getOwnerLink()}>bekijk eigenaar</Link>
          </div>

          <div className="text-right font-bold">Koper:</div>
          <div className="">
            <Link to={gettradederLink()}>bekijk bieder</Link>
          </div>

          <div className="text-right font-bold">Bieding:</div>
          <div className="">
            <Link to={gettradederLink()}>bekijk bieding</Link>
          </div>

          <div className="text-right font-bold">Datum:</div>
          <div className="">{utils.renderFriendlyDateTime(trade.createTime)}</div>

          {
            trade.tradeType == TradeType.BUSINESS_TRADE ?
              <></>
              : <>
                <div className="text-right font-bold ">Hoogste bod:</div>
                <div className="">{trade.isHighesttrade ? "ja" : "nee" }</div>
              </>
          }
          
          {
            trade.tradeType == TradeType.BUSINESS_TRADE ?
              <></>
              : <>
                <div className="text-right font-bold">Aantal biedingen:</div>
                <div className="">{trade.nrtrades}</div>
              </>
          }

          <div className="text-right font-bold mt-auto mb-auto">Bedrag:</div>
          <div className="offer-price text-2xl">{ getAmount() }</div>
        </div>
      </div>
    </>
  );
}