import moment from "moment";
import ColorScheme from "../domain/color.scheme";
import { DATE_FORMAT, DATE_TIME_FORMAT, FRIENDLY_DATE_TIME_FORMAT, FRIENDLY_PRECISE_DATE_TIME_FORMAT, PRECISE_TIME_FORMAT } from "../domain/constants";
import { Capacitor } from "@capacitor/core";
import { settings, utils } from ".";
import { FORCE_MOBILE, PLATFORM_OVERRIDE } from "../domain/env";
import { OfferState } from "../domain/offer/offer.state";
import { jwtDecode } from "jwt-decode";
import { Preferences } from '@capacitor/preferences';
import bigDecimal from 'js-big-decimal';

export class Utils {
  public static showGlobalConnectionError: boolean = false;
  public static mobileNavigator: any = null;
  public static showTabbar: boolean = true;
  
  public roundedAmount(amount: bigDecimal, nrDigits = 2, includeEuroSign = false): string {
    var pretty = amount.getPrettyValue();

    if (pretty.indexOf(".") != -1) {
      var split = pretty.split(".");

      if (split[1].length == 1) {
        return split[0] + "." + split[1] + "0";
      }
    }

    return pretty;
  }

  public round(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
  }

  public renderDateTime(date: Date): string {
    return moment(date, DATE_TIME_FORMAT).toISOString();
  }

  public renderFriendlyDateTime(date: Date): string {
    return moment(date).format(FRIENDLY_DATE_TIME_FORMAT);
  }

  public renderPreciseFriendlyDateTime(date: Date): string {
    return moment(date).format(FRIENDLY_PRECISE_DATE_TIME_FORMAT);
  }

  public renderDate(date: Date): string {
    return moment(date).format(DATE_FORMAT);
  }

  public renderPreciseTime(date: Date): string {
    return moment(date).format(PRECISE_TIME_FORMAT);
  }

  public isMobile(): boolean {
    if (FORCE_MOBILE) {
      return true;
    } else {
      var platform = Capacitor.getPlatform();
      return platform == "android" || platform == "ios";
    }
  }

  public getPlatform() {
    if (PLATFORM_OVERRIDE.length != 0) {
      return PLATFORM_OVERRIDE;
    } else {
      return Capacitor.getPlatform();
    }
  }
  
  public handleUnauthorized(navigate) {
    if (this.isMobile() && Utils.mobileNavigator) {
      if (Utils.mobileNavigator.isRunning()) {
        setTimeout(() => {
          this.handleUnauthorized(navigate);
        });
      } else {
        setTimeout(() => {
          Utils.mobileNavigator.pushPage({
            name: "login/" + utils.makeId(5)
          }, { animation: "none" });
        }, 10);
      }
    } else {
      navigate();
    }
  }
  
  public parseLinkHeaders(link: any) {
    var split = link.split(",");
    
    const clean = (url) => {
      var cleaned = url.replace("<", "").replace(">", "").trim();
      cleaned = cleaned.substring(0, cleaned.indexOf(";"));

      var doubleSlashIdx = cleaned.indexOf("//");
      
      if (doubleSlashIdx != -1) {
        var firstSlashIdx = cleaned.indexOf("/", doubleSlashIdx + 2);
      
        if (firstSlashIdx != -1) {
          cleaned = cleaned.substring(firstSlashIdx, cleaned.length);
        }
      }
      
      return cleaned;
    }

    var result = {};

    var first = split.findIndex(l => l.indexOf("first") != -1);

    if (first != -1) {      
      result["first"] = clean(split[first]);
    }

    var previous = split.findIndex(l => l.indexOf("prev") != -1);

    if (previous != -1) {      
      result["prev"] = clean(split[previous]);
    }

    var next = split.findIndex(l => l.indexOf("next") != -1);

    if (next != -1) {      
      result["next"] = clean(split[next]);
    }
    
    var last = split.findIndex(l => l.indexOf("last") != -1);

    if (last != -1) {      
      result["last"] = clean(split[last]);
    }
    
    return result;
  }

  public getDistance(offer) {
    
  }

  public makeId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    return result;
  }

  public cleanPageStack() {
    for (var i = Utils.mobileNavigator.pages.length - 2; i >= 0; i--) {
      Utils.mobileNavigator.pages.splice(i, 1);
    }
  }

  public printPageStack() {
    console.log("Current page stack (" + Utils.mobileNavigator.pages.length + " pages):");
    
    for (var i = 0; i < Utils.mobileNavigator.pages.length; i++) {
      console.log(i + ") ", Utils.mobileNavigator.pages[i].key);
    }
    
    console.log("-----");
  }

  public isOnPageStack(routeName) {
    if (!utils.isMobile() || !Utils.mobileNavigator) {
      return true;
    }

    for (var i = 0; i < Utils.mobileNavigator.pages.length; i++) {
      var key = Utils.mobileNavigator.pages[i].key;

      if (key != null && key.startsWith(routeName)) {
        return true;
      }
    }

    return false;
  }

  public getEmptyStar() {
    if (settings.colorScheme == ColorScheme.DARK) {
      return "/empty_star_dark.png";
    } else if (settings.colorScheme == ColorScheme.BEIGE) {
      return "/empty_star_beige.png";
    } else {
      return null;
    }
  }

  public getFullStar() {
    if (settings.colorScheme == ColorScheme.DARK) {
      return "/full_star_dark.png";
    } else if (settings.colorScheme == ColorScheme.BEIGE) {
      return "/full_star_beige.png";
    } else {
      return null;
    }
  }
  
  public getAuctionSeconds(offer, debug = false) {
    var now = new Date().getTime();
    var durationMs = offer.auctionDuration * 1000;

    var openTime = offer.openTime ? offer.openTime : offer.createTime;

    var auctionEnd = new Date(openTime.getTime() + durationMs);
    var result = parseInt("" + Math.abs(auctionEnd.getTime() - now) / 1000);

    return result;
  }

  public getAuctionTimeData(offer) {
    var now = new Date().getTime();
    var durationMs = offer.auctionDuration * 1000;

    var openTime = offer.openTime ? offer.openTime : offer.createTime;

    var auctionEnd = new Date(openTime.getTime() + durationMs);
    var seconds = parseInt("" + (auctionEnd.getTime() - now) / 1000);

    return {
      "alreadyClosed": auctionEnd.getTime() <= now,
      "now": new Date(),
      "nowMs": now,
      "durationMs": durationMs, 
      "openTime": openTime,
      "auctionEnd": auctionEnd,
      "auctionEndMs": auctionEnd.getTime(),
      "seconds": seconds
    };
  }

  public getAuctionCloseTime(offer): string {
    return moment(offer.auctionEnd).format(FRIENDLY_DATE_TIME_FORMAT);
  }

  public getAuctionDuration(offer): string {
    var delta = offer.auctionDuration;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    var seconds = Math.ceil(delta % 60);

    return (
      (days == 0 ? "" : days + "d ") + 
      (hours < 10 ? "0" + hours : hours) + ":" + 
      (minutes < 10 ? "0" + minutes : 
        (seconds == 60 ? minutes + 1 : minutes)) + ":" + 
      (seconds < 10 ? "0" + seconds : 
        (seconds == 60 ? "00" : seconds)));
  }

  public getAuctionDays(offer) {
    var delta = offer.auctionDuration;
    return Math.floor(delta / 86400);
  }

  public getAuctionHours(offer) {
    var delta = offer.auctionDuration;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    return Math.floor(delta / 3600) % 24;
  }

  public getAuctionMinutes(offer) {
    var delta = offer.auctionDuration;

    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    return Math.floor(delta / 60) % 60;
  }

  public getRemainingAuctionTimeStr(offer): string {
    if (offer.offerState != OfferState.OPEN) {
      return "";
    }

    // get total seconds between the times
    var delta = this.getAuctionSeconds(offer);

    if (delta <= 0) {
      return "Veiling gesloten";
    } else {
      // calculate (and subtract) whole days
      var days = Math.floor(delta / 86400);
      delta -= days * 86400;

      // calculate (and subtract) whole hours
      var hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;

      // calculate (and subtract) whole minutes
      var minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;

      // what's left is seconds
      var seconds = Math.ceil(delta % 60);

      return (
        (days == 0 ? "" : days + "d ") + 
        (hours < 10 ? "0" + hours : hours) + ":" + 
        (minutes < 10 ? "0" + minutes : 
          (seconds == 60 ? minutes + 1 : minutes)) + ":" + 
        (seconds < 10 ? "0" + seconds : 
          (seconds == 60 ? "00" : seconds)));
    }
  }

  public async isLoggedIn(): Promise<boolean> {
    return await this.getJwt().then((jwt) => {
      var exp = jwt["exp"];
      var currentTime = new Date().getTime() / 1000;
      var loggedIn = exp - currentTime > 0;

      return loggedIn;
    }).catch((_) => {
      return false;
    });
  }

  public async getLoggedInAccountId(): Promise<string> {
    return await Preferences.get({ key: "accountId" }).then((result) => {
      return result.value;
    });
  }

  public async getStripeAccountId(): Promise<string> {
    return await Preferences.get({ key: "stripeAccountId" }).then((result) => {
      return result.value;
    });
  }

  public async getStripeClientSecret(): Promise<string> {
    return await Preferences.get({ key: "stripeSessionId" }).then((result) => {
      return result.value;
    });
  }

  public async setJwt(jwt: string) {
    var decoded = jwtDecode(jwt);
    var exp = decoded["exp"];
    var currentTime = new Date().getTime() / 1000;

    if (exp - currentTime > 0) {
      Preferences.set({
        key: "jwt",
        value: jwt
      });

      Preferences.set({
        key: "accountId",
        value: decoded["id"]
      });

      if (decoded["stripeAccountId"]) {
        Preferences.set({
          key: "stripeAccountId",
          value: decoded["stripeAccountId"]
        });
      }

      if (decoded["stripeSessionId"]) {
        Preferences.set({
          key: "stripeSessionId",
          value: decoded["stripeSessionId"]
        });
      }
    }
  }

  public async deleteJwt() {
    Preferences.remove({ key: "jwt" });
  }

  public async getJwt(): Promise<string> {
    const ret = await Preferences.get({ key: "jwt" });
    return ret.value;
  }

  public cleanElements() {
    var body = document.getElementsByTagName("body")[0];
    var children = body.children;

    var toDelete = [];

    for (var i = 0; i < children.length; i++) {
      var grandChildren = children[i].children;

      for (var j = 0; j < grandChildren.length; j++) {
        if (grandChildren[j].tagName == 'ONS-ALERT-DIALOG') {
          toDelete.push(children[i]);
        }
      }
    }

    for (var i = 0; i < toDelete.length; i++) {
      toDelete[i].remove();
    }
  }
}
